import React, { useState, useRef, useEffect } from 'react';
import { Carousel,Spin, Tabs, Steps, Result, Select, Table, Popover, message, Popconfirm, Input, Switch } from 'antd';
import {Row, Col, Form, Button} from "react-bootstrap";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as com from "../Common";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import AddressAutocompleteWithGPS from "./AddressAutocompleteWithGPS";
import * as st from "../State";
import { CommaizedInput } from "../NumericalInput";
import "./GMCCProgramEngine.css";
import FlyerGenerator from './FlyerGenerator';
import MarketByProgramCheck from './CheckComponents/MarketByProgramCheck/MarketByProgramCheck';
import { set } from 'lodash';
import { useStatsigClient } from "@statsig/react-bindings/src";
import * as searchLogs from "./SearchLogs";

const matrices = require("./ProgramEngineMatrices.json");
 
export const programs_templateMap = {
    "GMCC - Diamond Community Lending": "Template_diamond.png",
    "GMCC - Celebrity $10-15K Grant": "Template_15K.png",
    "GMCC - Celebrity $5K Grant": "Template_5K.png",
    "GMCC - Universe CRA Outreach": "Template_cra_outreach.png",
    "GMCC - Grandslam": "Template_homeRun.png",
    "GMCC - Jumbo CRA": "Template_jumboCRA.png",
    "GMCC - Celebrity CRA Community Opportunity": "Template_0down.png",
    "GMCC - Radiant": "Template_radiant.png",
    "GMCC - Hermes": "Template_hermes.png",
    "GMCC - Jumbo Express": "Template_jumboExpress.png",
    "GMCC - Celebrity $5K Grant": "Template_5K.png",
    "GMCC - Celebrity $10K Grant": "Template_10K.png",
    "GMCC - Celebrity $15K Grant": "Template_15K.png",
    "GMCC - Celebrity $30K Grant": "Template_30K.png",
    "GMCC - Community Opportunity": "Template_0down.png",
    "GMCC - Universe": "Template_40down.png",
    "GMCC - Buy Without Sell First": "Template_buyWithoutSell.png",
    "GMCC - Foreign Nationals": "Template_foreigner.png",
    "GMCC - Celebrity Jumbo": "Template_affluent.png",
    "GMCC - Bank Statement Self Employed": "Template_bankStatement.png",
    "GMCC - DSCR Rental Flow": "Template_DSCR.png",
    "GMCC - WVOE P&L": "Template_WVOE_PL.png",
    "GMCC - Community Special": "Template_specialPurchase.png",
}

export const programs_pricesheetMap = {
    "GMCC - Universe": "Ratesheet_Universe.png",
    "GMCC - Hermes": "Ratesheet_Hermes.png",
    "GMCC - Radiant": "Ratesheet_Radiant.png",
    "GMCC - Universe CRA Outreach": "Ratesheet_Outreach.png",
    "GMCC - Celebrity $5K Grant": "Celebrity",
    "GMCC - Celebrity $10K Grant": "Celebrity",
    "GMCC - Celebrity $15K Grant": "Celebrity",
    "GMCC - Celebrity $30K Grant": "Celebrity",
    "GMCC - Community Opportunity": "Celebrity",
    // Requested by Kevin on Nov 4, 2024, the ratesheet for Diamond Community Lending is Epps
    "GMCC - Diamond Community Lending": "Epps", 
    "GMCC - Bank Statement Self Employed": "Loannex",
    "GMCC - Buy Without Sell First": "Multi",
    "GMCC - WVOE P&L": "Loannex",
    "GMCC - DSCR Rental Flow": "Loannex",
    "GMCC - Community Special": "Epps",
    "GMCC - Grandslam": "Epps"    
}

// 


const GMCCWishList = {
    AZ: {
        "Maricopa County": ["Phoenix"],
    },
    CA: {
      "Santa Clara County": ["San Jose","Santa Clara","Cupertino","Milpitas", "Mountain View", "Gilroy"],
      "San Francisco County": ["San Francisco"],
      "San Mateo County": ["San Mateo", "Burlingame"],
      "Los Angeles County": ["Rowland Heights", "San Gabriel", "Diamond Bar", "El Monte", "Pomona", "Compton", "La Puente", "Pasadena"],
      "San Diego County": ["San Diego","Carlsbad","San marcos","Escondido","Poway"],
      "Orange County": ["Santa Ana", "Anaheim","Irvine"],
      "San Bernardino County": ["Chino Hills"],
      "Ventura County": ["Oxnard"],
      "Alameda County": ["Livermore", "Berkeley", "Oakland"],
      "Sacramento County": ["Elk Grove", "Sacramento"],
      "Placer County": ["Roseville"],
      "El dorado": ["El dorado"],
      "San Bernadino County": ["Upland","Rancho Cucamonga"],
      "Riverside":["Moreno Valley","Riverside","Palm Springs"],
      "San Benito County": ["Hollister"],
      "Contra Costa County": ["Martinez"],
    },
    LA: {
        "Orleans": ["New Orleans"],
    },
    NJ: {
        "Essex County": ["Newark"],
        "Hudson County": ["Jersey City", "Union City"],
        "Middlesex County": ["Edison","New Brunswick"],
        "Hunterdon County": ["Clinton"],
        "Morris County": ["Denville"],
        "Sussex County": ["Sussex"],
        "Union County": ["Cranford"],
    },
    CO: {
        "Denver County": ["Denver"],
    },
    VA: {
        "District of Columbia": ["Arlington"],
        "Clarke County": ["Boyce"],
        "Fairfax County": ["Arlington"],
    },
    GA: {
      "Fulton County": ["Atlanta"],
      "Chatham County": ["Savannah"],
      "Gwinnett County": ["Duluth", "Norcross"],
    },
    MA: {
      "Norfolk County": ["Randolph"],
      "Suffolk County": ["Revere", "Dorchester", "Chelsea", "Boston"],
      "Essex County": ["Lawrence", "Lynn"],
      "Middlesex County": ["Burlington", "Ashland"]
    },
    MI: {
        "Wayne County": ["Detroit"],
    },
    NC: {
      "Mecklenburg County": ["Charlotte"], 
      "Wake County": ["Raleigh"],
      "Guilford County": ["Greensboro"]
    },
    SC: {
      "Charleston County": ["Charleston"]
    },
    WA: {
      "King County": ["Seattle", "Bellevue"]
    },
    WV: {
        "Jefferson County": ["Ranson"]
    },
    TX: {
      "Dallas County": ["Dallas"],
      "Travis County": ["Austin"],
    //   "Collin County": ["Frisco", "Plano"],
    //   "Denton County": ["Frisco"],
      "Harris County": ["Houston"],
      "Fort Bend County": ["Sugar Land"],
      "Collin County": ["Anna", "Plano"],
      "Denton County": ["Sanger"],
      "Hays County": ["Kyle"],
      "Williamson County": ["Taylor"],
      "Hunt County": ["Campbell"],
      "Bexar County": ["San Antonio"],
    },
    NY:{
        "Nassau County": ["Freeport","Syosset","Plainview","Hicksville","Levittown","Garden City","Great Neck","Hempstead"],
        "Kings County": ["Brooklyn", "Crown Heights", "Flatbush"],
        "Queens County": ["Flushing", "Corona","Bayside"],
        "New York County": ["Manhattan"],
        "Richmond County": ["Staten Island", "Sunnyside"],
        "Suffolk County": ["Riverhead","Long Island"],
        "Rockland County": ["Spring Valley"],
    },
    NV: {
      "Clark County": ["Las Vegas"]
    },
    OR: {
        "Multnomah County": ["Portland"],
    },
    FL: {
        "Miami-Dade County": ["Miami"],
        "Palm Beach County": ["Boynton Beach"],
        "Broward County": ["Sunrise"],
        "Hillsborough County": ["Tampa"],
        "Broward County": ["Pembroke Pines"],
    },
    UT: {
        "Summit County": ["Park City"],
    },
    MD: {
        "Frederick County": ["Frederick"],
        "Charles County": ["Hughesville"],
        "Calvert County": ["Solomons"],
        "Montgomery County": ["Clarksburg"],
    },
    HI: {
        "Honolulu County": ["Honolulu"],
    },
    IL: {
        "Cook County": ["Chicago","Oak Park"],
        "DuPage County": ["Naperville","Aurora"],
        "Kane County": ["Gilberts","Elburn"],
        "Will County": ["Beecher","Manhattan"],
        "McHenry County":["Richmond","Mchenry"],
    }
  };

  const MSAtoNameMap = {
    "11244": "Anaheim-Santa Ana-Irvine",
    "31084": "Los Angeles-Long Beach-Glendale",
    "41940": "San Jose-Sunnyvale-Santa Clara",
    "41884": "San Francisco-San Mateo-Redwook City",
    "36084": "Oakland-Berkeley-Livermore",
    "37100": "Oxnard-Thousand Oaks-Ventura",
    "33124": "Miami-Miami Beach-Kendall",
    "35004": "Nassau-Suffolk",
    "35084": "Newark NJ-PA",
    "19740": "Denver-Aurora-Lakewood",
    "47894": "Washington-Arlington-Alexandria",
    "12420": "Austin-Round Rock-Georgetown",
    "19124": "Dallas-Plano-Irving",
    "14860": "Bridgeport-Stamford-Norwalk",
    "48424": "West Palm Beach-Boca Raton-Boynton Beach",
    "23224": "Frederick-Gaithersburg-Rockville",
    "35614": "New York-Jersey City-White Plains",
    "22744": "Ft.Lauderdale-Pompano Beach-Sunrise",
    "41740": "San Diego-Chula Vista-Carlsbad",
    "16984": "Chicago-Naperville-Evanston",
  }

  const MSATypetoNameMap = {
    "conforming":[
        {value: "31084", label: "(CA 31084) "+ MSAtoNameMap["31084"]},
        {value: "41940", label: "(CA 41940) "+ MSAtoNameMap["41940"]},
        {value: "41884", label: "(CA 41884) "+ MSAtoNameMap["41884"]},
        {value: "36084", label: "(CA 36084) "+ MSAtoNameMap["36084"]},
        {value: "19124", label: "(TX 19124) "+ MSAtoNameMap["19124"]},
        {value: "12420", label: "(TX 12420) "+ MSAtoNameMap["12420"]},
        {value: "35004", label: "(NY 35004) "+ MSAtoNameMap["35004"]},
        {value: "33124", label: "(FL 33124) "+ MSAtoNameMap["33124"]},
        {value: "35084", label: "(NJ 35084) "+ MSAtoNameMap["35084"]},
        {value: "19740", label: "(CO 19740) "+ MSAtoNameMap["19740"]},
        {value: "47894", label: "(DC,MD,VA,WV 47894) "+ MSAtoNameMap["47894"]},
    ],
    "nonagency":[
        {value: "31084", label: "(CA 31084) "+ MSAtoNameMap["31084"]},
        {value: "41940", label: "(CA 41940) "+ MSAtoNameMap["41940"]},
        {value: "41884", label: "(CA 41884) "+ MSAtoNameMap["41884"]},
        {value: "11244", label: "(CA 11244) "+ MSAtoNameMap["11244"]},
        {value: "36084", label: "(CA 36084) "+ MSAtoNameMap["36084"]},
        {value: "37100", label: "(CA 37100) "+ MSAtoNameMap["37100"]},
        {value: "33124", label: "(FL 33124) "+ MSAtoNameMap["33124"]},
        {value: "22744", label: "(FL 22744) "+ MSAtoNameMap["22744"]},
        {value: "48424", label: "(FL 48424) "+ MSAtoNameMap["48424"]},
        {value: "23224", label: "(MD 23224) "+ MSAtoNameMap["23224"]},
        {value: "35004", label: "(NY 35004) "+ MSAtoNameMap["35004"]},
        {value: "35614", label: "(NY,NJ 35614) "+ MSAtoNameMap["35614"]},
        {value: "35084", label: "(NJ 35084) "+ MSAtoNameMap["35084"]},
        {value: "47894", label: "(DC,VA 47894) "+ MSAtoNameMap["47894"]},
        {value: "14860", label: "(CT 14860) "+ MSAtoNameMap["14860"]},
    ],
    "grandslam":[
        {value: "11244", label: "(CA 11244) "+ MSAtoNameMap["11244"]},
        {value: "31084", label: "(CA 31084) "+ MSAtoNameMap["31084"]},
        {value: "36084", label: "(CA 36084) "+ MSAtoNameMap["36084"]},
        {value: "41740", label: "(CA 41740) "+ MSAtoNameMap["41740"]},
        {value: "41940", label: "(CA 41940) "+ MSAtoNameMap["41940"]},
        {value: "37100", label: "(CA 37100) "+ MSAtoNameMap["37100"]},
        {value: "22744", label: "(FL 22744) "+ MSAtoNameMap["22744"]},
        {value: "48424", label: "(FL 48424) "+ MSAtoNameMap["48424"]},
        {value: "33124", label: "(FL 33124) "+ MSAtoNameMap["33124"]},
        {value: "35084", label: "(NJ 35084) "+ MSAtoNameMap["35084"]},
        {value: "35614", label: "(NJ,NY 35614) "+ MSAtoNameMap["35614"]},
        {value: "16984", label: "(IL 16984) "+ MSAtoNameMap["16984"]},
        {value: "35004", label: "(NY 35004) "+ MSAtoNameMap["35004"]},
    ]
  }

  const MSAtoCountyList_conforming = {
    CA: {
        "31084": ["Los Angeles County"],
        "41940": ["Santa Clara County", "San Benito County"],
        "41884": ["San Francisco County", "San Mateo County"],
        "36084": ["Alameda County", "Contra Costa County"],
    },
    TX: {
        "19124": ["Dallas County", "Hunt County", "Collin County", "Denton County"],
        "12420": ["Travis County", "Hays County", "Travis County", "Williamson County"],
    },
    NY: {
        "35004": ["Nassau County","Queens County", "Suffolk County"],
    },
    FL: {
        "33124": ["Miami-Dade County"],
    },
    NJ: {
        "35084": ["Essex County", "Hunterdon County", "Morris County", "Sussex County", "Union County"],
    },
    CO: {
        "19740": ["Denver County"],
    },
    VA: {
        "47894": ["District of Columbia", "Fairfax County", "Clarke County"],
    },
    WV: {
        "47894": ["Jefferson County"],
    }
  }

  const MSAtoCountyList_nonagency = {
    CA: {
        "31084": ["Los Angeles County"],
        "41940": ["Santa Clara County"],
        "41884": ["San Francisco County", "San Mateo County"],
        "11244": ["Orange County"],
        "36084": ["Alameda County", "Contra Costa County"],
        "37100": ["Ventura County"],
    },
    FL: {
        "33124": ["Miami-Dade County"],
        "22744": ["Broward County"],
        "48424": ["Palm Beach County"],
    },
    MD: {
        "23224": ["Frederick County", "Montgomery County"],
    },
    NY: {
        "35004": ["Nassau County", "Queens County", "Suffolk County"],
        "35614": ["Kings County", "Queens County", "New York County", "Richmond County"],
    },
    NJ:{
        "35084": ["Essex County"],
        "35614": ["Hudson County"],
    },
    VA: {
        "47894": ["District of Columbia", "Fairfax County"],
    }
  }

  const MSAtoCountyList_grandslam = {
    CA:{
        "11244": ["Orange County"],
        "31084": ["Los Angeles County"],
        "36084": ["Alameda County", "Contra Costa County"],
        "41740": ["San Diego County"],
        "41940": ["Santa Clara County"],
        "37100": ["Ventura County"],
    },
    FL:{
        "22744": ["Broward County"],
        "48424": ["Palm Beach County"],
        "33124": ["Miami-Dade County"],
    },
    NJ:{
        "35084": ["Essex County"],
        "35614": ["Hudson County"],
    },
    IL:{
        "16984": ["Cook County","DuPage County","McHenry County","Will County","Kane County"],
    },
    NY: {
        "35614": ["Kings County", "Queens County", "New York County", "Richmond County"],
        "35004": ["Nassau County","Queens County", "Suffolk County"],
    }
  }

const GMCCProgramEngine = (props) => {
    
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [targetMsa, setTargetMsa] = useState("");
    const [msaType, setMsaType] = useState("");
    const [currentFilterType, setCurrentFilterType] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchFastResults, setSearchFastResults] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [foreignVisa, setForeignVisa] = useState("notforeigner");
    const [documentLevel, setDocumentLevel] = useState("fulldoc");
    const [docVerification, setDocVerification] = useState("wageEarnerWVOE");
    const [liquidassets, setLiquidassets] = useState("0");
    const [nonliquidassets, setNonliquidassets] = useState("0");
    const [propertyAddress, setPropertyAddress] = useState("");
    const [displayedAddress, setDisplayedAddress] = useState("");
    const [gpsDisplayedAddress, setGPSDisplayedAddress] = useState("");
    const [GPSsearchClicked, setGPSsearchClicked] = useState(false);
    const [propertyState, setPropertyState] = useState("");
    const [propertyCounty, setPropertyCounty] = useState("");
    const [propertyCity, setPropertyCity] = useState("");
    const [propertyZip, setPropertyZip] = useState("");
    const [units, setUnits] = useState("1");
    const [fico, setFico] = useState(780);
    const [citizenship, setCitizenship] = useState("");
    const [purpose, setPurpose] = useState("purchase");
    const [occupancy, setOccupancy] = useState("primary");
    const [visa, setVisa] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("500000");
    const [downPayment, setDownPayment] = useState("100000");
    const [cashOutAmount, setCashOutAmount] = useState("");
    const [propertywithMortgage, setPropertywithMortgage] = useState("withoutMortgage");
    const [income, setIncome] = useState("100000");
    const [employment, setEmployment] = useState("wageEarner");
    const [loanLimits, setLoanLimits] = useState({})
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showResult, setShowResult] = useState(null)
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loanLimit, setLoanLimit] = useState({});
    const [medianIncome, setMedianIncome] = useState(0);
    const [incomeIndicator, setIncomeIndicator] = useState(0);
    const [showPropertyInfo, setShowPropertyInfo] = useState(false)
    const [lAmount, setLAmount] = useState(0)
    const [LTV, setLTV] = useState(0)
    const [mfi, setMfi] = useState(0)
    const [downPercent, setDownPercent] = useState(0)
    const [isHighCostArea, setIsHighCostArea] = useState(false);
    const [fromFast, setFromFast] = useState(false);
    const [minority, setMinority] = useState(0);
    const [searchOption, setSearchOption] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [monthlypayment, setMonthlypayment] = useState(0);
    const [assetIncome, setAssetIncome] = useState(0);
    const [depletionAsset, setDepletionAsset] = useState(0);
    const [firstTimeHomeBuyer, setFirstTimeHomeBuyer] = useState(false);
    const [firstGenHomeBuyer, setFirstGenHomeBuyer] = useState(false);
    const [hasListProperty, setHasListProperty] = useState("false");
    const [totalAssetAmount, setTotalAssetAmount] = useState(0);
    const [sufficientEquity, setSufficientEquity] = useState("false");
    const [loadingTractData, setLoadingTractData] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [nmls, setNMLS] = useState("");
    const [phone, setPhone] = useState("");
    const [totaldebts, setTotalDebts] = useState(0);
    const [fastCurrentSlide, setFastCurrentSlide] = useState(0);
    const [chosenMode, setChosenMode] = useState("fast");
    const [advanceCurrentSlide, setAdvanceCurrentSlide] = useState(0);
    const [advancedResultCurrentSlide, setAdvancedResultCurrentSlide] = useState(0);
    const [fastFlierSelectOptions, setFastFlierSelectOptions] = useState([]);
    const [flierChosen, setFlierChosen] = useState({
        value: "",
        label: "Select a program",
        status:"default",
        hasFlier: false
    });
    const [dreID, setDreID] = useState("");
    const [haveDRE, setHaveDRE] = useState(false);
    const [advancedFlierSelectOptions, setAdvancedFlierSelectOptions] = useState([]);
    const [qualifiedCounts, setQualifiedCounts] = useState(0);
    const [isRentalFlow, setIsRentalFlow] = useState("false");
    const [rentalIncome, setRentalIncome] = useState(0);
    const [DSCRRatio, setDSCRRatio] = useState(0);
    const [closingCost, setClosingCost] = useState(0);
    const [DTA, setDTA] = useState(0);
    const [monthDebt, setMonthDebt] = useState(0);
    const [hasFICO, setHasFICO] = useState("true");
    const [calcDTI, setCalcDTI] = useState(0);
    const [depletionDTI, setDepletionDTI] = useState(0);
    const [noDepletion, setNoDepletion] = useState(false);
    const [notSufficient, setNotSufficient] = useState(false);
    const [liscenseStates, setLiscenseStates] = useState([]);
    const [ageUnder59, setAgeUnder59] = useState("true");
    const [listingData, setListingData] = useState([]);
    const [massivePropertyCity, setMassivePropertyCity] = useState("");
    const [massivePropertyState, setMassivePropertyState] = useState("California");
    const [massivePropertyCounty, setMassivePropertyCounty] = useState("");
    const [originalListingData, setOriginalListingData] = useState([]);
    const [showListingFilter, setShowListingFilter] = useState(false);
    const [listingTarget, setListingTarget] = useState("");
    const [searchMassiveResults, setSearchMassiveResults] = useState([]);
    const [massiveResultsCount, setMassiveResultsCount] = useState(0);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 9,
        showSizeChanger: false,
    });
    const [currentSortType, setCurrentSortType] = useState("price");
    const [listingUnavailable,setListingUnavailable] = useState(false);
    const [totalPopulation, setTotalPopulation] = useState(0);
    const [hispanic, setHispanic] = useState(0);
    const [black, setBlack] = useState(0);
    const [asian, setAsian] = useState(0);
    const [pacific, setPacific] = useState(0);
    const [msaCode, setMsaCode] = useState(0);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [massiveCityOptions, setMassiveCityOptions] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [realtorName, setRealtorName] = useState("");
    const [realtorID, setRealtorID] = useState("");
    const [realtorPhone, setRealtorPhone] = useState("");
    const [realtorEmail, setRealtorEmail] = useState("");
    const [realtorInfoFilled, setRealtorInfoFilled] = useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [searchRadius, setSearchRadius] = useState(5);
    const [hoverRecord, setHoverRecord] = useState(null);
    const [GPSMsaType, setGPSMsaType] = useState("conforming");
    const [GPSTargetMsa, setGPSTargetMsa] = useState("31084");
    const [grantState, setGrantState] = useState("California");
    const [diamondCity, setDiamondCity] = useState("");
    const [currentTotalFilters, setCurrentTotalFilters] = useState([]);
    const [currentFilter1, setCurrentFilter1] = useState([]);
    const [currentFilter2, setCurrentFilter2] = useState("");
    const carouselRef = useRef(null);
    const fastCarouselRef = useRef(null);
    const advanceCarouselRef = useRef(null);
    const advancedResultCarouselRef = useRef(null);
    const massiveCarouselRef = useRef(null);
    const gpsCarouselRef = useRef(null);
    const massiveResultCarouselRef = useRef(null);
    const { client } = useStatsigClient();
    const timerRef = useRef(null);

    // search history related
    const [showAdvancedCheckSearchLogs, setShowAdvancedCheckSearchLogs] = useState(false);
    const advancedCheckSearchLogsRef = useRef(null);

    //check if screenwidth is less than 875px
    const isPad = window.innerWidth < 875;

    const getProfile = () => {
        let token = sessionStorage.getItem("ZeitroA");
        fetch("/los/getprofilesetup", {
          cache: 'no-cache',
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "X-CustomerID": com.getCustomerId(),
            "X-LoID": com.getLoId(),
          },
        }).then(response => {
          if (!response.ok) {
            console.log(response)
          } else {
            response.json().then(js => {
              let lo = js
              let su = lo.LandingPageSetUp
              if (typeof su.States === "undefined"){
                setLiscenseStates([])
              } else {
                setLiscenseStates(su.States)
              }
              let name = lo.FirstName + " " + lo.LastName
              setName(name)
              setEmail(lo.Email)
              setNMLS(lo.NMLS)  
              setPhone(lo.Phone)
            })
          }
        }).catch(error => {
          console.log("Revalidate catch error " + error)
        })
      };
 // california 10k now
 //MA  15K
  const fiveK_states = ["South Carolina", "North Carolina", "Georgia"]
  const tenK_states = ["California"]
  const fifteenK_states = ["Massachusetts"]
  const zeroDown_states = ["California", "Massachusetts", "North Carolina", "South Carolina", "Virginia", "Georgia"]
  const affluent_states = ["California", "Massachusetts", "North Carolina", "South Carolina", "Virginia"]
  const universe_states = ["California", "Massachusetts","Washington" ,"Texas", "Georgia", "Nevada"]
  const buyWithoutSell_states = "Nationwide"
  const communityBenefits_states = "Nationwide"
  const radiant_states = ["California","Hawaii" ,"Illinois","New Jersey","Nevada", "New York"]
  const bankstatement_states_VVOE = ["California", "Texas","Georgia","Illinois","Virginia","Washington","New Jersey","Colorado"]
  const hermes_states = ["California", "Massachusetts", "Georgia","Illinois" ,"Texas","Washington","New Jersey","Nevada","Colorado","Virginia", "New York"]
  const thirtyK_qualifiedCounties = {
    "Massachusetts": ["Essex", "Middlesex", "Norfolk", "Suffolk"],
  }

  const tenK_qualifiedCounties = {
    "California": ["Contra Costa", "Los Angeles", "Napa", "Orange", "Placer", "Riverside", "Sacramento", "San Bernardino", "San Diego", "San Francisco", "San Mateo", "Santa Clara", "Ventura"]
  }
  const zeroDown_qualifiedCounties = {
    "California": ["San Francisco", "San Mateo", "Santa Clara", "Sacramento", "Contra Costa","Los Angeles", "Orange", "Riverside", "San Bernardino", "San Diego", "Placer", "Napa", "Ventura"],
    // "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Massachusetts": ["Essex", "Middlesex", "Norfolk", "Suffolk"],
    "North Carolina": [],
    "South Carolina": [],
    "Georgia":[],
    "Virginia": ["Albermarle","Alleghany","Arlington","Augusta","Bedford","Botetourt","Brunswick","Campbell","Carroll","Charlottesville City","Chesterfield","Covington City","Danville City",
                "Emporia City","Fairfax","Fairfax City","Frederick","Fredericksburg City","Galax City","Greensville","Harrisonburg City","Henrico","Loudoun","Lunenburg","Lynchburg City",
                "Mecklenburg","Montgomery","Nottoway","Pittsylvania","Roanoke","Roanoke City","Rockingham","Salem City","Spotsylvania","Staunton City","Warren","Waynesboro City","Winchester City"],
  }  
  const outreach_qualifiedCounties ={
    "California": ["San Franciso", "Alameda", "San Mateo","Santa Clara", "Sacramento", "Constra Costa", "Los Angeles", "Orange", "Riverside","San Bernardino", "San Diego"],
    "Massachusetts": ["Middlesex", "Norfolk", "Suffolk"],
    "Texas": ["Collin", "Dallas", "Fort Bend", "Harris"],
    "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Nevada": ["Clark"],
    "Washington": ["King", "Snohomish", "Pierce"],
    "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond"]
  }

  const cronus_qualifiedCounties = {
    "California": [
      "Alameda",
      "Contra Costa",
      "Fresno",
      "Los Angeles",
      "Kern",
      "Kings",
      "Marin",
      "San Francisco",
      "San Mateo",
      "Merced",
      "Orange",
      "Sacramento",
      "Riverside",
      "San Bernardino",
      "San Diego",
      "Santa Clara",
      "Santa Cruz",
      "San Joaquin",
      "Stanislaus",
      "Ventura"
    ],
    "Connecticut": ["Fairfield"],
    "Florida": ["Broward", "Miami-Dade", "Palm Beach", "Duval"],
    "Illinois": ["Cook", "DuPage", "Kane", "Lake", "McHenry", "Will"],
    "Maryland": ["Montgomery", "Prince George's"],
    "Nevada": ["Clark"],
    "New Jersey": ["Bergen", "Hudson", "Passaic", "Essex"],
    // "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond", "Rockland", "Westchester", "Nassau", "Suffolk"],
    "South Dakota": ["Lincoln", "Turner", "McCook", "Minnehaha"],
    "Virginia": ["Arllington", "Alexandria", "Fairfax", "Fairfax City", "Falls Church"],
    "Washington": ["Columbia"],
    "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond", "Rockland", "Westchester", "Nassau", "Suffolk"]
  }
  const universe_qualifiedCounties = {
    "California": ["San Francisco", "Alameda", "San Mateo", "Santa Clara", "Sacramento", "Contra Costa", "Los Angeles", "Orange", "Riverside", "San Bernardino", "San Diego"],
    "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
    "Massachusetts": ["Middlesex", "Norfolk", "Suffolk"],
    "Nevada": ["Clark"],
    "Texas": ["Collin", "Dallas", "Fort Bend", "Harris"],
    "Washington": ["King", "Pierce", "Snohomish"],
    // "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond"]
  }

  const PROPERTY_TAX = 0.0125;
  const PMI = 0.005;
 
  const fetchLoanLimits = () =>
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res)
      })
      .catch((err) => {
        console.error(err);
      });

  useEffect(() => {
    fetchLoanLimits()
  }, [])

  useEffect(() => {
    getProfile()
  },[])

  useEffect(() => {
    getDTI()
  },[monthDebt, income, monthlypayment])

  useEffect(() => {
    getAssetDepletionDTI()
  },[monthDebt, income, monthlypayment, totalAssetAmount, downPayment, purchasePrice, employment,lAmount, fico])

  useEffect(() => {
    if (purchasePrice !== "" && downPayment !== "") {
      let dp = com.safeParseInt(downPayment);
      let pp = com.safeParseInt(purchasePrice);
      let loanAmount = pp - dp;
      let ltv = (loanAmount*100 / pp).toFixed(0);
      let downpayPercent = 100 - ltv;
      let monthpay = loanAmount * (0.07/12)*Math.pow(1+0.07/12,360)/(Math.pow(1+0.07/12,360)-1)
      let monthtax = pp * PROPERTY_TAX / 12
      let monthpmi = loanAmount * PMI / 12
      let totalmonthpay = monthpay + monthtax + monthpmi
      let dscr = com.safeParseInt(rentalIncome) / totalmonthpay
      let closeCost = com.safeParseInt(purchasePrice) * 0.015
      setMonthlypayment(totalmonthpay)
      setLAmount(loanAmount)
      setLTV(ltv)
      setDSCRRatio(dscr.toFixed(2))
      setDownPercent(downpayPercent)
      setClosingCost(closeCost)
    }
  },[purchasePrice,downPayment, rentalIncome])

  useEffect(() => {
    let assetdepletion = 0 
    let depletionincome = 0
    let totalassets = com.safeParseInt(liquidassets)
    if (ageUnder59 === "false") {
        totalassets = com.safeParseInt(liquidassets) + com.safeParseInt(nonliquidassets)
    }
    assetdepletion = totalassets - downPayment - 9*monthlypayment - 0.015*purchasePrice
    depletionincome = assetdepletion / 120
    setTotalAssetAmount(totalassets)
    setAssetIncome(depletionincome)
    setDepletionAsset(assetdepletion)
  },[downPayment,purchasePrice,liquidassets,nonliquidassets, monthlypayment, income, ageUnder59]);

  useEffect(() => {
    if (employment === "noemployment") {
      setDocumentLevel("altdoc")
      setDocVerification("noincomedoc")
    }

  },[employment])

//   useEffect(() => {
//     if (hasFICO === "false") {
//       setFico(0)
//     }
//   },[hasFICO])

  useEffect(() => {
    let dta = 0;
    let reserves = 12
    if (fico <700){
        reserves = 18
    }
    dta = 100* (com.safeParseInt(totaldebts) + com.safeParseInt(lAmount)) / (totalAssetAmount + com.safeParseInt(purchasePrice) - com.safeParseInt(downPayment) - closingCost - reserves*monthlypayment)
    setDTA(Math.floor(dta))
  },[totaldebts, lAmount, totalAssetAmount, closingCost, monthlypayment, purchasePrice, downPayment, fico])

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            if (propertyAddress.length === 0) {
                return;
            }
            const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
            let type = "";
            let eventData = {
                item_name: "programs_map",
                startTime: new Date,
                timestamp: Date.now(),
                customerid: savedUserInfo.custom.customerid,
                email: savedUserInfo.email,
                state: propertyState,
                address: propertyAddress,
                county: propertyCounty,
                city: propertyCity,
                zip: propertyZip,
                action: "changeProperty"
            };
            if (chosenMode === "fast") {
                type = "fastCheck";
                eventData = {
                    ...eventData,
                    type: type
                }
                client.logEvent("use_programs_map", type, eventData);
            } else if (chosenMode === "advanced") {
                type = "advancedCheck";
                eventData = {
                    ...eventData,
                    type: type
                }
                client.logEvent("use_programs_map", type, eventData);
            }
        }, 100);

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [propertyState, propertyCounty, propertyAddress]);

  useEffect(() => {
    if (propertyState !== "" && propertyCounty !== "") {
      setLoadingTractData(true);
      setTimeout(() => {
        fetch("/data/loanlimitswithincome", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Address: propertyAddress,
            State: propertyState,
            City: propertyCity,
            Zip: propertyZip,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              console.log(res);
              setLoanLimit(1149825);
              setShowError(true);
              setLoadingTractData(false);
              return;
            }
            res.json().then((js) => {
              let conformingLoanLimit = [766550, 981500, 1186350, 1474400];
              let highCost = false;
              let ll = "";
              switch (units) {
                case "1":
                  ll = js.OneUnit;
                  setLoanLimit(js.OneUnit);
                  if (js.OneUnit > conformingLoanLimit[0]) {
                    highCost = true;
                    setIsHighCostArea(true);
                    break;
                  }
                case "2":
                  ll = js.TwoUnit;
                  setLoanLimit(js.TwoUnit);
                  if (js.TwoUnit > conformingLoanLimit[1]) {
                    highCost = true;
                    setIsHighCostArea(true);
                    break;
                  }
                case "3":
                  ll = js.ThreeUnit;
                  setLoanLimit(js.ThreeUnit);
                  if (js.ThreeUnit > conformingLoanLimit[2]) {
                    highCost = true;
                    setIsHighCostArea(true);
                    break;
                  }
                case "4":
                  ll = js.FourUnit;
                  setLoanLimit(js.FourUnit);
                  if (js.FourUnit > conformingLoanLimit[3]) {
                    highCost = true;
                    setIsHighCostArea(true);
                    break;
                  }
                default:
                  break;
              }
              setMfi(js.MedianIncome);
              setIncomeIndicator(js.IncomeIndicator);
              setMinority(js.MinorityPercent);
              setTotalPopulation(js.TotalPopulation);
              setHispanic(js.Hispanic);
              setBlack(js.Black);
              setAsian(js.Asian);
              setPacific(js.Pacific);
              setMsaCode(js.MsaCode);
              setLoadingTractData(false);
            });
          })
          .catch((err) => {
            console.error(err);
            setLoanLimit(1149825);
            setShowPropertyInfo(false);
            setLoadingTractData(false);
            alert(
              "Unfortunately, part of the information for this address is not available at this time. You may still proceed with the search with estimated data on this address."
            );
          });
      }, 200);
    }}, [propertyState, propertyCounty, propertyAddress, displayedAddress, propertyCity, propertyZip])


  useEffect(() => {
    if (propertyState !== "" && propertyCounty !== "") {
        fetch("/data/gethudmedianfamilyincome", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              State: propertyState,
              County: propertyCounty,
            })
          })
            .then((res) => {
              if (!res.ok) {
                console.log(res);
                setLoading(false)
                //comment this set after testing
                setMedianIncome(181300)
                // alert("Server currently cannot get MFI data. Please try again later.")
                return
              }
              res.json().then((js) => {
                setMedianIncome(js.MedianIncome)
              });
            })
            .catch((err) => {
              console.error(err);
              alert("Some internal error happened. Please try again later.")
            });
    }   
  },[propertyState,propertyCounty,propertyAddress, displayedAddress])

  useEffect(() => {
    if (selectedRecord && chosenMode === "gps"){
        handleGPSCheckFormSubmit(new Event('submit'));
    }
  },[selectedRecord])

  const massivePopoverContent = (record) =>(
    <div>
        <div className="previewMSA">
            <div className='previewMSAInfo' style={{width: "80%"}}>
                <div>MSA Code:</div>
                <div>{record.msa}</div>
            </div>
        </div>

        <hr />
        <div className='populationIncomeWrap'>
            <div className="previewPopulation w-100">
                <div className='previewInfoWrap'>
                    <div>Tract Income Level:</div> 
                    {record.incomeindicator === "1" && <div>Low Income Area</div>}
                    {record.incomeindicator === "2" &&<div>Moderate Income Area</div>}
                    {record.incomeindicator === "3" &&<div>Middle Income Area</div>}
                    {record.incomeindicator === "4" &&<div>Upper Income Area</div>}
                </div>
                <div className='previewInfoWrap'>
                    <div>Tract Minority:</div>
                    <div>{Math.floor(record.minority)}%</div>
                </div>

                <div className='previewInfoWrap'>
                    <div>Majority AA/HP:</div>
                    <div>{parseInt(record.hispanic) + parseInt(record.black)} ({(100*(parseInt(record.hispanic) + parseInt(record.black))/record.totalpopulation).toFixed(0)}%)</div>
                </div>

                <div className='previewInfoWrap'>
                    <div>Total Population:</div>
                    <div>{record.totalpopulation}</div>
                </div>
                <div className='previewInfoWrap'>
                    <div>Hispanic Population:</div>
                    <div>{record.hispanic} ({(100*record.hispanic/record.totalpopulation).toFixed(0)}%)</div>
                </div>
                <div className='previewInfoWrap'>
                    <div>Black Population:</div>
                    <div>{record.black} ({(100*record.black/record.totalpopulation).toFixed(0)}%)</div>
                </div>
                <div className='previewInfoWrap'>
                    <div>Asian/Pacific Population:</div>
                    <div>{parseInt(record.asian) + parseInt(record.pacific)} ({(100*(parseInt(record.asian) + parseInt(record.pacific))/record.totalpopulation).toFixed(0)}%)</div>
                </div>
            </div>
        </div>
    </div>
  )

  const getMSAList = (type, state) =>{
    let msaList = [
        <option value="">Skip</option>
    ]
    if (type === "conforming"){
        const stateAbbr = com.convertStateToAbbr(state)
        const countyList = MSAtoCountyList_conforming[stateAbbr]
        if (countyList){
            msaList = Object.keys(countyList).map((key) => {
                return <option value={key}>{key} - {MSAtoNameMap[key]}</option>
            })
            msaList.push(<option value="">Skip</option>)
        } else {
            msaList = []
            msaList.push(<option value="">Unavailable</option>)
        }
    }
    if (type === "nonagency"){
        const stateAbbr = com.convertStateToAbbr(state)
        const countyList = MSAtoCountyList_nonagency[stateAbbr]
        if (countyList){
            msaList = Object.keys(countyList).map((key) => {
                return <option value={key}>{key} - {MSAtoNameMap[key]}</option>
            })
            msaList.push(<option value="">Skip</option>)
        } else {
            msaList = []
            msaList.push(<option value="">Unavailable</option>)
        }
    }
    if (type === "grandslam"){
        const stateAbbr = com.convertStateToAbbr(state)
        const countyList = MSAtoCountyList_grandslam[stateAbbr]
        if (countyList){
            msaList = Object.keys(countyList).map((key) => {
                return <option value={key}>{key} - {MSAtoNameMap[key]}</option>
            })
            msaList.push(<option value="">Skip</option>)
        } else {
            msaList = []
            msaList.push(<option value="">Unavailable</option>)
        }
    }
    return msaList
  }

  const getGPSMSAList = (type) =>{
    console.log("type", type)
    let msaList = []
    if (type === "conforming"){
        msaList = MSATypetoNameMap[type].map((obj) =>{
            return <option value={obj.value}>{obj.label}</option>
        })
    }
    if (type === "nonagency"){
        msaList = MSATypetoNameMap[type].map((obj) =>{
            return <option value={obj.value}>{obj.label}</option>
        })
    }
    if (type === "grandslam"){
        msaList = MSATypetoNameMap[type].map((obj) =>{
            return <option value={obj.value}>{obj.label}</option>
        })
    }
    return msaList
  }

  const handleChangeTargetMsa = (value) => {
    setTargetMsa(value)
    if (msaType === "conforming"){
        setMassivePropertyCounty(MSAtoCountyList_conforming[com.convertStateToAbbr(massivePropertyState)][value][0])   
    }
    if (msaType === "nonagency"){
        setMassivePropertyCounty(MSAtoCountyList_nonagency[com.convertStateToAbbr(massivePropertyState)][value][0])   
    }
    if (msaType === "grandslam"){
        setMassivePropertyCounty(MSAtoCountyList_grandslam[com.convertStateToAbbr(massivePropertyState)][value][0])   
    }
  }

  const mapMsaCountyOptions = (msa, type, state) => {
    let options = [
        <option value="">...</option>
    ]
    if (type === "conforming"){
        MSAtoCountyList_conforming[com.convertStateToAbbr(state)][msa].forEach((county) => {
            options.push(<option value={county}>{county}</option>)
        })
    }
    if (type === "nonagency"){
        MSAtoCountyList_nonagency[com.convertStateToAbbr(state)][msa].forEach((county) => {
            options.push(<option value={county}>{county}</option>)
        })
    }
    if (type === "grandslam"){
        MSAtoCountyList_grandslam[com.convertStateToAbbr(state)][msa].forEach((county) => {
            options.push(<option value={county}>{county}</option>)
        })
    }
    return options
  }

  const getMassiveCountyOptions = (state) => {
    let stateabbr = com.convertStateToAbbr(state)
    let counties = Object.keys(GMCCWishList[stateabbr])
    let countyOptions = []
    countyOptions.push(<option value="">...</option>)
    if (msaType === "celebrity"){
      counties = counties.filter((county) => {
        if (massivePropertyState === "California") {
          return tenK_qualifiedCounties["California"].includes(county.replace(" County", ""))
        } else if (massivePropertyState === "Massachusetts") {
          return thirtyK_qualifiedCounties["Massachusetts"].includes(county.replace(" County", ""))
        }
      })
    }
    for (let county of counties) {
      countyOptions.push(<option value={county}>{county}</option>)
    }

    return countyOptions
  }

  useEffect(() => {
    let stateabbr = com.convertStateToAbbr(massivePropertyState)
    let cities = GMCCWishList[stateabbr][massivePropertyCounty]
    let cityOptions = []
    cityOptions.push(<option value="">...</option>)
    try{
        for (let city of cities){
            cityOptions.push(<option value={city}>{city}</option>)
        }
    }
    catch(err){
        console.log("error in getMassiveCityOptions", err)
    }
    setMassiveCityOptions(cityOptions)
  },[massivePropertyState, massivePropertyCounty])

  const getMassiveCityOptions = (state, county) => {
    
    let stateabbr = com.convertStateToAbbr(state)
    let cities = GMCCWishList[stateabbr][county]
    let cityOptions = []
    cityOptions.push(<option value="">...</option>)
    try{
        for (let city of cities){
            cityOptions.push(<option value={city}>{city}</option>)
        }
    }
    catch(err){
        console.log("error in getMassiveCityOptions", err)
    }
    // for (let city of cities){
    //   cityOptions.push(<option value={city}>{city}</option>)
    // }
    return cityOptions
  }

  const findClosest = (failReasons) =>{
    let min = failReasons[0]
    for (let i = 1; i < failReasons.length; i++){
        if (Object.keys(failReasons[i]).length < Object.keys(min).length){
            min = failReasons[i]
        }
    }
    return min
  }

  const GetReasons = (matrices,lamount, skipDepletion, skipDTI, skipReserves, skipDSCR) => {
    let failReasons = []
    for (let matrix of matrices){
        if (lamount <= matrix["MaxLoanAmount"]){
            let temp = {}
            if (LTV > matrix["MaxLTV"]){
                temp["LTV"] = "LTV TOO HIGH - MAX LTV: " + matrix["MaxLTV"] + "%"
            }
            if (fico < matrix["MinFICO"]){
                temp["FICO"] = "FICO TOO LOW - MIN FICO: " + matrix["MinFICO"] 
            }
            if (!skipDTI && getDTI() > matrix["MaxDTI"]){
                temp["DTI"] = "DTI TOO HIGH - MAX DTI " + matrix["MaxDTI"] + "%"
            }
            if (!skipDepletion && depletionDTI > matrix["MaxDTI"]){
                temp["DTI"] = "DTI TOO HIGH - MAX DTI " + matrix["MaxDTI"] + "%"
            }
            if (totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment < 0 && !skipReserves){
                temp["Reserves"] = "INSUFFICIENT RESERVES - MIN RESERVES: " + matrix["Reserves"] + " MONTH"
            }
            if (!skipDSCR && (DSCRRatio < matrix["DSCRInterval"][0] || DSCRRatio > matrix["DSCRInterval"][1])){
                temp["DSCR"] = "DSCR OUT OF RANGE"
            }
            failReasons.push(temp)
        }
    }
    return failReasons
  }

  const GetReasonsforAgate = (matrices,lamount, skipDepletion, skipDTI, skipReserves, skipDSCR, maxAmount, minAmount) => {
    let failReasons = []
    for (let matrix of matrices){
        if (lamount <= matrix["MaxLoanAmount"]){
            let temp = {}
            if (LTV > matrix["MaxLTV"]){
                temp["LTV"] = "LTV TOO HIGH - MAX LTV: " + matrix["MaxLTV"] + "%"
            }
            if (fico < matrix["MinFICO"]){
                temp["FICO"] = "FICO TOO LOW - MIN FICO: " + matrix["MinFICO"] 
            }
            if (!skipDTI && getDTI() > matrix["MaxDTI"]){
                temp["DTI"] = "DTI TOO HIGH - MAX DTI " + matrix["MaxDTI"] + "%"
            }
            if (!skipDepletion && depletionDTI > matrix["MaxDTI"]){
                temp["DTI"] = "DTI TOO HIGH - MAX DTI " + matrix["MaxDTI"] + "%"
            }
            if (totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment < 0 && !skipReserves){
                temp["Reserves"] = "INSUFFICIENT RESERVES - MIN RESERVES: " + matrix["Reserves"] + " MONTH"
            }
            if (!skipDSCR && (DSCRRatio < matrix["DSCRInterval"][0] || DSCRRatio > matrix["DSCRInterval"][1])){
                temp["DSCR"] = "DSCR OUT OF RANGE"
            }
            if (lamount > maxAmount || lamount < minAmount){
                temp["LoanAmount"] = "LOAN AMOUNT OUT OF RANGE - Max Loan Amount: " + maxAmount + "; Min Loan Amount: " + minAmount
            }
            failReasons.push(temp)
        }
    }
    return failReasons
  }

  const getCurrentLoanInfo = (withDepletion) => {
    let reserves = Math.floor((totalAssetAmount - downPayment - 0.015*purchasePrice)/ monthlypayment)

    return <div className='currentInfoPane'>
        <div className='currentInfoWrap'>
            <div style={{fontSize:"14px",fontWeight:"500", marginBottom:"10px"}}>Current loan info</div>
            <div className='currentInfoItems'>
                <div>Loan amount</div>
                <div>${com.commaize(lAmount)}</div>
            </div>
            <div className='currentInfoItems'>
                <div>LTV:</div>
                <div>{LTV}%</div>
            </div>
            <div className='currentInfoItems'>
                <div>DTI:</div>
                <div>{withDepletion?depletionDTI: calcDTI}%</div>
            </div>
            <div className='currentInfoItems'>
                <div>FICO:</div>
                <div>{fico}</div>
            </div>
            <div className='currentInfoItems'>
                <div>Net assets in reserves:</div>
                <div>{reserves<0?0:reserves} months</div>
            </div>
        </div>
    </div>
  }

  const handleSubmit = (e) => {

    // statsig logEvent
    const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
    client.logEvent("use_programs_map", "advancedCheck", {
        item_name: "useProgramsMap",
        action: "clickSearch",
        startTime: new Date,
        timestamp: Date.now(),
        customerid: savedUserInfo.custom.customerid,
        email: savedUserInfo.email,
        type: "advancedCheck"
    });
    searchLogs.saveAdvancedCheckSearchLog({
        propertyAddress,
        displayedAddress,
        propertyCity,
        propertyState,
        propertyCounty,
        propertyZip,
        purpose,
        occupancy,
        purchasePrice,
        downPayment,
        fico,
        income,
        monthDebt,
        employment,
        documentLevel,
        docVerification,
        liquidassets,
        ageUnder59,
        nonliquidassets,
        foreignVisa,
        firstTimeHomeBuyer,
        firstGenHomeBuyer,
        propertywithMortgage,
        hasListProperty,
        sufficientEquity,
        totalDebts: totaldebts,
        isRentalFlow,
        rentalIncome,
        createdAt: new Date(),
    });

    setTimeout(() => {
      advancedCheckSearchLogsRef.current.fetchLogs();
    }, 1000);

    e.preventDefault();
    e.stopPropagation();

    setShowError(false);
    setLoading(true);
    setFromFast(false);

    let parsedResults = []
    let flierOptions = []
    let doclevel = documentLevel
    let docuverify = docVerification
    if (downPayment !== "" && purchasePrice !== "" && propertyState !== "" && propertyCounty !== "") {
        let dp = com.safeParseInt(downPayment);
        let pp = com.safeParseInt(purchasePrice);
        let loanAmount = pp - dp;
        let currentLTV = (pp-dp)/pp;
        let result_idx = 1
        let option_idx = 0
        if (employment === "noemployment"){
            doclevel = "altdoc"
            docuverify = "noincomedoc"
        }
        if (doclevel === "fulldoc"){
            docuverify = ""
        }
        if (doclevel === "fulldoc") {
            if (occupancy === 'primary' && loanAmount <= 766500){
                //Jumbo express check1
                if (loanAmount >= 400000){
                    let good = false
                    let failReasons = []
                    let min = {}
                    for (let matrix of matrices["JumboExpress_Mercury"]){

                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"]){
                            good = true
                        }
                    }
                    if (!good){
                        
                        failReasons.push(...GetReasons(matrices["JumboExpress_Mercury"],loanAmount, true, false, true, true))
                    }
                    for (let matrix of matrices["JumboExpress_Malachite"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"]){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["JumboExpress_Malachite"],loanAmount, true, false, true, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Jumbo Express",
                        children: <div className='resultTabPane text-center d-flex justify-content-center'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Jumbo Express Program"
                                    subTitle= ""
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Loan amount from $400K to $3M</li>
                                        <li>FICO no lower than 660.</li>
                                        <li>DTI below 49.99%</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Addtional restriction or conditions need to be met subject to Jumbo Express program.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Jumbo Express Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for reasons."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className=" flyerMobileView w-100 " src="/images/gmccflyers/GMCC_jumboExpressFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Jumbo Express",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //standard agency program check2
                if(fico >=680 && downPercent >=3){
                    let good = false
                    let min = {}
                    if (getDTI() <= 43){
                        good = true
                    }
                    if (!good){
                        min = {
                            "DTI": "DTI TOO HIGH - MAX DTI 43%"
                        }
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "Standard agency program",
                        children: <div className='resultTabPane text-center d-flex justify-content-center'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Standard Agency 97% Program"
                                        subTitle= ""
                                    />
                                <div className='text-left'>
                                <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Loan amount under conforming limit.</li>
                                        <li>Down payment over 3%.</li>
                                        <li>FICO no lower than 680.</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>At lease one borrower is first time home buyer.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Standard Agency 97% Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "Standard agency program",
                        status: good ? "pass" : "fail",
                        hasFlier: false
                    })
                }
                //community benefits check3
                if((income <=0.8*mfi || income <= 0.8*medianIncome || incomeIndicator === 1 || incomeIndicator === 2)){
                    let good = false
                    let prMatched = new Set()
                    let failReasons = []
                    let min = {}
                    for (let matrix of matrices["HomeRun"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("HomeRun")
                        }
                    }
                    for (let matrix of matrices["HomeReady"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("HomeReady")
                        }
                    }
                    for (let matrix of matrices["HomePossible"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("HomePossible")
                        }
                    }
                    let matchedArr = Array.from(prMatched)
                    if (matchedArr.length > 0){
                        good = true
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["HomeRun"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["HomeReady"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["HomePossible"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }


                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "Community benefits",
                        children: <div className='resultTabPane text-center d-flex justify-content-center'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the following Community Benefits Programs"
                                    subTitle= {
                                        <div>
                                            {matchedArr.map((pr) => {
                                                return <div className="matchedSubProgramLabel">** {pr} **</div>
                                            })}
                                        </div>
                                    }
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Loan amount under agency limit.</li>
                                        <li>Down payment over 3%.</li>
                                        <li>FICO no lower than 660.</li>
                                        <li>Income lower than 80% AMI or 80% MFI or property located in low-to-moderate area</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Addtional restriction or conditions need to be met subject to HomeRun, HomeReady, and HomePossible program.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Community Benefits Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Community Benefits",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //5k grant check4
                if ( fiveK_states.includes(propertyState) && (income <= mfi*1.5 || (income <= mfi*2 && firstGenHomeBuyer)) && (minority>50 || incomeIndicator === 1 || incomeIndicator === 2)){
                    let good = false
                        let min = {}
                        if (downPercent <= 20 && fico >=620 && lAmount >= 25000 && getDTI() <= 50 && ((totalAssetAmount - downPayment - 0.015*purchasePrice - 12*monthlypayment)<=0)){
                            good = true
                        }
                        if (!good){
                            if (downPercent > 20){
                                min["DownPayment"] = "Down payment can't exceed 20%"
                            }
                            if (lAmount < 25000){
                                min["LoanAmount"] = "Min loan amount $25,000"
                            }
                            if ((totalAssetAmount - downPayment - 0.015*purchasePrice - 12*monthlypayment)>0){
                                min["Asset"] = "Max assets after close is 12 months PITIA"
                            }
                            if (getDTI() > 50){
                                min["DTI"] = "DTI TOO HIGH - Max DTI 50%"
                            }
                            if (fico < 620){
                                min["FICO"] = "FICO TOO LOW - MIN FICO 620"
                            }
                        }

                        
                        parsedResults.push({
                            key: `result ${result_idx}`,
                            label: "GMCC - Celebrity $5k Grant",
                            children: <div className='resultTabPane text-center d-flex'>
                                {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                    <Result
                                            status="success"
                                            title="Your borrower is pre-qualified for the $5K Grant Program"
                                            subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                    />
                                    <div className='text-left'>
                                        <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                        <ul style={{color:"green"}}>
                                            <li>Property located in eligible states.</li>
                                            <li>Loan amount under conforming limit and over $25,000.</li>
                                            <li>FICO no lower than 620.</li>
                                            <li>Income no higher than 150% of MFI, or 200% if first generation home buyer.</li>
                                            <li>Down payment should be at most 20%.</li>
                                            <li>Max DTI 50%.</li>
                                            <li>Max assets after close is 12 months PITIA.</li>
                                        </ul>
                                        <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Can be applied with GMCC Community Opportunity program.</li>
                                            <li>Need to run DU for accurate DTI check.</li>
                                            <li>Up to $5K grant available if qualified.</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>}
                                {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                    <Result
                                        status="warning"
                                        title="Your borrower is likely to qualify for the $5K Grant Program"
                                        subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                    />
                                    {getCurrentLoanInfo()}
                                    <div className='mt-2'>Ineligible reasons:</div>
                                    <div style={{color:"red", marginTop:"10px"}}>
                                        {Object.keys(min).map((key) => {
                                        return <div>{min[key]}</div>
                                        })}
                                    </div>
                                    <div className='text-left programNotesWrap'>
                                        <div className='mb-2'>Note:</div>
                                        <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                    </div>
                                </div>}
                                <div className='flyerMobileView'>
                                    <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_5KFlyer_zeitro.png" />
                                </div>
                            </div>
                        })
                        result_idx+=1
                        option_idx+=1
                        flierOptions.push({
                            value: option_idx,
                            label: "GMCC - Celebrity $5K Grant",
                            status: good ? "pass" : "fail",
                            hasFlier: true
                        })
                }
                //10k grant check5
                if((tenK_qualifiedCounties[propertyState] !== undefined && (tenK_qualifiedCounties[propertyState].length===0|| tenK_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) && (income <= mfi*1.5 || (income <= mfi*2 && firstGenHomeBuyer)) && (minority>50 || incomeIndicator === 1 || incomeIndicator === 2)){
                    let good = false
                    let min = {}
                    if (downPercent <= 20 && fico>=620 && lAmount >= 25000 && getDTI() <= 50 && ((totalAssetAmount - downPayment - 0.015*purchasePrice - 12*monthlypayment)<=0)){
                        good = true
                    }
                    if (!good){
                        if (downPercent > 20){
                            min["DownPayment"] = "Down payment can't exceed 20%"
                        }
                        if (lAmount < 25000){
                            min["LoanAmount"] = "Min loan amount $25,000"
                        }
                        if ((totalAssetAmount - downPayment - 0.015*purchasePrice - 12*monthlypayment)>0){
                            min["Asset"] = "Max assets after close is 12 months PITIA"
                        }
                        if (getDTI() > 50){
                            min["DTI"] = "DTI TOO HIGH - Max DTI 50%"
                        }
                        if (fico < 620){
                            min["FICO"] = "FICO TOO LOW - MIN FICO 620"
                        }
                    }


                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Celebrity $15K Grant",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the $15K Grant Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in selected counties of CA.</li>
                                        <li>Loan amount under conforming limit and over $25,000.</li>
                                        <li>FICO no lower than 620.</li>
                                        <li>Income no higher than 150% of MFI, or 200% if first generation home buyer.</li>
                                        <li>Down payment should be at most 20%.</li>
                                        <li>Max DTI 50%.</li>
                                        <li>Max assets after close is 12 months PITIA.</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Can be applied with GMCC Community Opportunity program.</li>
                                        <li>Need to run DU for accurate DTI check.</li>
                                        <li>Up to $15K available if qualified.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div  className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the $15K Grant Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_15KFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Celebrity $15K Grant",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }

                //30k grant
                if((thirtyK_qualifiedCounties[propertyState] !== undefined && (thirtyK_qualifiedCounties[propertyState].length===0|| thirtyK_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) && (income <= mfi*1.5 || (income <= mfi*2 && firstGenHomeBuyer)) && (minority>50 || incomeIndicator === 1 || incomeIndicator === 2)){
                    let good = false
                    let min = {}
                    if (downPercent <= 20 && fico>=620 && lAmount >= 25000 && getDTI() <= 50 && ((totalAssetAmount - downPayment - 0.015*purchasePrice - 12*monthlypayment)<=0)){
                        good = true
                    }
                    if (!good){
                        if (downPercent > 20){
                            min["DownPayment"] = "Down payment can't exceed 20%"
                        }
                        if (lAmount < 25000){
                            min["LoanAmount"] = "Min loan amount $25,000"
                        }
                        if ((totalAssetAmount - downPayment - 0.015*purchasePrice - 12*monthlypayment)>0){
                            min["Asset"] = "Max assets after close is 12 months PITIA"
                        }
                        if (getDTI() > 50){
                            min["DTI"] = "DTI TOO HIGH - Max DTI 50%"
                        }
                        if (fico < 620){
                            min["FICO"] = "FICO TOO LOW - MIN FICO 620"
                        }
                    }

                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Celebrity $15K Grant",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the $15K Grant Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in MA selected area.</li>
                                        <li>Loan amount under conforming limit and over $25,000.</li>
                                        <li>FICO no lower than 620.</li>
                                        <li>Income no higher than 150% of MFI, or 200% if first generation home buyer.</li>
                                        <li>Down payment should be at most 20%.</li>
                                        <li>Max DTI 50%.</li>
                                        <li>Max assets after close is 12 months PITIA.</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Can be applied with GMCC Community Opportunity program.</li>
                                        <li>Need to run DU for accurate DTI check.</li>
                                        <li>Up to $15K grant available if qualified.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div  className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the $15K Grant Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_15KFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Celebrity $15K Grant",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }

                //0% down check6
                if ( (zeroDown_qualifiedCounties[propertyState] !== undefined && (zeroDown_qualifiedCounties[propertyState].length===0|| zeroDown_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) && (income<=mfi*1.5 || (firstGenHomeBuyer && income<=mfi*2) && (minority>50 || incomeIndicator===1 || incomeIndicator===2))){
                    let good = false
                    let failReasons = []
                    let min = {}
                    for (let matrix of matrices["ZeroDown"]){
                        if ( (totalAssetAmount - downPayment - 0.015*purchasePrice)<= 36*monthlypayment && lAmount>= 25000 && matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["ZeroDown"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Community Opportunity",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Community Opportunity Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Loan amount under agency limit.</li>
                                        <li>FICO no lower than 680.</li>
                                        <li>Income no higher than 150% of MFI.</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Not intended for borrower with assets but don't want to put down more down payment.</li>
                                            <li>If eligible, $5-30K Grant Program also applicable.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Community Opportunity Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_0downFlyer_zeitro.png" />
                            </div>
                        </div>

                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Community Opportunity",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //buy without sell first check7
                if (hasListProperty === "true" && sufficientEquity === "true" && loanAmount >= 150000 && fico >= 660){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Apollo"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Onyx"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Ultimate"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Cronus"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Apollo"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Onyx"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Ultimate"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Cronus"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Buy without sell first",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Buy Without Sell First Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Loan Amount check</li>
                                        <li>Income check</li>
                                        <li>Have property currently listing/intend to sell</li>
                                        <li>Own at least 20% equity of the departuring property</li>
                                        <li>FICO, LTV, DTI, Reserves matrix check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Letter of intent to sell/listing need to be provided</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Buy Without Sell First Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className=" flyerMobileView w-100 " src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Buy Without Sell First",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
            }
            if (loanAmount > 766500){

                //affluent (Celebrity Jumbo) check8
                // if(((totalAssetAmount >= 1.5 * lAmount) && (totalAssetAmount <= 550*monthlypayment)) && affluent_states.includes(propertyState) && loanAmount >=766550 && loanAmount <= 3000000){
                if(affluent_states.includes(propertyState) && loanAmount >=766550 && loanAmount <= 3000000 && totalAssetAmount >= 2*loanAmount){
    
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Affluent"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0) && depletionDTI<=45){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Affluent"],loanAmount, false, true, false, true))
                        min = findClosest(failReasons)
                    }
                
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Celebrity Jumbo with Asset Depletion",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className="resultInfoWrap" style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Celebrity Jumbo Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Max Loan Amount check</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>If exceed max DTI limit on guideline, if assets suffiencient for addtional 10 months PITIA for every 1% the DTI ratio exceeds (if DTI over 50%, min FICO 720 required), can still qualify.</li>
                                            <li>No need income</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className="resultInfoWrap" style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Celebrity Jumbo Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo(true)}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                        {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves for current DTI</div>}
                                        {Object.keys(min).map((key) => {
                                        return <div>{min[key]}</div>
                                        })}
                                    </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_AffluentFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Celebrity Jumbo",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }

                //Jumbo express check9
                if(loanAmount <=3000000){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["JumboExpress_Mercury"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"]){
                            good = true
                        }
                    }
                    for (let matrix of matrices["JumboExpress_Malachite"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"]){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["JumboExpress_Mercury"],loanAmount, true, false, true, true))
                        failReasons.push(...GetReasons(matrices["JumboExpress_Malachite"],loanAmount, true, false, true, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Jumbo Express",
                        children: <div className='resultTabPane text-center d-flex justify-content-center'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Jumbo Express Program"
                                    subTitle= ""
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Loan amount $400K to $3M.</li>
                                        <li>FICO greater than 660.</li>
                                        <li>DTI below 49.99%</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Addtional restriction or conditions need to be met subject to Jumbo Express program.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Jumbo Express Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className=" flyerMobileView w-100 " src="/images/gmccflyers/GMCC_jumboExpressFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Jumbo Express",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //Buy without sell first check9
                if (loanAmount <= 3500000 && fico >= 660 && hasListProperty === "true" && sufficientEquity === "true"){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Apollo"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Onyx"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Ultimate"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Cronus"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Apollo"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Onyx"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Ultimate"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Cronus"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Buy without sell first",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Buy Without Sell First Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Loan Amount check</li>
                                        <li>Income check</li>
                                        <li>Have property currently listing/intend to sell</li>
                                        <li>Own at least 20% equity of the departuring property</li>
                                        <li>FICO, LTV, DTI, Reserves matrix check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Letter of intent to sell/listing need to be provided</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Buy Without Sell First Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Buy Without Sell First",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //community benefits check10
                if(loanAmount <= 1149800){
                    let good = false
                    let prMatched = new Set()
                    let failReasons = []
                    let min = {}
                    for (let matrix of matrices["HomeReady"]){
                        if (income<=0.8*medianIncome && matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("HomeReady")
                        }
                    }
                    for (let matrix of matrices["HomePossible"]){
                        if (income<=0.8*medianIncome && firstTimeHomeBuyer==="true" && matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("HomePossible")
                        }
                    }
                    for (let matrix of matrices["CronusJumbo"]){
                        if (cronus_qualifiedCounties[propertyState] !== undefined && (cronus_qualifiedCounties[propertyState].length===0|| cronus_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim())) && matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("Cronus Jumbo")
                        }
                    }
                    let matchedArr = Array.from(prMatched)
                    if (matchedArr.length>0){
                        good = true
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["HomeReady"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["HomePossible"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["CronusJumbo"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "Community benefits",
                        children: <div className='resultTabPane text-center d-flex justify-content-center'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Community Benefits Program"
                                    subTitle= {
                                        <div>
                                            {matchedArr.map((pr) => {
                                                return <div className="matchedSubProgramLabel">** {pr} **</div>
                                            })}
                                        </div>
                                    }
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Loan amount under programs max loan amount limit.</li>
                                        <li>FICO, LTV, DTI eligibility matrix check passed.</li>
                                        <li>Income lower than 80% AMI or no income restriction.</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Addtional restriction or conditions need to be met subject to HomeReady, HomePossible or Cronus Jumbo CRA program.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Community Benefits Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Community Benefits",
                        status: good ? "pass" : "fail",
                        hasFlier: false
                    })
                }
                //community benefits check11
                if(cronus_qualifiedCounties[propertyState] !== undefined && (cronus_qualifiedCounties[propertyState].length===0|| cronus_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim())) && loanAmount >= 1149801 && loanAmount<= 3000000){
                    let good = false
                    let prMatched = new Set()
                    let failReasons = []
                    let min = {}
                    for (let matrix of matrices["CronusJumbo"]){
                        if ( matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            prMatched.add("Cronus Jumbo")
                        }
                    }
                    let matchedArr = Array.from(prMatched)
                    if (matchedArr.length>0){
                        good = true
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["CronusJumbo"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "Community benefits",
                        children: <div className='resultTabPane text-center d-flex justify-content-center'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Community Benefits Program"
                                    subTitle= {
                                        <div>
                                            {matchedArr.map((pr) => {
                                                return <div className="matchedSubProgramLabel">** {pr} **</div>
                                            })}
                                        </div>
                                    }
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Loan amount under Cronus Jumbo CRA program Max loan amount limit.</li>
                                        <li>FICO, LTV, DTI eligibility matrix check passed.</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Addtional restriction or conditions need to be met subject to Cronus Jumbo program.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Community Benefits Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Community Benefits",
                        status: good ? "pass" : "fail",
                        hasFlier: false
                    })
                }
                // parsedResults.push({
                //     key: `result ${result_idx}`,
                //     label: "GMCC Express",
                //     children: <div className='text-center'>
                //         <div style={{fontSize:"16px", fontWeight:"500"}}>Coming soon</div>
                //     </div>
                // })
                // result_idx+=1
            }
        }    
        if (doclevel ==="altdoc"){
            // DSCR check12
            if ((isRentalFlow === "true" && occupancy === "investment") && loanAmount <= 3000000 && loanAmount >= 7500){
                let good = false
                let failReasons = []
                let min = {}
                for (let prop in matrices["DSCR"]){
                    for (let mat of matrices["DSCR"][prop]){
                        if (mat["PurposeTypes"].includes("PURCHASE") && LTV<= mat["MaxLTV"] && (mat["MinFICO"] !== "foreigner" && fico>= mat["MinFICO"]) && lAmount <= mat["MaxLoanAmount"] && DSCRRatio >= mat["DSCRInterval"][0] && DSCRRatio <= mat["DSCRInterval"][1]){
                            good = true
                            
                        }
                    }
                }
                if (!good){
                    for (let key of Object.keys(matrices["DSCR"])){
                        failReasons.push(...GetReasons(matrices["DSCR"][key],loanAmount, true, false, true, false))
                    }
                    min = findClosest(failReasons)
                }

                
                parsedResults.push({
                    key: `result ${result_idx}`,
                    label: "GMCC - DSCR Rental Flow",
                    children: <div className='resultTabPane text-center d-flex'>
                        {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                            <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the DSCR Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                            />
                            <div className='text-left'>
                                <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                <ul style={{color:"green"}}>
                                    <li>Rental Property</li>
                                    <li>Alternative docs - rental income to qualify</li>
                                    <li>Does not exceed loan amount limit</li>
                                    <li>DSCR ratio no lower than min limit.</li>
                                    <li>LTV, FICO matrix check satisfied</li>
                                </ul>
                                <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                <div style={{fontWeight:"500"}}>Program notes:</div>
                                <ul>
                                    <li>Need 1-2 month bank statement.</li>
                                    <li>Restictions may applied for property listed for sales within certain months.</li>
                                    <li>Need 12 months housing history</li>
                                    <li>Eligible for all citizenships and visas, but with restictions for some case.</li>
                                </ul>
                            </div>
                            </div>
                        </div>}
                        {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                            <Result
                                status="warning"
                                title="Your borrower is likely to qualify for the DSCR Program"
                                subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                            />
                            {getCurrentLoanInfo()}
                            <div className='mt-2'>Ineligible reasons:</div>
                            <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>DSCR Ratio TOO LOW </div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                            </div>
                            <div className='text-left programNotesWrap'>
                                <div className='mb-2'>Note:</div>
                                <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                            </div>
                        </div>}
                        <div className='flyerMobileView'>
                            <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_DSCRFlyer_zeitro.png" />
                        </div>
                    </div>
                })
                result_idx+=1
                option_idx+=1
                flierOptions.push({
                    value: option_idx,
                    label: "GMCC - DSCR Rental Flow",
                    status: good ? "pass" : "fail",
                    hasFlier: true
                })
            }
            //Universe check13
            if (universe_qualifiedCounties[propertyState] !== undefined && (universe_qualifiedCounties[propertyState].length===0|| universe_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim())) && loanAmount <= 2000000 && downPercent >=40){
                let good = false
                let min = {}
                if ( DTA<60 && ((fico >= 700 && ((totalAssetAmount - downPayment - 0.015*purchasePrice) >= 12*monthlypayment)) || (fico<700 && ((totalAssetAmount - downPayment - 0.015*purchasePrice) >= 18*monthlypayment)))){
                    good = true
                }
                if (!good){
                    if (DTA>=60){
                        min["DTA"] = "DTA ratio TOO HIGH - MAX 60%"
                    }
                    if (fico < 700 && ((totalAssetAmount - downPayment - 0.015*purchasePrice) < 18*monthlypayment)){
                        min["Reserves"] = "In-sufficient Reserves - MIN 18 months for FICO < 700"
                    }
                    if (fico >= 700 && ((totalAssetAmount - downPayment - 0.015*purchasePrice) < 12*monthlypayment)){
                        min["Reserves"] = "In-sufficient Reserves - MIN 12 months for FICO >= 700"
                    }
                }
                
                
                parsedResults.push({
                    key: `result ${result_idx}`,
                    label: "GMCC - Universe",
                    children: <div className='resultTabPane text-center d-flex'>
                        {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                            <Result
                                status="success"
                                title="Your borrower is pre-qualified for the Universe Program"
                                subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                            />
                            <div className='text-left'>
                                <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                <ul style={{color:"green"}}>
                                    <li>Eligible Area check</li>
                                    <li>Max Loan Amount check</li>
                                    <li>Down payment at least 40%.</li>
                                    <li>Reserves check</li>
                                    <li>DTA check</li>
                                    {/* <li>Need to additionally check for 20% equity of departure property</li>
                                    <li>Letter of intent to sell/listing need to be provided</li> */}
                                </ul>
                                <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>FICO optional</li>
                                        <li>No need income</li>
                                    </ul>
                                </div>
                            </div>
                        </div>}
                        {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                            <Result
                                status="warning"
                                title="Your borrower is likely to qualify for the Universe Program"
                                subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                            />
                            {getCurrentLoanInfo()}
                            <div className='mt-2'>Ineligible reasons:</div>
                            <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                            <div className='text-left programNotesWrap'>
                                <div className='mb-2'>Note:</div>
                                <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                            </div>
                        </div>}
                        <div className='flyerMobileView'>
                            <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_40downFlyer_zeitro.png" />
                        </div>
                    </div>
                })
                result_idx+=1
                option_idx+=1
                flierOptions.push({
                    value: option_idx,
                    label: "GMCC - Universe",
                    status: good ? "pass" : "fail",
                    hasFlier: true
                })
                if (foreignVisa !== "notforeigner"){
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Foreign Nationals",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Foreign Nationals Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Max Loan Amount check</li>
                                        <li>Down payment at least 40%.</li>
                                        <li>Reserves check</li>
                                        <li>DTA check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>FICO optional</li>
                                            <li>No need income</li>
                                            <li>Intended for foreign nationals</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Foreign Nationals Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_ForeignerFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Foreign Nationals",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
            }
            if (docuverify === "wageEarnerWVOE"){
                //GMCC WVOE P&L
                if (employment !== "noemployment"){
                    let good = false
                    let failReasons = []
                    let min = {}
                    for (let prop in matrices["GMCC_WVOE"]){
                        if (prop !== "Agate"){
                            for (let mat of matrices["GMCC_WVOE"][prop]){
                                if (mat["PurposeTypes"].includes("PURCHASE") && LTV<= mat["MaxLTV"] && fico>=mat["MinFICO"] && lAmount <= mat["MaxLoanAmount"] && getDTI()<= mat["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -mat["Reserves"]*monthlypayment)>=0)){
                                    good = true
                                }
                            }
                        } else {
                            for (let mat of matrices["GMCC_WVOE"][prop]){
                                if (mat["PurposeTypes"].includes("PURCHASE") && LTV<= mat["MaxLTV"] && fico>=mat["MinFICO"] && (lAmount <= 1000000 && lAmount >=150000) && getDTI()<= mat["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -mat["Reserves"]*monthlypayment)>=0)){
                                    good = true
                                }
                            }

                        }
                    }
                    if (!good){
                        for (let key of Object.keys(matrices["GMCC_WVOE"])){
                            if (key === "Agate"){
                                failReasons.push(...GetReasonsforAgate(matrices["GMCC_WVOE"][key],loanAmount, true, false, false, true, 1000000, 150000))
                            } else{
                                failReasons.push(...GetReasons(matrices["GMCC_WVOE"][key],loanAmount, true, false, false, true))                               
                            }
                        }
                        min = findClosest(failReasons)
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - WVOE P&L",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the GMCC WVOE P& Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs</li>
                                        <li>Self employed</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>WVOE signed by HR, Accounting or Owner.</li>
                                        <li>Requires two years of work history at the same place of employment or in similar field.</li>
                                        <li>If employment info is not on credit report, need LOE.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the GMCC WVOE P&L Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_WVOE_PLFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - WVOE P&L",
                        status: good? "pass": "fail",
                        hasFlier: true
                    })
                }
                //hermes check15
                if (hermes_states.includes(propertyState) && loanAmount <= 2500000 && ((foreignVisa === "notforeigner" && downPercent>=30)||(foreignVisa !== "notforeigner" && downPercent>=40))){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Hermes"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Hermes"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Hermes",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Hermes Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs - Wage Earner WVOE</li>
                                        <li>Loan amount under $2.5M</li>
                                        <li>Max DTI 43%.</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>WVOE signed by HR, Accounting or Owner.</li>
                                        <li>Requires two years of work history at the same place of employment or in similar field.</li>
                                        <li>If employment info is not on credit report, need LOE.</li>
                                        <li>Gift fund allowed even for investment properties.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Hermes Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_HermesFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Hermes",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }

                //buy without sell first check17
                if (hasListProperty === "true" && sufficientEquity==="true" && loanAmount <= 3500000 && fico >= 660){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Apollo"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Onyx"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Ultimate"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Cronus"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Apollo"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Onyx"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Ultimate"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Cronus"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Buy without sell first",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Buy Without Sell First Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Loan Amount check</li>
                                        <li>Income check</li>
                                        <li>Have property currently listing/intend to sell</li>
                                        <li>Own at least 20% equity of the departuring property</li>
                                        <li>FICO, LTV, DTI, Reserves matrix check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Letter of intent to sell/listing need to be provided</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Buy Without Sell First Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Buy Without Sell First",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
            }
            if (docuverify === "wageEarnerVVOE"){
                //hermes check18
                if (hermes_states.includes(propertyState) && loanAmount <= 2500000 && ((foreignVisa === "notforeigner" && downPercent>=30)||(foreignVisa !== "notforeigner" && downPercent>=40))){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Hermes"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Hermes"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Hermes",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Hermes Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs- VVOE</li>
                                        <li>Loan amount under $2.5M</li>
                                        <li>Max DTI 43%.</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizens, 40% for foreign nationals.</li>
                                        <li>VVOE is required to verify employment info without income.</li>
                                        <li>Use income stated on 1003 to qulify.</li>
                                        <li>Need borrower to have 6 months income in the bank to validate income stated on 1003.</li>
                                        <li>Joint account with non-family member is not allowed.</li>
                                        <li>Gift fund allowed even for investment properties.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Hermes Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_HermesFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Hermes",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }

            }
            if (docuverify === "selfemployedPL"){
                //GMCC WVOE P&L
                if (employment !== "noemployment"){
                    let good = false
                    let failReasons = []
                    let min = {}
                    for (let prop in matrices["GMCC_P&L"]){
                        for (let mat of matrices["GMCC_P&L"][prop]){
                            if (mat["PurposeTypes"].includes("PURCHASE") && LTV<= mat["MaxLTV"] && fico>=mat["MinFICO"] && lAmount <= mat["MaxLoanAmount"] && getDTI()<= mat["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -mat["Reserves"]*monthlypayment)>=0)){
                                good = true
                            }
                        }
                    }
                    if (!good){
                        for (let key of Object.keys(matrices["GMCC_P&L"])){
                            failReasons.push(...GetReasons(matrices["GMCC_P&L"][key],loanAmount, true, false, false, true))
                        }
                        min = findClosest(failReasons)
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - WVOE P&L",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the GMCC WVOE P& Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs</li>
                                        <li>Self employed</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>WVOE signed by HR, Accounting or Owner.</li>
                                        <li>Requires two years of work history at the same place of employment or in similar field.</li>
                                        <li>If employment info is not on credit report, need LOE.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the GMCC WVOE P&L Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_WVOE_PLFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - WVOE P&L",
                        status: good? "pass": "fail",
                        hasFlier: true
                    })
                }
                //hermes check20
                if (hermes_states.includes(propertyState) && loanAmount <= 2500000 && ((foreignVisa === "notforeigner" && downPercent>=30)||(foreignVisa !== "notforeigner" && downPercent>=40))){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Hermes"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Hermes"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Hermes",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Hermes Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs - self employed P&L</li>
                                        <li>Loan amount under $2.5M</li>
                                        <li>Max DTI 43%.</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>CPA or borrower prepared P&L required.</li>
                                        <li>Tax preparer letter confirming.</li>
                                        <li>2 yearss of current business license.</li>
                                        <li>At least a two-year history of self employment is required.</li>
                                        <li>Gift fund allowed even for investment properties.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Hermes Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_HermesFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Hermes",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //bank statement self employed check21
                if (employment !== "noemployment"){
                    let good = false
                    let failReasons = []
                    let min = {}
                    for (let prop in matrices["BankStatement"]){
                        for (let mat of matrices["BankStatement"][prop]){
                            if (mat["PurposeTypes"].includes("PURCHASE") && LTV<= mat["MaxLTV"] && fico>=mat["MinFICO"] && lAmount <= mat["MaxLoanAmount"] && getDTI()<= mat["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -mat["Reserves"]*monthlypayment)>=0)){
                                good = true
                            }
                        }
                    }
                    if (!good){
                        for (let key of Object.keys(matrices["BankStatement"])){
                            failReasons.push(...GetReasons(matrices["BankStatement"][key],loanAmount, true, false, false, true))
                        }
                        min = findClosest(failReasons)
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Bank Statement Self Employed",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Hermes Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs</li>
                                        <li>Self employed</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>WVOE signed by HR, Accounting or Owner.</li>
                                        <li>Requires two years of work history at the same place of employment or in similar field.</li>
                                        <li>If employment info is not on credit report, need LOE.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Hermes Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_bankStatementFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Bank Statement Self Employed",
                        status: good? "pass": "fail",
                        hasFlier: true
                    })
                }
                
                //Buy without sell first check22
                if (hasListProperty==="true" && sufficientEquity==="true" &&loanAmount <= 3500000 && fico >= 660){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Apollo"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Onyx"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Ultimate"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Cronus"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Apollo"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Onyx"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Ultimate"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Cronus"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Buy without sell first",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Buy Without Sell First Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Loan Amount check</li>
                                        <li>Income check</li>
                                        <li>Have property currently listing/intend to sell</li>
                                        <li>Own at least 20% equity of the departuring property</li>
                                        <li>FICO, LTV, DTI, Reserves matrix check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Letter of intent to sell/listing need to be provided</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Buy Without Sell First Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Buy Without Sell First",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
            }
            if (docuverify === "selfemployedVVOE"){
                //hermes check23
                if (hermes_states.includes(propertyState) && loanAmount <= 2500000 && ((foreignVisa === "notforeigner" && downPercent>=30)||(foreignVisa !== "notforeigner" && downPercent>=40))){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Hermes"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Hermes"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Hermes",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Hermes Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs - self employed Borrower prepared P&L</li>
                                        <li>Loan amount under $2.5M</li>
                                        <li>Max DTI 43%.</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>Tax preparer letter confirming or 2 years of current business liscense required.</li>
                                        <li>Employment must be on credit report.</li>
                                        <li>Gift fund allowed even for investment properties.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Hermes Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_HermesFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Hermes",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //buy without sell first check24
                if (hasListProperty==="true" && sufficientEquity==="true" &&loanAmount <= 3500000 && fico >= 660){
                    let good = false
                    let min = {}
                    let failReasons = []
                    for (let matrix of matrices["Apollo"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Onyx"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Ultimate"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    for (let matrix of matrices["Cronus"]){
                        if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && getDTI()<= matrix["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -matrix["Reserves"]*monthlypayment)>=0)){
                            good = true
                        }
                    }
                    if (!good){
                        failReasons.push(...GetReasons(matrices["Apollo"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Onyx"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Ultimate"],loanAmount, true, false, false, true))
                        failReasons.push(...GetReasons(matrices["Cronus"],loanAmount, true, false, false, true))
                        min = findClosest(failReasons)
                    }
                    
                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Buy without sell first",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="success"
                                    title="Your borrower is pre-qualified for the Buy Without Sell First Program"
                                    subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Eligible Area check</li>
                                        <li>Loan Amount check</li>
                                        <li>Income check</li>
                                        <li>Have property currently listing/intend to sell</li>
                                        <li>Own at least 20% equity of the departuring property</li>
                                        <li>FICO, LTV, DTI, Reserves matrix check</li>
                                        {/* <li>Need to additionally check for 20% equity of departure property</li>
                                        <li>Letter of intent to sell/listing need to be provided</li> */}
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                        <div style={{fontWeight:"500"}}>Program notes:</div>
                                        <ul>
                                            <li>Letter of intent to sell/listing need to be provided</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Buy Without Sell First Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                 <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_BuyWithoutSellFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Buy Without Sell First",
                        status: good ? "pass" : "fail",
                        hasFlier: true
                    })
                }
                //bankstatement self employed check25
                if (employment !== "noemployment"){
                    let good = false
                    let failReasons = []
                    let min = {}
                    for (let prop in matrices["BankStatement"]){
                        for (let mat of matrices["BankStatement"][prop]){
                            if (mat["PurposeTypes"].includes("PURCHASE") && LTV<= mat["MaxLTV"] && fico>=mat["MinFICO"] && lAmount <= mat["MaxLoanAmount"] && getDTI()<= mat["MaxDTI"] && ((totalAssetAmount - downPayment - 0.015*purchasePrice -mat["Reserves"]*monthlypayment)>=0)){
                                good = true
                            }
                        }
                    }
                    if (!good){
                        for (let key of Object.keys(matrices["BankStatement"])){
                            failReasons.push(...GetReasons(matrices["BankStatement"][key],loanAmount, true, false, false, true))
                        }
                        min = findClosest(failReasons)
                    }

                    parsedResults.push({
                        key: `result ${result_idx}`,
                        label: "GMCC - Bank Statement Self Employed",
                        children: <div className='resultTabPane text-center d-flex'>
                            {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                        status="success"
                                        title="Your borrower is pre-qualified for the Hermes Program"
                                        subTitle= "Addtional restrictions check may needed, please see the program notes or reference program guideline."
                                />
                                <div className='text-left'>
                                    <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                                    <ul style={{color:"green"}}>
                                        <li>Property located in eligible states.</li>
                                        <li>Alternative docs</li>
                                        <li>Self employed</li>
                                        <li>LTV, FICO, DTI, Reserves matrix check passed</li>
                                    </ul>
                                    <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                    <div style={{fontWeight:"500"}}>Program notes:</div>
                                    <ul>
                                        <li>Down payment at least 30% for US citizen, 40% for foreign nationals.</li>
                                        <li>Borrower prepared P&L.</li>
                                        <li>Requires two years of work history at the same place of employment or in similar field.</li>
                                        <li>If employment info is not on credit report, need LOE.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>}
                            {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                                <Result
                                    status="warning"
                                    title="Your borrower is likely to qualify for the Hermes Program"
                                    subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                                />
                                {getCurrentLoanInfo()}
                                <div className='mt-2'>Ineligible reasons:</div>
                                <div style={{color:"red", marginTop:"10px"}}>
                                    {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                                    {Object.keys(min).map((key) => {
                                    return <div>{min[key]}</div>
                                    })}
                                </div>
                                <div className='text-left programNotesWrap'>
                                    <div className='mb-2'>Note:</div>
                                    <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                                </div>
                            </div>}
                            <div className='flyerMobileView'>
                                <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_bankStatementFlyer_zeitro.png" />
                            </div>
                        </div>
                    })
                    result_idx+=1
                    option_idx+=1
                    flierOptions.push({
                        value: option_idx,
                        label: "GMCC - Bank Statement Self Employed",
                        status: good? "pass": "fail",
                        hasFlier: true
                    })
                }
            }
        }
        //radiant check26
        if (doclevel === "altdoc" && radiant_states.includes(propertyState) ){
            let good = false
            let min = {}
            let failReasons = []
            for (let matrix of matrices["Radiant"]){
                if (matrix["PurposeTypes"].includes("PURCHASE") && matrix["OccupancyTypes"]=== "PRIMARY" && LTV<= matrix["MaxLTV"] && fico>= matrix["MinFICO"] && lAmount <= matrix["MaxLoanAmount"] && (((totalAssetAmount- downPayment - 0.015*purchasePrice) >= 12*monthlypayment)|| (foreignVisa!== "notforeigner" && (totalAssetAmount-downPayment-0.015*purchasePrice)>=24*monthlypayment) )){
                    good = true
                }
            }
            if (!good){
                failReasons.push(...GetReasons(matrices["Radiant"],loanAmount, true,true, true, true))
                min = findClosest(failReasons)
            }
            
            parsedResults.push({
                key: `result ${result_idx}`,
                label: "GMCC - Radiant",
                children: <div className='resultTabPane text-center d-flex justify-content-center'>
                    {good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                        <Result
                            status="success"
                            title="Your borrower is pre-qualified for the Radiant Program"
                            subTitle= ""
                        />
                        <div className='text-left'>
                            <div style={{fontWeight:"500"}}>Restriction checks passed:</div>
                            <ul style={{color:"green"}}>
                                <li>Property located in eligible states.</li>
                                <li>LTV, FICO matrix check.</li>
                            </ul>
                            <div className="programNotesWrap" style={{backgroundColor:"#EAEFFD"}}>
                                <div style={{fontWeight:"500"}}>Program notes:</div>
                                <ul>
                                    <li>Applicable for all type of citizenship, including foreign nationals.</li>
                                    <li>12 months reserves. For foreign nationals, 24 months reserves.</li>
                                    <li>No employment income needed.</li>
                                </ul>
                            </div>
                        </div>
                    </div>}
                    {!good && <div className='resultInfoWrap' style={{fontSize:"14px", fontWeight:"400",width:"50%"}}>
                        <Result
                            status="warning"
                            title="Your borrower is likely to qualify for the Radiant Program"
                            subTitle= "Your borrower scenario does not fully satisfy the required conditions of this program. Please check below for the unmet conditions."
                        />
                        {getCurrentLoanInfo()}
                        <div className='mt-2'>Ineligible reasons:</div>
                         <div style={{color:"red", marginTop:"10px"}}>
                            {Object.keys(min).length === 0 && <div>Asset not sufficient for reserves</div>}
                            {Object.keys(min).map((key) => {
                            return <div>{min[key]}</div>
                            })}
                        </div>
                        <div className='text-left programNotesWrap'>
                            <div className='mb-2'>Note:</div>
                            <div style={{fontSize:"12px"}}>Exceptions may apply, for more details please reference program guideline or GMCC assistant.</div>
                        </div>
                    </div>}
                    <div className='flyerMobileView'>
                        <img className="flyerMobileView w-100 " src="/images/gmccflyers/GMCC_RadiantFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1  
            option_idx+=1
            flierOptions.push({
                value: option_idx,
                label: "GMCC - Radiant",
                status: good ? "pass" : "fail",
                hasFlier: true
            })
        }
        // if ( )
    }
    let qualifiedResults = flierOptions.filter((x) => x.status === "pass");
    let failedResults = flierOptions.filter((x) => x.status === "fail");
    flierOptions = [
        {
            value: "",
            label: "Select a program",
            status: "default",
            hasFlier: false
        },
        ...qualifiedResults,
        ...failedResults,
    ]
    setAdvancedFlierSelectOptions(flierOptions)
    setQualifiedCounts(qualifiedResults.length)
    setResultsCount(parsedResults.length)
    setSearchResults(parsedResults)
    setLoading(false);
    setFlierChosen(flierOptions[1])
    setAdvanceCurrentSlide(advanceCurrentSlide + 1)
    advanceCarouselRef.current.next();
    parsedResults = []
    return
  }

  const goToNextMassive= () => {
    setCurrentSlide(currentSlide + 1);
    massiveCarouselRef.current.next();
  }

  const goToPrevMassive = () => {
    setCurrentSlide(currentSlide - 1);
    massiveCarouselRef.current.prev();
  }

  const goToNextGPS= () => {
    setCurrentSlide(currentSlide + 1);
    gpsCarouselRef.current.next();
  }

  const goToPrevGPS = () => {
    setCurrentSlide(currentSlide - 1);
    gpsCarouselRef.current.prev();
  }

  const goToNext = () => {
    setCurrentSlide(currentSlide + 1);
    carouselRef.current.next();
  };

  const goToPrev = () => {
    setCurrentSlide(currentSlide - 1);
    carouselRef.current.prev();
  };

  const navigateToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
    carouselRef.current.goTo(slideIndex, false);
  };

  const resetSlide = () => {
    setShowResult(null)
    setCurrentSlide(1)
  }

  const onStreetAddressCompletion = (address, latitude, longitude, isGPS) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
    if (isGPS){
      setGPSDisplayedAddress(stradd)
    }
    setShowPropertyInfo(true)
    setLatitude(latitude)
    setLongitude(longitude)
  };
  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value="">...</option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value="">...</option>);
    try {
      if (loanLimits[propertyState.toUpperCase()]) {
        let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
        keys.forEach((x) => {
          options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
        });
      }
    } catch (error) {
      console.log(error)
    }

    return options;
  };

  const getDTI = () => {
    let dti = 0;
    dti = ((monthlypayment + parseFloat(monthDebt))/parseFloat(income/12))*100;
    setCalcDTI(dti.toFixed(0))
    return dti.toFixed(0);
  }

  const resetPagination = () => {
    setPagination({...pagination, current: 1});
  }

  const handleTableChange = (pagination) => {
    setPagination({
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
    });
  }

  const handleMassiveTableFilter = (values) => {
    setCurrentSortType("")
    setCurrentFilterType(values)
    let filtered = originalListingData

    if (!Array.isArray(values) || values.length === 0) {
        filtered = originalListingData
    } else {
        values.forEach(value => {
            if (value === "minority") {
                filtered = filtered.filter(x => x["minority"] > 50)
            }
            if (value === "incomeindicator") {
                filtered = filtered.filter(x => x["incomeindicator"] === "1" || x["incomeindicator"] === "2")
            }
            if (value === "AAHP"){
                filtered = filtered.filter(x => 100*(parseInt(x["black"]) + parseInt(x["hispanic"]))/x["totalpopulation"]>50)
            }
            if (value === "less500") {
                filtered = filtered.filter(x => parseInt(x["price"]) <= 500000)
            }
            if (value === "500to1000") {
                filtered = filtered.filter(x => parseInt(x["price"]) > 500000 && x["price"] <= 1000000)
            }
            if (value === "1000to3000") {
                filtered = filtered.filter(x => parseInt(x["price"]) > 1000000 && x["price"] <= 3000000)
            }
            if (value === "over3000") {
                filtered = filtered.filter(x => parseInt(x["price"]) > 3000000)
            }
        })
    }

    setListingData(filtered)
    resetPagination()
}

  const handleMassiveTableSorter = (value) => {
    setCurrentSortType(value)
    let sorted = []
    if (value === undefined || value === ""){
        sorted = [...listingData]
    }
    if (value === "price"){
        sorted = [...listingData].sort((a,b) => a["price"] - b["price"])
    }
    if (value === "zip"){
        sorted = [...listingData].sort((a,b) => a["zip"] - b["zip"])
    }
    if (value === "address"){
        sorted = [...listingData].sort((a,b) => a["address"].localeCompare(b["address"]))
    }
    if (value === "incentives"){
        //sort the data by checking the number of conditions that the record met.  The more conditions met, the higher the record will be placed. Conditions are: minority >50, incomeindicator 1 or 2
        sorted = [...listingData].sort((a,b) => {
            let aCount = 0;
            let bCount = 0;
            if (a["minority"] >50){
                aCount+=1
            }
            if (a["incomeindicator"] === "1" || a["incomeindicator"] === "2"){
                aCount+=1
            }
            if (b["minority"] >50){
                bCount+=1
            }
            if (b["incomeindicator"] === "1" || b["incomeindicator"] === "2"){
                bCount+=1
            }
            return bCount - aCount
        })
    }
    setListingData(sorted)
    resetPagination()
  }

  useEffect(() => {
    let totalFilters = []
    totalFilters.push(...currentFilter1)
    totalFilters.push(currentFilter2)
    handleTotalFiltersUpdate(totalFilters)
  }, [currentFilter1, currentFilter2])

  const handleTotalFiltersUpdate = (totalFilters) => {
    let filtered = originalListingData
    if (!Array.isArray(totalFilters) || totalFilters.length === 0){
        filtered = originalListingData
    } else {
        totalFilters.forEach(filter => {
            if (filter === "minority") {
                filtered = filtered.filter(x => x["minority"] > 50)
            }
            if (filter === "incomeindicator") {
                filtered = filtered.filter(x => x["incomeindicator"] === "1" || x["incomeindicator"] === "2")
            }
            if (filter === "AAHP"){
                filtered = filtered.filter(x => 100*(parseInt(x["black"]) + parseInt(x["hispanic"]))/x["totalpopulation"]>50)
            }
            if (filter === "less500") {
                filtered = filtered.filter(x => parseInt(x["price"]) <= 500000)
            }
            if (filter === "500to1000") {
                filtered = filtered.filter(x => parseInt(x["price"]) > 500000 && x["price"] <= 1000000)
            }
            if (filter === "1000to3000") {
                filtered = filtered.filter(x => parseInt(x["price"]) > 1000000 && x["price"] <= 3000000)
            }
            if (filter === "over3000") {
                filtered = filtered.filter(x => parseInt(x["price"]) > 3000000)
            }
        })
    }
    setListingData(filtered)
    resetPagination()
  }

  const resetFilters = () => {
    setCurrentFilter1([])
    setCurrentFilter2("")
  }

  const getAssetDepletionDTI = () => {
    let dti = 0
    let reserves = 9
    if (lAmount > 2000000 && lAmount <=3000000){
        reserves = 24
    }
    if (lAmount <= 2000000 && lAmount > 766550 && fico < 720){
        reserves = 12
    }
    if (lAmount <= 766550 || lAmount > 3000000 ){
        setNoDepletion(true)
    } else{
        setNoDepletion(false)
    }
    let assetIncome = (totalAssetAmount - com.safeParseInt(downPayment) - 0.015*purchasePrice - reserves*monthlypayment)/240;
    if (assetIncome <= 0){
        assetIncome = 0
        setNotSufficient(true)
    } else {
        setNotSufficient(false)
    }
    if (income ==="" || parseFloat(income) === 0 || employment === "noemployment"){
        dti = ((com.safeParseInt(monthDebt)+monthlypayment)/assetIncome)*100;
        setDepletionDTI(dti.toFixed(0))
    } else {
        dti = ((com.safeParseInt(monthDebt)+monthlypayment)/(assetIncome+parseFloat(income/12)))*100;
        setDepletionDTI(dti.toFixed(0))
    }
  }

    const handleMassiveClick = () => {
        getListingOnCity();
        //statsig logevent
        const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
        client.logEvent("use_programs_map", "massiveCheck", {
            item_name: "useProgramsMap",
            action: "clickGetListings",
            startTime: new Date,
            timestamp: Date.now(),
            customerid: savedUserInfo.custom.customerid,
            email: savedUserInfo.email,
            type: "massiveCheck"
        });
   }

  const handleMassiveFormSubmit = (e) => {
    //statsig logevent
    const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
    client.logEvent("use_programs_map", "massiveCheck", {
        item_name: "useProgramsMap",
        action: "clickCheck",
        startTime: new Date,
        timestamp: Date.now(),
        customerid: savedUserInfo.custom.customerid,
        email: savedUserInfo.email,
        type: "massiveCheck"
    });

    e.preventDefault();
    e.stopPropagation();

    setShowError(false);
    setLoading(true);

    let massiveResults = [];
    let record = selectedRecord
    let flierOptions = [
        {
            value: "",
            label: "Select a program",
        }
    ];
    let result_idx = 0;
    if (massivePropertyState !== "" && massivePropertyCounty !== "" && listingTarget !== "") {
        if ( targetMsa !== "" && 100*(parseInt(record.black) + parseInt(record.hispanic))/record.totalpopulation > 50 && (record.incomeindicator === "1" || record.incomeindicator === "2")){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Community Special",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Community Special",
            })
            result_idx+=1
        }
        if (cronus_qualifiedCounties[massivePropertyState] !== undefined && (cronus_qualifiedCounties[massivePropertyState].length===0|| cronus_qualifiedCounties[massivePropertyState].includes(massivePropertyCounty.replace("County", "").trim())) &&
        (record.incomeindicator === "1" || record.incomeindicator === "2")){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Grandslam",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Grandslam",
            })
            result_idx+=1
        }

        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(massivePropertyState))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $5K Grant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity $5K Grant",
            })
            result_idx+=1
        }
        if((tenK_qualifiedCounties[massivePropertyState] !== undefined && (tenK_qualifiedCounties[massivePropertyState].length===0|| tenK_qualifiedCounties[massivePropertyState].includes(massivePropertyCounty.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $15K Grant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity $15K Grant",
            })
            result_idx+=1
        }
        if((thirtyK_qualifiedCounties[massivePropertyState] !== undefined && (thirtyK_qualifiedCounties[massivePropertyState].length===0|| thirtyK_qualifiedCounties[massivePropertyState].includes(massivePropertyCounty.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $15K Grant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity $15K Grant",
            })
            result_idx+=1
        }
        if (universe_qualifiedCounties[massivePropertyState] !== undefined && (universe_qualifiedCounties[massivePropertyState].length===0|| universe_qualifiedCounties[massivePropertyState].includes(massivePropertyCounty.replace("County", "").trim()))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Universe",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Universe",
            })
            result_idx+=1
        }
        if ((zeroDown_qualifiedCounties[massivePropertyState] !== undefined && (zeroDown_qualifiedCounties[massivePropertyState].length===0|| zeroDown_qualifiedCounties[massivePropertyState].includes(massivePropertyCounty.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Community Opportunity",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Community Opportunity",
            })
            result_idx+=1
        }
        if (hermes_states.includes(massivePropertyState)){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Hermes",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Hermes",
            })
            result_idx+=1
        }
        if (radiant_states.includes(massivePropertyState)){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Radiant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Radiant",
            })
            result_idx+=1
        }

        if (affluent_states.includes(massivePropertyState) && parseInt(record.price) >= 1000000){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity Jumbo",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity Jumbo",
            })
            result_idx+=1
        }

        if (parseInt(record.price) >= 500000){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Jumbo Express",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Jumbo Express",
            })
            result_idx+=1
        }

        flierOptions.push({
            value: result_idx,
            label: "GMCC - Bank Statement Self Employed",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - Bank Statement Self Employed",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - Buy Without Sell First",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - Buy Without Sell First",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - WVOE P&L",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - WVOE P&L",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - DSCR Rental Flow",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - DSCR Rental Flow",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - Diamond Community Lending",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - Diamond Community Lending",
        })
        result_idx+=1


        

    }
    setSearchMassiveResults(massiveResults)
    setMassiveResultsCount(massiveResults.length)
    setLoading(false);
    goToNextMassive()
    return
  }

  const handleGPSCheckFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowError(false);
    setLoading(true);
    console.log("Check selected record!!!!!!!",selectedRecord)
    let massiveResults = [];
    let record = selectedRecord
    let flierOptions = [
        {
            value: "",
            label: "Select a program",
        }
    ];
    let result_idx = 0;
    if (record.state !== "" && record.county !== "" && listingTarget !== "") {
        console.log("start checking programs!!!")
        if ( MSAtoCountyList_conforming[record.state] && Object.keys(MSAtoCountyList_conforming[record.state]).includes(record.msa) && 100*(parseInt(record.black) + parseInt(record.hispanic))/record.totalpopulation > 50){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Community Special",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Community Special",
            })
            result_idx+=1
        }
        if ( (cronus_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) &&
        (record.incomeindicator === "1" || record.incomeindicator === "2")){ 
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Grandslam",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Grandslam",
            })
            result_idx+=1
        }

        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $5K Grant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity $5K Grant",
            })
            result_idx+=1
        }
        if((tenK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $15K Grant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity $15K Grant",
            })
            result_idx+=1
        }
        if((thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $15K Grant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity $15K Grant",
            })
            result_idx+=1
        }
        if (universe_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (universe_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| universe_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Universe",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Universe",
            })
            result_idx+=1
        }
        if ((zeroDown_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (zeroDown_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| zeroDown_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Community Opportunity",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Community Opportunity",
            })
            result_idx+=1
        }
        if (hermes_states.includes(com.mapAbbrFullName[record.state])){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Hermes",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Hermes",
            })
            result_idx+=1
        }
        if (radiant_states.includes(com.mapAbbrFullName[record.state])){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Radiant",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Radiant",
            })
            result_idx+=1
        }

        if (affluent_states.includes(com.mapAbbrFullName[record.state]) && parseInt(record.price) >= 1000000){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity Jumbo",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Celebrity Jumbo",
            })
            result_idx+=1
        }

        if (parseInt(record.price) >= 500000){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Jumbo Express",
            })
            massiveResults.push({
                key: `massiveresult ${result_idx}`,
                label: "GMCC - Jumbo Express",
            })
            result_idx+=1
        }

        flierOptions.push({
            value: result_idx,
            label: "GMCC - Bank Statement Self Employed",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - Bank Statement Self Employed",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - Buy Without Sell First",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - Buy Without Sell First",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - WVOE P&L",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - WVOE P&L",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - DSCR Rental Flow",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - DSCR Rental Flow",
        })
        result_idx+=1

        flierOptions.push({
            value: result_idx,
            label: "GMCC - Diamond Community Lending",
        })
        massiveResults.push({
            key: `massiveresult ${result_idx}`,
            label: "GMCC - Diamond Community Lending",
        })
        result_idx+=1


        

    }
    setSearchMassiveResults(massiveResults)
    setMassiveResultsCount(massiveResults.length)
    setLoading(false);
    goToNextGPS()
    return
  }


  const handleFastFormSubmit = (e) => {
    // statsig logEvent
    const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
    client.logEvent("use_programs_map", "fastCheck", {
        item_name: "useProgramsMap",
        action: "clickSearch",
        startTime: new Date,
        timestamp: Date.now(),
        customerid: savedUserInfo.custom.customerid,
        email: savedUserInfo.email,
        state: propertyState,
        address: propertyAddress,
        county: propertyCounty,
        city: propertyCity,
        zip: propertyZip,
        type: "fastCheck"
    });
    e.preventDefault();
    e.stopPropagation();

    setShowError(false);
    setLoading(true);
    let fastResults = [];
    let flierOptions = [
        {
            value: "",
            label: "Select a program",
        }
    ];
    let result_idx = 0;
    if (propertyState !== "" || propertyCounty !== "" || income !== "") {
        if((tenK_qualifiedCounties[propertyState] !== undefined && (tenK_qualifiedCounties[propertyState].length===0|| tenK_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) && (income <= mfi*1.5 || (income <= mfi*2 && firstGenHomeBuyer)) && (minority>50 || incomeIndicator === 1 || incomeIndicator === 2)){

            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $15K Grant",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC - Celebrity $15K Grant",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black", textDecoration:"underline"}}>Satisfied conditions:</div>
                        <div className='mb-3'>
                            <div>1. Subject Property located in eligible areas.</div>
                            <div>2. Moving to/from address is in Majority Minority Census Tract or subject property is low-to-morderate income area.</div>
                            <div>3. Borrower income under income limit.</div>
                        </div>
                        <div style={{color:"black", textDecoration:"underline"}}>Notes:</div>
                        <div className='mb-3'>
                            <div>1. Income limit 150% MFI</div>
                            <div>2. If borrower is a first generation home buyer, income limit can be 200% MFI</div>
                            <div>3. Min FICO 620</div>  
                            <div>4. 20% or less down payment required</div>
                            <div>5. Primary purchase only</div>
                            <div>6. Up to $15K grant available if qualified</div>
                        </div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_15KFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
        }
        if((thirtyK_qualifiedCounties[propertyState] !== undefined && (thirtyK_qualifiedCounties[propertyState].length===0|| thirtyK_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) && (income <= mfi*1.5 || (income <= mfi*2 && firstGenHomeBuyer)) && (minority>50 || incomeIndicator === 1 || incomeIndicator === 2)){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $15K Grant",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC - Celebrity $15K Grant",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black", textDecoration:"underline"}}>Satisfied conditions:</div>
                        <div className='mb-3'>
                            <div>1. Subject Property located in eligible areas.</div>
                            <div>2. Moving to/from address is in Majority Minority Census Tract or subject property is low-to-morderate income area.</div>
                            <div>3. Borrower income under income limit.</div>
                        </div>
                        <div style={{color:"black", textDecoration:"underline"}}>Notes:</div>
                        <div className='mb-3'>
                            <div>1. Income limit 150% MFI</div>
                            <div>2. If borrower is a first generation home buyer, income limit can be 200% MFI</div>
                            <div>3. Min FICO 620</div>  
                            <div>4. 20% or less down payment required</div>
                            <div>5. Primary purchase only</div>
                            <div>6. Up to $15K grant available if qualified</div>
                        </div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_15KFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
        }
       
        if ( fiveK_states.includes(propertyState) && (income <= mfi*1.5 || (income <= mfi*2 && firstGenHomeBuyer)) && (minority>50 || incomeIndicator === 1 || incomeIndicator === 2)){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity $5K Grant",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC Celebrity $5K Grant",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black", textDecoration:"underline"}}>Satisfied conditions:</div>
                        <div className='mb-3'>
                            <div>1. Subject Property located in eligible areas.</div>
                            <div>2. Moving to/from address is in Majority Minority Census Tract or subject property is low-to-morderate income area.</div>
                            <div>3. Borrower income under income limit.</div>
                        </div>
                        <div style={{color:"black", textDecoration:"underline"}}>Notes:</div>
                        <div className='mb-3'>
                            <div>1. Income limit 150% MFI</div>
                            <div>2. If borrower is a first generation home buyer, income limit can be 200% MFI</div>
                            <div>3. Min FICO 620</div>  
                            <div>4. 20% or less down payment required</div>
                            <div>5. Primary purchase only</div>
                            <div>6. Up to $5K grant available if qualified</div>
                        </div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_5KFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
        }
        if (outreach_qualifiedCounties[propertyState] !== undefined && (outreach_qualifiedCounties[propertyState].length===0|| outreach_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim())) &&
        (income <= medianIncome*0.8 || (incomeIndicator === 1 || incomeIndicator === 2)))
        {
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Universe CRA Outreach",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC Universe CRA Outreach",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black", textDecoration:"underline"}}>Satisfied conditions:</div>
                        <div className='mb-3'>
                            <div>1. Property located in eligible counties.</div>
                            <div>2. Property address in low/moderate income area, or income under 80% AMI</div>
                        </div>
                        <div style={{color:"black", textDecoration:"underline"}}>Notes:</div>
                        <div className='mb-3'>
                            <div>1. Min FICO 660</div>
                            <div>2. If Full doc, 3-5% down payment</div>
                            <div>3. If no ratio, 25% down payment even for rental </div>
                            <div>4. 1-2 units only</div> 
                            <div>5. Primary, second home and investment, same loan amount limit, same LTV limit.</div> 
                        </div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_OutreachFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
        }
        if (cronus_qualifiedCounties[propertyState] !== undefined && (cronus_qualifiedCounties[propertyState].length===0|| cronus_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim())) &&
        (income <= mfi*0.8 || (incomeIndicator === 1 || incomeIndicator === 2)))
        {
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Grandslam",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC Grandslam",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black", textDecoration:"underline"}}>Satisfied conditions:</div>
                        <div className='mb-3'>
                            <div>1. Property located in eligible counties.</div>
                            <div>2. Property address is in low/moderate income area, or income under 80% MFI, or both</div>
                        </div>
                        <div style={{color:"black", textDecoration:"underline"}}>Notes:</div>
                        <div className='mb-3'>
                            <div>1. Primary purchase, fixed rate only.</div>
                            <div>2. Conforming only. </div>
                            <div>3. Min FICO 640.</div>  
                            <div>4. Wage earner only.</div>
                            <div>5. 1-2 unit only.</div>
                        </div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_HomeRunFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
        }
        if ( (zeroDown_qualifiedCounties[propertyState] !== undefined && (zeroDown_qualifiedCounties[propertyState].length===0|| zeroDown_qualifiedCounties[propertyState].includes(propertyCounty.replace("County", "").trim()))) && (income<=mfi*1.5 || (firstGenHomeBuyer && income<=mfi*2) && (minority>50 || incomeIndicator===1 || incomeIndicator===2))){
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Celebrity CRA Community Opportunity",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC Celebrity CRA Community Opportunity",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black", textDecoration:"underline"}}>Satisfied conditions:</div>
                        <div className='mb-3'>
                            <div>1. Property located in eligible counties.</div>
                            {(incomeIndicator === 1 || incomeIndicator === 2) &&<div>2. Property address is in {incomeIndicator ===1?"low":"moderate"} income area, income under 150% MFI</div>}
                            {!(incomeIndicator === 1 || incomeIndicator === 2) &&<div>2. Income under 150% MFI (200% MFI for first generation buyer).</div>}
                        </div>
                        <div style={{color:"black", textDecoration:"underline"}}>Notes:</div>
                        <div className='mb-3'>
                            <div>1. 30 years fixed only.</div>
                            <div>2. Primary purchase only.</div>
                            <div>3. Min FICO 680</div>
                            <div>4. Agency limit</div>  
                        </div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_0downFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
        }
            flierOptions.push({
                value: result_idx,
                label: "GMCC - Diamond Community Lending",
            })
            fastResults.push({
                key: `fastresult ${result_idx}`,
                label: "GMCC Diamond Community Lending",
                children: <div className='fastResultItemLayout'>
                    <div className='halfWidth'>
                        <div style={{color:"black"}}>Please use below link to check Diamond Lendng Program:</div>
                        <div style={{textDecoration:"underline", marginBottom:"150px"}} onClick={()=> window.open("https://hub.collateralanalytics.com/correspondentsearch","_blank")}>Diamond Lending Eligibility Tool</div>
                    </div>
                    <div className='halfWidth'>
                        <img className="w-100 " src="/images/gmccflyers/GMCC_DiamondFlyer_zeitro.png" />
                    </div>
                </div>
            })
            result_idx+=1
    }
    setFromFast(true);
    setResultsCount(fastResults.length)
    setSearchFastResults(fastResults)
    setFastFlierSelectOptions(flierOptions)
    setLoading(false);
    setFlierChosen(flierOptions[1])
    goToNext();
    return
  }
  const stepItems = [
    {
        title: "Property Info"
    },
    {
        title: "Employment & other Info"
    },
    {
        title: "Program specific Info"
    },
  ]


  const advanceFlierSelectOptions = [
    {
        value: "",
        label: "Get your qualified fliers here"
    }
  ]
  const handleSwitchFlier = (value) => {
    setFastCurrentSlide(value);
    let selected = fastFlierSelectOptions.find(option => option.value === value);
    setFlierChosen(selected);
    fastCarouselRef.current.goTo(value, false);
  }

  const handleSwitchFlierAdvanced = (value) => {
    setAdvancedResultCurrentSlide(value);
    let selected = advancedFlierSelectOptions.find(option => option.value === value);
    setFlierChosen(selected);
    advancedResultCarouselRef.current.goTo(value-1, false);
  }

  const getListingOnCity = () => {
    setLoading(true)
    fetch("/los/gmcc/saleslistings", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            City: massivePropertyCity,
            State: com.convertStateToAbbr(massivePropertyState),
            County: massivePropertyCounty.replace("County", "").trim()
        })
    })
    .then((res) => {
        if (!res.ok){
            console.log(res);
            setLoading(false)
            alert("Failed to get listing data. Please try again later.")
            return
        }
        res.json().then((js) => {
            console.log(js)
            let data
            if (js === undefined || js === null){
                data = []
                setListingUnavailable(true)
            } else{
                data = js.map((listing, index) => {
                    return {
                        key: index,
                        address: listing.FullAddress,
                        state: listing.State,
                        county: listing.County,
                        city: listing.City,
                        zip: listing.ZipCode,
                        propertytype: listing.PropertyType,
                        price: listing.Price.trim(),
                        incomeindicator: listing.IncomeIndicator,
                        minority: listing.MinorityPercent,
                        totalpopulation: listing.TotalPopulation,
                        hispanic: listing.Hispanic,
                        black: listing.Black,
                        asian: listing.Asian,
                        pacific: listing.Pacific,
                        msa: listing.MsaCode,
                    }
                })
                setListingUnavailable(false)
                //sort data on price
                data.sort((a,b) => a["price"] - b["price"])
                if (targetMsa !== ""){
                    data = data.filter(x => x["msa"] === targetMsa)
                }
            }
            setLoading(false)
            setCurrentFilterType("")
            setCurrentSortType("price")
            setOriginalListingData(data)
            setListingData(data)
            setShowListingFilter(true)
        })
    })
    .catch((err) => {
        console.log(err);
        setLoading(false)
        alert("Failed to get listing data. Please try again later.")
    })
  }  
  
  const copyTarget = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => {
        messageApi.success('copy success!')
      })
      .catch((error) => {
        messageApi.error('copy error!')
      })
  }

  const getUniqueStateCountyOption = (data, key) => {
    if (data === undefined || data === null){
      return []
    }
    return [...new Set(data.map(item => item[key]))].map(value => ({
        text: value,
        value: value
    }))
  }

  const listingColumns = [
    {
        title: "MSA",
        dataIndex: "msa",
        key: "msa",
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text) => <div>${com.commaize(text)}</div>
    },
    {
        title: "Active listing address",
        dataIndex: "address",
        key: "address",
        width: "45%",
        fixed: "left",
        render: (text, record) => ( 
                <Popover
                    rootClassName='massivePopover'
                    content = {massivePopoverContent(record)}
                    title = {null}
                    trigger = "hover"
                    open = {hoveredRow === record.key}
                >
                    <div className='d-flex justify-content-between'>
                        <div>{text}</div>
                        <div className='d-flex'>
                            {(record.incomeindicator === "1" || record.incomeindicator === "2") &&<div className='blueBulb mr-2'>Low-to-moderate</div>}
                            {record.minority > 50 && <div className='blueBulb'>In MMCT</div>}
                        </div>
                    </div>
                </Popover>
        )
    },
    {
        title: "State",
        dataIndex: "state",
        key: "state"
    },
    {
        title: "County",
        dataIndex: "county",
        key: "county"
    },
    {
        title: "City",
        dataIndex: "city",
        key: "city"
    },
    {
        title: "Zip code",
        dataIndex: "zip",
        key: "zip"
    },
    {
        title: "Property type",
        dataIndex: "propertytype",
        key: "propertytype",
        width: "150px"
    },
  ]

  const getQualifiedAddrByProg = (data) => {
    let qualifiedData = []
    switch (GPSMsaType){
        case "conforming":
            data.forEach((record, index) => {
                if (MSAtoCountyList_conforming[record.state] && Object.keys(MSAtoCountyList_conforming[record.state]).includes(record.msa) && 100*(parseInt(record.black) + parseInt(record.hispanic))/record.totalpopulation > 50){
                    qualifiedData.push(record)
                }
            });
            qualifiedData = qualifiedData.filter(x => x['msa'] === GPSTargetMsa)
            break;
        case "grandslam":
            data.forEach((record, index) => {
                if ((MSAtoCountyList_grandslam[record.state] && Object.keys(MSAtoCountyList_grandslam[record.state]).includes(record.msa)) || (cronus_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) &&
                (record.incomeindicator === "1" || record.incomeindicator === "2")){        
                    qualifiedData.push(record)
                }
            });
            qualifiedData = qualifiedData.filter(x => x['msa'] === GPSTargetMsa)
            break;
        case "nonagency":
            data.forEach((record, index) => {
                if (MSAtoCountyList_nonagency[record.state] && Object.keys(MSAtoCountyList_nonagency[record.state]).includes(record.msa) && (record.incomeindicator === "1" || record.incomeindicator === "2") && parseInt(record.price) > loanLimit){
                    qualifiedData.push(record)
                }
            });
            qualifiedData = qualifiedData.filter(x => x['msa'] === GPSTargetMsa)
            break;
        case "diamond":
            data.forEach((record, index) => {
                if (record.price <= loanLimit){
                    qualifiedData.push(record)
                }
            });
            qualifiedData = qualifiedData.filter(x => x["city"] === diamondCity)
            break;
        case "grants":
            data.forEach((record, index) => {
                switch (record.state){
                    case "CA":
                        if((tenK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            qualifiedData.push(record)
                        }
                        break;
                    case "NC":
                        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            qualifiedData.push(record)
                        }
                        break;
                    case "SC":
                        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            qualifiedData.push(record)
                        }
                        break;
                    case "MA":
                        if((thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            qualifiedData.push(record)
                        }
                        break;
                    case "GA":
                        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            qualifiedData.push(record)
                        }
                        break;
                }
            });
            qualifiedData = qualifiedData.filter(x => x["state"] === com.convertStateToAbbr(grantState))
            break;
        case "all":
            qualifiedData = data
            break;
    }
    return qualifiedData
  }

  const getMatchedProgramTags = (record) => {
    let tags= []
    if (GPSMsaType === "all"){
        if ( MSAtoCountyList_conforming[record.state] && Object.keys(MSAtoCountyList_conforming[record.state]).includes(record.msa) && 100*(parseInt(record.black) + parseInt(record.hispanic))/record.totalpopulation > 50){
            tags.push(<div className='blueBulb'>Special Conforming</div>)
        }
        if ((cronus_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) &&
        (record.incomeindicator === "1" || record.incomeindicator === "2")){        
            tags.push(<div className='blueBulb'>Grandslam</div>)
        }
        if ( (MSAtoCountyList_nonagency[record.state] && Object.keys(MSAtoCountyList_nonagency[record.state]).includes(record.msa)) && (record.incomeindicator === "1" || record.incomeindicator === "2") && parseInt(record.price) > loanLimit){
            tags.push(<div className='blueBulb'>Jumbo CRA</div>)
        }
        if ( record.price <= loanLimit){
            tags.push(<div className='blueBulb'>Diamond</div>)
        }
        switch (record.state){
            case "CA":
                if((tenK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                    tags.push(<div className='blueBulb'>Grant $15K</div>)
                }
                break;
            case "NC":
                if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                    tags.push(<div className='blueBulb'>Grant $5K</div>)
                }
                break;
            case "SC":
                if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                    tags.push(<div className='blueBulb'>Grant $5K</div>)
                }
                break;
            case "MA":
                if((thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                    tags.push(<div className='blueBulb'>Grant $15K</div>)
                }
                break;
            case "GA":
                if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                    tags.push(<div className='blueBulb'>Grant $5K</div>)
                }
                break;
        }
    } else {
        switch (GPSMsaType){
            case "conforming":
                if ( MSAtoCountyList_conforming[record.state] && Object.keys(MSAtoCountyList_conforming[record.state]).includes(record.msa) && 100*(parseInt(record.black) + parseInt(record.hispanic))/record.totalpopulation > 50){
                    tags.push(<div className='blueBulb'>Special Conforming</div>)
                }                
                break;
            case "grandslam":
                if ((cronus_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| cronus_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) &&
                (record.incomeindicator === "1" || record.incomeindicator === "2")){        
                    tags.push(<div className='blueBulb'>Grandslam</div>)
                }                
                break;
            case "nonagency":
                if ( (MSAtoCountyList_nonagency[record.state] && Object.keys(MSAtoCountyList_nonagency[record.state]).includes(record.msa)) && (record.incomeindicator === "1" || record.incomeindicator === "2") && parseInt(record.price) > loanLimit){
                    tags.push(<div className='blueBulb'>Jumbo CRA</div>)
                }                
                break;
            case "diamond":
                if ( record.price <= loanLimit){
                    tags.push(<div className='blueBulb'>Diamond</div>)
                }                
                break;
            case "grants":
                switch (record.state){
                    case "CA":
                        if((tenK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| tenK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            tags.push(<div className='blueBulb'>Grant $15K</div>)
                        }                        
                        break;
                    case "NC":
                        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            tags.push(<div className='blueBulb'>Grant $5K</div>)
                        }                        
                        break;
                    case "SC":
                        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            tags.push(<div className='blueBulb'>Grant $5K</div>)
                        }
                        break;
                    case "MA":
                        if((thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]] !== undefined && (thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].length===0|| thirtyK_qualifiedCounties[com.mapAbbrFullName[record.state]].includes(record.county.replace("County", "").trim()))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            tags.push(<div className='blueBulb'>Grant $15K</div>)
                        }
                        break;
                    case "GA":
                        if((fiveK_states!== undefined && (fiveK_states.length===0|| fiveK_states.includes(com.mapAbbrFullName[record.state]))) && (record.minority >50 || (record.incomeindicator === "1" || record.incomeindicator === "2"))){
                            tags.push(<div className='blueBulb'>Grant $5K</div>)
                        }
                        break;
                }
                break;
        }
    }
    return tags
  }

  const renderGPSCheck = () => {

    const GPSListingColumns = [
        {
            title: "#",
            dataIndex: "squence",
            key: "squence",
        },
        {
            title: "MSA",
            dataIndex: "msa",
            key: "msa",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: "400px",
            // render: (text, record) => ( 
 
            // )
        },
        {
            title: "Matched Programs",
            dataIndex: "matchedprograms",
            key: "matchedprograms",
            width: "400px",
            render: (text, record) => <div style={{display:"flex", justifyContent:"start",alignItems:"center", gap:"5px"}}>
                {
                    getMatchedProgramTags(record)
                }
            </div>
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            filters: getUniqueStateCountyOption(listingData, 'state'),
            onFilter: (value, record) => record.state === value
        },
        {
            title: "County",
            dataIndex: "county",
            key: "county",
            filters: getUniqueStateCountyOption(listingData, 'county'),
            onFilter: (value, record) => record.county === value
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            filters: getUniqueStateCountyOption(listingData, 'city'),
            onFilter: (value, record) => record.city === value
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            //add sorter
            sorter: (a, b) => a["price"] - b["price"],
            sortDirections: ['ascend', 'descend'],
            render: (text) => <div>${com.commaize(text)}</div>
        },
        {
            title: "Property type",
            dataIndex: "propertytype",
            key: "propertytype",
            width: "150px",
            filters: getUniqueStateCountyOption(listingData, 'propertytype'),
            onFilter: (value, record) => record.propertytype === value
        },
        {
            title: "Zip code",
            dataIndex: "zip",
            key: "zip"
        },
      ]

    const handleGPSCheckSearch = () => {
        // statsig logEvent
        const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
        client.logEvent("use_programs_map", "gpsCheck", {
            item_name: "useProgramsMap",
            action: "clickSearch",
            startTime: new Date,
            timestamp: Date.now(),
            customerid: savedUserInfo.custom.customerid,
            email: savedUserInfo.email,
            type: "gpsCheck"
        });
        setLoading(true)
        setGPSsearchClicked(true)
        fetch("/los/gmcc/gpslistingsearch", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Lat: latitude,
                Lon: longitude,
                Dist: parseFloat(searchRadius),
                Addr: gpsDisplayedAddress
            })
        })
        .then((res) => {
            if (!res.ok){
                console.log(res);
                alert("Failed to get listing data. Please try again later.")
                setLoading(false)
                return
            }
            res.json().then((js) => {
                let qualifiedData
                let data
                if (js === undefined || js === null){
                    data = []
                    setListingUnavailable(true)
                } else{
                    data = js.map((listing, index) => {
                        return {
                            key: index,
                            address: listing.FullAddress,
                            state: listing.State,
                            county: listing.County,
                            city: listing.City,
                            zip: listing.ZipCode,
                            propertytype: listing.PropertyType,
                            price: listing.Price.trim(),
                            incomeindicator: listing.IncomeIndicator,
                            minority: listing.MinorityPercent,
                            totalpopulation: listing.TotalPopulation,
                            hispanic: listing.Hispanic,
                            black: listing.Black,
                            asian: listing.Asian,
                            pacific: listing.Pacific,
                            msa: listing.MsaCode,
                        }
                    })
                    setListingUnavailable(false)
                    qualifiedData = getQualifiedAddrByProg(data)
                    qualifiedData.sort((a,b) => b["price"] - a["price"])
                    qualifiedData.forEach((item, index) => {
                        item["squence"] = index + 1;
                    });
                }
                setLoading(false)
                setListingData(qualifiedData)
                setCurrentFilterType("")
                setCurrentSortType("price")
                setOriginalListingData(qualifiedData)
                setShowListingFilter(true)
            })
        })
        .catch((err) => {
            console.log(err);
            alert("Failed to get listing data. Please try again later.")
        })
    }

    const renderInfoPanel = () => {
        return (
            <div className='infoPanelWrap'>
                <Row>
                    { hoverRecord?
                        <div className='justify-content-center'>
                            <div className='d-flex justify-content-center'>
                                <div className='msaHighlight'>MSA {hoverRecord.msa}</div>
                            </div>
                            <div className="addressHighlight">{hoverRecord.address}</div>
                        </div>
                        :
                        <div className='addressHighlight'>Mouse over an address to see tract info</div>
                    }
                </Row>
                <Row className='tractInfoBox'>
                    <div>
                        <div>Tract Minority: <span className='tractValueFont'>{hoverRecord?hoverRecord.minority+"%":"--"}</span></div>
                        {
                            hoverRecord?
                            <div>
                                Majority AA/HP: <span className='tractValueFont'>{parseInt(hoverRecord.hispanic) + parseInt(hoverRecord.black)} ({(100*(parseInt(hoverRecord.hispanic) + parseInt(hoverRecord.black))/hoverRecord.totalpopulation).toFixed(0)}%)</span>
                            </div>
                            :
                            <div>Majority AA/HP: <span className='tractValueFont'>{"--"}</span></div>
                        }
                        <div>Total Population: <span className='tractValueFont'>{hoverRecord?hoverRecord.totalpopulation:"--"}</span></div>
                    </div>
                    <div>
                        {
                            hoverRecord?
                            <div>Hispanic Population: <span className='tractValueFont'>{hoverRecord.hispanic} ({(100*hoverRecord.hispanic/hoverRecord.totalpopulation).toFixed(0)}%)</span></div>
                            :
                            <div>Hispanic Population: <span className='tractValueFont'>{"--"}</span></div>
                        }
                        {
                            hoverRecord?
                            <div>Black Population: <span className='tractValueFont'>{hoverRecord.black} ({(100*hoverRecord.black/hoverRecord.totalpopulation).toFixed(0)}%)</span></div>
                            :
                            <div>Black Population: <span className='tractValueFont'>{"--"}</span></div>
                        }
                        {
                            hoverRecord?
                            <div>Asian Population: <span className='tractValueFont'>{parseInt(hoverRecord.asian) + parseInt(hoverRecord.pacific)} ({(100*(parseInt(hoverRecord.asian) + parseInt(hoverRecord.pacific))/hoverRecord.totalpopulation).toFixed(0)}%)</span></div>
                            :
                            <div>Asian Population: <span className='tractValueFont'>{"--"}</span></div>
                        }
                    </div>
                </Row>
                {hoverRecord && <Row className='tractInfoTags'>
                    {(hoverRecord.incomeindicator === "1" || hoverRecord.incomeindicator === "2") && <div>Low-to-moderate</div>}
                    {hoverRecord.minority > 50 && <div>In MMCT</div>}
                    {(parseInt(hoverRecord.hispanic) + parseInt(hoverRecord.black))/hoverRecord.totalpopulation > 50 && <div>Majoriry AAHP</div>}
                    {hoverRecord.price <= loanLimit && <div>Diamond</div>}
                </Row>}
            </div>
        )
    }

    return (
        <div>
            <Carousel id="gpsFormCarousel" ref={gpsCarouselRef} afterChange={setCurrentSlide} dots={false} effect='fade' touchMove={false}>
                <div className='gpsSlideOne'>
                    <div className="gpsCheckHeader">Marketing/GPS Radius Check</div>
                    <Form onSubmit={handleGPSCheckFormSubmit}>
                        <Row>
                            <Col md={10} xs={12}>
                                <AddressAutocompleteWithGPS
                                    id='gpssearch-address'
                                    label="Target address"
                                    required={true}
                                    name="addessline"
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    placeholder="Please enter target property address"
                                    goodfeedback="Looks good!"
                                    badfeedback="Please provide target property address."
                                    defaultValue={gpsDisplayedAddress}
                                    onChange={(e) => setGPSDisplayedAddress(e.target.value)}
                                    size="md"
                                    onCompletion={(address, lat, long) => onStreetAddressCompletion(address, lat, long, true)}
                                />
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Label>Distance</Form.Label>
                                <Input 
                                    suffix="mi"
                                    value={searchRadius}
                                    onChange={(e) => setSearchRadius(e.target.value)}
                                    placeholder="Search distance"
                                />
                            </Col>
                        </Row>
                        <Row className="preFilterWrap">
                            <Col md={6} xs={12}>
                                <Form.Group >
                                    <Form.Label className="text-left">Type</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            setGPSMsaType(e.target.value);
                                        }}
                                        value={GPSMsaType}
                                    >
                                        <option value="all">All</option>
                                        <option value="grandslam">GMCC Grandslam</option>
                                        <option value="conforming">GMCC Special Conforming</option>
                                        <option value="nonagency">GMCC Jumbo CRA</option>
                                        <option value="diamond">GMCC Diamond Community Lending</option>
                                        <option value="grants">GMCC $5K - $15K Grant</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12}>
                                {
                                    GPSMsaType !== "all" && GPSMsaType === "grants" ?
                                    <Form.Group >
                                        <Form.Label className="text-left">State</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            as="select"
                                            onChange={(e) => {
                                                setGrantState(e.target.value)
                                            }}
                                            value={grantState}
                                        >
                                            <option value="California">California</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Massachusetts">Massachusetts</option>
                                            <option value="North Carolina">North Carolina</option>
                                            <option value="South Carolina">South Carolina</option>
                                        </Form.Control>
                                    </Form.Group>
                                    :
                                    GPSMsaType !== "all" && GPSMsaType === "diamond" ?
                                    <Form.Group >
                                        <Form.Label className="text-left">Target City (optional, available for all 50 states)</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            onChange={(e) => {
                                                setDiamondCity(e.target.value)
                                            }}
                                            value={diamondCity}
                                        />
                                    </Form.Group>
                                    :
                                    GPSMsaType !== "all" && (GPSMsaType === "grandslam" || GPSMsaType === "conforming" || GPSMsaType === "nonagency") ?
                                    <Form.Group >
                                        <Form.Label className="text-left">MSA</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            as="select"
                                            onChange={(e) => {
                                                setGPSTargetMsa(e.target.value)
                                            }}
                                            value={GPSTargetMsa}
                                        >
                                            {getGPSMSAList(GPSMsaType)}
                                        </Form.Control>
                                    </Form.Group>
                                    :
                                    <div></div>
                                }
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Button className="gpsSearchButton" onClick={handleGPSCheckSearch}>{loading?<Spin />:"Search"}</Button>
                        </Row>
                    </Form>

                    <div className='my-4 w-100' style={{display:"flex",paddingRight:"15px", paddingLeft:"15px"}}>
                        <div md={4} xs={12} className='gpsCheckLeftPanel'>
                            {renderInfoPanel()}
                        </div>
                        <div md={8} xs={12} className='gpsCheckRightPanel'>
                            <div className='comingSoon'>GPS Map Coming Soon</div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                        {/* <div className='mt-2' style={{paddingLeft:"15px"}}>
                            <Select
                                mode="multiple"
                                className="massiveTableFilter"
                                placeholder="Filter by"
                                allowClear
                                value={currentFilterType}
                                onChange={(value) => handleMassiveTableFilter(value)}
                            >
                                <Select.Option key={"default"} value={""}>Filter by</Select.Option>
                                <Select.Option key={"minority"} value={"minority"}>MMCT</Select.Option>
                                <Select.Option key={"incomeindicator"} value={"incomeindicator"}>Low-to-moderate</Select.Option>
                                <Select.Option key={"AAHP"} value={"AAHP"}>Majority AA/HP</Select.Option>
                                <Select.Option key={"less500"} value={"less500"}>Under $500K</Select.Option>
                                <Select.Option key={"500to1000"} value={"500to1000"}>$500K - $1M</Select.Option>
                                <Select.Option key={"1000to3000"} value={"1000to3000"}>$1M - $3M</Select.Option>
                                <Select.Option key={"over3000"} value={"over3000"}>Over $3M</Select.Option>
                            </Select>
                        </div> */}
                        <div className='mt-2' style={{paddingLeft:"15px"}}>
                            <Select
                                mode="multiple"
                                placeholder="Filter by tract"
                                allowClear
                                className="massiveTableFilter"
                                value={currentFilter1}
                                onChange={(value) => setCurrentFilter1(value)}
                            >
                                <Select.Option value={"minority"}>MMCT</Select.Option>
                                <Select.Option value={"AAHP"}>AA/HP</Select.Option>
                                <Select.Option value={"incomeindicator"}>Low-to-moderate</Select.Option>
                            </Select>
                        </div>
                        <div className='mt-2' style={{paddingLeft:"15px"}}>
                            <Select
                                placeholder="Filter by price" 
                                className="massiveTableFilter" 
                                value={currentFilter2}
                                onChange={(value) => setCurrentFilter2(value)}
                            >
                                <Select.Option value={""}>Filter by price</Select.Option>
                                <Select.Option value={"less500"}>Up to $500K </Select.Option>
                                <Select.Option value={"500to1000"}>Between $500K - $1M</Select.Option>
                                <Select.Option value={"1000to3000"}>Between $1M - $3M</Select.Option>
                                <Select.Option value={"over3000"}>Over $3M</Select.Option>
                            </Select>
                        </div>
                        <div className='resetFilters' onClick={resetFilters}>reset filters</div>
                    </div>
                    <div className="w-100" style={{paddingRight:"15px", paddingLeft:"15px"}}>
                        <Table 
                            className='gpsCheckTable'
                            columns={GPSListingColumns}
                            dataSource={listingData}
                            pagination={pagination}
                            onRow={(record) => ({
                                onClick: () => {
                                    setListingTarget(record.address)
                                    copyTarget(record.address)
                                    setSelectedRecord(record)
                                },
                                onMouseEnter: () => {
                                    setHoverRecord(record)
                                },
                            })}
                            scroll={{ x: "max-content" }}
                            rowClassName="gpsTableRow"
                            onChange={handleTableChange}
                            locale={{emptyText: GPSsearchClicked?"No listings found for current search condition":"No Data"}}
                        />
                    </div>
                </div>
                <div className='gpsSlideOne'>
                    <div className="fastResultMobileView" style={{display:"flex", flexDirection:"column"}}>
                        <div className='resultPaneHeader'>
                            <div className='text-left' style={{cursor:"pointer"}} onClick={()=>goToPrevGPS()}><LeftOutlined className='mr-2'/>Back</div>
                            {<div>
                                <div className="chooseFliersWrap">
                                    <div style={{fontWeight:"500"}}>Enter your DRE ID as needed for DRE endorsement:</div>
                                    <input 
                                        value={dreID}
                                        onChange={(e) => setDreID(e.target.value)}
                                        onBlur={(e) => setHaveDRE(e.target.value !== "")}
                                        placeholder='enter your DRE ID here'
                                        className='dreIDInput'
                                    />
                                    
                                </div>
                                
                            </div>}
                        </div>
                        <div className='text-center resultHeading mt-2'>{massiveResultsCount} programs found:</div>
                        <Popconfirm
                            okText='OK'
                            className='realtorInfoPop'
                            showCancel={false}
                            icon={null}
                            onConfirm={()=>{
                                if (realtorName === "" && realtorID ==="" && realtorEmail === "" && realtorPhone === ""){
                                    setRealtorInfoFilled(false)
                                }
                                else {
                                    setRealtorInfoFilled(true)
                                }
                            }}
                            description ={
                                <div className='realtorInfoWrap'>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Realtor name</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorName(e.target.value)}
                                            value ={realtorName}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>License number</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorID(e.target.value)}
                                            value ={realtorID}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Phone number</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorPhone(e.target.value)}
                                            value={realtorPhone}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Email</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorEmail(e.target.value)}
                                            value={realtorEmail}
                                        />
                                    </Form.Group>
                                </div>
                            }
                        >
                            <div className='d-flex justify-content-center w-100'>
                                <div className="realtorInfoBtn">Insert Realtor Info</div>
                            </div>
                        </Popconfirm>
                        <div className="massiveResultWrapMobile" style={{marginTop:"10px", padding:"0px 10px 0px 10px"}}>
                            {
                                searchMassiveResults.map((result, idx)=> {
                                    return (
                                            <div className='massiveResultContainer'>
                                                <div style={{fontWeight:"500",fontSize:"16px"}}>{result.label}</div>
                                                {/*Massive check flyergenerator*/}
                                                <FlyerGenerator
                                                    disabled={false}
                                                    buttonClass="downloadFlierLink"
                                                    display='none'
                                                    programName={`${result.label} program`}
                                                    buttonLabel='Download my flyer'
                                                    cta="Apply with me now for this qualified address!"
                                                    addressInfo={listingTarget}
                                                    name={name}
                                                    nmls={nmls}
                                                    dre={dreID!==""?"DRE# "+dreID:""}
                                                    email={email}
                                                    phone={phone}
                                                    reName={realtorName}
                                                    reID={realtorID}
                                                    rePhone={realtorPhone}
                                                    reEmail={realtorEmail}
                                                    hasRE = {realtorInfoFilled}
                                                    gotoCelebrity={props.gotoCelebrity}
                                                    imgsrc={`/images/gmccflyers/${programs_templateMap[result.label]}`}
                                                    ratesheet={programs_pricesheetMap[result.label]?"/images/gmccflyers/"+programs_pricesheetMap[result.label]:""}
                                                    loID={com.getLoId()}
                                                    chosenMode={chosenMode}
                                                />
                                            </div>
                                    )
                                })
                            }
                        </div>
                        {searchMassiveResults.length === 0 && <div className='d-flex align-items-center' style={{backgroundColor:"#EAEFFD", height:"400px", paddingLeft:"40px", paddingRight:"40px"}}>
                            <div className='text-center' style={{fontSize:"24px", fontWeight:"500"}}>Could not find matching program results, please try adjust your scenario and search again.</div>
                        </div>}
                    </div>
                </div>
            </Carousel>
        </div>
    )
  }

  const formTypes = [
    {
        key: `fast`,
        label: "Fast Check",
        children: <div className="fastSectionWrap">
            <Carousel id="fastFormCarousel" ref={carouselRef} afterChange={setCurrentSlide} dots={false} effect='fade' touchMove={false}>
                <div className='scenarioSlide'>
                    <div className="checkPaneTitle">CRA Address Fast Check</div>
                    <Form className="fastFormLayout" onSubmit={handleFastFormSubmit}>
                        <div className="mobileScrollControl">
                            <Row>
                                <Col md={isPad?12:12} xs={12} >
                                    <AddressAutocomplete
                                        id='fastsearch-address'
                                        label="Property address"
                                        required={true}
                                        name="addessline"
                                        defaultValue={displayedAddress}
                                        onChange={(e) => setDisplayedAddress(e.target.value)}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        placeholder="Please type your home address and select from the dropdown addresses"
                                        goodfeedback="Looks good!"
                                        badfeedback="Please provide your street address."
                                        onCompletion={e => onStreetAddressCompletion(e)}
                                        size="md"
                                    />
                                </Col>                  
                            </Row>
                            <Row className=" ">
                                <Col xs={12} md={isPad?12:3}>
                                    <Form.Label className="text-left">Property state</Form.Label>
                                        <Form.Group >
                                            <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            placeholder="State"
                                            onChange={(e) => {
                                                setPropertyState(e.target.value, 'borrower');
                                            }}
                                            value={propertyState}
                                            >
                                            {getStates()}
                                            </Form.Control>
                                        </Form.Group>
                                </Col> 
                                <Col xs={12} md={isPad?12:3}>
                                <Form.Group >
                                    <Form.Label className="text-left">Property county</Form.Label>
                                    <Form.Control
                                    required
                                    size="sm"
                                    as="select"
                                    onChange={(e) => {
                                        setPropertyCounty(e.target.value);
                                    }}
                                    value={propertyCounty}
                                    >
                                    {getCounties()}
                                    </Form.Control>
                                </Form.Group>
                                </Col>
                                <Col md={isPad?12:3} xs={12}>
                                    <Form.Label>Annual gross income</Form.Label>
                                    <CommaizedInput value={income} onChange={e => setIncome(e.target.value)} withDollarSign />
                                </Col>
                                <Col md={isPad?12:3} xs={12}>
                                    <Form.Group controlId="checkfghb" className="">
                                        <Form.Label className="text-left">First generation home buyer?</Form.Label>
                                        <Form.Control
                                        name="checkfghb"
                                        as="select"
                                        size="md"
                                        defaultValue={firstGenHomeBuyer}
                                        onChange={(e) => setFirstGenHomeBuyer(e.target.value)}
                                        >
                                        <option value="">...</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {showPropertyInfo && <div style={{display:"flex", justifyContent:"center"}}>
                                {loadingTractData && <div><Spin /></div>}
                                {!loadingTractData && <div className='tractInfoPreview'>
                                    <div className="previewMSA">
                                        <div className='previewMSAInfo'>
                                            <div>MSA Code:</div>
                                            <div>{msaCode}</div>
                                        </div>
                                    </div>
                                    
                                    <hr />
                                    <div className='populationIncomeWrap'>
                                        <div className="previewPopulation">
                                            <div className='previewInfoWrap'>
                                                <div>Tract Income Level:</div> 
                                                {incomeIndicator === 1 && <div>Low Income Area</div>}
                                                {incomeIndicator === 2 &&<div>Moderate Income Area</div>}
                                                {incomeIndicator === 3 &&<div>Middle Income Area</div>}
                                                {incomeIndicator === 4 &&<div>Upper Income Area</div>}
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>Tract Minority:</div>
                                                <div>{Math.floor(minority)}%</div>
                                            </div>

                                            <div className='previewInfoWrap'>
                                                <div>Total Population:</div>
                                                <div>{totalPopulation}</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>Hispanic Population:</div>
                                                <div>{hispanic} ({(100*hispanic/totalPopulation).toFixed(0)}%)</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>Black Population:</div>
                                                <div>{black} ({(100*black/totalPopulation).toFixed(0)}%)</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>Asian/Pacific Population:</div>
                                                <div>{asian+pacific} ({(100*(asian+pacific)/totalPopulation).toFixed(0)}%)</div>
                                            </div>
                                        </div>

                                        <div className='previewIncomeLimit'>
                                            <div className='previewInfoWrap'>
                                                <div>80% AMI:</div>
                                                <div>${com.commaize(0.8* medianIncome)}</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>100% AMI:</div>
                                                <div>${com.commaize(medianIncome)}</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>150% AMI:</div>
                                                <div>${com.commaize(1.5*medianIncome)}</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>80% MFI:</div>
                                                <div>${com.commaize(0.8* mfi)}</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>100% MFI:</div>
                                                <div>${com.commaize(mfi)}</div>
                                            </div>
                                            <div className='previewInfoWrap'>
                                                <div>150% MFI:</div>
                                                <div>${com.commaize(1.5* mfi)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>}
                            <div className='ml-3' style={{marginBottom:"10px", marginTop:"10px", fontSize:"12px", fontWeight:"500"}}>
                                <div>Looking for listing property address to check?</div>
                                <div>Visit  <span onClick={() => window.open("https://www.mls.com","_blank")} style={{color:"#325CEB", textDecoration:"underline"}}>MLS Listing</span> website to find a target property address.</div>
                            </div>
                        </div>
                        <div className='text-center mt-2 d-flex justify-content-center'>
                            <Button type="submit" className="programEngineSearch">{loading? <div className='gmccSpin'><Spin /></div>:"Search"}</Button>
                        </div>
                    </Form>
                </div>
                <div className='scenarioSlide'>
                    <div className="fastResultMobileView" style={{display:"flex", flexDirection:"column"}}>
                        <div className='resultPaneHeader'>
                            <div className='text-left' style={{cursor:"pointer"}} onClick={()=>goToPrev()}><LeftOutlined className='mr-2'/>Back</div>
                            {propertyState.includes("California") && <div>
                                <div className="chooseFliersWrap">
                                    <div style={{fontWeight:"500"}}>Enter your DRE ID as needed for DRE endorsement:</div>
                                    <input 
                                        value={dreID}
                                        onChange={(e) => setDreID(e.target.value)}
                                        onBlur={(e) => setHaveDRE(e.target.value !== "")}
                                        placeholder='enter your DRE ID here'
                                        className='dreIDInput'
                                    />
                                    
                                </div>
                                
                            </div>}
                        </div>
                        <div className='text-center resultHeading mt-2'>{resultsCount} programs matched:</div>
                        <Popconfirm
                            okText='OK'
                            className='realtorInfoPop mb-2'
                            showCancel={false}
                            icon={null}
                            onConfirm={()=>{
                                if (realtorName === "" && realtorID ==="" && realtorEmail === "" && realtorPhone === ""){
                                    setRealtorInfoFilled(false)
                                }
                                else {
                                    setRealtorInfoFilled(true)
                                }
                            }}
                            description ={
                                <div className='realtorInfoWrap'>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Realtor name</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorName(e.target.value)}
                                            value ={realtorName}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>License number</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorID(e.target.value)}
                                            value ={realtorID}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Phone number</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorPhone(e.target.value)}
                                            value={realtorPhone}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Email</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorEmail(e.target.value)}
                                            value={realtorEmail}
                                        />
                                    </Form.Group>
                                </div>
                            }
                        >
                            <div className='d-flex justify-content-center w-100'>
                                <div className="realtorInfoBtn">Insert Realtor Info</div>
                            </div>
                        </Popconfirm>
                        {resultsCount !== 0 && <>
                            <div className='actionPanel'>
                                <Select 
                                    options={fastFlierSelectOptions}
                                    className='chooseFliers ml-2'
                                    value={flierChosen.value}
                                    onChange={handleSwitchFlier}
                                />
                                <FlyerGenerator 
                                    disabled={false}
                                    buttonClass="downloadFlierLink"
                                    display='none'
                                    programName={`${flierChosen.value === "" ? "No": flierChosen.label} program`}
                                    buttonLabel='Download my flyer'
                                    cta="Apply with me now for this qualified address!"
                                    addressInfo={displayedAddress}
                                    name={name}
                                    nmls={nmls}
                                    dre={dreID!==""?"DRE# "+dreID:""}
                                    email={email}
                                    phone={phone}
                                    reName={realtorName}
                                    reID={realtorID}
                                    rePhone={realtorPhone}
                                    reEmail={realtorEmail}
                                    hasRE = {realtorInfoFilled}
                                    imgsrc={`/images/gmccflyers/${flierChosen.value === ""? "Template_15K.png" : programs_templateMap[flierChosen.label]}`}
                                    ratesheet={programs_pricesheetMap[flierChosen.label]?"/images/gmccflyers/"+programs_pricesheetMap[flierChosen.label]:""}
                                    loID={com.getLoId()}
                                    gotoCelebrity={props.gotoCelebrity}
                                    chosenMode={chosenMode}
                                />
                            </div>
                        </>}

                        {searchFastResults.length === 0 && <div className='d-flex align-items-center' style={{backgroundColor:"#EAEFFD", height:"400px", paddingLeft:"40px", paddingRight:"40px"}}>
                            <div className='text-center' style={{fontSize:"24px", fontWeight:"500"}}>Could not find matching program results, please try adjust your scenario and search again.</div>
                        </div>}
                        <div className="fastCarouselWrap">
                            <LeftOutlined className='fastArrow' onClick={()=>fastCarouselRef.current.prev()} />
                            <div className='fastCarouselContentBody'>
                                <Carousel 
                                    id="fastResultCarousel"
                                    ref={fastCarouselRef} 
                                    afterChange={setFastCurrentSlide} 
                                    dots={false} 
                                    effect='fade' 
                                    touchMove={false} 
                                >
                                    {searchFastResults.map((result, idx)=> {
                                        return (
                                            <div className='fastResultCard'>
                                                <div className='fastResultLabel'>{result.label}</div>
                                                <div className='fastResultContent'>{result.children}</div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <RightOutlined className='fastArrow' onClick={()=>fastCarouselRef.current.next()} />
                        </div>
                        {/* <div className='mt-3'>*For full restictions check, please use in-depth check or reference GMCC program guidelines and GMCC AI support. </div> */}
                    </div>
                </div>
            </Carousel>
        </div>
    },
    {
        key: `advanced`,
        label: "In-depth Check",
        children:                 
        <div className='advancedSectionWrap d-flex flex-row'>
        {/* <div style={{fontSize:"12px", marginBottom:"10px"}}>*In current draft version, search scenario is limited to primary purchase of 1 unit property. More scenario options comming soon.</div> */}
        <Carousel
            id="advancedFormCarousel"
            ref={advanceCarouselRef}
            afterChange={setAdvanceCurrentSlide}
            dots={false}
            effect='fade'
            touchMove={false}
            style={{width: showAdvancedCheckSearchLogs ? "70%" : "100%"}}
        >
            <div className='scenarioSlide' style={{marginBottom:isPad?"300px":""}}>
                <div className="checkPaneTitle">
                    Specialty Eligibility Advanced Check
                    <div style={{cursor:"pointer", fontSize:"12px"}} onClick={() => setShowAdvancedCheckSearchLogs(!showAdvancedCheckSearchLogs)}>Search history</div>
                </div>
                <Form className='px-3' onSubmit={handleSubmit}>
                    <Steps className='my-3' current={currentStep} items={stepItems}></Steps>
                        {currentStep === 0 && <div className="stepContentWrap">
                            <div>
                                <Row>
                                    <Col md={12} >
                                    <AddressAutocomplete
                                        id='programengineaddress'
                                        label="Property address"
                                        required={true}
                                        name="addessline"
                                        defaultValue={displayedAddress}
                                        onChange={(e) => setDisplayedAddress(e.target.value)}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        placeholder="Please type your home address and select from the dropdown addresses"
                                        goodfeedback="Looks good!"
                                        badfeedback="Please provide your street address."
                                        onCompletion={e => onStreetAddressCompletion(e)}
                                        size="md"
                                    />
                                    </Col>
                                    {/* <Col md={6}>
                                        <Form.Group controlId="purpose" className="">
                                            <Form.Label className="text-left">Number of units</Form.Label>
                                            <Form.Control
                                            required
                                            name="units"
                                            as="select"
                                            size="md"
                                            defaultValue={units}
                                            onChange={(e) => setUnits(e.target.value)}
                                            >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col> */}                         
                                </Row>
                                <Row className=" ">
                                    <Col xs={12} md={isPad?12:6}>
                                    <Form.Label className="text-left">Property state</Form.Label>
                                    <Form.Group >
                                        <Form.Control
                                        required
                                        size="sm"
                                        as="select"
                                        placeholder="State"
                                        onChange={(e) => {
                                            setPropertyState(e.target.value);
                                        }}
                                        value={propertyState}
                                        >
                                        {getStates()}
                                        </Form.Control>
                                    </Form.Group>
                                    </Col>
                                    <Col xs={12} md={isPad?12:6}>
                                    <Form.Group >
                                        <Form.Label className="text-left">Property county</Form.Label>
                                        <Form.Control
                                        required
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            setPropertyCounty(e.target.value);
                                        }}
                                        value={propertyCounty}
                                        >
                                        {getCounties()}
                                        </Form.Control>
                                    </Form.Group>
                                    </Col>
                                </Row>
                                {showPropertyInfo && <div style={{display:"flex", justifyContent:"center"}}>
                                    {loadingTractData && <div><Spin /></div>}
                                    {!loadingTractData && <div className='tractInfoPreview'>
                                        <div className="previewMSA">
                                            <div className='previewMSAInfo'>
                                                <div>MSA Code:</div>
                                                <div>{msaCode}</div>
                                            </div>
                                        </div>
                                        
                                        <hr />
                                        <div className='populationIncomeWrap'>
                                            <div className="previewPopulation">
                                                <div className='previewInfoWrap'>
                                                    <div>Tract Income Level:</div> 
                                                    {incomeIndicator === 1 && <div>Low Income Area</div>}
                                                    {incomeIndicator === 2 &&<div>Moderate Income Area</div>}
                                                    {incomeIndicator === 3 &&<div>Middle Income Area</div>}
                                                    {incomeIndicator === 4 &&<div>Upper Income Area</div>}
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Tract Minority:</div>
                                                    <div>{Math.floor(minority)}%</div>
                                                </div>

                                                <div className='previewInfoWrap'>
                                                    <div>Total Population:</div>
                                                    <div>{totalPopulation}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Hispanic Population:</div>
                                                    <div>{hispanic} ({(100*hispanic/totalPopulation).toFixed(0)}%)</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Black Population:</div>
                                                    <div>{black} ({(100*black/totalPopulation).toFixed(0)}%)</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Asian/Pacific Population:</div>
                                                    <div>{asian+pacific} ({(100*(asian+pacific)/totalPopulation).toFixed(0)}%)</div>
                                                </div>
                                            </div>

                                            <div className='previewIncomeLimit'>
                                                <div className='previewInfoWrap'>
                                                    <div>80% AMI:</div>
                                                    <div>${com.commaize(0.8* medianIncome)}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>100% AMI:</div>
                                                    <div>${com.commaize(medianIncome)}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>150% AMI:</div>
                                                    <div>${com.commaize(1.5*medianIncome)}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>80% MFI:</div>
                                                    <div>${com.commaize(0.8* mfi)}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>100% MFI:</div>
                                                    <div>${com.commaize(mfi)}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>150% MFI:</div>
                                                    <div>${com.commaize(1.5* mfi)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>}
                                <div className='ml-3' style={{marginBottom:"30px", marginTop:"20px", fontSize:"12px", fontWeight:"500"}}>
                                    <div>Looking for listing property address to check?</div>
                                    <div>Visit  <span onClick={() => window.open("https://www.mls.com","_blank")} style={{color:"#325CEB", textDecoration:"underline"}}>MLS Listing</span> website to find a target property address.</div>
                                </div>
                            </div>
                            <div className='text-center mt-2 d-flex justify-content-center'>
                                <Button className="programEngineSearch" onClick={() => setCurrentStep(1)}>Next</Button>
                            </div>
                        </div>}
                        {currentStep === 1 && <div className="stepContentWrap">
                            <div>
                                <Row>
                                    <Col xs={12} md={isPad?12:3}>
                                    <Form.Group controlId="purpose" className="">
                                        <Form.Label className="text-left">Loan purpose</Form.Label>
                                        <Form.Control
                                        required
                                        name="purpose"
                                        as="select"
                                        size="md"
                                        defaultValue={purpose}
                                        value={purpose}
                                        onChange={(e) => setPurpose(e.target.value)}
                                        >
                                        <option value="">...</option>
                                        <option value="purchase">Purchase</option>
                                        {/* <option value="rateTermRefinance">Rate/Term refinance</option>
                                        <option value="cashOutRefinance">Cash out refinance</option> */}
                                        </Form.Control>
                                    </Form.Group>
                                    </Col>
                                    <Col xs={12} md={isPad?12:3}>
                                    <Form.Group controlId="purpose" className="">
                                        <Form.Label className="text-left">Occupancy</Form.Label>
                                        <Form.Control
                                        required
                                        name="occupancy"
                                        as="select"
                                        size="md"
                                        defaultValue={occupancy}
                                        value={occupancy}
                                        onChange={(e) => setOccupancy(e.target.value)}
                                        >
                                        <option value="">...</option>
                                        <option value="primary">Primary</option>
                                        <option value="investment">Investment</option>
                                        {/* <option value="secondHome">Second home</option> */}
                                        {/* <option value="investment">Investment(rental)</option> */}
                                        </Form.Control>
                                    </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Property price</Form.Label>
                                            <CommaizedInput value={purchasePrice} onChange={e => setPurchasePrice(e.target.value)} withDollarSign />
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Down payment</Form.Label>
                                            <CommaizedInput className="gmccCalculatorInput" value={downPayment} onChange={e => setDownPayment(e.target.value)} withDollarSign />
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Has FICO?</Form.Label>
                                            <Form.Control
                                                name="checkhasfico"
                                                as="select"
                                                size="md"
                                                defaultValue={hasFICO}
                                                onChange={(e) => setHasFICO(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>FICO</Form.Label>
                                            <Form.Control type="number" placeholder="" value={fico} onChange={e => setFico(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Label>Annual gross income</Form.Label>
                                        <CommaizedInput value={income} onChange={e => setIncome(e.target.value)} withDollarSign />
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Label>Monthly debt payment</Form.Label>
                                        <CommaizedInput value={monthDebt} onChange={e => setMonthDebt(e.target.value)} withDollarSign />
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                    <Form.Group controlId="employment" className="">
                                            <Form.Label className="text-left">Employment</Form.Label>
                                            <Form.Control
                                            name="employment"
                                            as="select"
                                            size="md"
                                            defaultValue={employment}
                                            value={employment}
                                            onChange={(e) => setEmployment(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="wageEarner">Wage earner</option>
                                            <option value="selfemployed">Self employed</option>
                                            <option value="noemployment">No employment</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="doclevel" className="">
                                            <Form.Label className="text-left">Documentation level</Form.Label>
                                            <Form.Control
                                            name="documentlevel"
                                            as="select"
                                            size="md"
                                            defaultValue={documentLevel}
                                            value={documentLevel}
                                            onChange={(e) => setDocumentLevel(e.target.value)}
                                            >
                                            {employment !== "noemployment" && <option value="">...</option>}
                                            {employment !=="noemployment" && <option value="fulldoc">Full doc</option>}
                                            <option value="altdoc">Alt doc</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {documentLevel !== "fulldoc" && <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="doclevel" className="">
                                            <Form.Label className="text-left">Document verification type</Form.Label>
                                            <Form.Control
                                            name="documentlevel"
                                            as="select"
                                            size="md"
                                            defaultValue={docVerification}
                                            value={docVerification}
                                            onChange={(e) => setDocVerification(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            {employment === "wageEarner" && <option value="wageEarnerWVOE">Wage earner - WVOE</option>}
                                            {employment === "wageEarner" && <option value="wageEarnerVVOE">Wage earner - VVOE</option>}
                                            {employment === "selfemployed" && <option value="selfemployedPL">Self employed - P&L</option>}
                                            {employment === "selfemployed" && <option value="selfemployedVVOE">Self employed - Borrower prepared P&L</option>}
                                            <option value="noincomedoc">No income doc</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>}
                                </Row>
                                <Row>
                                    <Col md={12} xs={12} className='d-flex justify-content-center'>
                                        <div className="propertyInfoWrap">
                                                <div className='previewInfoWrap'>
                                                    <div>Loan amount:</div> 
                                                    <div>${com.commaize(lAmount)}</div>
                                                </div>  
                                                <div className='previewInfoWrap'>
                                                    <div>LTV:</div>
                                                    <div>{LTV}%</div>
                                                </div>  
                                                <div className='previewInfoWrap'>
                                                    <div>Down payment:</div>
                                                    <div>{downPercent}%</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Estimated monthly PITIA:</div>
                                                    <div>${monthlypayment.toFixed(0)}</div> 
                                                </div> 
                                                <div className='previewInfoWrap'>
                                                    <div>Estimated DTI: </div>
                                                    <div>{calcDTI}%</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Estimated Asset Depletion DTI: </div>
                                                    <div>{noDepletion? "Not applicable": (notSufficient? "Asset insufficient":depletionDTI+"%")}</div>
                                                </div>
                                                <div className='previewInfoWrap'>
                                                    <div>Estimated closing costs: </div>
                                                    <div>${closingCost.toFixed(0)}</div>
                                                </div>    
                                        </div>
                                    
                                    </Col>
                                </Row>
                            </div>
                            <div className='text-center d-flex justify-content-center'>
                                <Button className="programEngineSearch mr-3" onClick={() => setCurrentStep(0)}>Previous</Button>
                                <Button className="programEngineSearch" onClick={() => setCurrentStep(2)}>Next</Button>
                            </div>
                        </div>}
                        {currentStep === 2 && <div className="stepContentWrap">
                            <div>
                                <Row>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Liquid Assets</Form.Label>
                                            <CommaizedInput className="gmccCalculatorInput" value={liquidassets} onChange={e => setLiquidassets(e.target.value)} withDollarSign />
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Borrower age under 59.5 years old?</Form.Label>
                                            <Form.Control
                                                name="checkage"
                                                as="select"
                                                size="md"
                                                defaultValue={ageUnder59}
                                                value={ageUnder59}
                                                onChange={(e) => setAgeUnder59(e.target.value)}
                                            >
                                                <option value={"true"}>Yes</option>
                                                <option value={"false"}>No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {ageUnder59 === "false" && <Col md={isPad?12:3} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Non-liquid Assets</Form.Label>
                                            <CommaizedInput className="gmccCalculatorInput" value={nonliquidassets} onChange={e => setNonliquidassets(e.target.value)} withDollarSign />
                                        </Form.Group>
                                    </Col>}
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checkforeigner" className="">
                                            <Form.Label className="text-left">Foreign national?</Form.Label>
                                            <Form.Control
                                            name="checkforeigner"
                                            as="select"
                                            size="md"
                                            defaultValue={foreignVisa}
                                            value={foreignVisa}
                                            onChange={(e) => setForeignVisa(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="B1">B1 visa</option>
                                            <option value="F1">F1 visa</option>
                                            <option value="J1">J1 visa</option>
                                            <option value="notforeigner">Not a foreign national</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checkfthb" className="">
                                            <Form.Label className="text-left">First time home buyer?</Form.Label>
                                            <Form.Control
                                            name="checkfthb"
                                            as="select"
                                            size="md"
                                            defaultValue={firstTimeHomeBuyer}
                                            value={firstTimeHomeBuyer}
                                            onChange={(e) => setFirstTimeHomeBuyer(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checkfghb" className="">
                                            <Form.Label className="text-left">First generation home buyer?</Form.Label>
                                            <Form.Control
                                            name="checkfghb"
                                            as="select"
                                            size="md"
                                            defaultValue={firstGenHomeBuyer}
                                            value={firstGenHomeBuyer}
                                            onChange={(e) => setFirstGenHomeBuyer(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checkownedmortgage" className="">
                                            <Form.Label className="text-left">Own a property with a mortgage?</Form.Label>
                                            <Form.Control
                                            name="checkownedmortgage"
                                            as="select"
                                            size="md"
                                            defaultValue={propertywithMortgage}
                                            value={propertywithMortgage}
                                            onChange={(e) => setPropertywithMortgage(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="withMortgage">Yes</option>
                                            <option value="withoutMortgage">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checklistingproperty" className="">
                                            <Form.Label className="text-left">Has property currently listing/intend to sell?</Form.Label>
                                            <Form.Control
                                            name="checklistingproperty"
                                            as="select"
                                            size="md"
                                            defaultValue={hasListProperty}
                                            value={hasListProperty}
                                            onChange={(e) => setHasListProperty(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {hasListProperty === "true" && <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checkequity" className="">
                                            <Form.Label className="text-left">Own at least 20% equity of selling property?</Form.Label>
                                            <Form.Control
                                            name="checkequity"
                                            as="select"
                                            size="md"
                                            defaultValue={sufficientEquity}
                                            value={sufficientEquity}
                                            onChange={(e) => setSufficientEquity(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>}
                                    <Col md={isPad?12:3} xs={12}>
                                        <Form.Label>Total debts before closing</Form.Label>
                                        <CommaizedInput value={totaldebts} onChange={e => setTotalDebts(e.target.value)} withDollarSign />
                                    </Col>
                                    { occupancy === "investment" && <Col md={isPad?12:3} xs={12}>
                                        <Form.Group controlId="checkIsRentalFlow" className="">
                                            <Form.Label className="text-left">Using rental income to qualify? </Form.Label>
                                            <Form.Control
                                            name="checkrentalflow"
                                            as="select"
                                            size="md"
                                            defaultValue={isRentalFlow}
                                            value={isRentalFlow}
                                            onChange={(e) => setIsRentalFlow(e.target.value)}
                                            >
                                            <option value="">...</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>}
                                    {isRentalFlow === "true" &&
                                        <Col md={isPad?12:3} xs={12}>
                                            <Form.Label>Gross monthly rental income generated by the propery</Form.Label>
                                            <CommaizedInput value={rentalIncome} onChange={e => setRentalIncome(e.target.value)} withDollarSign />
                                        </Col>
                                    }   
                                </Row>
                                <Row>
                                    <Col md={12} xs={12} className='d-flex justify-content-center'>
                                        <div className="propertyInfoWrap">
                                                <div className='previewInfoWrap'>
                                                    <div>DTA ratio:</div> 
                                                    <div>{totalAssetAmount === 0? "Assets not declared":DTA+"%"}</div>
                                                </div>  
                                                {occupancy === "investment" && isRentalFlow && <div className='previewInfoWrap'>
                                                    <div>DSCR ratio:</div>
                                                    <div>{isRentalFlow === "false"? "---":DSCRRatio}</div>
                                                </div>} 
                                        </div>
                                    
                                    </Col>
                                </Row>
                            </div>
                            <div className='text-center d-flex justify-content-center'>
                                <Button className="programEngineSearch mr-3" onClick={() => setCurrentStep(1)}>Previous</Button>
                                <Button type="submit" className="programEngineSearch">{loading? <div className='gmccSpin'><Spin /></div>:"Search"}</Button>
                            </div>
                        </div>}
                </Form>
            </div>
            <div className='scenarioSlide'>
                <div className='resultPaneHeader'>
                    <div 
                        className='text-left' 
                        style={{cursor:"pointer"}} 
                        onClick={
                            ()=>{
                                setCurrentStep(0)
                                setAdvanceCurrentSlide(advanceCurrentSlide-1)
                                advanceCarouselRef.current.prev()
                            }
                        }
                    >
                        <LeftOutlined className='mr-2'/>Back
                    </div>
                    {propertyState.includes("California") && <div className="chooseFliersWrap">
                        <div className="mobileLmargin" style={{fontWeight:"500"}}>Enter your DRE ID as needed for DRE endorsement:</div>
                        <input 
                            value={dreID}
                            onChange={(e) => setDreID(e.target.value)}
                            onBlur={(e) => setHaveDRE(e.target.value !== "")}
                            placeholder='enter your DRE ID here'
                            className='dreIDInput'
                        />
                    </div> }      
                </div>
                <div className='text-center resultHeading mt-2'>
                    <div>{resultsCount} matched results</div>
                    <div className='resultHeading-sub'><span style={{color:"green"}}>{qualifiedCounts} qualified</span></div>


                </div>
                <Popconfirm
                    okText='OK'
                    className='realtorInfoPop mb-2'
                    showCancel={false}
                    icon={null}
                    onConfirm={()=>{
                        if (realtorName === "" && realtorID ==="" && realtorEmail === "" && realtorPhone === ""){
                            setRealtorInfoFilled(false)
                        }
                        else {
                            setRealtorInfoFilled(true)
                        }
                    }}
                    description ={
                        <div className='realtorInfoWrap'>
                            <Form.Group>
                                <Form.Label className='text-left'>Realtor name</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    as="input"
                                    onChange={(e) => setRealtorName(e.target.value)}
                                    value ={realtorName}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='text-left'>License number</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    as="input"
                                    onChange={(e) => setRealtorID(e.target.value)}
                                    value ={realtorID}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='text-left'>Phone number</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    as="input"
                                    onChange={(e) => setRealtorPhone(e.target.value)}
                                    value={realtorPhone}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='text-left'>Email</Form.Label>
                                <Form.Control 
                                    size="sm"
                                    as="input"
                                    onChange={(e) => setRealtorEmail(e.target.value)}
                                    value={realtorEmail}
                                />
                            </Form.Group>
                        </div>
                    }
                >
                    <div className='d-flex justify-content-center w-100'>
                        <div className="realtorInfoBtn">Insert Realtor Info</div>
                    </div>
                </Popconfirm>
                {searchResults.length === 0  ?
                    <div>
                        <div className='mt-2 d-flex justify-content-center' style={{color:"#CB8600",fontSize:"24px",fontWeight:"500"}}>
                            Please try adjust your scenario and search again.
                        </div>
                        <div className='w-100 d-flex justify-content-center mt-3'>
                            <Button 
                                className='programEngineSearch' 
                                style={{width:"160px"}}
                                onClick={
                                    ()=>{
                                        setCurrentStep(0)
                                        setAdvanceCurrentSlide(advanceCurrentSlide-1)
                                        advanceCarouselRef.current.prev()
                                    }
                                }
                            >
                                Back to filters
                            </Button>
                        </div>
                    </div>
                :
                    <>
                        <div className='d-flex justify-content-center'>
                        {
                            flierChosen.status === "fail" ?
                                <div className='dreCTA'>Flyer generation disabled for unqualified programs</div>
                            :
                            (
                                flierChosen.hasFlier === false ?
                                    <div className='dreCTA'>Flyer not available for this program</div>
                                :
                                    ""
                            )
                            
                        }
                        </div>
                        <div className='actionPanel'>
                            <Select 
                                // options={advancedFlierSelectOptions}
                                options={advancedFlierSelectOptions.map(option => ({
                                    ...option,
                                    label: (
                                        <div style={{ fontWeight:"500", color: option.status === 'default'? "" :(option.status === "fail"? 'red' : 'green') }}>
                                            {option.label}
                                        </div>
                                    )
                                }))}
                                className='chooseFliers mr-3'
                                value={flierChosen.value}
                                onChange={handleSwitchFlierAdvanced}
                            />
                            <FlyerGenerator 
                                disabled={flierChosen.status === "fail" || flierChosen.hasFlier === false}
                                buttonClass="downloadFlierLink"
                                display='none'
                                programName={`${flierChosen.value === "" ? "No": flierChosen.label} program`}
                                buttonLabel='Download my flyer'
                                cta="Apply with me now for this qualified address!"
                                addressInfo={displayedAddress}
                                name={name}
                                nmls={nmls}
                                dre={dreID!==""?"DRE# "+dreID:""}
                                email={email}
                                phone={phone}
                                reName={realtorName}
                                reID={realtorID}
                                rePhone={realtorPhone}
                                reEmail={realtorEmail}
                                hasRE = {realtorInfoFilled}
                                imgsrc={`/images/gmccflyers/${flierChosen.value === ""? "Template_15K.png" : programs_templateMap[flierChosen.label]}`}
                                ratesheet={programs_pricesheetMap[flierChosen.label]?"/images/gmccflyers/"+programs_pricesheetMap[flierChosen.label]:""}
                                loID={com.getLoId()}
                                gotoCelebrity={props.gotoCelebrity}
                                chosenMode={chosenMode}
                            />
                        </div>
                        <div className='resultTabsWrap'>
                            <div className='fastCarouselContentBody'>
                                <Carousel 
                                    id="advancedResultCarousel"
                                    ref={advancedResultCarouselRef} 
                                    afterChange={setAdvancedResultCurrentSlide} 
                                    dots={false} 
                                    effect='fade' 
                                    touchMove={false} 
                                >
                                    {searchResults.map((result, idx)=> {
                                        return (
                                            <div className='fastResultCard'>
                                                <div className='fastResultLabel'>{result.label}</div>
                                                <div className='fastResultContent'>{result.children}</div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            {/* <Tabs id="resultTabs" defaultActiveKey="result 1" items={searchResults} /> */}
                            {searchResults.length === 0 && <div className='d-flex align-items-center' style={{backgroundColor:"#EAEFFD", height:"400px", paddingLeft:"40px", paddingRight:"40px"}}>
                                <div className='text-center' style={{color:"#CB8600",fontSize:"24px", fontWeight:"500"}}>Could not find matching program results, please try adjust your scenario and search again.</div>
                            </div>}
                        </div>  
                    </>
                }
            </div>
        </Carousel>
        <searchLogs.AdvancedCheckSearchLogs
            setFilterOptions={{
                setPropertyAddress, setDisplayedAddress,
                setPropertyCity, setPropertyState, setPropertyCounty, setPropertyZip,
                setPurpose, setOccupancy, setPurchasePrice, setDownPayment, setFico, setIncome,
                setMonthDebt, setEmployment, setDocumentLevel, setDocVerification, setLiquidassets,
                setAgeUnder59, setNonliquidassets, setForeignVisa, setFirstTimeHomeBuyer, setFirstGenHomeBuyer,
                setPropertywithMortgage, setHasListProperty, setSufficientEquity,
                setTotalDebts, setIsRentalFlow, setRentalIncome
            }}
            showSearchLogs={showAdvancedCheckSearchLogs}
            setShowSearchLogs={setShowAdvancedCheckSearchLogs}
            carouselRef={advanceCarouselRef}
            setShowPropertyInfo={setShowPropertyInfo}
            setCurrentStep={setCurrentStep}
            ref={advancedCheckSearchLogsRef}
        />
    </div>
    },
    {
        key: `massive`,
        label: "Massive Check",
        children: <div className="fastSectionWrap">
            {contextHolder}
            <Carousel id="massiveFormCarousel" ref={massiveCarouselRef} afterChange={setCurrentSlide} dots={false} effect='fade' touchMove={false}>
                <div className='scenarioSlide'>
                    <div className="checkPaneTitle">Massive Marketing City Check</div>
                    <Form className="fastFormLayout" onSubmit={handleMassiveFormSubmit}>
                        <div className="mobileScrollControl">
                            <Row style={{marginBottom:"40px", justifyContent:"center"}}>
                                <Col xs={12} md={isPad?12:2}>
                                    <Form.Label className="text-left">Property state</Form.Label>
                                        <Form.Group >
                                            <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            placeholder="State"
                                            onChange={(e) => {
                                                setMassivePropertyState(e.target.value, 'borrower');
                                                setMsaType("")
                                                setTargetMsa("")
                                            }}
                                            value={massivePropertyState}
                                            >
                                                <option value="">...</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col> 
                                    
                                <Col xs={12} md={isPad?12:2}>
                                    <Form.Group >
                                        <Form.Label className="text-left">Type (optional)</Form.Label>
                                        <Form.Control
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            setMsaType(e.target.value);
                                            setTargetMsa("")
                                        }}
                                        value={msaType}
                                        >
                                            <option value="">Skip</option>
                                            <option value="grandslam">GMCC Grandslam</option>
                                            <option value="conforming">GMCC Special Conforming</option>
                                            <option value="nonagency">GMCC Jumbo CRA</option>
                                            <option value="diamond">GMCC Diamond Community Lending</option>
                                            { [...fiveK_states, ...tenK_states, ...fifteenK_states].includes(massivePropertyState)
                                                && <option value="celebrity">GMCC $5K - $15K Grants</option>
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={isPad?12:2}>
                                    <Form.Group >
                                        <Form.Label className="text-left">MSA (optional)</Form.Label>
                                        <Form.Control
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            handleChangeTargetMsa(e.target.value);
                                        }}
                                        value={targetMsa}
                                        >
                                            {getMSAList(msaType, massivePropertyState)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={isPad?12:2}>
                                    <Form.Group >
                                        <Form.Label className="text-left">Property county</Form.Label>
                                        <Form.Control
                                        required
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            setMassivePropertyCounty(e.target.value);
                                        }}
                                        value={massivePropertyCounty}
                                        >
                                            {targetMsa!==""?mapMsaCountyOptions(targetMsa, msaType, massivePropertyState):getMassiveCountyOptions(massivePropertyState)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={isPad?12:2}>
                                    <Form.Group >
                                        <Form.Label className="text-left">Property city</Form.Label>
                                        <Form.Control
                                        required
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            setMassivePropertyCity(e.target.value);
                                        }}
                                        value={massivePropertyCity}
                                        >
                                            {getMassiveCityOptions(massivePropertyState,massivePropertyCounty)}
                                        </Form.Control>
                                    </Form.Group>   
                                </Col>
                                <div className='d-flex align-items-center pl-2'>
                                    <Button className='programEngineSearchLong' onClick={handleMassiveClick}>{loading? <div className='gmccSpin'><Spin /></div>: "Get listings"}</Button>
                                </div>
                            </Row>
                            {showListingFilter && <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='mt-2' style={{paddingLeft:"15px"}}>
                                        <Select
                                            mode="multiple"
                                            className="massiveTableFilter"
                                            placeholder="Filter by"
                                            allowClear
                                            value={currentFilterType}
                                            onChange={(value) => handleMassiveTableFilter(value)}
                                        >
                                            <Select.Option key={"default"} value={""}>Filter by</Select.Option>
                                            <Select.Option key={"minority"} value={"minority"}>MMCT</Select.Option>
                                            <Select.Option key={"incomeindicator"} value={"incomeindicator"}>Low-to-moderate</Select.Option>
                                            <Select.Option key={"AAHP"} value={"AAHP"}>Majority AA/HP</Select.Option>
                                            <Select.Option key={"less500"} value={"less500"}>Under $500K</Select.Option>
                                            <Select.Option key={"500to1000"} value={"500to1000"}>$500K - $1M</Select.Option>
                                            <Select.Option key={"1000to3000"} value={"1000to3000"}>$1M - $3M</Select.Option>
                                            <Select.Option key={"over3000"} value={"over3000"}>Over $3M</Select.Option>
                                        </Select>
                                    </div>
                                    <div className='mt-2' style={{paddingLeft:"15px"}}>
                                        <Select
                                            className="massiveTableFilter"
                                            placeholder="Sort by"
                                            value={currentSortType}
                                            onChange={(value) => handleMassiveTableSorter(value)}
                                        >
                                            <Select.Option key={"default"} value={""}>Sort by</Select.Option>
                                            <Select.Option key={"address"} value={"address"}>Address</Select.Option>
                                            <Select.Option key={"zip"} value={"zip"}>Zipcode</Select.Option>
                                            <Select.Option key={"price"} value={"price"}>Price</Select.Option>
                                            <Select.Option key={"incentives"} value={"incentives"}>Incentives status</Select.Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className='text-center mt-2 mr-3 d-flex justify-content-center align-items-center'>
                                    <div className='massiveTargetInfo mr-3'>{listingTarget === ""?"Select an address to check":`Checking for ${listingTarget}`}</div>
                                    {/* <div>MSA: {msaCode}</div> */}
                                    <Button type="submit" disabled={listingTarget === ""} className="programEngineSearch">{loading? <div className='gmccSpin'><Spin /></div>:"Check"}</Button>
                                </div>
                            </div>}
                            <div className='px-3 mt-2'>
                                <Table 
                                    columns={listingColumns}
                                    dataSource={listingData}
                                    pagination={pagination}
                                    onRow={(record)=> ({
                                        onClick: () => {
                                            setListingTarget(record.address)
                                            copyTarget(record.address)
                                            setSelectedRecord(record)
                                            // setPropertyAddress(record.address.split(",")[0])
                                            // setPropertyState(com.mapAbbrFullName[record.state])
                                            // setPropertyCity(record.city)
                                            // setPropertyZip(record.zip)
                                            // setPropertyCounty(record.county)
                                        },
                                        onMouseEnter: () => {
                                           setHoveredRow(record.key)
                                        },
                                        onMouseLeave: () => {
                                            setHoveredRow(null)
                                        }
                                    })}
                                    rowClassName="listingTableRow"
                                    scroll={{ x: 1300, y: 400 }}
                                    className='listingTable'
                                    onChange = {handleTableChange}
                                />
                            </div>                   
                        </div>
                    </Form>
                </div>
                <div className='scenarioSlide'>
                    <div className="fastResultMobileView" style={{display:"flex", flexDirection:"column"}}>
                        <div className='resultPaneHeader'>
                            <div className='text-left' style={{cursor:"pointer"}} onClick={()=>goToPrevMassive()}><LeftOutlined className='mr-2'/>Back</div>
                            {massivePropertyState.includes("California") && <div>
                                <div className="chooseFliersWrap">
                                    <div style={{fontWeight:"500"}}>Enter your DRE ID as needed for DRE endorsement:</div>
                                    <input 
                                        value={dreID}
                                        onChange={(e) => setDreID(e.target.value)}
                                        onBlur={(e) => setHaveDRE(e.target.value !== "")}
                                        placeholder='enter your DRE ID here'
                                        className='dreIDInput'
                                    />
                                    
                                </div>
                                
                            </div>}
                        </div>
                        <div className='text-center resultHeading mt-2'>{massiveResultsCount} programs found:</div>
                        <Popconfirm
                            okText='OK'
                            className='realtorInfoPop'
                            showCancel={false}
                            icon={null}
                            onConfirm={()=>{
                                if (realtorName === "" && realtorID ==="" && realtorEmail === "" && realtorPhone === ""){
                                    setRealtorInfoFilled(false)
                                }
                                else {
                                    setRealtorInfoFilled(true)
                                }
                            }}
                            description ={
                                <div className='realtorInfoWrap'>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Realtor name</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorName(e.target.value)}
                                            value ={realtorName}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>License number</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorID(e.target.value)}
                                            value ={realtorID}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Phone number</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorPhone(e.target.value)}
                                            value={realtorPhone}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='text-left'>Email</Form.Label>
                                        <Form.Control 
                                            size="sm"
                                            as="input"
                                            onChange={(e) => setRealtorEmail(e.target.value)}
                                            value={realtorEmail}
                                        />
                                    </Form.Group>
                                </div>
                            }
                        >
                            <div className='d-flex justify-content-center w-100'>
                                <div className="realtorInfoBtn">Insert Realtor Info</div>
                            </div>
                        </Popconfirm>
                        <div className="massiveResultWrapMobile" style={{marginTop:"10px", padding:"0px 10px 0px 10px"}}>
                            {
                                searchMassiveResults.map((result, idx)=> {
                                    return (
                                            <div className='massiveResultContainer'>
                                                <div style={{fontWeight:"500",fontSize:"16px"}}>{result.label}</div>
                                                {/*Massive check flyergenerator*/}
                                                <FlyerGenerator
                                                    disabled={false}
                                                    buttonClass="downloadFlierLink"
                                                    display='none'
                                                    programName={`${result.label} program`}
                                                    buttonLabel='Download my flyer'
                                                    cta="Apply with me now for this qualified address!"
                                                    addressInfo={listingTarget}
                                                    name={name}
                                                    nmls={nmls}
                                                    dre={dreID!==""?"DRE# "+dreID:""}
                                                    email={email}
                                                    phone={phone}
                                                    reName={realtorName}
                                                    reID={realtorID}
                                                    rePhone={realtorPhone}
                                                    reEmail={realtorEmail}
                                                    hasRE = {realtorInfoFilled}
                                                    gotoCelebrity={props.gotoCelebrity}
                                                    imgsrc={`/images/gmccflyers/${programs_templateMap[result.label]}`}
                                                    ratesheet={programs_pricesheetMap[result.label]?"/images/gmccflyers/"+programs_pricesheetMap[result.label]:""}
                                                    loID={com.getLoId()}
                                                    chosenMode={chosenMode}
                                                />
                                            </div>
                                    )
                                })
                            }
                        </div>

                        {searchMassiveResults.length === 0 && <div className='d-flex align-items-center' style={{backgroundColor:"#EAEFFD", height:"400px", paddingLeft:"40px", paddingRight:"40px"}}>
                            <div className='text-center' style={{fontSize:"24px", fontWeight:"500"}}>Could not find matching program results, please try adjust your scenario and search again.</div>
                        </div>}
                        {/* <div className="fastCarouselWrap">
                            <LeftOutlined className='fastArrow' onClick={()=>fastCarouselRef.current.prev()} />
                            <div className='fastCarouselContentBody'>
                                <Carousel 
                                    id="fastResultCarousel"
                                    ref={fastCarouselRef} 
                                    afterChange={setFastCurrentSlide} 
                                    dots={false} 
                                    effect='fade' 
                                    touchMove={false} 
                                >
                                    {searchFastResults.map((result, idx)=> {
                                        return (
                                            <div className='fastResultCard'>
                                                <div className='fastResultLabel'>{result.label}</div>
                                                <div className='fastResultContent'>{result.children}</div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <RightOutlined className='fastArrow' onClick={()=>fastCarouselRef.current.next()} />
                        </div> */}
                        {/* <div className='mt-3'>*For full restictions check, please use in-depth check or reference GMCC program guidelines and GMCC AI support. </div> */}
                    </div>
                </div>
            </Carousel>
        </div>
    },
    {
        key: `gps`,
        label: "GPS Check",
        children: renderGPSCheck()
    },
    {
        key: `byProgram`,
        label: "Market by Program Check",
        children: <MarketByProgramCheck gotoCelebrity={props.gotoCelebrity} chosenMode={chosenMode} />
    }
  ];

  return (
    <div className="programEngineWrap">
      {/* <Row> */}
        {/* <div className='w-100'>        
          <div className='bannerWrap'>
              <div className='title-1' style={{color:"white"}}>GMCC - Programs Map</div>
          </div>
        </div> */}
        <div className='bannerWrap'>
            <div className="headingText">GMCC - Programs Map</div>
            <div className='checkModeWrap'>
                <div 
                    className={chosenMode === "fast"?"modeSwitchLeft modeSwitchLeft-active":"modeSwitchLeft"} 
                    onClick={()=> {
                        setChosenMode("fast")
                    }}
                >
                    CRA Address <br /> Fast Check
                </div>
                <div 
                    className={chosenMode === "advanced"?"modeSwitchMiddle modeSwitchMiddle-active":"modeSwitchMiddle"}
                    onClick={()=> setChosenMode("advanced")}
                >
                    Specialty Eligibility <br /> Advanced Check
                </div>
                <div
                    className={chosenMode === "massive"?"modeSwitchMiddle modeSwitchMiddle-active":"modeSwitchMiddle"}
                    onClick={()=> {
                        setListingTarget("")
                        setSelectedRecord(null)
                        setListingData([])
                        setChosenMode("massive")
                    }}
                >
                    Massive Marketing <br /> City Check
                </div>
                <div 
                    className={chosenMode === "gps"?"modeSwitchMiddle modeSwitchMiddle-active":"modeSwitchMiddle"}
                    onClick={()=> {
                        setListingTarget("")
                        setSelectedRecord(null)
                        setListingData([])
                        setChosenMode("gps")
                    }}
                >
                    Marketing/GPS <br /> Radius Check
                </div>
                <div
                    className={chosenMode === "byProgram"?"modeSwitchRight modeSwitchRight-active":"modeSwitchRight"}
                    onClick={()=> {
                        setListingTarget("")
                        setSelectedRecord(null)
                        setListingData([])
                        setChosenMode("byProgram")
                    }}
                >
                    Market by Program <br /> Check
                </div>
            </div>
        </div>
        <div className="programEngineBody" style={{height: chosenMode === "fast"?"":""}}>
            <div className='generalDisclaimer'>
                <div>
                    *All programs/rates are subject to change and underwriting approval without advance notice.Additional restrictions might apply. 
                    This dose not represent any credit decision.
                </div>
            </div>
            <Tabs className="scenarioTabs" id="searchForms" activeKey={chosenMode} items={formTypes} />

           
        </div>
       
    </div>
  );
};

export default GMCCProgramEngine;
