import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useHistory } from "react-router-dom"
import FullScreenModal from '../FullHeightModal'
import './index.css'
import * as com from '../../../Common'
import { useSelector, useDispatch } from 'react-redux'
import { useStatsigClient } from "@statsig/react-bindings/src";

const LoanBorrowerPopUp = ({ type, open, onClose, submit, skipBorrower, getInfo }) => {
    const [form] = Form.useForm()
    const history = useHistory()
    const [isSuccess, setIsSuccess] = useState(false)
    const isHardMoneyLoanCustomer = com.isHardMoneyLoanCustomer()
    const [currentData, setCurrentData] = useState({})
    const [currentFormData, setCurrentFormData] = useState({})

    const [isAlready, setIsAlready] = useState(false)
    const [borrowId, setBorrowId] = useState('')
    const [loanId, setLoanId] = useState(false)
    const [CurrentInfo, setCurrentInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const { client } = useStatsigClient();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    useEffect(() => {

        form.resetFields()
        setIsSuccess(false)
    }, [open])
    useEffect(async () => {


        if (getInfo) {
            // console.log(location.);
            const info = sessionStorage.getItem('currentBorrower')
            const data = JSON.parse(info)



            form.setFieldsValue({

                firstname: data.name.split(' ')[0],
                lastname: data.name.split(' ')[1],
                email: data.email,
                purpose: data.purpose
            })

        }
    }, [getInfo])
    const checkFormComplete = () => {
        if (type !== 'borrower') {
            const values = form.getFieldsValue()
            const requiredFields = ['firstname', 'lastname', 'email', 'purpose']
            const allFieldsFilled = requiredFields.every(field => values[field])
            setIsButtonDisabled(!allFieldsFilled)
        }
    }
    const LoanForm = () => {
        return <div style={{ margin: 'auto', paddingBottom: '75px'}}>
            <div className='loadTitle'>
                {type === 'borrower' ? 'Add a borrower' : ' Create new loan'}
            </div>
            {type === 'borrower' ? <></> : <div className='loadInformation'>Add borrower’s information</div>
            }
            <Form
                form={form}
                className='loan-form'
                layout="vertical"
                onFieldsChange={checkFormComplete}
                requiredMark={false}
                onFinish={(value) => {
                    setCurrentFormData(value)
                    if (type == 'borrower') {

                        createBorrow(value)
                    } else {
                        createNewApplication(value)
                    }
                }}
                style={{ maxWidth: 320, margin: " auto", marginTop: 26 }}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "First name  is required "
                        },
                    ]}
                    style={{ color: 'red' }} label="First name" name="firstname">
                    <Input className='loan-borrower-input' placeholder="Enter borrower’s first name" />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Last name is required "
                    },
                ]} label="Last name" name='lastname'
                >
                    <Input className='loan-borrower-input' placeholder='Enter borrower’s last name' />
                </Form.Item>
                {
                    type == 'borrower' ? null :
                        <Form.Item rules={[
                            {
                                required: true,
                                message: "Loan purpose  is required "
                            },
                        ]} label="Loan purpose" name='purpose'>
                            <Select suffixIcon={<CaretDownOutlined style={{ color: '#6E6E70' }} />} className='loan-borrower-select' placeholder='Choose loan purpose'>
                                {isHardMoneyLoanCustomer ? (
                                    <>
                                        <Select.Option value="purchaserehab">Purchase and rehab</Select.Option>
                                        <Select.Option value="refinance">Refinance</Select.Option>
                                        <Select.Option value="cashoutrefinance">Cash out refinance</Select.Option>
                                        <Select.Option value="hardmoneyloan">Hard money loan</Select.Option>

                                    </>
                                ) : (
                                    <>
                                        <Select.Option value="prequal">Pre-qualification</Select.Option>
                                        <Select.Option value="purchase">Purchase</Select.Option>
                                        <Select.Option value="refinance">Refinance</Select.Option>
                                        <Select.Option value="cashoutrefinance">Cash out refinance</Select.Option>
                                    </>
                                )}
                            </Select>
                        </Form.Item>
                }

                <Form.Item label="Email" name='email'

                    rules={[

                        {
                            required: 'true',
                            type: 'email',
                            message: "Email  is required "

                        },

                    ]}
                >
                    <Input className='loan-borrower-input' placeholder='Enter borrower’s email address' />
                </Form.Item>
                {type == 'borrower' ? null : <div className='loadInformation' style={{ marginTop: '-18px' }}>
                    An invitation to complete a loan application will be sent to the borrower's email after creating the loan.
                </div>}
                <Button
                    loading={loading}
                    htmlType='submit'
                    className='loan-borrower-modal-btn'
                    disabled={type != 'borrower' && isButtonDisabled}
                    style={{
                        height: 44,
                        width: '100%',
                        color: '#FCFCFC',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: '600',
                        marginTop: "24px"
                    }}
                    type='primary'>{

                        type == 'borrower' ? 'Add and send invitation' : 'Create'
                    } </Button>
                {type == 'borrower' ? <div className='loadInformation' style={{ marginTop: '10px' }}>
                    By clicking the button, an invitation to complete the application will be sent to your borrower's email address. You can always return to the "Borrowers" page to check this borrower's information.
                </div> : null}
            </Form>
        </div >

    }
    const createNewApplication = async (data) => {

        let token = sessionStorage.getItem("ZeitroA")
        let local = window.location.hostname === "localhost"
        let js = {
            email: data.email.toLowerCase(),
            firstName: data.firstname,
            lastName: data.lastname,
            purpose: data.purpose
        }

        //statsig logevent
        const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
        client.logEvent("create_loan_application", savedUserInfo.custom.customerid, {
            item_name: "createLoanApplication",
            startTime: new Date,
            timestamp: Date.now(),
            application_email: data.email.toLowerCase(),
            application_purpose: data.purpose,
            customerid: savedUserInfo.custom.customerid,
            email: savedUserInfo.email
        })

        fetch('/los/createapplicationbylo', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                userEmail: savedUserInfo.email
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    alert("Please add a borrower")
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Looks like there was a problem, please try again later.")
                    } else {
                        submit()
                        setIsSuccess(true)
                        setCurrentData(js)
                        // this.setState({
                        //     openLoanPopup: false
                        // })
                        // setNewAppLoanID(js.Token)
                        // getBorrowerLoans()
                        // setIsNewAppModalStatus(2)

                    }

                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
            if (!local)
                window.grecaptcha.reset()
        })
    }
    const successContent = () => {

        return (
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <img src='/images/loanSuccess.svg'></img>
                <div className='loadTitle' style={{
                    marginTop: 30
                }}>
                    {type == 'borrower' ? isAlready ? 'This Borrower has signed up before' : 'Borrower added successfully' : "Loan appliation created successfully"}
                </div>
                <div className='loadInformation' style={{
                    width: '50%', margin: "auto", marginTop: '10px'
                }}>
                    {
                        type == 'borrower' ? isAlready ? 'Use the button below to start the application.' : 'The invitation email has been successfully sent. While waiting for the borrower to complete their information, you may proceed to start the application process by clicking the button below.' : "An invitation to fill out the application information has also been sent to the borrower's email address."
                    }
                </div>
                <Button
                    onClick={() => {
                        if (type == 'borrower') {
                            if (loanId == '') {
                                let token = sessionStorage.getItem("ZeitroA")
                                let local = window.location.hostname === "localhost"
                                let js = {
                                    email: currentFormData.email.toLowerCase(),
                                    firstName: currentFormData.firstname,
                                    lastName: currentFormData.lastname,
                                    purpose: "purchase"   //since create borrower does not ask user for purpose, default purpose to purchase
                                }

                                //statsig logevent
                                const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
                                client.logEvent("create_loan_application", savedUserInfo.custom.customerid, {
                                    item_name: "createLoanApplication",
                                    startTime: new Date,
                                    timestamp: Date.now(),
                                    application_email: currentFormData.email.toLowerCase(),
                                    application_purpose: "purchase",
                                    customerid: savedUserInfo.custom.customerid,
                                    email: savedUserInfo.email
                                })

                                fetch('/los/createapplicationbylo', {
                                    method: 'POST',
                                    headers: {
                                        Authorization: "Bearer " + token,
                                        Cache: "no-cache",
                                        userEmail: savedUserInfo.email
                                    },
                                    body: JSON.stringify(js)
                                }).then(
                                    response => {
                                        if (response.status !== 200) {
                                            alert("Looks like there was a problem, please try again later or contact Zeitro support.")
                                            return
                                        }
                                        response.json().then(js => {
                                            if (js.Status !== "OK") {
                                                alert("Looks like there was a problem, please try again later.")
                                            } else {
                                                message.success('created successfully')
                                                // submit()
                                                sessionStorage.setItem("edit", "false")
                                                sessionStorage.setItem("state", "{}")
                                                sessionStorage.setItem("originalstate", "{}")
                                                sessionStorage.setItem("borrowermenu", "loanSummary")
                                                // this.props.history.push("/app/borrower/" + currentData?.Token)
                                                sessionStorage.setItem("borrowermenu", '1003View')
                                                history.push('/app/active-loans')
                                                history.push(`/app/borrower/${js?.Token}`)
                                                // window.open(`/app/borrower/${js?.Token}`, "_self")
                                                // window.open(`/app/borrower/${js?.Token}`, "_self")

                                                // onClose()


                                            }

                                        })
                                    }
                                )
                            } else {
                                sessionStorage.setItem("borrowermenu", '1003View')
                                history.push("/app/borrower/" + loanId)

                            }

                            onClose()

                        } else {
                            sessionStorage.setItem("edit", "false")
                            sessionStorage.setItem("state", "{}")
                            sessionStorage.setItem("originalstate", "{}")
                            sessionStorage.setItem("borrowermenu", '1003View')
                            onClose()
                            // history.push("/app/borrower/" + currentData?.Token)
                            window.location.href = "/app/borrower/" + currentData?.Token
                        }

                    }}
                    style={{
                        height: 44,
                        width: '50%',
                        color: '#FCFCFC',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: '600',
                        marginTop: "24px",
                        marginBottom: 15

                    }}
                    type='primary'>
                    {type == 'borrower' ? "Start borrower's loan application" : ' Go to this application'}
                </Button>
                {/* Copy the invitation link for the borrower */}
                <div
                >

                    <Link style={{ textDecoration: 'underline', marginTop: "12px" }} onClick={() => {

                        if (type == 'borrower') {
                            submit()
                            onClose()
                            history.push(`/app/borrowers/${borrowId}`)
                            window.location.reload()

                            // skipBorrower({
                            //     email: currentFormData.email,
                            //     token: currentData.Token
                            // })


                        }

                    }}>{
                            type == 'borrower' ? "View this borrower's Information page" : ''
                        }</Link>
                </div>
            </div >
        )


    }

    const createBorrow = async (data) => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        let local = window.location.hostname === "localhost"

        let tosend =
            JSON.stringify({
                email: data.email.toLowerCase(),
                firstName: data.firstname,
                lastName: data.lastname,
                // purpose: data.purpose,
            })
        fetch('/los/signupborrowerbylo', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    if (!local)
                        window.grecaptcha.reset()
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        if (js.Error.includes("Account validated")) {
                            setBorrowId(js.BorrowerID)

                            // alert("The email you've entered is already in our system. You have the option to initiate a new loan from the borrower's page, or alternatively, use a different email that hasn't been registered yet.")
                            setIsSuccess(true)
                            setIsAlready(true)
                        } else {
                            alert(js.Text)

                            setIsAlready(false)
                        }
                    } else {
                        setCurrentData(js)
                        setBorrowId(js.BorrowerID)
                        setLoanId(js.LoanID)
                        setIsSuccess(true)
                        setIsAlready(false)
                        submit()
                    }
                    setLoading(false)
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
            // this.setState({ message: "Our servers are experiencing problems. Please check later.", show: true })
            if (!local)
                window.grecaptcha.reset()

        })
    }
    return (
        <FullScreenModal width='50%' open={open} onClose={() => {

            onClose()
            setIsSuccess(false)
            setIsButtonDisabled(true)
        }} content={isSuccess ? successContent() : LoanForm()} title='' footer={null}>


        </FullScreenModal>
    )
}

export default LoanBorrowerPopUp
