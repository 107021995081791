import React from 'react'
import { Typography } from 'antd'
import './MyCompany.css'
import { useStatsigClient } from "@statsig/react-bindings/src";

const { Text } = Typography

function MyCompany({ aboutInfo, loanOfficerEmail }) {
  const client = useStatsigClient();

  return (
    <div className="my-company-container">
      <div className="my-company-title">My company</div>

      <div className="my-company-content">
        {/* Left Column - Logo */}
        <div className="logo-column">
          {aboutInfo.companyLogo !== '' ? <img
            src={aboutInfo.companyLogo}
            alt="Company Logo"
            className="company-logo"
          /> :  <img src="/images/my-company.svg" alt="Company Image" className="company-image" />}

        </div>

        {/* Right Column - Content */}
        <div className="content-column">
          {/* Company name heading */}
          <Text className="company-name">{aboutInfo.companyName}</Text>
          {/* Company title */}
          <Text className="company-title">{aboutInfo.banner}</Text>
          {/* Company description */}
          <Text className="company-description">{aboutInfo.companyIntroduction}</Text>

          {/* View company button */}
            {/*{aboutInfo.companyLink !== "" && (<button
            className="view-company-button"
            onClick={() => {
              window.open(
                aboutInfo.companyLink.includes('http')
                  ? aboutInfo.companyLink
                  : 'https://' + aboutInfo.companyLink,
                '_blank'
              );
              client.logEvent("lo_detail_page_view_company_click", window.location.href, {
                item_name: "loDetailPageViewCompanyClick",
                startTime: new Date,
                timestamp: Date.now(),
                pageUrl: window.location.href,
                loanOfficerID: loanOfficerEmail,
                type: "personalsite",
                companyLink: aboutInfo.companyLink,
              });
            }}
          >
            View our company
          </button>)} */}
        </div>
      </div>
    </div>
  )
}

export default MyCompany
