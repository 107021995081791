import React, { useState, useEffect, useRef } from 'react'
import { Modal, Row, Col, Table, Button, Tabs, Radio, Tooltip, Collapse, message } from 'antd'
import * as com from '../../Common'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { CloseOutlined, InfoCircleFilled, MinusOutlined, QuestionCircleOutlined, DownOutlined } from '@ant-design/icons'
import Draggable from 'react-draggable';
import './DTICalculatorModal.css'
import { set } from 'lodash'

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}
export default function DTICalculatorModal(props) {
    const {
        open, changeStep, onConfirm, onCancel
    } = props
    const history = useHistory()
    const [dtiType, setDtiType] = useState(0)
    const [creditIspulled, setCreditIspulled] = useState(true)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [minimize, setMinimize] = useState(false)
    const [activeTab, setActiveTab] = useState("all")
    const [modalMode, setModalMode] = useState(1)
    const [editType, setEditType] = useState("")
    const [editIndex, setEditIndex] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [showInfo, setShowInfo] = useState(false)
    const [stateChange, setStateChange] = useState(false)
    const [bounds, setBounds] = useState({
        left: 0, top: 0, bottom: 0, right: 0
    })
    const [grossIncomeList, setGrossIncomeList] = useState([])
    const [liabilityList, setLiabilityList] = useState([])
    const [activeKeys, setActiveKeys] = useState([])
    const draggleRef = useRef()
    const hasMounted = useRef(false)
    useEffect(() => {
        if (!open || minimize || !minimize) {
            setBounds({ left: 0, top: 0, bottom: 0, right: 0 })
            if (draggleRef?.current) {
                draggleRef.current.style.transform = ''
            }
        }
        if (open) {
            getXml()
        }
    }, [open, minimize])
    useEffect(() => {
        const { borrower, coborrower, hascoborrower } = props.application
        const { loans, newloans } = props.application.assetsandliabilities
        const processedGross = processIncomeData(borrower, coborrower, hascoborrower)
        const processedLiability = processLiabilityData(borrower, coborrower, hascoborrower, loans, newloans)
        setGrossIncomeList(processedGross)
        setLiabilityList(processedLiability)
        onConfirm({ dti: getDTI(), frontEndDti: getFrontEndDTI() })
    }, [props.application])
    useEffect(() => {
        if (hasMounted.current) {
            setStateChange(true)
        } else {
            hasMounted.current = true
        }
    }, [props.application.selection.selectedloan.monthly_payment, props.application.selection.loansize])
    const getXml = async() => {
        const urlParams = window.location.pathname.replace('/app/borrower/', '')
        let token = sessionStorage.getItem("ZeitroA")

        const response = await fetch(`/los/getcreditreport/${urlParams}`, {
            method: 'get',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const { Error, Status, CreditReports } = await response.json()
        if (Status === 'Error') {
            setCreditIspulled(CreditReports && CreditReports.length > 0)
            return
        } else {
            setCreditIspulled(CreditReports && CreditReports.length > 0)
        }
    }
    const processIncomeData = (borrower, coborrower, hasCoborrower) => {
        const processOccupation = (occupation, from, href) => {
            const title = occupation.selfemployed ? 'Self-employment' : 'Employment';
      
            const priceList = occupation.selfemployed ? [
                {
                    title: 'Monthly income',
                    price: parseFloat(occupation.incomeorloss) || 0,
                },
                {
                    title: 'Ownership share',
                    price: parseFloat(occupation.percentownership) || 0,
                    type: 'percent'
                },
            ] : [
                {
                    title: 'Base Employment Income',
                    price: parseFloat(occupation.income.base) || 0,
                },
                {
                    title: 'Overtime',
                    price: parseFloat(occupation.income.overtime) || 0,
                },
                {
                    title: 'Bonuses and Commissions',
                    price:
                        (parseFloat(occupation.income.bonuses) || 0) +
                        (parseFloat(occupation.income.commissions) || 0),
                },
                {
                    title: 'Millitary entitlements',
                    price: parseFloat(occupation.income.military) || 0,
                },
                {
                    title: 'Other',
                    price: parseFloat(occupation.income.other) || 0,
                },
            ]
      
            const totalPrice = occupation.selfemployed ? (parseFloat(occupation.incomeorloss) || 0) : priceList.reduce((sum, item) => sum + item.price, 0)
      
            return {
                title,
                from,
                href,
                employername: occupation.employername || '',
                price: totalPrice,
                priceList,
            }
        }
      
        const processAllOccupations = (occupations, from, href) => {
            return occupations.map((occupation) => processOccupation(occupation, from, href))
        }
      
        const processIncome = (income, from, href) => {
            if (!income) return []
            const formatTitle = (key) => {
                const item = com.othersouresofincome.find(([firstValue]) => firstValue === key)
                return item ? item[1] : null
            }
            const priceList = (income.otherincome || []).map((item) => ({
                title: formatTitle(item.incometype),
                price: parseFloat(item.amount) || 0,
            }))
      
            const totalPrice = priceList.reduce((sum, item) => sum + item.price, 0)
      
            return [{
                title: 'Income from other sources',
                from,
                href,
                employername: '',
                price: totalPrice,
                priceList,
            }]
        }
        const borrowerData = [
            ...processAllOccupations([borrower.occupation], 'borrower', '#1b'),
            ...processAllOccupations(borrower.otheroccupations || [], 'borrower', '#1c'),
            ...processIncome(borrower.income, 'borrower', '#1e'),
        ]
      
        const coborrowerData = hasCoborrower === 'withcoborrower' ? [
            ...processAllOccupations([coborrower.occupation], 'coborrower', '#1b'),
            ...processAllOccupations(coborrower.otheroccupations || [], 'coborrower', '#1c'),
            ...processIncome(coborrower.income, 'coborrower', '#1e'),
        ] : []
      
        return [...borrowerData, ...coborrowerData]
    }
    const processLiabilityData = (borrower, coborrower, hasCoborrower, loanList, newLoanList) => {
        const housingExpenses = () => {
            const priceList = []
            const application = props.application
            let loans = []
            let totalMonthlyAmount = 0
            if (application.property.purpose === "purchase") {
                loans = application.assetsandliabilities.newloans || []
                totalMonthlyAmount = loans.reduce((sum, item) => {
                    const amount = parseFloat(item.monthlyamount) || 0
                    return sum + amount
                }, 0)
            } else {
                loans = application.property.loans || []
                totalMonthlyAmount = loans.reduce((sum, item) => {
                    const amount = parseFloat(item.monthlypayment) || 0
                    return sum + amount
                }, 0)
            }
            const insurancemaintenancetaxes = parseFloat(application.property.insurancemaintenancetaxes) || 0
            let price = loans.length > 0
                ? totalMonthlyAmount + insurancemaintenancetaxes
                : insurancemaintenancetaxes
            if (application.borrower.presentaddressownership === "rent") {
                const currentRent = parseFloat(application.borrower.expenses.currentrent) || 0
                price += currentRent
            }
            if (application.hascoborrower === "withcoborrower" && application.coborrower.presentaddressownership === "rent" && application.coborrower.presentaddressstreet !== application.borrower.presentaddressstreet) {
                const currentRent = parseFloat(application.coborrower.expenses.currentrent) || 0
                price += currentRent
            }
            priceList.push({
                title: 'Current Mortgage Payment or Rent',
                price: price
            })
            if (!(application.borrower.presentaddressownership === "rent" && application.property.purpose === "purchase" && application.property.occupancy === "investment")) {
                priceList.push({
                    title: 'Proposed Mortgage Payment',
                    price: parseFloat(application.selection.selectedloan.monthly_payment) || 0,
                })
            }
            const ownedproperties = application.borrower.ownedproperties || []
            const coborrowerOwnedproperties = application.hascoborrower === "withcoborrower" ? (application.coborrower.ownedproperties || []) : []
            const totalExpense = (borrowerProperties, coborrowerProperties) => {
                return borrowerProperties.reduce((sum, item) => {
                    const taxes = isNaN(Number(item.insurancemaintenancetaxes)) ? 0 : Number(item.insurancemaintenancetaxes)
                    const loanPayments = item.loans.reduce((loanSum, loan) => {
                        const loanPayment = isNaN(Number(loan.monthlypayment)) ? 0 : Number(loan.monthlypayment)
                        return loanSum + loanPayment
                    }, 0)
                    return sum + taxes + loanPayments
                }, 0) + coborrowerProperties.reduce((sum, item) => {
                    const matchingBorrowerItem = borrowerProperties.find(borrowerItem => borrowerItem.address === item.address)
                    const taxes = matchingBorrowerItem ? 0 : (isNaN(Number(item.insurancemaintenancetaxes)) ? 0 : Number(item.insurancemaintenancetaxes))
                    const loanPayments = item.loans.reduce((loanSum, loan) => {
                        const matchingLoan = matchingBorrowerItem ? matchingBorrowerItem.loans.find(borrowerLoan => borrowerLoan.accountnumber === loan.accountnumber) : null
                        const loanPayment = matchingLoan ? 0 : (isNaN(Number(loan.monthlypayment)) ? 0 : Number(loan.monthlypayment))
                        return loanSum + loanPayment
                    }, 0)
            
                    return sum + taxes + loanPayments
                }, 0)
            }
            const totalHousingExpense = totalExpense(ownedproperties, coborrowerOwnedproperties)
            priceList.push({
                title: 'Additional Housing Expense',
                price: totalHousingExpense,
            })
            const totalPrice = priceList.reduce((sum, item) => sum + item.price, 0)
          
            return [
                {
                    title: 'Housing Expenses',
                    from: 'all',
                    href: '#3a',
                    employername: '',
                    price: totalPrice,
                    priceList,
                },
            ]
        }
        const otherLiability = (liability, from, href) => {
            if (!liability) return []
            const data = [
                ["alimony", "Alimony Payments"],
                ["childsupport", "Child Support Payments"],
                ["maintenancepayments", "Separate Maintenance Payments"],
                ["jobrelatedexpenses", "Job-Related Expenses"],
                ["other", "Other"],
            ]
            const formatTitle = (key) => {
                const item = data.find(([firstValue]) => firstValue === key)
                return item ? item[1] : null
            }
            const priceList = (liability.alimony || []).map((item) => ({
                title: formatTitle(item.alimonyorsupport),
                price: parseFloat(item.amount) || 0,
            }))
        
            const totalPrice = priceList.reduce((sum, item) => sum + item.price, 0)
        
            return [{
                title: 'Other Liability',
                from,
                href,
                employername: '',
                price: totalPrice,
                priceList,
            }]
        }
        const installmentLoans = (loans) => {
            if (!loans || loans.length === 0) return []

            const filteredLoans = loans.filter(
              (item) =>
                item.creditliabilityaccounttype === 'Mortgage' &&
                parseFloat(item.monthlypayment) > 0 &&
                parseFloat(item.remainingbalance) > 0
            )
          
            if (filteredLoans.length === 0) return []

            const priceList = filteredLoans.map((item) => ({
                title: item.name,
                price: parseFloat(item.monthlypayment) || 0,
            }))
          
            const totalPrice = priceList.reduce((sum, item) => sum + item.price, 0)
          
            return [
                {
                    title: 'Installment Loans',
                    from: 'all',
                    href: '#2c',
                    employername: '',
                    price: totalPrice,
                    priceList,
                },
            ]
        }
        const estateLiablities = (loans) => {
            if (!loans || loans.length === 0) return []

            const priceList = loans.map((item) => ({
                title: item.creditor,
                price: parseFloat(item.monthlyamount) || 0,
            }))
          
            const totalPrice = priceList.reduce((sum, item) => sum + item.price, 0)
          
            return [
                {
                    title: 'Other Real Estate Liablities',
                    from: 'all',
                    href: '#4b',
                    employername: '',
                    price: totalPrice,
                    priceList,
                },
            ]
        }
        const borrowerData = [
            ...housingExpenses(),
            ...otherLiability(borrower.finances, 'borrower', '#2d'),
            ...installmentLoans(loanList),
            ...estateLiablities(newLoanList)
          ]
        
          const coborrowerData = hasCoborrower === 'withcoborrower' ? [
            ...otherLiability(coborrower.finances, 'coborrower', '#2d')
          ] : []
        
          return [...borrowerData, ...coborrowerData]
    }
    const getDTI = () => {
        const totalIncome = grossIncomeList.filter((item) => activeTab === 'all' || item.from === activeTab || item.from === 'all').reduce((sum, item) => sum + item.price, 0)
        const totalLiability = liabilityList.filter((item) => activeTab === 'all' || item.from === activeTab || item.from === 'all').reduce((sum, item) => sum + item.price, 0)
        return totalIncome === 0 ? 0 : Math.round((totalLiability / totalIncome) * 100);
    }
    const getFrontEndDTI = () => {
        const totalIncome = grossIncomeList.filter((item) => activeTab === 'all' || item.from === activeTab || item.from === 'all').reduce((sum, item) => sum + item.price, 0)
        const application = props.application
        let price = 0
        if ((application.property.purpose === "purchase" || application.property.purpose === "refinance") && application.property.occupancy === "secondhome") {
            const from = activeTab === 'coborrower' ? 'coborrower' : 'borrower'
            const data = application[from].ownedproperties || []
            price = data.filter(item => item.occupancy === "principal")
            .reduce((sum, item) => {
                const insurancemaintenancetaxes = parseFloat(item.insurancemaintenancetaxes) || 0
                const loansSum = item.loans.reduce((loanSum, loan) => {
                    const monthlypayment = parseFloat(loan.monthlypayment) || 0
                    return loanSum + monthlypayment
                }, 0)
                return sum + insurancemaintenancetaxes + loansSum
            }, 0)
        } else if (application.property.purpose === "refinance" && application.property.occupancy === "investment") {
            if (application.borrower.presentaddressownership === 'rent') {
                price = parseFloat(application.borrower.expenses.currentrent) || 0
            } else {
                price = 0
            }
        } else if (application.property.purpose === "purchase" && application.property.occupancy === "investment" && application.borrower.presentaddressownership === 'rent') {
            price = 0
        } else {
            price = parseFloat(application.selection.selectedloan.monthly_payment) || 0
        }
        if (totalIncome === 0) {
            return 0
        }
        return price === 0 ? 0 : Math.round(price / totalIncome * 100)
    }
    const modalCancel = () => {
        onCancel()
        setModalMode(1)
        setActiveTab('all')
        onConfirm({ dti: getDTI(), frontEndDti: getFrontEndDTI() })
    }
    const handleCancel = () => {
        modalCancel()
    }
    const handleBack = () => {
        setModalMode(1)
    }
    const handleConfirm = () => {
        modalCancel()
    }
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        })
    }
    const modalMinimize = () => {
        setMinimize(!minimize)
    }
    const dtiChange = (e) => {
        setDtiType(e.target.value)
    }
    const editItem = editType === 'income' ? grossIncomeList : liabilityList
    const customHeader = (type) => {
        const list  = type === 'income' ? grossIncomeList : liabilityList
        const totalPrice = list.filter((item) => activeTab === 'all' || item.from === activeTab || item.from === 'all').reduce((sum, item) => sum + item.price, 0)
        return (
            <div className='dti-calculator-header'>
                <div className="dti-calculator-item">{type === 'income' ? 'Gross income' : 'Liability'} :<span style={{fontWeight: 700}}>$ {com.commaize(totalPrice)}</span></div>
            </div>   
        )
    }
    const getTabs = () => {
        const options = [
            {
                label: "All",
                key: 'all',
            },
            {
                label: `${props.application.borrower.firstname} ${props.application.borrower.lastname}`,
                key: 'borrower',
            }
        ]
        if (props.application.hascoborrower === "withcoborrower") {
            options.push(
                {
                    label: `${props.application.coborrower.firstname} ${props.application.coborrower.lastname}`,
                    key: 'coborrower',
                }
            )
        }
        return options
    }
    const changeEditMode = (type, title, from, href, index) => {
        changeStep('1003View', from, href)
        setShowInfo(title === 'Housing Expenses')
        setModalTitle(type === 'income' ? `income - item ${href.split("#")[1]}` : `liability - item ${href.split("#")[1]}`)
        setEditType(type)
        setEditIndex(index)
        setModalMode(2)
    }
    const footer = modalMode === 1 ? [
        <Button className="dti-btn-back" type='link' onClick={handleCancel}>Cancel</Button>,
        <Button className="dti-btn-confirm" type="primary" loading={confirmLoading} onClick={handleConfirm}>Done</Button>
    ] : [
        <div className='dti-card-statistics'>
            <div>Overall DTI: <span style={{ color: '#325CEB', fontWeight: 700 }}>{getDTI()}%</span></div>
            <div>Front-end DTI: <span style={{ color: '#325CEB', fontWeight: 700 }}>{getFrontEndDTI()}%</span></div>
        </div>,
        <Button className="dti-btn-confirm" type="primary" loading={confirmLoading} onClick={handleBack}>Back</Button>
    ]
    const handleCollapseChange = (keys) => {
        setActiveKeys(keys)
    }

    return (
        <>
            <Modal
                wrapClassName={modalMode === 2 || minimize ? 'dti-calculator-modal-right' : ''}
                className={`dti-calculator-modal ${modalMode === 1 ? 'normal-modal' : 'edit-modal'} ${minimize ? 'dti-calculator-modal-minimize' : ''}`}
                mask={!minimize && modalMode === 1}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                maskClosable={false}
                width={minimize || modalMode === 2 ? 438 : 974}
                open={open}
                centered={!minimize && modalMode === 1}
                title=""
                onCancel={modalCancel}
                footer={minimize ? null : footer}
                modalRender={modal => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                <div 
                    className='dti-calculator-top'
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false)
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true)
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    <div className='dti-calculator-title'>{modalMode === 1 ? 'DTI Calculator' : modalTitle} </div>
                    {
                        !minimize && modalMode === 1 && (
                            <div className='dti-calculator-tabs'>
                                <Tabs
                                    activeKey={activeTab}
                                    centered
                                    items={getTabs()}
                                    onChange={(key) => {
                                        setActiveTab(key)
                                    }}
                                />
                            </div>
                        )
                    }
                    <div className='dti-calculator-minimize' onClick={modalMinimize}><MinusOutlined /></div>
                </div>
                <div className='dti-calculator-content'>
                    {
                        modalMode === 1 ? (
                            <>
                                <Radio.Group className="dti-calculator-group" onChange={dtiChange} value={dtiType}>
                                    <label className={`radio-item ${dtiType === 0 ? 'select-item' : ''}`}>
                                        <Radio className='dti-radio' value={0}>
                                            <div className="radio-text">Front-end DTI: {getFrontEndDTI()}%
                                                <Tooltip
                                                    overlayClassName="dti-calculator-tooltip"
                                                    title={
                                                        <div>
                                                        <div>Front-end DTI ratio is the ratio of monthly housing expenses to the borrower’s gross monthly income:</div>
                                                        <div style={{ color: '#222', fontWeight: 500 }}>Front-End DTI Ratio = Housing Expenses ÷ Gross Monthly Income</div>
                                                        </div>
                                                    }
                                                >
                                                    <QuestionCircleOutlined style={{ fontSize: 14, color: '#8A8C90', marginLeft: 6, cursor: 'pointer' }} />
                                                </Tooltip>
                                            </div>
                                        </Radio>
                                    </label>
                                    <label className={`radio-item ${dtiType === 1 ? 'select-item' : ''}`}>
                                        <Radio className='dti-radio' value={1}>
                                            <div>
                                                <div className="radio-text">DTI :
                                                    <span className={`${getDTI() >= 50 ? 'overLimit' : '' }`}>{getDTI()}%</span>
                                                    <Tooltip
                                                        overlayClassName="dti-calculator-tooltip"
                                                        title={
                                                            <div>
                                                                <div>The overall DTI ratio is the combined consideration of the front-end and back-end DTI ratios to provide a comprehensive view of the borrower’s debt obligations:</div>
                                                                <div style={{ color: '#222', fontWeight: 500 }}>Overall DTI = (Total Monthly Debt Payments / Gross Monthly Income) * 100%</div>
                                                            </div>
                                                        }
                                                    >
                                                        <QuestionCircleOutlined style={{ fontSize: 14, color: '#8A8C90', marginLeft: 6, cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </div>{getDTI() >= 50 && <div className='dti-warning-text'>May exceeds allowable limit</div>}
                                            </div>
                                        </Radio>
                                    </label>
                                </Radio.Group>
                                <div className='dti-calculator-credit-status'>Credit report status: 
                                    {creditIspulled ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><span style={{ color: '#2E605A' }}> Pulled</span><img style={{marginLeft: 12}} src='/images/credit-report-pulled.svg' /></div>
                                        ) : (
                                            <span><span style={{ color: '#CB8600' }}> Not Pulled</span> <span style={{ color: '#6E6E70', fontWeight: 400 }}>(Results may be inaccurate without a credit pull)</span></span>
                                        )
                                    }
                                </div>
                                <div className='dti-calculator-collapse-wrap'>
                                    <Collapse 
                                        className='dti-calculator-collapse' 
                                        expandIcon={({ isActive }) => <img className={`${isActive ? 'expand' : ''} collapse-icon`} src="/images/dti-calculator-arrow.svg" /> } 
                                        expandIconPosition="end"
                                        onChange={handleCollapseChange}
                                    >
                                        <Collapse.Panel className={`dti-calculator-collapse-item ${activeKeys.length === 0 ? 'retract-collapse' : ''}`} header={customHeader('income')}>
                                            <div className="dti-calculator-card-wrap">
                                                {
                                                    grossIncomeList.filter((item) => activeTab === 'all' || item.from === activeTab || item.from === 'all').map((item, index) => (
                                                        <div className='dti-calculator-card' key={index}>
                                                            <div>
                                                                <div className='dti-calculator-card-title'>
                                                                    <div>{item.title}</div>
                                                                    <div>${com.commaize(item.price)}</div>
                                                                </div>
                                                                <div className='dti-calculator-card-subtitle'>{`${props.application[item.from].firstname} ${props.application[item.from].lastname} ${item.employername === "" ? '' : '- ' + item.employername}`}</div>
                                                            </div>
                                                            {
                                                                item.priceList.map((priceItem, priceIndex) => (
                                                                    <div className='dti-calculator-price' key={priceIndex}>
                                                                        <div>{priceItem.title}</div>
                                                                        <div>{priceItem.type === 'percent' ? `${priceItem.price}%` : `$ ${com.commaize(priceItem.price)}`}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className='dti-calculator-edit'>
                                                                <div onClick={() => changeEditMode('income', item.title, item.from, item.href, index)}><img src='/images/document-edit.svg' /><span>Edit in 1003</span></div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Collapse.Panel>
                                        <Collapse.Panel className={`dti-calculator-collapse-item ${activeKeys.length === 0 ? 'retract-collapse-last' : ''}`} header={customHeader('liability')}>
                                            <div className="dti-calculator-card-wrap">
                                                {
                                                    liabilityList.filter((item) => activeTab === 'all' || item.from === activeTab || item.from === 'all').map((item, index) => (
                                                        <div className='dti-calculator-card' key={index}>
                                                            <div>
                                                                <div className='dti-calculator-card-title'>
                                                                    <div>{item.title}</div>
                                                                    <div>${com.commaize(item.price)}</div>
                                                                </div>
                                                                {item.from !== 'all' && <div className='dti-calculator-card-subtitle'>{`${props.application[item.from].firstname} ${props.application[item.from].lastname} ${item.employername === "" ? '' : '- ' + item.employername}`}</div>}
                                                            </div>
                                                            {
                                                                item.priceList.map((priceItem, priceIndex) => (
                                                                    <div className='dti-calculator-price' key={priceIndex}>
                                                                        <div>{priceItem.title}</div>
                                                                        <div>{priceItem.type === 'percent' ? `${priceItem.price}%` : `$ ${com.commaize(priceItem.price)}`}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className='dti-calculator-edit'>
                                                            <div onClick={() => changeEditMode('liability', item.title, item.from, item.href, index)}><img src='/images/document-edit.svg' /><span>Edit in 1003</span></div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Collapse.Panel>
                                    </Collapse>
                                </div>
                            </>
                        ) : (
                            <div>
                                {
                                    showInfo && stateChange && (
                                        <div className='dti-tooltip-card'>
                                            <div className='dti-tooltip-card-title'><img style={{width: 18, height: 18}} src='/images/landingPage-warning.svg'/><span>Loan information has been changed</span></div>
                                            <div className='dti-tooltip-card-content'>Section 4a information has been updated. Please reselect the <span style={{ color: '#325CEB', cursor: 'pointer' }}>rate recommendation</span> and recalculate DTI when done.</div>
                                        </div>
                                    )
                                }
                                <div className='dti-calculator-card'>
                                    <div>
                                        <div className='dti-calculator-card-title'>
                                            <div>{editItem[editIndex].title}</div>
                                            <div>${com.commaize(editItem[editIndex].price)}</div>
                                        </div>
                                        {editItem[editIndex].from !== 'all' && <div className='dti-calculator-card-subtitle'>{`${props.application[editItem[editIndex].from].firstname} ${props.application[editItem[editIndex].from].lastname} ${editItem[editIndex].employername === '' ? '' : '- ' + editItem[editIndex].employername }`}</div>}
                                    </div>
                                    {
                                        editItem[editIndex].priceList.map((priceItem, priceIndex) => (
                                            <div className='dti-calculator-price' key={priceIndex}>
                                                <div>{priceItem.title}</div>
                                                <div>{priceItem.type === 'percent' ? `${priceItem.price}%` : `$ ${com.commaize(priceItem.price)}`}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>
        </>
    )
}
DTICalculatorModal = connect(mapStateToProps)(DTICalculatorModal)