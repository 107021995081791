import { Button } from "antd";
import React, { useState, useRef, useEffect } from "react";

const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const startRecording = async () => {
    try {
      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Create MediaRecorder instance
      mediaRecorderRef.current = new MediaRecorder(stream);

      // Collect audio data in chunks
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) chunksRef.current.push(event.data);
      };

      // Create audio blob and URL when recording stops
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/webm" });
        const url = URL.createObjectURL(blob);
        setAudioBlob(blob);
        setAudioUrl(url);
        chunksRef.current = [];
      };

      // Start recording
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const downloadRecording = () => {
    if (audioBlob) {
      const link = document.createElement("a");
      link.href = audioUrl;
      link.download = "recording.webm";
      link.click();
    }
  };

  const [recordingTime, setRecordingTime] = useState(0);
  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => setRecordingTime((prev) => prev + 1), 1000);
    } else {
      clearInterval(timer);
      setRecordingTime(0);
    }
    return () => clearInterval(timer);
  }, [isRecording]);

  return (
    <div>
      {!isRecording ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyItems: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Button onClick={startRecording}>
            {audioUrl ? "Rerecord your voice" : "Start recording your voice"}
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyItems: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Button onClick={stopRecording}>Stop Recording</Button>
          <p>Recording time: {recordingTime} seconds</p>
        </div>
      )}
      {audioUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <p>Playback your recording:</p>
          <audio controls src={audioUrl}></audio>
          <Button onClick={downloadRecording}>Download Recording</Button>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
