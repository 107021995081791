import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal, Spin } from "antd";

interface VideoPlayerProps {
  name: string;
  streamingUrl: string;
  downloadUrl: string;
  isModalOpen: boolean;
  handleCancel: () => void;
}

const VideoPlayer = forwardRef(
  (
    {
      name,
      streamingUrl,
      downloadUrl,
      isModalOpen,
      handleCancel,
    }: VideoPlayerProps,
    ref
  ) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const handleDownload = () => {
      setIsDownloading(true);
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${name}.mov`;
        link.click();
        setIsDownloading(false);
      }, 1000);
    };

    useImperativeHandle(ref, () => ({
      handleDownload,
    }));

    return (
      <Modal
        title={`${name}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <iframe
          src={streamingUrl}
          width="100%"
          height="450"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <Button type="primary" onClick={handleDownload}>
            Download Video
          </Button>
        </div>
      </Modal>
    );
  }
);

export default VideoPlayer;
