import React, { useState } from 'react'
import { Modal, Row, Col, Table, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons'
import * as com from "../../../Common.js";
import AutoProgress from '../../../DocumentChecklist/AutoProgress';
import { Upload } from 'antd'
import { getLoanAmount as getHardMoneyLoanLoanAmount } from "../../../HardMoneyLoan/HardMoneyLoan/Overview";
import './index.css'
const { Dragger } = Upload
export default function ImportMISMOFile(props) {
    const {
        open, onCancel
    } = props
    const history = useHistory()
    const [applicationData, setApplicationData] = useState({})
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loanID, setLoanID] = useState("")
    const [fileName, setFileName] = useState("")
    const [formList, setFormList] = useState([])
    const [tableData, setTableData] = useState([])
    const [isExistingBorrower, setIsExistingBorrower] = useState(false)
    const [existingBorrower, setExistingBorrower] = useState("")
    const [uploadStatus, setUploadStatus] = useState('not uploaded')
    const modalCancel = () => {
        setIsExistingBorrower(false)
        setTableData([])
        setUploadStatus('not uploaded')
        onCancel()
    }
    const handleBack = () => {
        if (uploadStatus === 'uploadSuccess') {
            setIsExistingBorrower(false)
            setTableData([])
            setUploadStatus('not uploaded')
        } else if (uploadStatus === 'importSuccess') {
            setUploadStatus('uploadSuccess')
        }
    }
    const createLoan = () => {
        setConfirmLoading(true)
        let token = sessionStorage.getItem('ZeitroA')
        let tosend = JSON.stringify({
            borrowerEmail: applicationData.borrower.email,
            applicationData: applicationData,
        })
        fetch('/los/createloanfrommismo', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },
        })
        .then((response) => response.json())
        .then((data) => {
            setConfirmLoading(false)
            if (data.status === "Error") {
                
                return;
            }
            setLoanID(data.loanID)
            setUploadStatus('importSuccess')
        })
        .catch((error) => {
            setConfirmLoading(false)
        })
    }
    const handleConfirm = () => {
        if (uploadStatus === 'uploadSuccess') {
            createLoan()
        } else if (uploadStatus === 'importSuccess') {
            window.location.href = `/app/borrower/${loanID}`
        }
    }
    const getLoanAmount = (props) => {
        if (props.product.mortgageapplied === "hardmoneyloan") {
            return getHardMoneyLoanLoanAmount(props)
        }

        if (props.selection.loansize === "") {
            return "-"
        }
        return "$" + com.commaize(props.selection.loansize)
    }
    const draggerProps = {
        name: 'file',
        showUploadList: false,
        className: 'import-mismo-dragger',
        maxCount: 1,
        onChange(info) {
            const uploadFileName = info.file.name.substring(0, info.file.name.lastIndexOf('.'));
            setFileName(uploadFileName)
            let token = sessionStorage.getItem('ZeitroA')
            const formData = new FormData()
            formData.append('upload', info.file)
            setUploadStatus('loading')
            fetch('/los/importmismo', {
                cache: 'no-cache',
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "Error") {
                    setUploadStatus('uploadFailed')
                    return;
                }
                setIsExistingBorrower(data.existedBorrowerEmail !== '')
                if (data.existedBorrowerEmail !== '') {
                    setExistingBorrower(`${data.existedBorrowerName}${data.existedCoBorrowerName !== '' ? ', ' + data.existedCoBorrowerName : ''}`)
                }
                let applicationData = data.applicationData
                let form = [
                    {
                        key: 'borrower',
                        label: 'Primary borrower',
                        value: `${applicationData.borrower.firstname ? applicationData.borrower.firstname + ' ' + applicationData.borrower.lastname  : '-'}`,
                    },
                    {
                        key: 'coborrower',
                        label: 'Co-borrower(s)',
                        value: `${applicationData.coborrower.firstname ? applicationData.coborrower.firstname + ' ' + applicationData.coborrower.lastname  : '-'}`,
                    },
                    {
                        key: 'dateOfBirth',
                        label: 'Date of birth',
                        value: `${applicationData.borrower.dateofbirth ? dayjs(applicationData.borrower.dateofbirth).format('MM/DD/YYYY') : '-'}`,
                    },
                    {
                        key: 'coborrowerDateOfBirth',
                        label: 'Date of birth',
                        value: `${applicationData.coborrower.dateofbirth ? dayjs(applicationData.coborrower.dateofbirth).format('MM/DD/YYYY') : '-'}`,
                    },
                    {
                        key: 'loanPurpose',
                        label: 'Loan purpose',
                        value: `${applicationData.property.purpose ? com.capitalize(applicationData.property.purpose) : '-'}`,
                    },
                    {
                        key: 'loanAmount',
                        label: 'Loan amount',
                        value: `${getLoanAmount(applicationData)}`,
                    },
                    {
                        key: 'propertyType',
                        label: 'Property type',
                        value: `${applicationData.property.propertytype ? com.capitalize(applicationData.property.propertytype) : '-'}`,
                    },
                    {
                        key: 'propertyAddress',
                        label: 'Property Adress',
                        value: `${applicationData.property.address ? applicationData.property.address : '-'}`,
                    }
                ]
                if (applicationData.borrower.firstname) {
                    setTableData((prevTableData) => [
                        ...prevTableData, 
                        {
                            key: '1',
                            name: `${applicationData.borrower.firstname} ${applicationData.borrower.lastname}`,
                            phone: applicationData.borrower.homephone ? com.fixPhoneInput(applicationData.borrower.homephone) : '-',
                            email: applicationData.borrower.email ? applicationData.borrower.email : '-',
                        }
                    ])
                }
                if (applicationData.coborrower.firstname) {
                    setTableData((prevTableData) => [
                        ...prevTableData, 
                        {
                            key: '2',
                            name: `${applicationData.coborrower.firstname} ${applicationData.coborrower.lastname}`,
                            phone: applicationData.coborrower.homephone ? com.fixPhoneInput(applicationData.coborrower.homephone) : '-',
                            email: applicationData.coborrower.email ? applicationData.coborrower.email : '-',
                        }
                    ])
                }
                setApplicationData(applicationData)
                setFormList(form)
                setUploadStatus('uploadSuccess')
            })
            .catch((error) => {
                setUploadStatus('uploadFailed')
            })
        },
        onDrop(e) {
            
        },
        beforeUpload: (file) => {
            const allowedFileTypes = ['text/xml']
            const fileType = file.type
            const isAllowed = allowedFileTypes.includes(fileType)
            if (!isAllowed) {
                message.warning('You can only upload xml files.')
                return Upload.LIST_IGNORE
            }
            return false
        },
    }
    const confirmStatus = () => {
        if (uploadStatus === 'uploadSuccess' || uploadStatus === 'importSuccess') {
            return false
        }
        return true
    }
    const footer = uploadStatus === 'not uploaded' || uploadStatus === 'uploadFailed' ? [
        <Button className="mismo-btn-confirm" type="primary" loading={confirmLoading} disabled={confirmStatus()} onClick={handleConfirm}>{uploadStatus === 'importSuccess' ? 'Continue to application' : 'Confirm upload'}</Button>
    ] : [
        <Button className="mismo-btn-back" type='link' onClick={handleBack}>Back</Button>,
        <Button className="mismo-btn-confirm" type="primary" loading={confirmLoading} disabled={confirmStatus()} onClick={handleConfirm}>{uploadStatus === 'importSuccess' ? 'Continue to application' : 'Confirm upload'}</Button>
    ]
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text, record) => <div style={{fontWeight: 500}}>{text}</div>,
        },
        {
          title: 'Phone number',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          key: 'email',
        },
    ]
    return (
        <>
            <Modal
                className={`import-mismo-modal ${footer.length === 1 ? 'no-back-modal' : ''}`}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                maskClosable={true}
                width={974}
                open={open}
                centered={true}
                title=""
                onCancel={modalCancel}
                footer={footer}
            >
                <div className='import-mismo-title'>Import MISMO file</div>
                <div className='import-mismo-content'>
                    {
                        uploadStatus === 'importSuccess' ? (
                            <>
                                <div className='import-mismo-success-title'>Your file has been successfully imported. Following new borrower information has been added to your borrower list.</div>
                                <Table
                                    className="import-mismo-borrower-table"
                                    pagination={false}
                                    columns={columns}
                                    dataSource={isExistingBorrower ? [{
                                        key: '1',
                                        name: '-',
                                        phone: '-',
                                        email: '-',
                                    }] : tableData}
                                />
                            </>
                        ) : (
                            <>
                                {
                                    uploadStatus !== 'importSuccess' && (
                                        <div className='upload-file-wrap'>
                                            {
                                                (uploadStatus === 'uploadSuccess' || uploadStatus === 'uploadFailed') && (
                                                    <>
                                                        <div className={`${uploadStatus === 'uploadFailed' ? 'read-failed' : '' } file-card`}>
                                                            <div><img src={`/images/mismo-file-icon${uploadStatus === 'uploadFailed' ? '-red' : ''}.svg`} style={{width: 24, height: 24, marginRight: 6}} /><span>{fileName}</span></div>
                                                            {
                                                                uploadStatus === 'uploadSuccess' ? (
                                                                    <div style={{width: 24, height: 24, marginLeft: 6, cursor: 'pointer', fontSize: 10, color: '#6E6E70'}} onClick={handleBack}><CloseOutlined /></div>
                                                                ) : (
                                                                    <InfoCircleFilled style={{fontSize: 18, color: '#8D1313', marginRight: 12}} />
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            uploadStatus === 'uploadSuccess' ? (
                                                                <div className='file-cancel-btn' onClick={handleBack}>Cancel</div>
                                                            ) : (
                                                                <div className='file-read-failed-text'>The file you uploaded cannot be read. Please upload again.</div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                <div className='upload-file-content' style={uploadStatus === 'uploadSuccess' ? {minHeight: 'auto'} : {}}>
                                    {
                                        (uploadStatus === 'not uploaded' || uploadStatus === 'uploadFailed') && (
                                            <Dragger {...draggerProps}>
                                                <div>
                                                    <div className="ant-upload-text">Drag and drop a MISMO file here or</div>
                                                    <div><Button className="mismo-upload" type="primary">Upload from files</Button></div>
                                                    <div className="ant-upload-hint" style={{color: '#6E6E70'}}>
                                                        Only one file at a time
                                                    </div>
                                                </div>
                                            </Dragger>
                                        )
                                    }
                                    {
                                        uploadStatus === 'loading' && (
                                            <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                                <AutoProgress 
                                                    type="circle"
                                                    percent="auto"
                                                    strokeWidth={10} 
                                                    trailColor="#BDCBFB" 
                                                    strokeColor="#325CEB"
                                                    size={100}
                                                    format={(percent) => <span style={{fontSize: 20, fontFamily: 'Inter', color: '#325CEB', fontWeight: 600}}>{percent.toFixed(0)}%</span>}
                                                />
                                                <div style={{fontFamily: 'Inter', fontSize: 16, fontWeight: 500, lineHeight: 'normal', marginTop: 16}}>Uploading...</div>
                                            </div>
                                        )
                                    }
                                    {
                                        uploadStatus === 'uploadSuccess' && (
                                            <Row>
                                                {
                                                    formList.map(item => (
                                                        <Col className='upload-file-item' key={item.key} md={12} span={24}>
                                                            <div>
                                                                <div className='upload-file-label'>{item.label}</div>
                                                                <div className='upload-file-value'>{item.value}</div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        )
                                    }
                                </div>
                                {
                                    isExistingBorrower && (
                                        <div className='upload-file-match'>
                                            <div className='upload-file-match-text'>
                                                <div>Create new application for matching existing borrower:</div>
                                                {(uploadStatus === 'uploadSuccess' || uploadStatus === 'importSuccess') && <div style={{color: '#325CEB'}}>{existingBorrower}</div>}
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Modal>
        </>
    )
}