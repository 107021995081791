
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import * as com from "../Common.js"
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'

import AddressAutocomplete from '../Common/AddressAutocomplete'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import { property } from '../State'
import { getCurrentState } from '../Store'
import { calculateBalancesAndPayments } from './Liabilities'
import {countSubjectMortgages} from './AlimonySolar'

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    addBorrowerLoan: (event, who) => {
        dispatch(act.AddBorrowerLoan(event, who))
    },
    removeBorrowerLoan: (event, i, who) => {
        dispatch(act.RemoveBorrowerLoan(event, i, who))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },

    changeBorrowerLoanCompany: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanCompany(event.target.value, i, who))
    },
    changeBorrowerLoanAddress: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanAddress(event.target.value, i, who))
    },
    changeBorrowerLoanAccount: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanAccount(event.target.value, i, who))
    },
    changeBorrowerLoanMonthly: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanMonthly(event.target.value, i, who))
    },
    changeBorrowerLoanRemaining: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanRemaining(event.target.value, i, who))
    },
    changeBorrowerLoanSatisfiedUpon: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanSatisfiedUpon(event.target.value, i, who))
    },

    changeBorrowerLoanRate: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanRate(event.target.value, i, who))
    },

    changeBorrowerLoanForSubject: (val, i, who) => {
        dispatch(act.ChangeBorrowerLoanForSubject(val, i, who))
    },

    changeBorrowerLoanCorrection: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanCorrection(event.target.value, i, who))
    },
    changeBorrowerLoanExplanation: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanExplanation(event.target.value, i, who))
    },

    changeBorrowerMonthsLeftToPay: (event, i, who) => {
        dispatch(act.ChangeBorrowerMonthsLeftToPay(event.target.value, i, who))
    },
    updateFinances: (t, who, verb) => {
        dispatch(act.UpdateFinances(t, who, verb))
    },
    addBorrowerAdditionaCredits: (event, who) => {
        dispatch(act.AddBorrowerAdditionaCredits(event, who))
    },
    removeBorrowerAdditionalCredits: (i, who) => {
        dispatch(act.RemoveBorrowerAdditionalCredits(i, who))
    },
    clearBorrowerAdditionalCredits: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalCredits(event, who))
    },
    changeBorrowerAdditionalCreditAltName: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalCreditAltName(event.target.value, i, who))
    },
    changeBorrowerAdditionalCreditAccount: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalCreditAccount(event.target.value, i, who))
    },
    changeBorrowerAdditionalCreditCreditor: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalCreditCreditor(event.target.value, i, who))
    },
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },
    addAlimony: (event, who) => {
        dispatch(act.AddAlimony(event, who))
    },
    removeAlimony: (i, who) => {
        dispatch(act.RemoveAlimony(i, who))
    },
    clearAlimonies: (event, who) => {
        dispatch(act.ClearAlimonies(event, who))
    },
    changeOwedTo: (event, i, who) => {
        dispatch(act.ChangeOwedTo(event.target.value, i, who))
    },
    changeAlimony: (event, i, who) => {
        dispatch(act.ChangeAlimony(event.target.value, i, who))
    },
    changePaymentType: (event, i, who) => {
        dispatch(act.ChangePaymentType(event.target.value, i, who))
    },

    updateSolarPanels: (payload, verb) => {
        dispatch(act.UpdateSolarPanels(payload, verb))
    },
    changeBorrowerFreeAndClear: (payload, who) => {
        dispatch(act.ChangeBorrowerFreeAndClear(payload, who))
    },

    addOtherLien: () => {
        dispatch(act.AddOtherLien())
    },
    removeOtherLien: (index) => {
        dispatch(act.RemoveOtherLien(index))
    },
    clearBorrowerOtherLiens: () => {
        dispatch(act.ClearBorrowerOtherLiens())
    },

    changeBorrowerOtherLienValue: (t, i, verb) => {
        dispatch(act.ChangeBorrowerOtherLienValue(t, i, verb))
    },

    addBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.AddBorrowerAdditionalProperty(event, who))
    },
    clearBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalProperty(event, who))
    },
    removeBorrowerAdditionalProperty: (i, who) => {
        dispatch(act.RemoveBorrowerAdditionalProperty(i, who))
    },
    changeBorrowerAdditionalPropertyAddress: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyAddress(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyAttribution: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyAttribution(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyStatus: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyStatus(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyType: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyType(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyValue: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyValue(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyLiens: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyLiens(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyGrossIncome: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyGrossIncome(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyPayment: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyPayment(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyExpenses: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyExpenses(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyNetIncome: (event, i, who) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyNetIncome(event.target.value, i, who))
    },
    changeBorrowerAdditionalPropertyAttribute: (value, index, verb) => {
        dispatch(act.ChangeBorrowerAdditionalPropertyAttribute(value, index, verb))
    },
    updateApplicationCheck: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.checked, verb))
    },
    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    changeBorrowerInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    changeBorrowerAdditionalPropertiesFromCreditAttr: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerAdditionalPropertiesFromCreditAttr(payload, index, verb))
    },

    addNewMortgageLoan: () => {
        dispatch(act.AddNewMortgageLoan())
    },
    removeNewMortgageLoan: (index) => {
        dispatch(act.RemoveNewMortgageLoan(index))
    },
    clearNewMortgageLoans: () => {
        dispatch(act.ClearNewMortgageLoans())
    },
    updateNewMortgageLoanAttribute: (value, index, verb) => {
        dispatch(act.UpdateNewMortgageLoanAttribute(value, index, verb))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
});

export default class MoreLiabilities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
            loanbalance: parseInt(this.props.application.loanbalance),
            showWarning: false
        }
        this.getBalancePayment = calculateBalancesAndPayments.bind(this)
      /* eslint-disable-next-line no-unused-vars */
        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        this.state.loanbalance = mortgagebalance

        this.reficount = countSubjectMortgages(this.props.application.property.purpose, 
            this.props.application.assetsandliabilities.loans, this.props.application.otherliens)

        window.setTimeout(() => {

            if (this.reficount === 0 &&
                !this.props.application.assetsandliabilities.propertyownedfreeandclear && (this.props.application.otherliens == null ||
                    (this.props.application.otherliens !== null && this.props.application.otherliens.length === 0))
            ) {
                this.props.addOtherLien()
            }
        }, 100)
        // build the additional properties
        this.buildAdditionalRealEstate()
    }


    editAdditionalRealEstate = () => {
        // check if this is new code
        let loans = this.props.application.assetsandliabilities.loans
        let additionalproperties = []
        let propertiesbyaddress = {}

        let getLoanByKey = (key) => {
            for (let i = 0; i < loans.length; i++) {
                let loan = loans[i]
                if (loan.key === key) {
                    return loan
                }
            }
            return null
        }
        let getAdditionalByKey = (key) => {
            for (let i = 0; i < additionalproperties.length; i++) {
                if (key === additionalproperties[i].key)
                    return additionalproperties[i]
            }
            return null
        }

        // remove additional properties that are not subject
        for (let i = 0; i < this.props.application.assetsandliabilities.propertiesfrompull.length; i++) {
            let additional = { ...this.props.application.assetsandliabilities.propertiesfrompull[i] }
            let loan = getLoanByKey(additional.key)
            if (null === loan)
                continue
            if (loan.forsubjectproperty === true)
                continue
            if (loan.correction !== "none")
                continue
            if ("undefined" === typeof propertiesbyaddress[loan.propertyaddress]) {
                additional.address = loan.propertyaddress
                additional.partoforiginalpurchase = loan.partoforiginalpurchase
                additional.includesmi = loan.includesmi

                additional.ownedfreeandclear = false
                additional.liens = loan.remainingbalance
                additional.payment = loan.monthlypayment

                additionalproperties.push(additional)
                propertiesbyaddress[additional.address] = additional
            } else {
                let p = propertiesbyaddress[loan.propertyaddress]
                p.liens = parseInt(loan.remainingbalance) + parseInt(p.liens)
                p.payment = parseInt(loan.monthlypayment) + parseInt(p.payment)
            }
        }
        // now all present in additionalproperties are actual
        // see if there are more:
        for (let i = 0; i < loans.length; i++) {
            let loan = loans[i]
            if (null != getAdditionalByKey(loan.key))
                continue
            if (loan.propertyaddress === null || loan.propertyaddress === "")
                continue
            if (loan.forsubjectproperty === true)
                continue
            if (loan.correction !== "none")
                continue
            if ("undefined" === typeof propertiesbyaddress[loan.propertyaddress]) {
                let p = new property()

                p.address = loan.propertyaddress
                p.creditor = loan.name
                p.account = loan.accountnumber
                p.ownedfreeandclear = false
                p.liens = loan.remainingbalance
                p.payment = loan.monthlypayment
                p.attribution = loan.attribution
                p.key = loan.key
                propertiesbyaddress[loan.propertyaddress] = p
                additionalproperties.push(p)
            } else {
                let p = propertiesbyaddress[loan.propertyaddress]

                p.liens = parseInt(loan.remainingbalance) + parseInt(p.liens)
                p.payment = parseInt(loan.monthlypayment) + parseInt(p.payment)
            }
        }

        this.props.addBorrowerAdditionalPropertiesFromCredit(additionalproperties)
    }
    buildAdditionalRealEstate = () => {

        if (null !== this.props.application.assetsandliabilities.propertiesfrompull)
            return this.editAdditionalRealEstate()// EDIT!!!

        let loans = this.props.application.assetsandliabilities.loans
        if(loans == null) return
        let additionalproperties = []
        let propertiesbyaddress = {}

        for (let i = 0; i < loans.length; i++) {
            let loan = loans[i]
            if (loan.propertyaddress === null || loan.propertyaddress === "")
                continue
            if ("undefined" === typeof propertiesbyaddress[loan.propertyaddress]) {
                let p = new property()
                propertiesbyaddress[loan.propertyaddress] = p
                additionalproperties.push(p)

                p.address = loan.propertyaddress
                p.creditor = loan.name
                p.account = loan.accountnumber
                p.ownedfreeandclear = false
                p.liens = loan.remainingbalance
                p.payment = loan.monthlypayment
                p.attribution = loan.attribution
                p.key = loan.key
            } else {
                let p = propertiesbyaddress[loan.propertyaddress]

                p.liens = parseInt(loan.remainingbalance) + parseInt(p.liens)
                p.payment = parseInt(loan.monthlypayment) + parseInt(p.payment)
            }
        }
        this.props.addBorrowerAdditionalPropertiesFromCredit(additionalproperties)
        getCurrentState()
    }



    componentDidMount() {

    }

    componentWillUnmount() { }

    updateJointly = (e) => {
        com.touch()
        let f = e.target.value === "true"
        this.props.updateJointly({ target: { checked: f } })
    }

    processOtherCredits = (val, index) => {
        if (null === val)
            return ""
        let { altname, creditor, account, key } = val

        return this.renderRow(index, altname, creditor, account, key)
    }
    updateFinancesCheck = (verb) => {
        return e => {
            let val = e.target.value === "true"
            this.props.updateFinances(val, this.props.who, verb)
        }
    }
    updateFinances = (verb) => {
        return e => this.props.updateFinances(e.target.value, this.props.who, verb)
    }

    nbsp = () => {
        return (<div className="mt-4">&nbsp;<br />&nbsp;</div>)
    }
    addOtherSources = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerLoans(0, this.props.who)
        } else {
            this.props.addBorrowerLoan(0, this.props.who)
        }
    }

    processAlimonies = (val, index) => {
        if (null === val)
            return ""

        let amount = val.amount;
        let owedto = val.owedto;
        let whatisit = val.alimonyorsupport;
        let key = val.key
        return this.generateAlimony(index, amount, owedto, whatisit, key)
    }

    generateAlimony = (index, amount, owedto, whatisit, key) => {
        index = parseInt(index)
        let removeAlimony = (event) => {
            com.touch()
            this.props.removeAlimony(index, this.props.who)
        }
        let changeAlimony = (event) => {
            com.touch()
            this.props.changeAlimony(event, this.props.who, index)
        }
        let changeOwedTo = (event) => {
            com.touch()
            this.props.changeOwedTo(event, this.props.who, index)
        }
        let addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, this.props.who)
        }
        let updateWhat = (event) => {
            com.touch()
            this.props.changePaymentType(event, this.props.who, index)
        }
        return (
            <div key={key} className="incard">

                <Row className="p-0">
                    <Col className="p-0 px-1">
                        <Row >

                            <Col xs="auto">
                                <Form.Group controlId="Amount" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Monthly Amount:</Form.Label>
                                    <NumericalInput isValid={false} size="sm"
                                        required
                                        name={"amount" + index}

                                        type="text"
                                        defaultValue={amount}
                                        onChange={changeAlimony}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="type" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Payment type</Form.Label>
                                    <Form.Control name="purpose" as="select"
                                        value={whatisit}
                                        onChange={updateWhat}
                                        size="sm">

                                        <option value="alimony">Alimony</option>
                                        <option value="childsupport">Child Support</option>
                                        <option value="maintenancepayments">Separate Maintenance Payments</option>

                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col   >
                                <Form.Group controlId="owedto" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Owed To:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"owedto" + index}
                                        type="text"
                                        defaultValue={owedto}
                                        onChange={changeOwedTo}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the payee.
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== this.props.application[this.props.who].finances.alimony.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAlimony} ></i>
                        <i hidden={index === this.props.application[this.props.who].finances.alimony.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>

                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAlimony} ></i>
                    </Col>
                </Row>
            </div >
        )
    }

    displayOtherLiens = (lien, index) => {

        let lender = lien.lender
        let monthly = lien.monthly
        let balance = lien.balance
        let account = lien.account
        let monthslefttopay = lien.monthslefttopay
        let key = lien.key

        return this.generateOtherLien(index, lender, monthly, balance, account, monthslefttopay, key)
    }
    sameaddress = () => {
        let addr = com.formatAddress(this.props.application.borrower)
        return addr
    }

    generateOtherLien = (index, lender, monthly, balance, account, monthslefttopay, key) => {

        let changeBorrowerLoanSatisfiedUpon = e => {
            com.touch()

            if (this.props.application.property.purpose === st.POL_Refinance && e.target.value === "withproceeds") {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < this.props.application.assetsandliabilities.loans.length; i++) {
                    let loan = this.props.application.assetsandliabilities.loans[i]
                    if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                        alreadyRefinanced = true
                        this.recalcLoanDelayed()
                        break
                    }
                }
                if (alreadyRefinanced) {
                    this.setState({ observedIndex: index, showWarning: true })
                    this.recalcLoanDelayed()
                    return
                }

                for (let i = 0; i < index; i++) {
                    let loan = this.props.application.otherliens[i]
                    if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                        alreadyRefinanced = true
                        this.recalcLoanDelayed()
                        break
                    }
                }
                if (alreadyRefinanced) {
                    this.setState({ showWarning: true })
                    return
                }
            }

            this.props.changeBorrowerOtherLienValue(e.target.value, index, "satisfiedupon")
            this.recalcLoanDelayed()
        }
        let onHeloc = e => { 
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value === "isheloc", index, "isHELOC")
            if(e.target.value === "isheloc") {
                this.props.changeBorrowerOtherLienValue(false, index, "isprimary")
            }
             this.recalcLoanDelayed()
        }
        let switchCashout = () => {
            this.setState({ showWarning: false })
            this.props.changeBorrowerOtherLienValue("withproceeds", index, "satisfiedupon")
            this.props.changeMainPropertyPurpose({ target: { value: st.POL_Cashoutrefinance } })
        }
        let originalPurchase = () => {
            this.setState({ showWarning: false })
            this.props.changeBorrowerOtherLienValue("withproceeds", index, "satisfiedupon")
            this.props.changeBorrowerLoan(true, index, "partoforiginalpurchase")
        }
        let askHowToPay = () => {


            return (
                <div className="pl-2">
                    <Modal show={this.state.showWarning} onHide={() => { this.setState({ showWarning: false }) }}

                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h4>Tell us more about these loans</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="py-3">
                            <div style={{ minWidth: '400px' }}>
                                You indicated that you'd like to refinance more than one loan.
                            </div>
                            <div className="mt-4">
                                This is possible within the limits of rate/term refinance only if these loans were obtained together as a part of a purchase transaction.

                            </div>
                            <div className="mt-4">
                                Otherwise, we can switch to a cash out refinance. Your rates might be higher. Note, that you can always go back and change your choice.
                            </div>
                            <form name={"doo" + index}>
                            </form>
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Button onClick={switchCashout}>Switch to cash out refinance</Button>
                                </Col>
                                <Col className="text-center">
                                    <Button onClick={originalPurchase}>The loans were part of original purchase</Button>
                                </Col>
                            </Row>

                        </Modal.Body>

                    </Modal>
                    <div className="sectionquestion "><i className="fas fa-angle-right"></i>Is this a HELOC?
                    </div>
                    <Form.Group>
                        <fieldset>
                            <div className="d-flex mb-0">
                                <div className="ml-2 mr-3 ">
                                    <input required onChange={onHeloc} value="isheloc" checked={this.props.application.otherliens[index].isHELOC === true} type="radio" id={"heloc" + index} name={"heloc" + index}></input>
                                    <label id={"lheloc" + index} className="zeitro-radio divlink" htmlFor={"heloc" + index}>Yes, it is</label>
                                </div>
                                <div className="ml-2 mr-3 ">
                                    <input required onChange={onHeloc} value="notheloc" checked={this.props.application.otherliens[index].isHELOC === false} type="radio" id={"notheloc" + index} name={"heloc" + index}></input>
                                    <label id={"lnotheloc" + index} className="zeitro-radio divlink" htmlFor={"notheloc" + index}>No, it isn't</label>
                                </div>
                            </div>
                        </fieldset>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please make your choice.
                        </Form.Control.Feedback>
                    </Form.Group>

                {/*
                    <div className="sectionquestion"><i className="fas fa-angle-right"></i>Will you pay the balance before this transaction?
                    </div>
                    <Form.Group>
                        <fieldset>
                            <div className="d-flex mb-0">
                            {this.props.application.property.purpose === "purchase" ? "" :
                                <div className="ml-2 mr-3 ">
                                    <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={this.props.application.otherliens[index].satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + index} name={"satisfied" + index}></input>
                                    <label id={"hsatisfiedlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfied" + index}>Yes, I'm refinancing this loan</label>
                                </div>
                            }
                                <div className="ml-2 mr-3 ">
                                    <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={this.props.application.otherliens[index].satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + index} name={"satisfied" + index}></input>
                                    <label id={"hsatisfiedownlabel" + index} className="zeitro-radio zeitro-radio divlink" htmlFor={"satisfiedown" + index}>Yes, I'm paying it off myself</label>
                                </div>
                                <div>
                                    <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={this.props.application.otherliens[index].satisfiedupon === "not"} type="radio" id={"not" + index} name={"satisfied" + index}></input>
                                    <label id={"hnotlabel" + index} className="zeitro-radio divlink" htmlFor={"not" + index}>No, I won't</label>
                                </div>
                            </div>
                        </fieldset>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please make your choice.
                        </Form.Control.Feedback>
                    </Form.Group>
                        */}
                </div>
            )

        }

        let changeLender = e => {
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value, index, "lender")
        }
        let changeMonthly = e => {
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value, index, "monthly")
        }
        let changeAccount = e => {
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value, index, "account")
        }
        let changeBalance = e => {
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value, index, "balance")
            window.setTimeout(this.recalcLoan, 100)

        }
        let addOtherLien = e => {
            com.touch()
            this.props.addOtherLien()
        }
        let removeLien = e => {
            com.touch()
            this.props.removeOtherLien(index)
        }
        let changeMoLeftToPay = e => {
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value, index, "monthslefttopay")
        }
        let changeRate = e => {
            com.touch()
            this.props.changeBorrowerOtherLienValue(e.target.value, index, "rate")
        }
        let isPrimary = () => {
            if (this.props.application.property.purpose !== st.POL_Purchase 
            ) {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < this.props.application.assetsandliabilities.loans.length; i++) {
                    let loan = this.props.application.assetsandliabilities.loans[i]
                    if (this.props.application.assetsandliabilities.loans[i].forsubjectproperty &&
                        loan.satisfiedupon === "withproceeds") {
                        alreadyRefinanced = true
                        break
                    }
                }

                for (let i = 0; !alreadyRefinanced && i < index; i++) {
                    let loan = this.props.application.otherliens[i]
                    if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                        alreadyRefinanced = true
                        break
                    }
                }        
                if (alreadyRefinanced) {
                    return false
                }                        
                return true
            }
            return false
        }
        return (
            <div key={key} className="incard px-0">
                <Row>
                    <Col className="pl-2">
                        <Row >
                            <Col xs="auto" >
                                <Form.Group controlId="lender" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Lender:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"lender" + index}
                                        type="text"
                                        defaultValue={lender}
                                        onChange={changeLender}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the payee.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="Account" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Account Number:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"account" + index}

                                        type="text"
                                        defaultValue={account}
                                        onChange={changeAccount}
                                        placeholder="XXXX (last 4 digits)"
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col xs="auto">
                                <Form.Group controlId="Amount" className="text-left" size="sm">
                                    <Form.Label className="text-left" >Mo. Payment:</Form.Label>
                                    <NumericalInput isValid={false} size="sm"
                                        required
                                        name={"amount" + index}

                                        type="text"
                                        defaultValue={monthly}
                                        onChange={changeMonthly}

                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId={"balance" + index} className="text-left" size="sm">
                                    <Form.Label className="text-left" >Balance:</Form.Label>
                                    <NumericalInput isValid={false} size="sm"
                                        required
                                        name={"balance" + index}

                                        type="text"
                                        defaultValue={balance}
                                        onChange={changeBalance}
                                        onBlur={this.recalcLoan}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="auto">
                                <Form.Group controlId={"moleft" + index} className="text-left" size="sm">
                                    <Form.Label className="text-left" >Months left to pay:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"moleft" + index}
                                        onChange={changeMoLeftToPay}
                                        type="number"
                                        defaultValue={monthslefttopay}
                                        min="1"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide months left to pay.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top">
                        <i hidden={index !== this.props.application.otherliens.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addOtherLien} ></i>
                        <i hidden={index === this.props.application.otherliens.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        {index === 0 && this.reficount === 0 &&
                            !this.props.application.assetsandliabilities.propertyownedfreeandclear ? "" :
                            <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeLien} ></i>}
                    </Col>

                </Row>
                {isPrimary() ?
                    <Row>
                        <Col>
                            <Form.Group controlId={"liability" + index} className="text-left d-flex text-nowrap  m-0  p-0 pb-2 " >
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '1.1em' }} className="text-left pt-1 pl-2" > &gt;Your current rate? </Form.Label>
                                <fieldset>
                                    <InputGroup style={{ width: '8em' }} >
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            className="ml-1"
                                            required
                                            name={"liability" + index}
                                            type="text"
                                            defaultValue={this.props.application.otherliens[index].rate}
                                            onChange={changeRate}
                                            style={{ width: '4em' }}
                                            pattern="^[0-9]+\.[0-9]+$"
                                            placeholder="XX.XX"
                                        />
                                        <InputGroup.Append  >
                                            <InputGroup.Text style={{ fontSize: '1.1em' }} >%
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    <div>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide description.
                                        </Form.Control.Feedback>
                                    </div>
                                </fieldset>
                            </Form.Group>
                        </Col>
                    </Row> : ""}
                <Row className="">
                    <Col >

                        {askHowToPay()}
                    </Col>

                </Row>
            </div>
        )
    }
    processAdditionalRealEstateFromCR = (val, index) => {
        if (null === val)
            return ""

        let { address, status, propertytype, marketvalue, liens,
            grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key } = val
        return this.renderAdditionalCR(index, address, status, propertytype, marketvalue, liens,
            grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key)
    }
    renderAdditionalCR = (index, address, status, propertytype, marketvalue, liens,
        grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key) => {
        index = parseInt(index)

        let addBorrowerAdditionalProperty = (event) => {
            com.touch()
            return this.props.addBorrowerAdditionalProperty(index, this.props.who)
        }

        let changeBorrowerAdditionalPropertiesFromCreditAttr = verb => {
            return (event) => {
                com.touch()
                return this.props.changeBorrowerAdditionalPropertiesFromCreditAttr(event.target.value, index, verb)
            }
        }

        return (
            <div key={key} className="incard px-0">
                <Row className="px-0">

                    <Col xs="auto" className="mb-2 text-wrap">
                        Property Address:
                        <div className="ml-2 mt-3">{this.props.application.assetsandliabilities.propertiesfrompull[index].address} </div>
                    </Col>

                    {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                        <Col xs="2">
                            <Form.Label className="text-left" >Ownership</Form.Label>
                            <Form.Group controlId="employment" >

                                <Form.Control name="employment" as="select" size="sm"
                                    defaultValue={attribution}
                                    requried
                                    onChange={changeBorrowerAdditionalPropertiesFromCreditAttr("attribution")}
                                >
                                    <option value="">...</option>
                                    <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                    <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                    <option value="3">Joint</option>

                                </Form.Control>
                            </Form.Group>
                        </Col> : ""
                    }

                    <Col xs="auto" as="div" className="text-right aligh-top">
                        <i hidden={true} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerAdditionalProperty} ></i>
                        <i hidden={true} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i hidden={true} className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" ></i>
                    </Col>

                </Row>
                <Row>
                    <Col xs="auto" >
                        <Form.Group controlId="propertytype" className="text-left" >
                            <Form.Label className="text-left" >Property Type:&nbsp;
                            </Form.Label>
                            <Form.Control name="propertytype" as="select"
                                defaultValue={this.props.application.assetsandliabilities.propertiesfrompull[index].propertytype}
                                type="text"
                                size="sm"

                                onChange={changeBorrowerAdditionalPropertiesFromCreditAttr("propertytype")}
                            >
                                <option value="singlefamilyattached">Single Family Attached</option>
                                <option value="singlefamilydetached">Single Family </option>
                                <option value="condominium">Condominium</option>
                                <option value="pudattached">PUD attached</option>
                                <option value="puddetached">PUD detached</option>         
                                <option value="twotofourfamily">Two to Four Unit Multifamily</option>                                        

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs="auto" >
                        <Form.Group controlId={"Status" + index} className="text-left" >
                            <Form.Label className="text-left" >Status:&nbsp;</Form.Label>
                            <Form.Control name={"status" + index} as="select" size="sm"
                                defaultValue={this.props.application.assetsandliabilities.propertiesfrompull[index].status}
                                onChange={changeBorrowerAdditionalPropertiesFromCreditAttr("status")}

                            >
                                <option value="other">Keeping in posession</option>
                                <option value="sold">Sold</option>
                                <option value="pending">Pending sale</option>
                                <option value="rental">Rental</option>
                                <option value="second">Second home</option>

                            </Form.Control>

                        </Form.Group>
                    </Col>
                    <Col xs="auto">
                        <Form.Group controlId="bonuses" className="text-left" >
                            <Form.Label className="text-left text-nowrap" >Present Market Value:</Form.Label>
                            <NumericalInput isValid={false}
                                required
                                size="sm"
                                name="assets"
                                defaultValue={this.props.application.assetsandliabilities.propertiesfrompull[index].marketvalue}
                                onChange={changeBorrowerAdditionalPropertiesFromCreditAttr("marketvalue")}

                                min={1}
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide the value
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col sm="auto" as="div" className="text-right aligh-top">
                        <i hidden={false} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i hidden={false} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                    </Col>

                </Row>
                <Row>
                    <Col xs="auto">
                        <Form.Group controlId={"taxes" + index} className="text-left" >
                            <Form.Label className="text-left text-wrap" >Insurance, Maintenance, Taxes/mo: </Form.Label>

                            <NumericalInput isValid={false}
                                required
                                size="sm"

                                name={"taxes" + index}
                                defaultValue={this.props.application.assetsandliabilities.propertiesfrompull[index].insurancemaintenancetaxes}
                                onChange={changeBorrowerAdditionalPropertiesFromCreditAttr("insurancemaintenancetaxes")}

                                min={0}
                            />

                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="auto">
                        <Form.Group controlId={"income" + index} className="text-left" >
                            <Form.Label className="text-left text-nowrap" >Gross Rental Income/mo:</Form.Label>

                            <NumericalInput isValid={false}
                                required
                                size="sm"

                                name={"income" + index}
                                defaultValue={this.props.application.assetsandliabilities.propertiesfrompull[index].grossrentalincome}
                                onChange={changeBorrowerAdditionalPropertiesFromCreditAttr("grossrentalincome")}

                                min={0}
                            />

                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide the value
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col><br />


                    </Col>
                    <Col sm="auto" as="div" className="text-right aligh-top">
                        <i hidden={false} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i hidden={false} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                    </Col>
                </Row>
            </div>
        )
    }
    processAdditionalRealEstate = (val, index) => {
        if (null === val)
            return ""
        let { address, status, propertytype, marketvalue, liens,
            grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key } = val

        return this.renderAdditional(index, address, status, propertytype, marketvalue, liens,
            grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key)
    }

    renderAdditional = (index, address, status, propertytype, marketvalue, liens,
        grossrentalincome, payment, insurancemaintenancetaxes, netrentalincome, attribution, key) => {
        index = parseInt(index)
        let removeBorrowerAdditionalProperty = (event) => {
            com.touch()
            return this.props.removeBorrowerAdditionalProperty(index, this.props.who)
        }
        let addBorrowerAdditionalProperty = (event) => {
            com.touch()
            return this.props.addBorrowerAdditionalProperty(index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyAddress = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyAddress(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyStatus = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyStatus(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyType = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyType(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyValue = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyValue(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyLiens = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyLiens(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyGrossIncome = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyGrossIncome(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyPayment = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyPayment(event, index, this.props.who)
        }
        let changeBorrowerAdditionalPropertyExpenses = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyExpenses(event, index, this.props.who)
        }

        let changeBorrowerAdditionalPropertyAttribution = (event) => {
            com.touch()
            return this.props.changeBorrowerAdditionalPropertyAttribution(event, index, this.props.who)
        }
        let updateFreeAncClear = (event) => {
            com.touch()
            this.props.changeBorrowerAdditionalPropertyAttribute(event.target.checked, index, "ownedfreeandclear")
        }
        let changeBorrowerAdditionalRE = verb => {
            return e => {
                com.touch()
                this.props.changeBorrowerAdditionalPropertyAttribute(e.target.value, index, verb)
            }
        }
        return (
            <div key={key} className="incard">
                <Row>
                    <Col className="p-0 ">
                        <Row className="p-0 ">
                            <Col >
                                <AddressAutocomplete id={"Address" + index} label="Property Address:"
                                    required={true} name={"address" + index}
                                    defaultValue={this.props.application.assetsandliabilities.properties[index].address}
                                    onChange={changeBorrowerAdditionalPropertyAddress}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    goodfeedback="Looks good!"
                                    badfeedback="Please provide address."
                                />
                            </Col>

                            {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                                <Col xs="2">
                                    <Form.Label className="text-left" >Ownership</Form.Label>
                                    <Form.Group controlId="employment" >

                                        <Form.Control name="employment" as="select" size="sm"
                                            defaultValue={attribution}
                                            onChange={changeBorrowerAdditionalPropertyAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                            <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                            <option value="3">Joint</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col> : ""
                            }


                        </Row>
                        <Row>
                            <Col xs="auto" >
                                <Form.Group controlId="propertytype" className="text-left" >
                                    <Form.Label className="text-left" >Property Type:&nbsp;
                                    </Form.Label>
                                    <Form.Control name="propertytype" as="select"
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].propertytype}
                                        type="text"
                                        size="sm"

                                        onChange={changeBorrowerAdditionalPropertyType}
                                    >
                                        <option value="singlefamilyattached">Single Family Attached</option>
                                        <option value="singlefamilydetached">Single Family </option>
                                        <option value="condominium">Condominium</option>
                                        <option value="pudattached">PUD attached</option>
                                        <option value="puddetached">PUD detached</option>
                                        <option value="twotofourfamily">Two to Four Unit Multifamily</option>                                                                                
                                        <option value="land">Land</option>                                             
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" >
                                <Form.Group controlId={"Status" + index} className="text-left" >
                                    <Form.Label className="text-left" >Status:&nbsp;</Form.Label>
                                    <Form.Control name={"status" + index} as="select" size="sm"
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].status}
                                        onChange={changeBorrowerAdditionalPropertyStatus}

                                    >
                                        <option value="other">Keeping in posession</option>
                                        <option value="sold">Sold</option>
                                        <option value="pending">Pending sale</option>
                                        <option value="rental">Rental</option>
                                        <option value="second">Second home</option>

                                    </Form.Control>

                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="bonuses" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Present Market Value:</Form.Label>
                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"
                                        name="assets"
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].marketvalue}
                                        onChange={changeBorrowerAdditionalPropertyValue}

                                        min={1}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the value
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>



                        </Row>
                        <Row>
                            <Col xs="auto">
                                <Form.Group controlId={"taxes" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Insurance, Maintenance, Taxes/mo: </Form.Label>

                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"

                                        name={"taxes" + index}
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].insurancemaintenancetaxes}
                                        onChange={changeBorrowerAdditionalPropertyExpenses}

                                        min={0}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the value.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId={"income" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Gross Rental Income/mo:</Form.Label>

                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"

                                        name={"income" + index}
                                        defaultValue={this.props.application.assetsandliabilities.properties[index].grossrentalincome}
                                        onChange={changeBorrowerAdditionalPropertyGrossIncome}

                                        min={0}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the value
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col><br />
                                <div className="mt-3">
                                    <input type="checkbox" id={"freenadclear" + index}
                                        defaultChecked={this.props.application.assetsandliabilities.properties[index].ownedfreeandclear}
                                        onChange={updateFreeAncClear} ></input>
                                    <label id={"freenadclearlabel" + index} style={{ textDecoration: "underline" }}
                                        className="divlink ml-1 text-wrap" htmlFor={"freenadclear" + index}>I own this property free and clear</label>
                                </div>

                            </Col>

                        </Row>

                        {this.props.application.assetsandliabilities.properties[index].ownedfreeandclear ? "" :
                            <Row>
                                <Col>
                                    <Form.Group controlId={"creditor" + index} className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Creditor:</Form.Label>
                                        <Form.Control isValid={false}
                                            required
                                            type="text"
                                            size="sm"
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].creditor}
                                            onChange={changeBorrowerAdditionalRE("creditor")}

                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId={"creditor" + index} className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Account Number:</Form.Label>
                                        <Form.Control isValid={false}
                                            required
                                            type="text"
                                            size="sm"
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].account}
                                            onChange={changeBorrowerAdditionalRE("account")}

                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId="Liens" className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Mortgages and Liens:</Form.Label>
                                        <NumericalInput isValid={false}
                                            required
                                            size="sm"
                                            name="liens"
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].liens}
                                            onChange={changeBorrowerAdditionalPropertyLiens}
                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId={"mortgage" + index} className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Mortgage Payments:</Form.Label>

                                        <NumericalInput isValid={false}
                                            required
                                            size="sm"

                                            name={"mortgage" + index}
                                            defaultValue={this.props.application.assetsandliabilities.properties[index].payment}
                                            onChange={changeBorrowerAdditionalPropertyPayment}

                                            min={0}
                                        />
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the value
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== this.props.application.assetsandliabilities.properties.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addBorrowerAdditionalProperty} ></i>
                        <i hidden={index === this.props.application.assetsandliabilities.properties.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeBorrowerAdditionalProperty}></i>

                    </Col>
                </Row>
            </div>
        )
    }
    addOtherProperties = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerAdditionalProperty(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionalProperty(0, this.props.who)
        }
    }
    processAdditionalNewMortgages = (val, index) => {
        let { creditor, lientype, monthlyamount, loanamount, creditlimit, key } = val
        let changeNewLoanAttr = (verb) => {
            return event => {
                com.touch()
                this.props.updateNewMortgageLoanAttribute(event.target.value, index, verb)
            }
        }
        let removeNewMortgageLoan = e => {
            com.touch()
            this.props.removeNewMortgageLoan(index)
        }

        return <div className="incard" key={key}>
            <Row>
                <Col>
                    <Form.Row>
                        <Col xs="auto">
                            <Form.Group controlId={"newcreditor" + index} className="text-left" >
                                <Form.Label className="text-left text-nowrap" >Creditor:</Form.Label>
                                <Form.Control isValid={false}
                                    required
                                    type="text"
                                    size="sm"
                                    defaultValue={creditor}
                                    onChange={changeNewLoanAttr("creditor")}

                                />
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide the value
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId={"lientype" + index} className="text-left" >
                                <Form.Label className="text-left" >Lien Type:</Form.Label>
                                <Form.Control name={"lientype" + index} as="select" size="sm"
                                    defaultValue={lientype}
                                    onChange={changeNewLoanAttr("lientype")}

                                >
                                    <option value="">...</option>
                                    <option value="firstlien">First Lien</option>
                                    <option value="subordinatelien">Subordinate Lien</option>
                                </Form.Control>

                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId={"monthlyamount" + index} className="text-left" >
                                <Form.Label className="text-left text-nowrap" >Monthly Amount:</Form.Label>
                                <NumericalInput isValid={false}
                                    required
                                    size="sm"
                                    defaultValue={monthlyamount}
                                    onChange={changeNewLoanAttr("monthlyamount")}
                                    min={0}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide the value
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Form.Row>
                    <Form.Row>
                        <Col xs="auto">
                            <Form.Group controlId={"loanamount" + index} className="text-left" >
                                <Form.Label className="text-left text-nowrap" >Loan Amount:</Form.Label>
                                <NumericalInput isValid={false}
                                    required
                                    size="sm"
                                    defaultValue={loanamount}
                                    onChange={changeNewLoanAttr("loanamount")}
                                    min={0}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide the value
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId={"creditlimit" + index} className="text-left" >
                                <Form.Label className="text-left text-nowrap" >Credit Limit (0 if N/A):</Form.Label>
                                <NumericalInput isValid={false}
                                    required
                                    size="sm"
                                    defaultValue={creditlimit}
                                    onChange={changeNewLoanAttr("creditlimit")}
                                    min={0}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide the value
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>



                    </Form.Row>
                </Col>
                <Col xs="auto" as="div" className="text-right aligh-top">
                    <i hidden={index !== this.props.application.assetsandliabilities.newloans.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={this.props.addNewMortgageLoan} ></i>
                    <i hidden={index === this.props.application.assetsandliabilities.newloans.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                    <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeNewMortgageLoan}></i>
                </Col>
            </Row>
        </div>
    }
    updateApplicationAttribute = (value) => {

        this.props.updateApplicationAttribute({ target: { value } }, "loanbalance")
    }

    recalcLoan = () => {
        /* eslint-disable-next-line no-unused-vars */
        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        this.setState({ loanbalance: mortgagebalance })

        this.updateApplicationAttribute(mortgagebalance.toString())
        if (this.props.application.property.purpose === st.POL_Cashoutrefinance) {
            this.props.updateSelectField(cashout, "mincashout")
        }

    }
    recalcLoanDelayed = () => {
        setTimeout(() => { this.recalcLoan()}, 100)
    }
    handleSubmit = e => {
        if (this.props.application.property.purpose !== st.POL_Purchase) {



            if (this.props.application.otherliens !== null && this.props.application.otherliens.length === 0 &&
                this.reficount === 0 )
                return false
            if (this.reficount === 0 &&
                !this.props.application.assetsandliabilities.propertyownedfreeandclear &&
                this.props.application.otherliens.length === 0
            ) {
                window.setTimeout(this.props.addOtherLien, 100)
                return false
            }
            this.recalcLoan()
        }

        return true
    }
    showALimony = () => {
        if (this.props.application.property.purpose === st.POL_Purchase)
            return true
        if (this.props.application.otherliens !== null && this.props.application.otherliens.length === 0 &&
            this.reficount === 0)
            return false
        return this.props.application.otherliens !== null
    }
    showSolar = () => {
        return this.showALimony() && this.props.application[this.props.who].finances.alimony !== null
    }
    showAdditionalRealEstate = () => {
        return true
    }
    addOtherMortgages = e => {
        let val = e.target.value === "true"
        com.touch()
        if (val)
            this.props.addNewMortgageLoan()
        else
            this.props.clearNewMortgageLoans()
    }
    isPreapproval = () => {
        return false === this.props.application.property.hascontract && this.props.application.property.purpose === "purchase"
    }
    getSubjAddress = () => {
        if(this.props.application.property.purpose !== st.POL_Purchase )
            return this.props.application.property.address
        let addr = com.formatAddress(this.props.application.borrower)

        return addr        
    }
    render = () => {
        let iwe = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
            "we" : "I"
        //let label = `Do ${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and 
        //${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} report assets and liabilities jointly?`

        let meus = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ? 'we' : 'I'

        let hasLiens = e => {
            this.props.addOtherLien()
        }
        let noLiens = e => {
            this.props.clearBorrowerOtherLiens()
        }

        let showOnlyForBorrower = () => {
            if (this.props.application.hascoborrower !== "withcoborrower")
                return true
            return this.props.who === com.borrower
        }

        return (
            <div
                className=" mb-4 text-left px-0" >
                <h2 className=" heading">
                    <span>
                        More on {
                            `${this.props.application[this.props.who].firstname} ${this.props.application[this.props.who].lastname} `
                        }'s Liabilities
                    </span>
                    
                </h2>


                {!this.isPreapproval() && showOnlyForBorrower() ?
                    <div className="viewport mt-3">
                        <Row>
                            <Col><div className="sectionquestion">
                                Do you have any new mortgage loans for the property that you're applying for now?
                            </div>
                                <div className="d-flex mt-1">

                                    <div className="mr-3 ">
                                        <input required onChange={this.addOtherMortgages} value={true} checked={null != this.props.application.assetsandliabilities.newloans && this.props.application.assetsandliabilities.newloans.length !== 0} type="radio" id="newmortgages" name="newmortgages"></input>
                                        <label className="zeitro-radio divlink" htmlFor="newmortgages">Yes, {meus} do</label></div>
                                    <div><input required onChange={this.addOtherMortgages} value={false} checked={null != this.props.application.assetsandliabilities.newloans && this.props.application.assetsandliabilities.newloans.length === 0} type="radio" id="newmortgagesot" name="newmortgages"></input>
                                        <label className="zeitro-radio divlink" htmlFor="newmortgagesot">No, {meus} don't</label></div>
                                </div>
                            </Col>
                        </Row>
                        {null != this.props.application.assetsandliabilities.newloans ?
                            this.props.application.assetsandliabilities.newloans.map(this.processAdditionalNewMortgages) : ""}
                    </div> :  <div  className="viewport mt-3">Please go to the next page</div>}

                    {(showOnlyForBorrower() && this.props.application.property.purpose !== "purchase" && this.props.who === "borrower") ?
                    <div className="viewport mt-3">
                        <Row>
                            <Col>
                                {this.reficount === 0 && false === this.props.application.assetsandliabilities.propertyownedfreeandclear ?
                                    <div>
                                        <div className="sectionquestion text-wrap">You have not marked any mortgage loans for the property at {this.sameaddress()} in the credit report, but declared that the property is not owned free and clear!
                                            <br />Please list all the liens against this property below:</div>


                                    </div>
                                    :
                                    <div>
                                        <div className="sectionquestion">
                                            Do you have any other liens against the property at <span style={{fontWeight: '600'}}>{this.getSubjAddress()}</span>?
                                        </div>

                                        <Form.Group>
                                            <fieldset>
                                                <div className="d-flex mt-1">

                                                    <div className="mr-3 ">
                                                        <input required onChange={hasLiens} value={true} checked={this.props.application.otherliens !== null && this.props.application.otherliens.length !== 0} type="radio" id="otherliens" name="otherliens"></input>
                                                        <label className="zeitro-radio divlink" htmlFor="otherliens">Yes, {iwe} do</label>
                                                    </div>
                                                    <div>
                                                        <input required onChange={noLiens} value={false} checked={this.props.application.otherliens !== null && this.props.application.otherliens.length === 0} type="radio" id="otherliensnot" name="otherliens"></input>
                                                        <label className="zeitro-radio divlink" htmlFor="otherliensnot">No, {iwe} don't</label>
                                                    </div>



                                                </div>
                                            </fieldset>
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please specify if you have any liens against the property you're refinancing
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {this.props.application.otherliens !== null && this.props.application.otherliens.length === 0 && this.reficount === 0 ?
                                            <div style={{ fontSize: '1.3em', color: '#633' }} className="d-block text-wrap">
                                                Please specify if you have any liens against the property you're refinancing. Please either find the loans for the property that you are refinancing on the previous page, or if they are not listed, specify them here.
                                            </div>
                                            : ""

                                        }
                                    </div>
                                }

                            </Col>
                        </Row>

                        {this.props.application.otherliens !== null && this.props.application.otherliens.length !== 0 ? this.props.application.otherliens.map(this.displayOtherLiens) : ""}

                    </div> : ""
                }
                {(showOnlyForBorrower() && this.props.application.property.purpose === "purchase" && this.props.who === "borrower" && this.props.application.borrower.presentaddressownership === "own") ?
                    <div className="viewport mt-3">
                        <Row>
                            <Col>
                                {false === this.props.application.assetsandliabilities.propertyownedfreeandclear ?
                                    <div>
                                        <div className="sectionquestion text-wrap">You have not marked any mortgage loans for your primary residence at <span style={{fontWeight: '500'}}>{this.sameaddress()}</span> in the credit report, but declared that the property is not owned free and clear!
                                        <div className="my-1">Please list all the liens against your primary residence below:</div></div>


                                    </div>
                                    : ""
                                }

                            </Col>
                        </Row>

                        {this.props.application.otherliens !== null ? this.props.application.otherliens.map(this.displayOtherLiens) : ""}

                    </div> : ""
                }

                {showOnlyForBorrower() &&
                    this.props.application.assetsandliabilities.propertiesfrompull !== null && this.props.application.assetsandliabilities.propertiesfrompull.length > 0 ?
                    <div className="viewport mt-3">
                        <Row>
                            <Col><div className="sectionquestion mb-2">
                                Please fill in details about your additional properties:
                            </div>
                            </Col></Row>


                        {this.props.application.assetsandliabilities.propertiesfrompull.map(this.processAdditionalRealEstateFromCR)}
                    </div> : ""
                }


            </div>
        )
    }
}

MoreLiabilities = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MoreLiabilities)
