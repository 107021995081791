import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  Menu,
  message,
  Row,
  Select,
  Switch,
} from "antd";

import * as com from "../../Common";
import AddressAutocomplete from "../../Common/AddressAutocomplete";

import "./FlyersHub.css";
import {
  getAIVideoFilePath,
  groupedPrograms,
  keyToProgram,
  programs,
} from "./programs";
import FlyerGenerator from "./FlyerGenerator";
import { LoanOfficerInfo, RealtorInfo } from "./types";
import { RealtorInformation } from "./RealtorInfo";
import VideoPlayer from "./VideoPlayer";
import { loHasAccess } from "./temporary_access";
import { useStatsigClient } from "@statsig/react-bindings/src";
import WebcamCapture from "./WebcamCapture";
import VoiceRecorder from "./VoiceRecorder";

const DEFAULT_PROGRAM = programs[0];

export const GMCCFlyersHub = () => {
  // Set default program
  const [selectedProgramKey, setSelectedProgramKey] = useState(
    DEFAULT_PROGRAM.key
  );

  // LO info setup
  const [loInfo, setLoInfo] = useState<LoanOfficerInfo>({
    name: "",
    nmls: "",
    email: "",
    phone: "",
  });
  const [showLOInfo, setShowLOInfo] = useState(true);
  const [flyerIsLoading, setFlyerIsLoading] = useState(true);

  // Address related setup
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");

  // Realtor info setup
  const [realtorInfo, setRealtorInfo] = useState<RealtorInfo | undefined>({
    name: "",
    licenseNumber: "",
    phone: "",
    email: "",
  });

  // Flyer related setup
  const [showAddressInFlyer, setShowAddressInFlyer] = useState(false);
  const [showRealtorInFlyer, setShowRealtorInFlyer] = useState(false);
  const flyerGeneratorRef = useRef(null);

  // AI video related setup
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  console.log(process.env.NODE_ENV, process.env);
  const videoPlayerRef = useRef(null);

  //statsig logEvent
  const { client } = useStatsigClient();
  const handleClickDownload = () => {
    flyerGeneratorRef.current?.downloadFlyer();

    const savedUserInfo = JSON.parse(
      sessionStorage.getItem("statsig_user_info")
    );
    client.logEvent("use_flyers_hub", "flyer", {
      item_name: "useFlyersHub",
      action: "clickDownload",
      startTime: new Date().toString(),
      timestamp: Date.now().toString(),
      customerid: savedUserInfo.custom.customerid,
      email: savedUserInfo.email,
      realtorEmail: realtorInfo.email,
      type: "flyer",
    });
    client.logEvent("leads_generated", realtorInfo.email, {
      item_name: "leadsGenerated",
      startTime: new Date().toString(),
      timestamp: Date.now().toString(),
      realtorName: realtorInfo.name,
      realtorPhone: realtorInfo.phone,
      realtorEmail: realtorInfo.email,
      entryPoint: "flyersHub",
    });
  };

  const onStreetAddressCompletion = (address: string) => {
    const num = com.getSubaddress(address, "street_number", "long_name");
    const city = com.getSubaddress(address, "locality", "long_name");
    const state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    const zip = com.getSubaddress(address, "postal_code", "long_name");
    const county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );

    const longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );

    const short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short);
    const stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd);
    setTimeout(() => {
      flyerGeneratorRef.current?.drawTextAndPreview();
    }, 100);
  };

  const getProfile = () => {
    setFlyerIsLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: "no-cache",
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-CustomerID": com.getCustomerId(),
        "X-LoID": com.getLoId(),
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        } else {
          response.json().then((lo) => {
            const name = lo.FirstName + " " + lo.LastName;
            setLoInfo({
              name: name,
              nmls: lo.NMLS,
              email: lo.Email,
              phone: lo.Phone,
            });
            setFlyerIsLoading(false);
          });
        }
      })
      .catch((error) => {
        console.log("Revalidate catch error " + error);
        setFlyerIsLoading(false);
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="flyersHubWrap">
      <Row
        className="flyersHubSelect flex flex-row align-items-center justify-content-around"
        gutter={[16, 16]}
      >
        <Select
          showSearch
          placeholder="Select a program"
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={groupedPrograms}
          value={selectedProgramKey}
          onChange={(value) => {
            setSelectedProgramKey(value);
          }}
          style={{ width: "80%" }}
          listHeight={500}
        />
        <Button
          type="primary"
          onClick={handleClickDownload}
          className="download-flyer-btn"
        >
          Download
        </Button>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={0} style={{ display: "none" }}>
          <Menu
            items={programs}
            onClick={(e) => {
              setSelectedProgramKey(e.key);
            }}
          />
        </Col>
        <Col span={4}></Col>
        <Col span={16}>
          <Row className="justify-content-center mb-2">
            <div>Property address</div>
          </Row>
          <AddressAutocomplete
            id="address"
            label=""
            required={true}
            name="addessline"
            defaultValue={displayedAddress}
            onChange={(e) => {
              setDisplayedAddress(e.target.value);
              setTimeout(() => {
                flyerGeneratorRef.current?.drawTextAndPreview();
              }, 100);
            }}
            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
            placeholder="Please type your home address and select from the dropdown addresses"
            goodfeedback="Looks good!"
            badfeedback="Please provide your street address."
            onCompletion={(e) => onStreetAddressCompletion(e)}
            size="lg"
            style={{ width: "100%" }}
          />

          <RealtorInformation
            setRealtorInfo={setRealtorInfo}
            onRealtorInfoChange={() => {
              setTimeout(() => {
                flyerGeneratorRef.current?.drawTextAndPreview();
              }, 100);
            }}
          />

          <Row
            className="flex flex-row align-items-center mt-4"
            gutter={[16, 16]}
          >
            <Col span={6} className="flex flex-row align-items-center">
              <div className="mr-4">Show address</div>
              <Switch
                checked={showAddressInFlyer}
                onChange={(checked) => {
                  if (checked) {
                    if (displayedAddress !== "") {
                      setShowAddressInFlyer(true);
                      setTimeout(() => {
                        flyerGeneratorRef.current?.drawTextAndPreview();
                      }, 100);
                    } else {
                      message.error("Please enter a property address first.");
                    }
                  } else {
                    setShowAddressInFlyer(false);
                    setTimeout(() => {
                      flyerGeneratorRef.current?.drawTextAndPreview();
                    }, 100);
                  }
                }}
              />
            </Col>
            <Col span={6} className="flex flex-row align-items-center">
              <div className="mr-4">Show realtor info</div>
              <Switch
                checked={showRealtorInFlyer}
                onChange={(checked) => {
                  if (checked) {
                    if (
                      realtorInfo?.name !== "" &&
                      realtorInfo?.licenseNumber !== ""
                    ) {
                      setShowRealtorInFlyer(true);
                      setTimeout(() => {
                        flyerGeneratorRef.current?.drawTextAndPreview();
                      }, 100);
                    } else {
                      message.error(
                        "Please enter a realtor name and license number."
                      );
                    }
                  } else {
                    setShowRealtorInFlyer(false);
                    setTimeout(() => {
                      flyerGeneratorRef.current?.drawTextAndPreview();
                    }, 100);
                  }
                }}
              />
            </Col>
            {keyToProgram(selectedProgramKey).hasAIVideo &&
              keyToProgram(selectedProgramKey).videoStreamingUrl &&
              loHasAccess(loInfo.name) && (
                <>
                  <Col
                    span={6}
                    className="flex flex-row align-items-center justify-content-center"
                  >
                    <Button
                      className="ai-video-btn"
                      type="primary"
                      onClick={() => {
                        setIsVideoModalOpen(true);
                      }}
                    >
                      View Video
                    </Button>
                  </Col>
                  <Col
                    span={6}
                    className="flex flex-row align-items-center justify-content-center"
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setTimeout(() => {
                          videoPlayerRef.current?.handleDownload();
                        }, 100);
                      }}
                    >
                      Download Video
                    </Button>
                  </Col>
                  <VideoPlayer
                    name={keyToProgram(selectedProgramKey).label}
                    streamingUrl={
                      keyToProgram(selectedProgramKey).videoStreamingUrl
                    }
                    downloadUrl={
                      keyToProgram(selectedProgramKey).videoDownloadUrl
                    }
                    isModalOpen={isVideoModalOpen}
                    handleCancel={() => setIsVideoModalOpen(false)}
                    ref={videoPlayerRef}
                  />
                </>
              )}
          </Row>
          {keyToProgram(selectedProgramKey).hasAIVideo &&
            keyToProgram(selectedProgramKey).videoStreamingUrl &&
            loHasAccess(loInfo.name) && (
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  marginTop: "10px",
                  padding: "16px 10px",
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={11}>
                    <WebcamCapture />
                  </Col>
                  <Col span={2}>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col span={11}>
                    <VoiceRecorder />
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Button type="primary">Generate AI Video</Button>
                </Row>
              </div>
            )}

          <Row className="mt-4">
            {!flyerIsLoading ? (
              <FlyerGenerator
                ref={flyerGeneratorRef}
                program={keyToProgram(selectedProgramKey)}
                showLOInfo={showLOInfo}
                loInfo={loInfo}
                showAddressInfo={displayedAddress !== "" && showAddressInFlyer}
                addressInfo={displayedAddress}
                showRealtorInfo={showRealtorInFlyer}
                realtorInfo={realtorInfo}
              />
            ) : (
              <div>Loading...</div>
            )}
          </Row>
        </Col>
        <Col span={4}></Col>
      </Row>
    </div>
  );
};

export default GMCCFlyersHub;
