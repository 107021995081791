import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Typography } from 'antd'
import './Reviews.css'
import { useStatsigClient } from "@statsig/react-bindings/src";

const { Text } = Typography

function Reviews({ reviewsList, loanOfficerEmail }) {
  const client = useStatsigClient()

  const reviews = useMemo(
      () =>
          reviewsList
              .filter(
                  (review) => review.review !== '' && review.firstname !== ''
              )
              .map((review) => ({
                  comment: review.review,
                  author: `${review.firstname} ${review.lastname}`,
              })),
      [reviewsList]
  )

  const [visibleIndexes, setVisibleIndexes] = useState(new Set())
  const reviewRefs = useRef([])
  const containerRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setVisibleIndexes((prevIndexes) => {
          const newVisibleIndexes = new Set(prevIndexes)

          entries.forEach((entry) => {
            const index = reviewRefs.current.findIndex(
              (ref) => ref === entry.target
            )
            if (index !== -1) {
              if (entry.isIntersecting) {
                newVisibleIndexes.add(index)
              } else {
                newVisibleIndexes.delete(index)
              }
            }
          })

          return newVisibleIndexes
        })
      },
      {
        threshold: 0.5,
        root: null,
      }
    )

    reviewRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref)
    })

    return () => observer.disconnect()
  }, [])

  const scrollToReview = (index) => {
    const review = reviewRefs.current[index]
    if (review && containerRef.current) {
      const container = containerRef.current
      const scrollLeft =
        review.offsetLeft -
        container.offsetLeft -
        (container.offsetWidth - review.offsetWidth) / 2

      container.scrollTo({
        left: Math.max(0, scrollLeft),
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="reviews-container">
      <div className="reviews-title">
        Reviews
      </div>

      {/* Review Cards */}
      <div ref={containerRef} className="reviews-cards-container">
        {reviews.map((review, index) => (
          <div
            key={index}
            ref={(el) => {
              reviewRefs.current[index] = el
            }}
            className="review-card"
          >
            {/* Quotation Mark as Background */}
            <div className="quotation-mark">
              <img
                src="/images/quotation-mark.svg"
                alt="Quotation Mark"
                width={38}
                height={27}
              />
            </div>

            {/* Review Content */}
            <div className="review-content">
              <Text className="review-comment">
                {review.comment}
              </Text>
            </div>

            {/* Reviewer Info */}
            <div className="reviewer-info">
              <img
                src="/avatar.png"
                alt="Reviewer"
                className="reviewer-avatar"
              />
              <div>
                <Text className="reviewer-name">
                  {review.author}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Indicators */}
      {reviews && reviews.length > 1 && (
        <div className="review-indicators">
          {reviews.map((_, index) => (
            <button
              key={index}
            onClick={() => {
                scrollToReview(index);
                client.logEvent("lo_detail_page_review_slide_click", window.location.href, {
                item_name: "loDetailPageReviewSlideClick",
                startTime: new Date,
                timestamp: Date.now(),
                pageUrl: window.location.href,
                loanOfficerID: loanOfficerEmail,
                type: "personalsite",
              });
            }}
            className={`review-indicator ${
              visibleIndexes.has(index) ? 'active' : ''
            }`}
          />
          ))}
        </div>
      )}
    </div>
  )
}

export default Reviews