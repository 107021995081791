import { set } from 'lodash';
import React, { useRef, useState } from 'react';
import {Button} from 'react-bootstrap';
import { Spin, Modal, Select, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useStatsigClient } from "@statsig/react-bindings/src";

const drawTexts = (
  ctx,
  canvas,
  hasRE,
  reName,
  reID,
  reContactLine1,
  reContactLine2,
  loName,
  idLine,
  loContactLine1,
  loContactLine2,
  addressInfo,
  disclaimer1,
  disclaimer2,
  centerX
) => {
  const fontFamily = "Inter";
  if (hasRE) {
    // Realtor info in left half
    const lStartX = 100;
    const lStartY = 130;
    const lLineHeight = 24;
    ctx.textAlign = "left";
    ctx.font = `600 20px ${fontFamily}`;
    ctx.fillStyle = "black";

    const leftContents = [
      "Realtor",
      reName,
      // If DRE# is empty, don't show it
      reID.trim() === "DRE#" ? "" : reID,
      reContactLine1,
      reContactLine2,
    ].filter(content => content.trim() !== "");
    leftContents.forEach((content, index) => {
      ctx.fillText(content, lStartX, lStartY + index * lLineHeight);
    });

    // LO info in right half
    const RightStartingX = 100;
    const RightStartingY = 130;
    const rightLineHeight = 24;
    ctx.textAlign = "right";
    ctx.font = `600 20px ${fontFamily}`;
    const rightContents = [
      "Loan Officer",
      loName,
      idLine,
      loContactLine1,
      loContactLine2,
    ];
    rightContents.forEach((content, index) => {
      ctx.fillText(content, canvas.width - RightStartingX, RightStartingY + index * rightLineHeight);
    });
  } else {
    ctx.textAlign = "center";
    ctx.textbaseline = "middle";
    ctx.font = `600 40px ${fontFamily}`;
    ctx.fillStyle = "black";
    ctx.fillText(loName, centerX, 150);
    ctx.font = `600 24px ${fontFamily}`;
    ctx.fillStyle = "black";
    ctx.fillText(idLine, centerX, 190);
    ctx.fillText(loContactLine1 + " | " + loContactLine2, centerX, 220);
  }

  // Address info
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = `600 30px ${fontFamily}`;
  ctx.fillStyle = "#5D94FF";
  ctx.fillText(addressInfo, centerX, 260);

  // Disclaimer
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = `400 12px ${fontFamily}`;
  ctx.fillStyle = "black";
  ctx.fillText(disclaimer1, centerX, 295);
  ctx.fillText(disclaimer2, centerX, 309);
};

const FlyerGenerator = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [receiver, setReceiver] = useState('lo');
  const [borrowerName, setBorrowerName] = useState('');
  const [borrowerEmail, setBorrowerEmail] = useState('');
  const [messageText, setMessageText] = useState('');
  const [messageSubject, setMessageSubject] = useState('');
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [showRatesheet, setShowRatesheet] = useState(false);
  const canvasRef = useRef(null);
  const imageSrc = props.imgsrc;

  const GMCCPPTShareLink =
    "https://netorgft1191593.sharepoint.com/sites/LOTraining/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FLOTraining%2FShared%20Documents%2FKiller%20Programs&FolderCTID=0x012000CF752C56A7846845A87DA40CB38AE1E9&View=%7B591BEB65%2D297E%2D416F%2D8CD2%2DD6F131D2897A%7D";
  const LoannexUrl = "https://loannex.com";
  const EPPSUrl = "https://epps.elliemae.com/pricing/client/base/";
  const { client } = useStatsigClient();
  const TYPE_MAP = {
    fast: "fastCheck",
    advanced: "advancedCheck",
    massive: "massiveCheck",
    gps: "gpsCheck",
    byProgram: "marketCheck",
  };

  let PPEUrl = ""
  let DiamondUrl = ""
  if (props.ratesheet.includes("Epps")){
    PPEUrl = EPPSUrl
  }
  if (props.ratesheet.includes("Loannex")){
    PPEUrl = LoannexUrl
  }

  const handleClickEmail = () => {
    setIsModalOpen(true)

  }

  const drawTextAndDownload = () => {
    // statsig logEvent
    const type = TYPE_MAP[props.chosenMode] || "unknownType";
    const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
    const eventData = {
      item_name: "useProgramsMap",
      action: "clickDownload",
      startTime: (new Date).toString(),
      timestamp: Date.now().toString(),
      customerid: savedUserInfo.custom.customerid,
      email: savedUserInfo.email,
      type: type
    };
    client.logEvent("use_programs_map", type, eventData);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`
      let idLine = "NMLS# " + props.nmls + "  " + props.dre;
      if (props.dre === ""){
        idLine = "NMLS#  " + props.nmls
      }
      const contactLine = props.email + "  " + props.phone;
      const textMargin = 50; // Adjust this value to change the margin from the right edge
      const idLineWidth = ctx.measureText(idLine).width;
      const contactLineWidth = ctx.measureText(contactLine).width;
      const nameWidth = ctx.measureText(props.name).width;
      const addressWidth = ctx.measureText(props.addressInfo).width;
      const disclaimer1Width = ctx.measureText(disclaimer1).width;
      const disclaimer2Width = ctx.measureText(disclaimer2).width;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      const reTag = "Realtor"
      const loTag = "Loan officer"
      const reID = "DRE#"+"  "+props.reID
      const reContactLine1 = props.rePhone
      const reContactLine2 = props.reEmail
      const loContactLine1 = props.phone
      const loContactLine2 = props.email

      ctx.textAlign = 'center';
      ctx.textbaseline = 'middle';
      ctx.font = '600 48px Arial';
      ctx.fillStyle = 'black';
      
      drawTexts(ctx, canvas, props.hasRE, props.reName, reID, 
        reContactLine1, reContactLine2, props.name, idLine, 
        loContactLine1, loContactLine2, props.addressInfo, 
        disclaimer1, disclaimer2, centerX);  

      const imageURL = canvas.toDataURL('image/png');
      setPreviewImage(imageURL);
      const link = document.createElement('a');
      link.download = `${props.programName}_Flyer.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    };
  };
  const drawTextAndPreview = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`
      let idLine = "NMLS# " + props.nmls + "  " + props.dre;
      if (props.dre === ""){
        idLine = "NMLS#  " + props.nmls
      }
      const contactLine = props.email + "  " + props.phone;
      const textMargin = 50; // Adjust this value to change the margin from the right edge
      const idLineWidth = ctx.measureText(idLine).width;
      const contactLineWidth = ctx.measureText(contactLine).width;
      const nameWidth = ctx.measureText(props.name).width;
      const addressWidth = ctx.measureText(props.addressInfo).width;
      const disclaimer1Width = ctx.measureText(disclaimer1).width;
      const disclaimer2Width = ctx.measureText(disclaimer2).width;

      const reTag = "Realtor"
      const loTag = "Loan officer"
      const reID = "DRE#"+"  "+props.reID
      const reContactLine1 = props.rePhone
      const reContactLine2 = props.reEmail
      const loContactLine1 = props.phone
      const loContactLine2 = props.email

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      ctx.textAlign = 'center';
      ctx.textbaseline = 'middle';
      ctx.font = '600 48px Arial';
      ctx.fillStyle = 'black';
      
      drawTexts(ctx, canvas, props.hasRE, props.reName, reID, 
        reContactLine1, reContactLine2, props.name, idLine, 
        loContactLine1, loContactLine2, props.addressInfo, 
        disclaimer1, disclaimer2, centerX); 

      const imageURL = canvas.toDataURL();
      setPreviewImage(imageURL);
      setShowRatesheet(false);
      setPreviewModalOpen(true);
    };
  };

  const drawTextAndEmail = () => {
    setLoading(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let imageDataURL = "";
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const disclaimer1 = `All special programs are subject to underwriting approval verification, guideline change without advance notice.`
      const disclaimer2 = `Contact me to clarify your qualification of the below program for this address.This does not represent credit decision.`
      let idLine = "NMLS# " + props.nmls + "  " + props.dre;
      if (props.dre === ""){
        idLine = "NMLS#  " + props.nmls
      }
      const contactLine = props.email + "  " + props.phone;
      const textMargin = 50; // Adjust this value to change the margin from the right edge
      const idLineWidth = ctx.measureText(idLine).width;
      const contactLineWidth = ctx.measureText(contactLine).width;
      const nameWidth = ctx.measureText(props.name).width;
      const addressWidth = ctx.measureText(props.addressInfo).width;
      const disclaimer1Width = ctx.measureText(disclaimer1).width;
      const disclaimer2Width = ctx.measureText(disclaimer2).width;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      const reTag = "Realtor"
      const loTag = "Loan officer"
      const reID = "DRE#"+"  "+props.reID
      const reContactLine1 = props.rePhone
      const reContactLine2 = props.reEmail
      const loContactLine1 = props.phone
      const loContactLine2 = props.email

      ctx.textAlign = 'center';
      ctx.textbaseline = 'middle';
      ctx.font = '600 48px Arial';
      ctx.fillStyle = 'black';
      
      drawTexts(ctx, canvas, props.hasRE, props.reName, reID, 
        reContactLine1, reContactLine2, props.name, idLine, 
        loContactLine1, loContactLine2, props.addressInfo, 
        disclaimer1, disclaimer2, centerX); 

      let dataURL = canvas.toDataURL('image/png');
      let base64Data = dataURL.replace(/^data:image\/png;base64,/, "");

      try {
        // statsig logEvent
        const type = TYPE_MAP[props.chosenMode] || "unknownType";
        const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
        const eventData = {
          item_name: "useProgramsMap",
          action: "clickEmail",
          startTime: (new Date).toString(),
          timestamp: Date.now().toString(),
          customerid: savedUserInfo.custom.customerid,
          email: savedUserInfo.email,
          type: type,
          borrowerEmail: borrowerEmail,
        };
        client.logEvent("use_programs_map", type, eventData);

        client.logEvent("leads_generated", borrowerEmail, {
          item_name: "leadsGenerated",
          startTime: (new Date).toString(),
          timestamp: Date.now().toString(),
          borrowerEmail: borrowerEmail,
          type: type,
          entryPoint: "programsMap"
        })
      } catch (error) {
        console.error('Failed to log statsig event:', error);
      }

      let body = {
        imageData: base64Data,
        programName: props.programName,
        loName: props.name,
        subjectAddr: props.addressInfo,
        loPhone: props.phone,
        loEmail: props.email,
        nmls: props.nmls,
        dre: props.dre,
        receiver: receiver,
        loID: props.loID,
        borrowerName: borrowerName,
        borrowerEmail: borrowerEmail,
        messageText: messageText,
        messageSubject: messageSubject,
      }
      fetch('/los/gmcc/sendflier', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res) => {
        if (!res.ok) {
          setLoading(false);
          console.log(res);
          alert('Failed to send email, please try again later or contact support.');
          return;
        }

        setLoading(false);
        setIsModalOpen(false);
        alert('Flier sent successfully!');

      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        alert('Some internal error happened, please try again later or contact support.');
      });
      
    };
  }

  const priceItems = [
    {
      label: <div onClick={()=> window.open(EPPSUrl,"_blank")}>
        EPPS
      </div>,
      key: '1',
    },
    {
      label: <div onClick={()=> window.open(LoannexUrl,"_blank")}>
        Loannex
      </div>,
      key: '2',
    }
  ]

  return (
    <div>
      <Modal
        open={isModalOpen}
        onCancel={()=> setIsModalOpen(false)}
        onOk={drawTextAndEmail}
        title="Send Flyer"
        okText="Send"
      >
        <div className='d-flex justify-content-center w-100'>
          { loading ? <Spin /> :
            <div className='w-100'>
              <div style={{fontWeight:"500", marginTop:"10px"}}>Email to :</div>
              <Select 
                style={{width: '100%'}}
                placeholder="Select a receiver"
                onChange={(value) => setReceiver(value)}
                value={receiver}
                options = {[
                  {value: 'lo', label: 'Myself'},
                  {value: 're', label: 'Realtor'},
                  {value: 'bo', label: 'Borrower'},
                ]}
              />
              {receiver === "bo" && <>
                <div style={{fontWeight:"500", marginTop:"10px"}}>Borrower Name:</div>
                <input className='sendFlierInput' type="text" value={borrowerName} onChange={(e) => setBorrowerName(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Borrower Email:</div>
                <input className='sendFlierInput' type="email" value={borrowerEmail} onChange={(e) => setBorrowerEmail(e.target.value)} />
              </>}
              {receiver === "re" && <>
                <div style={{fontWeight:"500", marginTop:"10px"}}>Realtor Name:</div>
                <input className='sendFlierInput' type="text" value={borrowerName} onChange={(e) => setBorrowerName(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Realtor Email:</div>
                <input className='sendFlierInput' type="email" value={borrowerEmail} onChange={(e) => setBorrowerEmail(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Email Subject:</div>
                <input className='sendFlierInput' type="text" value={messageSubject} onChange={(e) => setMessageSubject(e.target.value)} />
                <div style={{fontWeight:"500", marginTop:"10px"}}>Message:</div>
                <textarea className='sendFlierInput' style={{height: '100px'}}
                  placeholder="Type your message here..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                />
                </>
              }
            </div>
          }
        </div>
      </Modal>
      <Modal
        className='flyerPreviewModal'
        rootClassName='flyerPreviewModalRoot'
        open={previewModalOpen}
        onCancel={()=> setPreviewModalOpen(false)}
        title="Flyer Preview"
        footer={null}
      >
        <div className='d-flex justify-content-center w-100'>
          <img className='w-100' src={showRatesheet? props.ratesheet : previewImage} />
        </div>
      </Modal>
      <canvas style={{display: props.display}} ref={canvasRef} ></canvas>
      <div className='d-flex'>
      <Button className={ props.buttonClass} onClick={drawTextAndPreview}>Preview</Button>
        <Button className={props.disabled? "flyerBtn-disabled": props.buttonClass} onClick={drawTextAndDownload}>Download</Button>
        <Button className={props.disabled ? "flyerBtn-disabled" : props.buttonClass} onClick={() => setIsModalOpen(true)}>Email</Button>
        {props.ratesheet === "" ?
          ""
          :
          props.ratesheet.includes("Epps") || props.ratesheet.includes("Loannex") ?
            <Button className={props.buttonClass} onClick={()=> window.open(PPEUrl,"_blank")}>Pricing</Button>
            :
            props.ratesheet.includes("DiamondLink") ?
            <Button className={props.buttonClass} onClick={()=> window.open(DiamondUrl,"_blank")}>Pricing</Button>
            :
            props.ratesheet.includes("Multi") ?
            <Dropdown menu={{items: priceItems}}>
                <Button className={props.buttonClass}>
                  Pricing
                  <DownOutlined className='ml-1' />
                </Button>
            </Dropdown>
            :
            props.ratesheet.includes("Celebrity") ?
            <Button className={props.buttonClass} onClick={()=> props.gotoCelebrity()}>Pricing</Button>
            :
            <Button className={props.buttonClass} onClick={()=> {setPreviewModalOpen(true); setShowRatesheet(true)}}>Pricing</Button>
        }
        <Button className={props.buttonClass} onClick={()=> window.open(GMCCPPTShareLink, "_blank")}>Guideline</Button>
      </div>
    </div>
  );
};
export default FlyerGenerator;