import React, { useState, useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import { Form, Button, Container } from "react-bootstrap";
import { Col, Row, Tabs, Drawer, QRCode } from 'antd';
import ReProfile, { LoProfile } from "./Profile";
import Properties from "./Properties";
import Resources from "./Resources";
import BasicInfo from "./BasicInfo";
import AboutMe from "./AboutMe";
import CustomerReview from "./CustomerReview"
import Tools, { LandingAffordability, LandingRateQuoter } from "./Tools";
import CallBack from "./CallBack";
import "./NewLandingPage.css";

const backgroundColor = "#F7F8FC";

const LandingPage = (props) => {
  // if (window.location.href.includes("/re/") || props.url.includes("/re/")) {
  //   return <ReLandingPage {...props} />;
  // } else {
  // }
  return  <LoLandingPage {...props} />;
};

const ReLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const refProperties = useRef(null);
  const refRecommendations = useRef(null);
  const refTools = useRef(null);
  const handleClickRefTools = () => {
    if (refTools.current)
      refTools.current.scrollIntoView({ behavior: "smooth" });
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    console.log(window.location.href);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div>
      <div>
        <div>
          {/* <Navbar expand="lg" className="p-0 m-0  landingNav">
            <Nav className="landingnav">
              <Nav.Item className="landingNavName">{name}</Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="affordability"
                  onClick={handleClickRefProperties}
                >
                  Properties
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="prequal"
                  onClick={handleClickRefRecommendations}
                >
                  Recommendations
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link eventKey="resources" onClick={handleClickRefTools}>
                  Mortgage Tools
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar> */}
          <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="#features">Features</Nav.Link>
                  <Nav.Link href="#pricing">Pricing</Nav.Link>
                </Nav>
                <Nav>
                  <Nav.Link href="#deets">More deets</Nav.Link>
                  <Nav.Link eventKey={2} href="#memes">
                    Dank memes
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="landingProfile">
            <ReProfile
              id={props.id}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
            />
          </div>
        </div>
      </div>

      <div ref={refProperties} style={{ backgroundColor: "white" }}>
        <Properties id={props.id} {...props}/>
      </div>
      <div
        ref={refRecommendations}
        style={{ backgroundColor: backgroundColor }}
        className="pb-5"
      >
        <Resources id={props.id} width={width} height={height} />
      </div>
      <div
        style={{ backgroundColor: "white" }}
        className="landingTools"
        id="landingTools"
        ref={refTools}
      >
        <Tools id={props.id} width={width} height={height} {...props}/>
      </div>
      <div>
        <CallBack id={props.id} name={name} email={email} />
      </div>
      <div style={{ backgroundColor: backgroundColor }}>
        <div className="landingFooterClaim">
          The Security of your personal information is our highest priority. We
          use bank-level encryption (256-bit AEE) to ensure it is never
          compromised.
        </div>
        <div className="landingFooter">
          © Zeitro, Inc. All Rights Reserved 2023.
        </div>
      </div>
    </div>
  );
};

const LoLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showContactmeModal, setShowContactmeModal] = useState(false);

  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);
  const [moduleInfo, setModuleInfo] = useState({})
  const [basicInfo, setBasicInfo] = useState({})
  const [aboutInfo, setAboutInfo] = useState({})
  const [reviewList, setReviewList] = useState([])
  const [landingUrl, setLandingUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const refCalculators = useRef(null);
  const handleClickCalculators = (tab) => {
    if(refCalculators.current){
      setActiveTab(tab)
      refCalculators.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    setLandingUrl(window.location.href)
    setModuleInfo(JSON.parse(sessionStorage.getItem('landing-info')))
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const getContactmeModal = () => {
    return (
      <Modal
        show={showContactmeModal}
        onHide={() => setShowContactmeModal(false)}
      >
        <Modal.Body>
          <CallBack id={props.id} name={name} email={email} forHeader={true} />
        </Modal.Body>
      </Modal>
    );
  };

  const tabsItems = [
    {
      key: '1',
      label: 'Check Current Rate',
    },
    {
      key: '2',
      label: 'Check Affordability',
    }
  ]
  const [activeTab, setActiveTab] = useState('1')
  const tabsOnChange = (key) => {
    setActiveTab(key)
  }
  const [navDrawer, setNavDrawer] = useState(false);
  const showDrawer = () => {
    setNavDrawer(true)
  }
  const onClose = () => {
    setNavDrawer(false)
  }
  const dynamicExternalLink = (href) => {
    if (!/^https?:\/\//i.test(href)) {
        return `https://${href}`
    }
    return href
  }
  const getPreQualificationLink = () => {
    if (window.location.host.includes("zeitro.us")) {
      return "https://app.zeitro.us/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    if (window.location.host.includes("localhost")) {
      return "http://localhost:3000/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    return "https://app.zeitro.com/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
  }
  return (
    <>
      { moduleInfo && moduleInfo.module1 && moduleInfo.module2 && moduleInfo.module4 && (
        <div className="landing-page">
        <div className="landing-nav">
          <div className="menu-icon"><img src='/images/menu.svg' onClick={showDrawer} /></div>
          <div className="landing-navList">
            <span onClick={() => handleClickCalculators('1')}>Check Current Rate</span>
            <span onClick={() => handleClickCalculators('2')}>Check Affordability</span>
            <span>Get Prequalified</span>
            <Button className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact me today</Button>
          </div>
        </div>
        <div>
          <div>
            {/* <Navbar expand="lg" className="p-0 m-0  landingNav">
              <Nav.Item className="landingNavName">{name}</Nav.Item>
              <Nav className="landingnav1 mr-auto">
                <Nav.Item className="landingnavitem">
                  <Nav.Link
                    eventKey="affordability"
                    onClick={handleClickRefAffordability}
                  >
                    Affordability
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="landingnavitem">
                  <Nav.Link eventKey="prequal" onClick={handleClickRefRateQuoter}>
                    Rate Quoter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="landingnavitem">
                  <Nav.Link eventKey="getprequalified" onClick={() =>
                        (window.location.href = getPreQualificationLink())
                      }>
                  Get Prequalified
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Nav>
                <Nav.Item className="landingnavcontact">
                  <Nav.Link eventKey="rates">
                    <Button
                      variant="landingNavContact"
                      onClick={() =>
                        (window.location.href = getPreQualificationLink())
                      }
                    >
                      Apply Now
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="landingnavcontact">
                  <Nav.Link eventKey="contact">
                    <Button
                      variant="landingNavContact"
                      onClick={() => setShowContactmeModal(true)}
                    >
                      Contact me
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar> */}
            <div className="landingProfile">
              {/* <LoProfile
                customerid={props.customerid}
                loid={props.loid}
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                handleClickRefAffordability={handleClickRefAffordability}
                handleClickRefRateQuoter={handleClickRefRateQuoter}
              /> */}
              <Row gutter={20}>
                <Col className="gutter-row" span={24} lg={10}>
                  <BasicInfo customerid={props.customerid} loid={props.loid} basicInfo={moduleInfo.module1} contact={() => setShowContactmeModal(true)} />
                </Col>
                <Col className="gutter-row" span={24} lg={14}>
                  <AboutMe basicInfo={moduleInfo.module1} aboutInfo={moduleInfo.module2} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div ref={refCalculators} className="container calculators">
          <div className="calculators-title">Mortgage Calculators</div>
          <Tabs className="calculators-tabs" activeKey={activeTab} items={tabsItems} onChange={tabsOnChange} />
        </div>
        {
          activeTab === '1' && (
            <div
              style={{ backgroundColor: backgroundColor }}
              className="landingRateQuoter"
              id="landingRateQuoter"
              ref={refRateQuoter}
            >
              <LandingRateQuoter customerid={props.customerid} loid={props.loid} width={width} height={height} {...props}/>
            </div>
          )
        }
        {
          activeTab === '2' && (
            <div
              style={{ backgroundColor: "white" }}
              className="landingTools pb-2"
              id="landingTools"
              ref={refAffordability}
            >
              <LandingAffordability customerid={props.customerid} loid={props.loid} width={width} height={height} />
            </div>
          )
        }
        <CustomerReview reviewList={moduleInfo.module4} />
        <div className="landingFooter">
          <Row>
            <Col span={12} lg={4} className="sitemap">
              <div className="rowTitle">Sitemap</div>
              <div className="rowContent">
                <div>Home</div>
                <div onClick={() => handleClickCalculators('1')}>Check Current Rate</div>
                <div onClick={() => handleClickCalculators('2')}>Affordability</div>
                <div>Get Prequalified</div>
              </div>
            </Col>
            <Col span={12} lg={4} className="contact">
              <div className="rowTitle">Contact</div>
              <div className="rowContent">
                <div>{ moduleInfo.module2.phone }</div>
                <div>{ moduleInfo.module2.email }</div>
              </div>
            </Col>
            <Col span={24} lg={6} className="socialMedia">
              <div className="rowTitle">Social media</div>
              <div className="rowContent">
                { moduleInfo.module2.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(moduleInfo.module2.google)} /> }
                { moduleInfo.module2.linkedin && <img src='/images/linkedin.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(moduleInfo.module2.linkedin)} /> }
                { moduleInfo.module2.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(moduleInfo.module2.twitter)} /> }
                { moduleInfo.module2.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(moduleInfo.module2.yelp)} /> }
              </div>
            </Col>
            <Col span={24} lg={6} className="security">
              <div className="rowTitle">Security</div>
              <div className="rowContent">
                The Security of your personal information is our highest priority. We use bank-level encryption (256-bit AEE) to ensure it is never compromised.
              </div>
            </Col>
            <Col span={24} lg={4} className="QRCode">
              <div>
                <div className="rowTitle" style={{marginBottom: '4px'}}>Scan my QR code</div>
                <div className="rowContent">
                  <QRCode
                    className="landing-qrcode"
                    errorLevel="H"
                    value={landingUrl}
                    size={110}
                    bordered={false}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="copyright">Powered by Zeitro, Inc. All Rights Reserved {new Date().getFullYear()}.</Col>
          </Row>
        </div>
        {/* <div>
          <CallBack id={props.id} name={name} email={email} />
        </div>
        <div style={{ backgroundColor: backgroundColor }}>
          <div className="landingFooterClaim">
            The Security of your personal information is our highest priority. We
            use bank-level encryption (256-bit AEE) to ensure it is never
            compromised.
          </div>
          <div className="landingFooter">
            © Zeitro, Inc. All Rights Reserved 2023.
          </div>
        </div>  */}
        {showContactmeModal && getContactmeModal()}
        <Drawer className="drawer-nav" width="60%" placement="right" onClose={onClose} open={navDrawer}>
          <div className="nav" onClick={() => handleClickCalculators('1')}>Check Current Rate</div>
          <div className="nav" onClick={() => handleClickCalculators('2')}>Check Affordability</div>
          <div className="nav">Get Prequalified</div>
          <div style={{textAlign: 'center'}}><Button className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact me today</Button></div>
        </Drawer>
      </div>
      )}
    </>
  );
};

export default LandingPage;
