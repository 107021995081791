import * as com from "../../Common";
import {
  fiveK_qualifiedCounties,
  GMCCWishList,
  MSAtoCountyList_grandslam,
  MSAtoCountyList_grandslam_full,
  MSAtoCountyList_nonagency_full,
  tenK_qualifiedCounties,
  thirtyK_qualifiedCounties,
} from "./programs_consts";
import {
  EProgramType,
  MSAtoCountyList_conforming,
  MSAtoCountyList_nonagency,
  MSAtoNameMap,
} from "./programs_consts";

export const getMSAList = (
  type: EProgramType | "",
  state: string,
  onlyShowExtraExtraIncentive: boolean = false
): { value: string; label: string }[] => {
  let msaList: { value: string; label: string }[] = [
    { value: "", label: "Skip" },
  ];
  if (type === EProgramType.CONFORMING) {
    const stateAbbr = com.convertStateToAbbr(state);
    const countyList = MSAtoCountyList_conforming[stateAbbr];
    if (countyList) {
      msaList = Object.keys(countyList).map((key) => {
        return { value: key, label: `${key} - ${MSAtoNameMap[key]}` };
      });
      msaList.push({ value: "", label: "Skip" });
    } else {
      msaList = [];
      msaList.push({ value: "", label: "Unavailable" });
    }
  }
  if (type === EProgramType.NONAGENCY) {
    const stateAbbr = com.convertStateToAbbr(state);
    const countyList = onlyShowExtraExtraIncentive
      ? MSAtoCountyList_nonagency[stateAbbr]
      : MSAtoCountyList_nonagency_full[stateAbbr];
    console.log(countyList);
    if (countyList) {
      msaList = Object.keys(countyList).map((key) => {
        return { value: key, label: `${key} - ${MSAtoNameMap[key]}` };
      });
      msaList.push({ value: "", label: "Skip" });
    } else {
      msaList = [];
      msaList.push({ value: "", label: "Unavailable" });
    }
  }
  if (type === EProgramType.GRANDSLAM) {
    const stateAbbr = com.convertStateToAbbr(state);
    const countyList = onlyShowExtraExtraIncentive
      ? MSAtoCountyList_grandslam[stateAbbr]
      : MSAtoCountyList_grandslam_full[stateAbbr];
    if (countyList) {
      msaList = Object.keys(countyList).map((key) => {
        return { value: key, label: `${key} - ${MSAtoNameMap[key]}` };
      });
      msaList.push({ value: "", label: "Skip" });
    } else {
      msaList = [];
      msaList.push({ value: "", label: "Unavailable" });
    }
  }
  return msaList;
};

/**
 * This function returns a list of counties for a given MSA, program type, and state.
 *
 * Each option has the value and label in the format of "county_name County", e.g. "Contra Costa County".
 * @param msa - The MSA to get the counties for.
 * @param type - The program type to get the counties for.
 * @param state - The state to get the counties for.
 * @returns A list of counties for the given MSA, program type, and state.
 */
export const getCountyOptionsFromMsaProgramState = (
  msa: string,
  type: EProgramType | "",
  state: string,
  isExtraExtraIncentive: boolean = false
): {
  value: string;
  label: string;
}[] => {
  let options: {
    value: string;
    label: string;
  }[] = [{ value: "", label: "Select a county" }];
  if (type === EProgramType.CONFORMING) {
    if (msa !== "") {
      MSAtoCountyList_conforming[com.convertStateToAbbr(state)][msa].forEach(
        (county) => {
          options.push({ value: county, label: county });
        }
      );
    } else {
      const msaList = MSAtoCountyList_conforming[com.convertStateToAbbr(state)];
      const counties = [];
      if (msaList) {
        for (const msaCode of Object.keys(msaList)) {
          counties.push(...msaList[msaCode]);
        }
      }
      options.push(
        ...counties.map((county) => ({ value: county, label: county }))
      );
    }
  }
  if (type === EProgramType.NONAGENCY) {
    let MSAToCountyList = MSAtoCountyList_nonagency_full;
    if (isExtraExtraIncentive) {
      MSAToCountyList = MSAtoCountyList_nonagency;
    }
    if (msa !== "") {
      MSAToCountyList[com.convertStateToAbbr(state)][msa].forEach((county) => {
        options.push({ value: county, label: county });
      });
    } else {
      const msaList = MSAToCountyList[com.convertStateToAbbr(state)];
      const counties = [];
      if (msaList) {
        for (const msaCode of Object.keys(msaList)) {
          counties.push(...msaList[msaCode]);
        }
      }
      options.push(
        ...counties.map((county) => ({ value: county, label: county }))
      );
    }
  }
  if (type === EProgramType.GRANDSLAM) {
    let MSAToCountyList = MSAtoCountyList_grandslam_full;
    if (isExtraExtraIncentive) {
      MSAToCountyList = MSAtoCountyList_grandslam;
    }
    if (msa !== "") {
      MSAToCountyList[com.convertStateToAbbr(state)][msa].forEach((county) => {
        options.push({ value: county, label: county });
      });
    } else {
      const msaList = MSAToCountyList[com.convertStateToAbbr(state)];
      const counties = [];
      if (msaList) {
        for (const msaCode of Object.keys(msaList)) {
          counties.push(...msaList[msaCode]);
        }
      }
      options.push(
        ...counties.map((county) => ({ value: county, label: county }))
      );
    }
  }
  // For celebrity, we need to find the counties that are in the list of states
  if (type === EProgramType.CELEBRITY) {
    const stateAbbr = com.convertStateToAbbr(state);
    if (stateAbbr === "MA") {
      const counties = thirtyK_qualifiedCounties["Massachusetts"];
      counties.forEach((county) => {
        const countyWithCounty = county + " County";
        options.push({ value: countyWithCounty, label: countyWithCounty });
      });
    } else if (stateAbbr === "CA") {
      const counties = tenK_qualifiedCounties["California"];
      counties.forEach((county) => {
        const countyWithCounty = county + " County";
        options.push({ value: countyWithCounty, label: countyWithCounty });
      });
    } else if (stateAbbr === "GA" || stateAbbr === "NC" || stateAbbr === "SC") {
      const fullStateName = com.mapAbbrFullName[stateAbbr];
      const counties = fiveK_qualifiedCounties[fullStateName];
      counties.forEach((county) => {
        const countyWithCounty = county + " County";
        options.push({ value: countyWithCounty, label: countyWithCounty });
      });
    }
  }
  if (type === EProgramType.DIAMOND) {
    options.push(...getCountyOptionsFromState(state));
  }
  return options;
};

export const getCountyOptionsFromState = (
  state: string
): {
  value: string;
  label: string;
}[] => {
  let stateabbr = com.convertStateToAbbr(state);
  const countyOptions: {
    value: string;
    label: string;
  }[] = [];
  if (!GMCCWishList.hasOwnProperty(stateabbr)) {
    return countyOptions;
  }
  const counties = Object.keys(GMCCWishList[stateabbr]);
  for (let county of counties) {
    countyOptions.push({ value: county, label: county });
  }
  return countyOptions;
};

/**
 * This function returns a list of cities in the format of options (value, label) for a given state and county. This is
 * ready to be used in a dropdown.
 * @param state - The state to get the cities for.
 * @param county - The county to get the cities for.
 * @returns A list of cities for the given state and county.
 */
export const getCityOptions = (
  state: string,
  county: string
): {
  value: string;
  label: string;
}[] => {
  const stateabbr = com.convertStateToAbbr(state);
  const cityOptions: {
    value: string;
    label: string;
  }[] = [];
  // Add a placeholder option
  cityOptions.push({
    value: "",
    label: "Select a city",
  });
  if (!GMCCWishList.hasOwnProperty(stateabbr)) {
    return cityOptions;
  }
  if (!GMCCWishList[stateabbr].hasOwnProperty(county)) {
    return cityOptions;
  }
  const cities = GMCCWishList[stateabbr][county];

  if (cities) {
    for (let city of cities) {
      cityOptions.push({
        value: city,
        label: city,
      });
    }
  }

  return cityOptions;
};
