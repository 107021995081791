import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Typography } from 'antd';
import { isEmpty } from "lodash";
import './AboutMe.css';
import { convertAbbStateToFull } from '../../../Common';
import { useStatsigClient } from "@statsig/react-bindings/src";

const { Paragraph, Text } = Typography;

function AboutMe({ aboutInfo, basicInfo }) {
    const [showMore, setShowMore] = useState(false);
    const [showAllStates, setShowAllStates] = useState(false);
    const [isTextTruncated, setIsTextTruncated] = useState(false);
    const textRef = useRef(null);
    const client = useStatsigClient();

  const languages = useMemo(() => {
    if (isEmpty(basicInfo.languages)) {
      return []
    }
    return basicInfo.languages;
  }, [basicInfo.languages]);

    const states = useMemo(() => {
      if (isEmpty(basicInfo.states)) {
        return []
      }
      return basicInfo.states.map((state) => convertAbbStateToFull(state));
    });

    const visibleStates = useMemo(() => showAllStates ? states : states.slice(0, 5), [showAllStates, states]);

    useEffect(() => {
      const element = textRef.current;
      if (element) {
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
        const isOverflowing = element.scrollHeight > lineHeight * 3;
        setIsTextTruncated(isOverflowing);
      }
    }, [aboutInfo.introduction]);

    return (
      <div className="about-me-wrapper">
        <div className="about-me-title">About me</div>

        <Paragraph
          ref={textRef}
          className={`about-me-text ${!showMore ? 'truncated' : ''}`}
          style={{ marginBottom: 0 }}
        >
          {aboutInfo.introduction}
        </Paragraph>

        {isTextTruncated && (
          <Text
            onClick={() => {
              setShowMore(!showMore); 
              if (!showMore) {
                client.logEvent("lo_detail_page_read_more_click", window.location.href, {
                  item_name: "LoDetailPageReadMoreClick",
                  startTime: new Date,
                  timestamp: Date.now(),
                  pageUrl: window.location.href,
                  loanOfficerID: aboutInfo?.email,
                  type: "personalsite",
              });}
            }}
            className="read-more-button"
          >
            {showMore ? 'read less' : 'read more'}
          </Text>
        )}

        <div className="sections-container">
          {/* Licensed In Section */}
          <div className="section">
            <Text className="section-title">Licensed in</Text>
            <div className="section-content">
              {visibleStates.map((state, index) => (
                <div
                  key={state}
                  className={`state-item ${index === 0 ? 'primary' : 'secondary'}`}
                >
                  {state}
                </div>
              ))}
              {!isEmpty(states) && states.length > 5 && (
                <button
                  onClick={() => setShowAllStates(!showAllStates)}
                  className="show-more-button"
                >
                  {showAllStates ? 'show less' : `+${states.length - 5} states`}
                </button>
              )}
            </div>
          </div>

          {/* Language Section */}
          <div className="section">
            <Text className="section-title">Language</Text>
            <div className="section-content">
              {languages.map((language) => (
                <div key={language} className="language-item">
                  {language}
                </div>
              ))}
            </div>
          </div>

          {/* Highlights Section */}
          <div className="section">
            <Text className="section-title">Highlights</Text>
            <div className="section-content">
              {!isEmpty(basicInfo.tagsList) && basicInfo.tagsList.map((tag, index) => (
                <span
                  key={index}
                  className="highlight-tag"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
}

export default AboutMe;