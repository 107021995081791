import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import * as com from "../../Common.js";
import { connect } from "react-redux";
import * as act from "../../Store/actions.js";
import {
  Alert,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";

import {
  getFees,
  getDTI,
  getCitizenship,
  getOccupancy,
  getPropertyType,
  productReload,
} from "../ProductPicker.js";

import PreQualPlan from "./PreQualPlanPicker";
import {
  parseStringToNumber,
  USDollarCurrencyLocale,
  USDollarCurrencyNoSignLocale,
} from "./utils";
import { getBest, getProductType, getRearrangeProducts } from "./utils_loans";
import PreQualLetterHistory from "./PrequalLetters";
import { DollarPrefix } from "./DollarPrefix";
import { getErrorMessage } from "./error_codes";
import "./PrequalV2.css";
import moment from "moment";

const { Paragraph, Text, Title } = Typography;

const mapStateToProps = (state) => {
  return {
    hascoborrower: state.application.hascoborrower,
    cohabiting: state.application.cohabiting,
    application: state.application,
    property: state.application.property,
    selection: state.application.selection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearPreviousAddress: (event, who) => {
    dispatch(act.ClearPreviousAddress(event.target.value, who));
  },
  changeMainProperty: (value, verb) => {
    dispatch(act.ChangeMainProperty(value, verb));
  },
  changeMainPropertyUnits: (event) => {
    dispatch(act.ChangeMainPropertyUnits(event.target.value));
  },
  changeMainPropertyYearBuilt: (event) => {
    dispatch(act.ChangeMainPropertyYearBuilt(event.target.value));
  },
  changeMainPropertyRefinanceYearAcquired: (event) => {
    dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event));
  },
  changeMainPropertyRefinanceOriginalCost: (event) => {
    dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event));
  },
  updateApplicationAttribute: (input, verb) => {
    dispatch(act.UpdateApplicationAttribute(input, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
  updateFico: (input) => {
    dispatch(act.UpdateFico(input));
  },
});

const EPP_COLUMN_SPAN = 8;
const LTV_COLUMN_SPAN = 4;
const CLOSING_COST_COLUMN_SPAN = 8;
const FICO_COLUMN_SPAN = 4;

type PrequalV2Props = {
  loanid: string;
  borrowerid: string;
  application: any;
  property: any;
  selection: any;
  changeMainProperty: (value: string | number, verb: string) => void;
  updateSelectField: (input: string | number, verb: string) => void;
  updateFico: (input: number) => void;
};

class PrequalV2 extends Component<
  PrequalV2Props,
  {
    loading: boolean;
    loadingPdf: boolean;
    purpose: string;
    loansize: number;
    purchasePrice: string;
    closingCost: string;
    showPrequal: boolean;
    ltv: number;
    fico: number;
    bestProduct: any;
    displayingCreatePlanPanel: boolean;
    adjustmentTipVisible: boolean;
    errorCode?: string;
    errorText?: string;
  }
> {
  count: number;
  letterHistoryRef: React.RefObject<any>;
  planPickerRef: React.RefObject<any>;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingPdf: false,
      purpose: "Purchase",
      loansize: this.props.selection.loansize === "" ? 0 : com.safeParseInt(this.props.selection.loansize),
      purchasePrice: com
        .safeParseInt(this.props.property.salesprice)
        .toString(),
      closingCost: "1000",
      showPrequal: false,
      ltv: this.props.property.salesprice===""? 0 : Math.round(
        (com.safeParseInt(this.props.selection.loansize) /
          com.safeParseInt(this.props.property.salesprice)) *
          100
      ),
      fico: this.props.application.scre
        ? com.safeParseInt(this.props.application.scre)
        : 800,
      bestProduct: null,
      displayingCreatePlanPanel: false,
      adjustmentTipVisible: true,
    };
    this.count = 0;
    this.letterHistoryRef = React.createRef();
    this.planPickerRef = React.createRef();
  }
  componentDidMount() {
    this.getFees(this.props.loanid);
    this.setState({
      purchasePrice: USDollarCurrencyNoSignLocale.format(
        parseStringToNumber(this.state.purchasePrice)
      ),
      closingCost: USDollarCurrencyNoSignLocale.format(
        parseStringToNumber(this.state.closingCost)
      ),
    });
  }
  getFees = getFees.bind(this);
  getDTI = getDTI.bind(this);
  getCitizenship = getCitizenship.bind(this);
  getOccupancy = getOccupancy.bind(this);
  getPropertyType = getPropertyType.bind(this);
  productReload = productReload.bind(this);
  refetchPreQualLetterHistory = () => {
    if (this.letterHistoryRef.current) {
      this.letterHistoryRef.current.fetchData();
    }
  };

  previewPrequalPDF = () => {
    if (this.planPickerRef.current) {
      this.planPickerRef.current.previewPrequalPDF();
    }
  };

  askedForAssistance = () => false;

  cullRate = (products) => {
    if (products.length === 0) return products;

    let newproducts = [];
    newproducts.push(products[0]);
    let base_rate = products[0].base_rate;

    for (let i = 1; i < products.length; i++) {
      if (products[i].base_rate !== base_rate) {
        newproducts.push(products[i]);
        base_rate = products[i].base_rate;
      } else {
      }
    }
    return newproducts;
  };

  compareRate = (a, b) => {
    if (a.base_rate !== b.base_rate) return b.base_rate - a.base_rate;
    return b.closing_cost - a.closing_cost; // take into account remaining credits
  };

  processProducts = (products) => {
    console.log("products", products.length, products);
    this.setState({ loading: true });
    if (products.length > 0) {
      this.setState({ showPrequal: true });
    } else {
      this.setState({ showPrequal: false, bestProduct: null });
      return;
    }

    let rearranged = getRearrangeProducts(
      products,
      this.props.application.property.propertytype
    );

    rearranged = rearranged.filter((product) => {
      return (
        Number(product.closing_cost) <=
        parseStringToNumber(this.state.closingCost)
      );
    });
    rearranged.sort(this.compareRate);
    rearranged = this.cullRate(rearranged);
    if (rearranged.length > 0) {
      this.setState({ showPrequal: true });
    } else {
      this.setState({ showPrequal: false, bestProduct: null });
      return;
    }

    const bestProduct = getBest(rearranged);

    if (!!bestProduct) {
      this.setState({ showPrequal: true, bestProduct: bestProduct });
    } else {
      this.setState({ showPrequal: false, bestProduct: null });
      return;
    }
    this.setState({ loading: false });
  };

  render = () => {
    return (
      <div className="mb-4  text-center text-wrap">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <Row style={{ padding: "20px" }}>
            <Space
              style={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Title level={4}>Pre-qualification letter</Title>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ displayingCreatePlanPanel: true })
                }
                style={{ height: "100%", padding: "0px 1em" }}
              >
                Create
              </Button>
            </Space>
          </Row>
          <PreQualLetterHistory
            ref={this.letterHistoryRef}
            name={
              this.props.application.borrower.firstname +
              " " +
              this.props.application.borrower.lastname
            }
          />
          <Modal
            title={null}
            open={this.state.displayingCreatePlanPanel}
            footer={null}
            onCancel={() => this.setState({ displayingCreatePlanPanel: false })}
            width={"85vw"}
            style={{
              position: "fixed",
              top: 0,
              left: "7.5%",
              height: "100vh",
              display: "flex",
              overflow: "hidden",
              padding: 0,
            }}
            bodyStyle={{
              height: "calc(100% - 1em)",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              padding: 0,
            }}
            zIndex={1000}
          >
            <div className="text-left" style={{ padding: "20px", flexGrow: 1 }}>
              <Title level={2} style={{ padding: "10px 0px 0px 0px" }}>
                Create a pre-qualification letter
              </Title>
              <Divider style={{ margin: "10px 0" }} />
              <Row>
                <Paragraph>
                  Adjust the loan terms to create a pre-qualification letter for
                  your borrower.
                </Paragraph>
              </Row>
              <Row gutter={32} className="prequal_plan_container">
                <Col span={12}>
                <Row className="prequal_plan_labels_row">
                    <span className="prequal_plan_labels">
                      Est. purchase price
                    </span>
                  </Row>
                  <Row>
                    <Input
                      value={this.state.purchasePrice}
                      onChange={(e) => {
                        const salesPrice = parseStringToNumber(e.target.value);
                        this.props.changeMainProperty(salesPrice, "salesprice");
                        this.props.changeMainProperty(salesPrice, "appraisal");
                        const loanSize = Math.round(
                          (this.state.ltv * salesPrice) / 100
                        );
                        const downpayment = Math.ceil(salesPrice - loanSize);
                        this.props.updateSelectField(
                          downpayment,
                          "downpayment"
                        );
                        this.props.updateSelectField(loanSize, "loansize");
                        this.setState({
                          loansize: loanSize,
                          purchasePrice:
                            USDollarCurrencyNoSignLocale.format(salesPrice),
                          loading: true,
                          errorCode: null,
                          bestProduct: null,
                        });
                        this.getFees(this.props.loanid);
                      }}
                      prefix={<DollarPrefix />}
                      type="text"
                      className="prequal_plan_controls"
                    />
                  </Row>
                  <Row className="prequal_plan_labels_row">
                    <span className="prequal_plan_labels">Down payment</span>
                  </Row>
                  <Row>
                    <Input
                      value={USDollarCurrencyNoSignLocale.format(
                        this.props.application.selection.downpayment
                      )}
                      onChange={(e) => {
                        const downPayment = Math.round(parseStringToNumber(e.target.value));
                        this.props.updateSelectField(
                          downPayment,
                          "downpayment"
                        );
                        const salesPrice = com.safeParseInt(
                          this.props.property.salesprice
                        );
                        if (salesPrice !== 0 ) {
                          // We will round LTV to the nearest whole number
                          const newLTV = Math.round(
                            ((salesPrice - downPayment) / salesPrice) * 100
                          );
                          let loanSize = salesPrice - downPayment

                          this.props.updateSelectField(loanSize.toFixed(0), "loansize");
                          this.setState({
                            loansize: loanSize,
                            ltv: newLTV,
                            loading: true,
                            errorCode: null,
                            bestProduct: null,
                          });
                          this.getFees(this.props.loanid);
                        }

                      }}
                      prefix={<DollarPrefix />}
                      suffix={
                        <span
                          style={{
                            color: "#1f3988",
                            backgroundColor: "#d6e2fb",
                            padding: "0.2em",
                            borderRadius: "5px",
                          }}
                        >
                          {100 - this.state.ltv}%
                        </span>
                      }
                      type="text"
                      className="prequal_plan_controls"
                    />
                  </Row>

                  <Row className="prequal_plan_labels_row">
                    <span className="prequal_plan_labels">
                      Maximum closing cost
                    </span>
                  </Row>
                  <Row>
                    <Input
                      value={this.state.closingCost}
                      onChange={(e) => {
                        const closingCost = parseStringToNumber(e.target.value);
                        const closingCostString = closingCost.toString();
                        this.setState({
                          closingCost: USDollarCurrencyNoSignLocale.format(
                            Number(closingCost)
                          ),
                          errorCode: null,
                          bestProduct: null,
                        });
                        this.props.updateSelectField(
                          closingCostString,
                          "maxupfrontcost"
                        );
                        this.getFees(this.props.loanid);
                      }}
                      prefix={<DollarPrefix />}
                      className="prequal_plan_controls"
                      type="text"
                    />
                  </Row>
                  <Row gutter={16} className="prequal_plan_labels_row">
                    <Col span={12}>
                      <span className="prequal_plan_labels">LTV</span>
                    </Col>
                    <Col span={12}>
                      <span className="prequal_plan_labels">FICO</span>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        value={this.state.ltv}
                        onChange={(e) => {
                          const newLTV = com.safeParseInt(e.target.value);
                          const salesPrice = com.safeParseInt(
                            this.props.property.salesprice
                          );
                          const loanSize = Math.round(
                            (newLTV * salesPrice) / 100
                          );
                          const downpayment = Math.ceil(salesPrice - loanSize);
                          this.props.updateSelectField(
                            downpayment,
                            "downpayment"
                          );
                          this.props.updateSelectField(loanSize, "loansize");
                          this.setState({
                            loansize: loanSize,
                            ltv: newLTV,
                            loading: true,
                            errorCode: null,
                            bestProduct: null,
                          });
                          this.getFees(this.props.loanid);
                        }}
                        className="prequal_plan_controls"
                        suffix="%"
                        type="number"
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        value={this.state.fico}
                        onChange={(e) => {
                          const newFICO = com.safeParseInt(e.target.value);
                          this.props.updateFico(newFICO);

                          this.setState({
                            fico: newFICO,
                            loading: true,
                            errorCode: null,
                            bestProduct: null,
                          });
                          this.getFees(this.props.loanid);
                        }}
                        className="prequal_plan_controls"
                        type="text"
                        suffix=""
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <PreQualPlan
                    ref={this.planPickerRef}
                    borrowerID={this.props.borrowerid}
                    loanID={this.props.loanid}
                    ltv={this.state.ltv}
                    purchasePrice={this.props.property.salesprice}
                    bestProduct={this.state.bestProduct}
                    email={this.props.application.borrower.email.toLowerCase()}
                    borrowerName={
                      this.props.application.borrower.firstname +
                      " " +
                      this.props.application.borrower.lastname
                    }
                    onClickSendToBorrower={() => {
                      this.setState({ displayingCreatePlanPanel: false });
                      this.refetchPreQualLetterHistory();
                    }}
                    loading={this.state.loading}
                  />
                </Col>
              </Row>

              {/* This is kept for debugging to check if our products logic is working or not. 
              We should remove it after we are sure that the products logic is working. */}
              {(!(this.state.showPrequal || this.state.loading) ||
                this.state.errorCode) && (
                <Alert
                  style={{ marginTop: "20px", display: "none" }}
                  message={getErrorMessage(
                    this.state.errorCode,
                    this.state.errorText
                  )}
                  type="error"
                />
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PrequalV2);
