import React, { useCallback, useState, useMemo } from 'react'
import { Button, Card, Space, Tag } from 'antd'
import { isEmpty, join, compact } from 'lodash'
import './PersonalInfoCard.css'
import { useStatsigClient } from "@statsig/react-bindings/src";

function PersonalInfoCard({ aboutInfo, basicInfo, contact, preQualify }) {
    const client = useStatsigClient();

    const languages = useMemo(() => {
        if (isEmpty(basicInfo.languages)) {
            return ''
        }
        return basicInfo.languages.join(', ')
    }, [basicInfo.languages])

    const name = useMemo(() => {
        return join(compact([basicInfo.firstname, basicInfo.middlename, basicInfo.lastname]), ' ')
    }, [basicInfo])

    const socials = useMemo(() => {
        // Define supported social platforms
        const platforms = ['google', 'linkedin', 'twitter', 'yelp']

        // Map through platforms and create array of active social links
        return platforms.reduce((acc, platform) => {
            if (basicInfo[platform]) {
                acc.push({
                    platform,
                    link: basicInfo[platform]
                })
            }
            return acc
        }, [])
    }, [basicInfo])

    const dynamicExternalLink = (href) => {
        if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`
        }
        return href
    }

    return (
        <div className="personal-info-container">
            <Card className="personal-info-card">
                {!isEmpty(basicInfo.file) ? (
                    <img
                        src={basicInfo.file[0].url}
                        alt={basicInfo.name}
                        className="personal-info-avatar"
                    />
                ) : (
                    <div
                        className="personal-info-avatar"
                        style={{
                            backgroundColor: '#d9d9d9',
                        }}
                        aria-label={basicInfo.name}
                    />
                )}
                <div className="personal-info-name">{name}</div>

                <div className="personal-info-details">
                    {/* NMLS Number and Verification Status */}
                    <div className="personal-info-row">
                        <div className="personal-info-nmls">
                            NMLS
                            <span className="personal-info-nmls-number">{` # ${basicInfo.nmls}`}</span>
                        </div>
                        {/* <Tag className="personal-info-verified-tag">
                            <img
                                src="/images/verified-icon.svg"
                                alt="Verified"
                                className="personal-info-verified-icon"
                            />
                            <span>Verified</span>
                        </Tag> */}
                    </div>

                    {/* Company Information */}
                    <div className="personal-info-company">
                        Works at
                        <span className="personal-info-company-name">{` ${aboutInfo.companyName}`}</span>
                    </div>

                    {/* Language */}
                    <div className="personal-info-languages">
                        Language
                        <span className="personal-info-languages-list">{` ${languages}`}</span>
                    </div>
                </div>

                {/* Contact Information */}
                <div className="personal-info-contact">
                    <Space
                        direction="vertical"
                        size={12}
                        className="personal-info-contact-space"
                    >
                        <div className="personal-info-contact-item">
                            <img src="/images/phone-blue.svg" alt="Phone" />
                            <span>{aboutInfo.phone}</span>
                        </div>
                        <div className="personal-info-contact-item">
                            <img src="/images/email-blue.svg" alt="Email" />
                            <span>{aboutInfo.email}</span>
                        </div>
                        <div className="personal-info-contact-item">
                            <img
                                src="/images/location-blue.svg"
                                alt="Location"
                            />
                            <span>{aboutInfo.companyAddress}</span>
                        </div>
                    </Space>
                </div>

                {/* Contact Buttons */}
                <Space
                    direction="vertical"
                    size={12}
                    className="personal-info-buttons"
                >
                    <Button
                        type="primary"
                        className="personal-info-contact-btn"
                        onClick={() => {
                            contact();
                            client.logEvent("Lo_detail_page_contact_click", aboutInfo.email, {
                            item_name: "loDetailPageContactClick",
                            startTime: new Date,
                            timestamp: Date.now(),
                            pageUrl: window.location.href,
                            loanOfficerID: aboutInfo.email,
                            type: "personalsite",
                        });
                        }}
                    >
                        Contact
                    </Button>
                    <Button
                        className="personal-info-prequalify-btn"
                        onClick={() => {
                            preQualify();
                            client.logEvent("lo_detail_page_pre-qualified_click", aboutInfo.email, {
                                item_name: "loDetailPagePrequalifiedClick",
                                startTime: new Date,
                                timestamp: Date.now(),
                                pageUrl: window.location.href,
                                loanOfficerID: aboutInfo.email,
                                type: "personalsite",
                            })
                    }}>
                        Pre-qualify today
                    </Button>
                </Space>

                {/* Social Platforms */}
                {!isEmpty(socials) && (
                    <div className={`personal-info-social ${socials.length < 4 ? 'justify-start' : 'justify-between'}`}>
                        {socials.map((item, index) => {
                            return (
                                <img
                                    key={index}
                                    src={`/images/${item.platform}.svg`}
                                    alt={item.platform}
                                    onClick={() =>
                                        window.open(
                                            dynamicExternalLink(item.link),
                                            '_blank'
                                        )
                                    }
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )
                        })}
                    </div>
                )}
            </Card>
        </div>
    )
}

export default PersonalInfoCard
