import React, { useState, useEffect, useRef } from 'react'
import { useImmer } from "use-immer"
import { Dropdown, Radio, Tabs, Table, Input, Alert, Button, Menu, Col, Row, Modal, Space, Checkbox, Popover, Card, Switch, Select, Tooltip } from 'antd'
import dayjs from 'dayjs';
// import mockXML from './mockXML.json'
import './CreditReport.css'
import './newCreditReport.css'

import { DownOutlined, UpOutlined, ExclamationCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import FullHeightModal from "./Common/components/FullHeightModal"
import { useSelector, useDispatch } from 'react-redux'
import { checkAccess } from "./Auth"
import { UR_Owner } from './State'
import { set } from 'lodash'
const { TabPane } = Tabs


export default function NewCreditReport() {
    const [modal, contextHolder] = Modal.useModal()
    const application = useSelector(state => {

        return state.application
    })
    const urlParams = window.location.pathname.replace('/app/borrower/', '')

    const [user, setUser] = useImmer({
        borrower: {},
        coborrower: {},
        hascoborrower: ''
    })
    const [CheckLoading, setCheckLoading] = useImmer(false)
    const [creditLiability, setCreditLiability] = useImmer([])
    const [creditLoanType, setCreditLoanType] = useImmer([])
    const [accountStatus, setAccountStatus] = useImmer([])
    const [currentDataSource, setCurrentDataSource] = useImmer([])
    const [currentTable, setCurrentTable] = useImmer(null)
    const [isSwitch, setIsSwitch] = useImmer(false)
    const [credit, setCredit] = useImmer('')
    const [status, setState] = useImmer('')
    const [pdfString, setPdfString] = useImmer('')
    const [summary, setSummary] = useImmer([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isConsentOpen, setIsConsentOpen] = useState(false)
    const [parties, setParties] = useImmer([])
    const [isShowSummary, setIsShowSummary] = useImmer('CreditHistory')
    const [isChecked, setIsChecked] = useState(['borrower', 'coborrower'])
    const [accountData, setAccountData] = useState([])
    const partiesReplace = [
        { time: '', value: '', title: 'ExperianFairIsaac', factors: [] },
        { time: '', value: '', title: 'FICORiskScoreClassic04', factors: [] },
        { time: '', value: '', title: 'EquifaxBeacon5.0', factors: [] }
    ]
    const [checkButton, setCheckButton] = useState(true)
    const [errorData, setErrorData] = useImmer({
        error: false,
        content: ''
    })
    const [next, setNext] = useImmer(true)
    const [creditRadio, setCreditRadio] = useImmer('SoftPull')
    const [bureauRadio, setBureauRadio] = useImmer(['Experian'])
    const [creditReports, setCreditReports] = useState([])
    const [consentType, setConsentType] = useState(undefined)
    const [openNotice, setOpenNotice] = useState(false)
    const currentType = {
        SoftPull: "CustomerSoftCreditReport",
        HardPull: 'CustomerHardCreditReport'
    }
    const [currentReports, setCurrentReports] = useState('')
    const [creditConsentStatus, setCreditConsentStatus] = useState([])
    const [handleRole, setHandleRole] = useState('')
    const Bureau = [
        {
            value: "Experian",

        },
        {
            value: "Equifax",

        },
        {
            value: "TransUnion",

        }
    ]
    const vendorsList = {
        'KCBCredit': 'KCB Credit',
        'AdvantageCredit': 'Advantage Credit',
        'CreditTechnology': 'Credit Technology',
    }
    const getcreditaccountconfig = async () => {
        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch('/los/getcreditaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const js = await response.json()
        // const isNotSet = js?.Accounts?.findIndex(x => x.IsCurrent) !== -1 && js?.AutomatedSoftCreditPull
        const isNotSet = js?.Accounts.length > 0 ? true : false
        setCheckButton(isNotSet)
    }
    useEffect(() => {
        const creditConsentData = JSON.parse(localStorage.getItem('creditConsentData'));

        let updatedStatus = [
            {
                role: 'borrower',
                isConsent: false,
                isConsentType: '',
            }
        ]
        if (application.hascoborrower === 'withcoborrower') {
            updatedStatus.push({
                role: 'coborrower',
                isConsent: false,
                isConsentType: '',
            })
        }
        if (creditConsentData && creditConsentData.loanID === urlParams) {
            const consentMap = creditConsentData.creditConsentStatus.reduce((acc, item) => {
                acc[item.role] = item
                return acc
            }, {})

            updatedStatus = updatedStatus.map(item => {
                const matchedItem = consentMap[item.role];
                return {
                    ...item,
                    isConsent: matchedItem ? matchedItem.isConsent : item.isConsent,
                    isConsentType: matchedItem ? matchedItem.isConsentType : item.isConsentType,
                }
            })
        }
        setCreditConsentStatus(updatedStatus)
        getcreditaccountconfig()
        setUser({
            borrower: application.borrower,
            coborrower: application.coborrower,
            hascoborrower: application.hascoborrower
        })
        getXml()

    }, [])
    const borrowerMarried = () => {
        return application.married && application.hascoborrower === 'withcoborrower' && application.coborrower.maritalstatus === 'married'
    }
    const handleSelectChange = (value) => {
        const getCurrentReports = creditReports.find((data) => data.key.toString() === value)
        setCurrentReports(getCurrentReports.label)
        setXml(getCurrentReports.CreditReportXML)
    }
    const camelCaseToSpaces = (str) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2')
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Remaining Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (_, record) => (
                <div style={{ color: '#222' }}>${_ === '' ? '0.00' : Number(_).toFixed(2)}</div>
            ),
        },
        {
            title: 'Mo. pay',
            dataIndex: 'payment',
            key: 'payment',
            render: (_, record) => (
                <div style={{ color: '#222' }}>${_ === '' ? '0.00' : Number(_).toFixed(2)}</div>
            ),
        },
        {
            title: 'Mo. left to pay',
            dataIndex: 'monthly',
            key: 'monthly',
            render: (_, record) => (
                <div style={{ color: '#222' }}>${_ === '' ? '0.00' : Number(_).toFixed(2)}</div>
            ),
        },
        {
            title: 'Opened date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => {
                if (a.date < b.date) {
                    return -1
                }
                if (a.date > b.date) {
                    return 1
                }
                return 0
            },
            render: (_, record) => (
                <div style={{ color: '#222' }}>{dayjs(_).format('MM/DD/YYYY')}</div>
            ),
        },
        {
            title: 'Credit loan type',
            dataIndex: 'credit',
            key: 'credit',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{camelCaseToSpaces(_)}</div>
            ),
        },
        {
            title: 'Acct status',
            dataIndex: 'account',
            key: 'account',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
    ]
    const columnsSummary = [
        {
            title: 'Account Type',
            dataIndex: 'Type',
            key: 'Type',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{camelCaseToSpaces(_)}</div>
            ),
        },

        {
            title: 'Balance',
            dataIndex: 'Balance',
            key: 'Balance',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Count',
            dataIndex: 'Count',
            key: 'Count',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Payment',
            dataIndex: 'Payment',
            key: 'Payment',

            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'PastDue',
            dataIndex: 'PastDue',
            key: 'PastDue',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'HighCredit',
            dataIndex: 'HighCredit',
            key: 'HighCredit',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
    ]
    const type = {
        "ExperianFairIsaac": "Experian",
        "EquifaxBeacon5.0": 'Equifax',
        'TransUnion': 'TransUnion',
        'FICORiskScoreClassic': 'TransUnion'
    }
    const handleConsent = (role) => {
        setHandleRole(role)
        setIsConsentOpen(true)
    }
    const handleConsentType = (value) => {
        setConsentType(value)
    }
    const handleConsentClose = () => {
        setIsConsentOpen(false)
        setConsentType(undefined)
    }
    const consentConfirm = () => {
        setCreditConsentStatus(prevStatus => {
            const updatedStatus = prevStatus.map(item =>
                item.role === handleRole
                    ? { ...item, isConsent: true, isConsentType: consentType }
                    : item
            )
            let obj = {
                'loanID': urlParams,
                'creditConsentStatus': updatedStatus
            }
            localStorage.setItem('creditConsentData', JSON.stringify(obj))
            return updatedStatus
        })
        handleConsentClose()
        setOpenNotice(true)
        setTimeout(() => {
            setOpenNotice(false)
        }, 5000)
    }
    const formatDate = (dateString) => {
        return dayjs(dateString, 'MM/DD/YYYY').format('MMMM D, YYYY')
    }
    const formatAddress = (type) => {
        return `${user[type].presentaddressstreet}, ${user[type].presentaddresscity}, ${user[type].presentaddressstate}, ${user[type].presentaddresszip}, USA`
    }
    return (
        <>
            {contextHolder}
            <div style={{
                padding: '18px 36px'
            }}>

                <div style={{ fontFamily: 'Inter', fontSize: 20, fontWeight: 600, color: '#000', marginBottom: 12, lineHeight: '26px' }}>
                    <span>Credit report</span>
                </div>
                {!checkButton && <div className='setUpAccount'>
                    <div>
                        <div style={{ color: "#222", fontSize: 16, fontWeight: 600, lineHeight: 'normal', marginBottom: 6 }}>No account added</div>
                        <div style={{ color: "#222", fontSize: 14, lineHeight: 'normal', letterSpacing: '0.2px' }}>{checkAccess([UR_Owner]) ? "Your credit account has not been set up. Please proceed to set it up." : "The credit account has not been set up. Please contact your admin to set up the credit account."}</div>
                    </div>
                    <div>
                        <Button className='zeitroButton' onClick={() => {

                            if (checkAccess([UR_Owner])) {
                                window.open('/app/management', '_self')
                            } else {
                                modal.confirm({
                                    title: '',
                                    icon: null,
                                    content: <div>Please contact your admin to set up the credit account</div>,
                                    cancelButtonProps: { style: { display: 'none' } },
                                })
                            }

                        }}>Contact</Button>

                    </div>
                </div>}
                <Row className='credit-report-top-bar'>
                    <Col span={24} sm={12}>
                        <Select                        
                            className='check-date-select'
                            onChange={handleSelectChange}
                            options={creditReports.map((item) => ({
                                value: item.key,
                                label: item.label,
                            }))}
                            placeholder="Select credit check date"
                        />
                    </Col>
                    <Col span={24} sm={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '16px'}}>
                            <Button 
                                className='credit-report-btn credit-report-download' 
                                type="primary"
                                disabled={parties.every(p => p?.id === '')}
                                onClick={() => {
                                    const link = document.createElement("a")
                                    link.href = `data:application/pdf;base64,${pdfString}`
                                    link.download = 'Credit Report'
                                    document.body.appendChild(link)
                                    link.click()
                                    document.body.removeChild(link)
                                }}
                            >
                                Download
                            </Button>
                            {
                                checkButton ?
                                    <Button type="primary" className='credit-report-btn credit-report-check' onClick={() => {
                                        setIsModalOpen(true)
                                    }}>Check credit</Button>
                                    : <Tooltip placement="left" title={checkAccess(["Owner"]) ? 'This feature requires a credit account. Please configure your credit account in Management to unlock it.' : 'This feature requires a credit account. Please contact your account admin to configure your credit account in Management and unlock it.'}>
                                        <Button disabled={true} className='credit-report-btn credit-report-check' onClick={() => {
                                            setIsModalOpen(true)
                                        }}>Check credit</Button>
                                        </Tooltip>
                            }
                        </div>
                    </Col>
                </Row>
                <div >
                    <Tabs className='credit-report-tabs' defaultActiveKey="CreditSummary">
                        <TabPane tab="Credit summary" key="CreditSummary">
                            <div style={{ marginTop: '10px' }}>
                                <div className='CreditHistory' >Credit Summary</div>
                                <Table
                                    style={{ marginTop: '10px' }}
                                    columns={columnsSummary}
                                    dataSource={summary}
                                ></Table>

                            </div>
                        </TabPane>
                        <TabPane tab="Credit history" key="CreditHistory">
                            {errorData.error && <div style={{ color: "#CB8600", display: 'flex', alignItems: 'center', marginTop: '10px', fontWeight: 500 }}><ExclamationCircleFilled /> <span style={{ marginLeft: '10px' }}>
                                Credit check unavailable</span></div>}
                            {errorData.error && <Alert style={{ marginTop: "10px" }} message={<div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>

                                <div>{errorData.content}</div>
                                <div>
                                    <Popover placement='bottom' content={
                                        <div style={{
                                            color
                                                : "#222",
                                        }}>
                                            <div style={{
                                                minWidth: 150,
                                                fontSize: '28px',
                                                paddingBottom: 10,
                                                paddingLeft: 4,
                                                borderBottom: "1px solid #DBDCDE"
                                            }}>{user.borrower.firstname} {user.borrower.lastname}</div>

                                            <div style={{ marginTop: 10, }}>
                                                <div className='PopoverTitle'>Phone</div>
                                                <div>{user.borrower.homephone === '' ? '--' : user.borrower.homephone} </div>
                                            </div>
                                            <div style={{ marginTop: 10 }}>
                                                <div className='PopoverTitle'>Email</div>
                                                <div style={{ textDecoration: 'underline' }}>{user.borrower.email} </div>
                                            </div>

                                        </div>


                                    }>
                                        <Button className='ant-btn-default-zeitro'>Contact borrower</Button>
                                    </Popover>

                                </div>

                            </div>} type="warning" />}
                            {/* <Tabs> */}
                            {
                                parties.map((partiesItem, partiesIndex) => {
                                    const newCredit = partiesItem.credit.length ? partiesItem.credit : partiesReplace
                                    return (
                                        // <TabPane tab={partiesItem.name} key={partiesIndex}>
                                        <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: 24 }}>
                                            <div style={{ fontFamily: 'Inter', color: "#222", fontSize: 16, fontWeight: 500, lineHeight: '24px', marginBottom: -4 }}>
                                                <span>{partiesItem.name}</span>
                                            </div>
                                            {newCredit.map((item, index) => {

                                                return <Card className='credit-history-card' style={!checkButton ? { filter: 'blur(1px)' } : {}}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: 36, padding: '0 18px'}}>
                                                        <div>
                                                            <div style={{ height: 30, marginBottom: 9}}>{getImg(item.title)}</div>
                                                            <div className='reportScore'>Score</div>
                                                            <div className='reportValue' style={item.value === '' ? {color: '#DBDCDE'} : {}}>{item.value === '' ? '---' : item.value}</div>


                                                        </div>
                                                        <div style={{ width: '100%' }} >
                                                            <div style={{ fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 500, height: 30, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div>{getName(item.title)}</div>
                                                                <div>

                                                                    <div style={{ cursor: "pointer", height: 24, width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                                                        if (currentTable === `${partiesIndex}${index}`) {
                                                                            setCurrentTable(null)
                                                                        } else {
                                                                            setCredit('')
                                                                            setState('')
                                                                            setIsSwitch(false)
                                                                            setCurrentTable(`${partiesIndex}${index}`)
                                                                            const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title])))
                                                                            setCurrentDataSource(list)
                                                                        }

                                                                    }}>
                                                                        {currentTable === `${partiesIndex}${index}` ? <UpOutlined /> : < DownOutlined />}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item.factors.length > 0 ?
                                                                <div className='factorScore'>
                                                                    <div className='FactorsTitle'>Factors Influencing Credit Score:</div>
                                                                    <ul style={{
                                                                        paddingLeft : '24px',
                                                                        color: 'rgba(34, 34, 34, 0.90)',
                                                                        fontSize: '14px', 
                                                                        margin: '4px 0 0',
                                                                        lineHeight: '18px'
                                                                    }}>
                                                                        {
                                                                            item.factors.map((factorsItem) => {

                                                                                return (
                                                                                    <li key={factorsItem}>{factorsItem}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div> : <div className='factorScore' style={{ textAlign: "center", height: 64, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                                                    <div style={{ color: "#6E6E70", fontSize: "16px", lineHeight: '20px' }}>Credit not pulled</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ width: '100%', height: '1px', background: "#DBDCDE", marginTop: "10px" }}></div> */}
                                                    {

                                                        currentTable === `${partiesIndex}${index}` ? <div style={{ marginTop: "10px" }}>
                                                            <div style={{padding: '0 18px'}} className='CreditHistory'>Credit History</div>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: '0 18px' }}>
                                                                <div style={{display: 'flex', alignItems: "center"}}>
                                                                    <Switch value={isSwitch} onChange={(e) => {
                                                                        setIsSwitch(e)
                                                                        const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title]))).filter(info => info.credit.includes(credit === undefined ? '' : credit)).filter(info => info.account.includes(status === undefined ? '' : status)).filter((info) => {
                                                                            if (e) {
                                                                                return Number(info.payment) !== 0
                                                                            } else {
                                                                                return info
                                                                            }


                                                                        })
                                                                        setCurrentDataSource(list)
                                                                    }}></Switch> <div style={{fontFamily: 'Inter', fontSize: 16, color: '#222', lineHeight: 'normal', paddingLeft: 10,}}>Exclude 0 monthly payment records</div>
                                                                </div>
                                                                <div>
                                                                    <Select
                                                                        className='credit-history-select'
                                                                        placeholder='Credit loan type'
                                                                        allowClear
                                                                        onChange={(e) => {
                                                                            // console.log(e)
                                                                            setCredit(e)
                                                                            const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title]))).filter(info => info.credit.includes(e === undefined ? '' : e)).filter(info => info.account.includes(status === undefined ? '' : status)).filter((info) => {
                                                                                if (isSwitch) {
                                                                                    return Number(info.payment) !== 0
                                                                                } else {
                                                                                    return info
                                                                                }


                                                                            })
                                                                            setCurrentDataSource(list)
                                                                        }}
                                                                        style={{ width: '250px', height: '38px', marginRight: '16px' }} options={creditLoanType}></Select>
                                                                    <Select
                                                                        className='credit-history-select'
                                                                        allowClear
                                                                        onChange={(e) => {

                                                                            setState(e)
                                                                            const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title]))).filter(info => info.credit.includes(credit === undefined ? '' : credit)).filter(info => info.account.includes(e === undefined ? '' : e)).filter((info) => {
                                                                                if (isSwitch) {
                                                                                    return Number(info.payment) !== 0
                                                                                } else {
                                                                                    return info
                                                                                }


                                                                            })
                                                                            setCurrentDataSource(list)
                                                                        }}
                                                                        placeholder='Status'
                                                                        style={{ width: '150px', height: '38px' }} options={accountStatus}></Select>

                                                                </div>

                                                            </div>

                                                            <Table className='credit-history-table' style={{ marginTop: '10px' }} columns={columns} dataSource={currentDataSource}
                                                            ></Table>
                                                        </div> : <></>
                                                    }

                                                </Card>
                                            })}

                                        </Space>
                                        // </TabPane>
                                    )
                                })

                            }

                            {/* </Tabs> */}
                        </TabPane>
                    </Tabs>
                    {/* <div>
                        <Radio.Group value={isShowSummary} onChange={(e) => {

                            setIsShowSummary(e.target.value)
                        }} defaultValue="CreditSummary">
                            <Radio value="CreditHistory">Credit history</Radio>
                            <Radio value="CreditSummary">Credit summary</Radio>


                        </Radio.Group>

                    </div> */}


                </div>


                {/* {
                    isShowSummary === 'CreditSummary' ?
                  
                 

                } */}



            </div >
            <Modal
                className='credit-check-modal'
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                destroyOnClose
                width={976}
                open={isModalOpen}
                title="Credit check"
                onCancel={() => {
                    setIsModalOpen(false)
                    setNext(true)
                }}
                footer={
                    <div className='btn-wrap'>
                        {next ? <div></div> : <Button className='credit-check-btn' type='link' onClick={() => {
                        setNext(true)
                        setCheckLoading(false)
                        }}> GO back</Button>}
                        <Button className='credit-check-btn' type='primary' disabled={next && creditConsentStatus.some(item => !item.isConsent)} loading={CheckLoading} onClick={async () => {
                            if (next) {

                                let token = sessionStorage.getItem("ZeitroA")
                                const response = await fetch('/los/getcreditaccountconfig', {
                                    method: 'GET',
                                    headers: {
                                        Authorization: "Bearer " + token,
                                        Cache: "no-cache"
                                    }
                                })
                                if (response.status !== 200) {
                                    console.log('Looks like there was a problem. Status Code: ' +
                                        response.status)

                                    return
                                }
                                const js = await response.json()
                                console.log(js)
                                setAccountData(js.Accounts)
                                setNext(false)
                            } else {

                                try {
                                    setCheckLoading(true)
                                    let token = sessionStorage.getItem("ZeitroA")
                                    const response = await fetch(`/los/customercreditreport`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            LoanID: urlParams,
                                            Type: currentType[creditRadio]
                                        }),
                                        headers: {
                                            Authorization: "Bearer " + token,
                                            Cache: "no-cache"
                                        }
                                    })
                                    if (response.status !== 200) {
                                        const errorMessage = await response.text()
                                        console.log("Error response:", errorMessage)
                                        alert("We were unable to retrieve the credit report because the credit service returned the error message: " + errorMessage)
                                        setNext(true)
                                        setIsModalOpen(false)
                                        setCheckLoading(false)
                                        return
                                    }

                                    const responseBody = await response.text()
                                    if (responseBody.includes("AuthenticationFailure")) { // Check if response contains AuthenticationFailure
                                        const msg = checkAccess([UR_Owner])
                                            ? 'We were unable to retrieve the credit report because there was an authentication failure with your credit account. Please verify your credit account settings in the management portal.'
                                            : 'We were unable to retrieve the credit report because there was an authentication failure with your credit account. Please contact your admin to verify your credit account settings in the management portal.'

                                        alert(msg)
                                        setNext(true)
                                        setIsModalOpen(false)
                                        setCheckLoading(false)
                                        return
                                    }


                                    setNext(true)
                                    setIsModalOpen(false
                                    )
                                    getXml()
                                } catch (error) {
                                    console.log(error)
                                    alert('An unexpected error occurred. Please try again later.')
                                    setNext(true)
                                    setIsModalOpen(false)
                                    setCheckLoading(false)
                                }
                            }



                        }}>{!next ? 'Check now' : 'Next'}</Button>
                    </div>
                }
            >
                {next ? getContent() : getOrder()}
            </Modal>
            <Modal
                className='consent-confirmation-modal'
                mask={false}
                centered
                destroyOnClose
                zIndex={1001}
                width={516}
                open={isConsentOpen}
                title="Consent confirmation"
                footer={[
                    <Button className='consent-confirmation-btn' type='link' onClick={handleConsentClose}>
                      Cancel
                    </Button>,
                    <Button className='consent-confirmation-btn' key="submit" type="primary" disabled={!consentType} onClick={consentConfirm}>
                      Confirm
                    </Button>]}
                onCancel={handleConsentClose}
            >
                    <div className='consent-confirmation-content'>
                        <div className='consent-confirmation-content-tips'>Please maintain the necessary documentation evidencing the borrower’s consent if you obtain credit consent outside of Zeitro's borrower application.</div>
                        <div className='consent-confirmation-content-select'>
                            <div className='consent-select-label'>How was consent received?</div>
                            <Select
                                value={consentType}
                                style={{
                                    width: '100%',
                                }}
                                onChange={handleConsentType}
                                options={[
                                    {
                                    value: 'email',
                                    label: 'Email',
                                    }
                                ]}
                                placeholder="Please select"
                            />
                        </div>
                    </div>
            </Modal>
        </>

    )
    function getContent() {
        return <div className='borrowerContent'>
            <div>
                <div className='credit-check-title'>
                    Borrowers
                </div>
                <div className='credit-check-subtitle'>
                    Select the borrower(s) you would like to run credit for
                </div>
                <div>
                    <Checkbox.Group onChange={(e) => {

                        console.log(e)
                    }} value={isChecked} style={{
                        display: "flex", flexDirection: 'column', justifyContent: 'space-around',


                    }}>
                        {!creditConsentStatus.find(item => item.role === 'borrower')?.isConsent && !borrowerMarried() && <div className='credit-check-consented-title'>The following borrower(s) hasn't consented to access their personal credit information yet, so they will not be pulled.</div>}
                        <div 
                            style={{
                                border: !creditConsentStatus.find(item => item.role === 'borrower')?.isConsent && !borrowerMarried() ? '1px solid #CB8600' : '1px solid #ECECEC',
                                borderBottom: borrowerMarried() ? 'none' : '1px solid #ECECE',
                                borderBottomLeftRadius: borrowerMarried() ? '0' : '8px',
                                borderBottomRightRadius: borrowerMarried() ? '0' : '8px',
                                marginBottom: borrowerMarried() ? '0' : '16px',
                                paddingBottom: borrowerMarried() ? '0' : '20px',
                            }} 
                            className='credit-check-borrowers-box' onClick={() => {

                            const borrower = isChecked.includes('borrower')
                            if (borrower) {
                                setIsChecked(isChecked.filter((item) => item !== 'borrower'))
                            } else {
                                setIsChecked([...isChecked, 'borrower'])
                            }

                        }}>
                            {/* <Checkbox value='borrower'></Checkbox> */}
                            <div className='credit-check-borrowers-top'>

                                <div className='personal-information'>
                                    <span >{user.borrower.firstname} {user.borrower.lastname}</span> 
                                    <span style={{
                                        fontWeight: 400
                                    }}> · SSN {user.borrower.socialsecurity}</span>
                                </div>
                                <div className='credit-status'>
                                    Credit not pulled
                                </div>
                            </div>
                            <div style={{ color: "#586570", marginTop: '15px' }}>
                                <div className='credit-check-info-item'>
                                    <div className='info-item-label'>Date of birth:</div><div>{user.borrower.dateofbirth === '' ? '--' : formatDate(user.borrower.dateofbirth)}</div>
                                </div>
                                <div className='credit-check-info-item'>
                                    <div className='info-item-label'>Current address:</div><div>{user.borrower.presentaddressstreet === '' ? '--' : formatAddress('borrower')}</div>
                                </div>
                            </div>
                            <div className='credit-check-borrowers-bottom'>
                                <div style={creditConsentStatus.find(item => item.role === 'borrower')?.isConsent ? { backgroundColor: "#D4F8D3", color: '#138D2E' } : { backgroundColor: "#F8E8D3", color: '#CB8600' }} className='credit-consent-status'>
                                    <img src={`/images/credit-${creditConsentStatus.find(item => item.role === 'borrower')?.isConsent ? 'confirmed' : 'unconfirmed'}.svg`} /><span>Credit consent</span>
                                </div>
                                {!creditConsentStatus.find(item => item.role === 'borrower')?.isConsent && <div className='credit-consent-btn' onClick={() => handleConsent('borrower')}>Consent confirmation</div>}
                            </div>
                            {borrowerMarried() && <div style={{ height: 1, backgroundColor: '#ECECEC', width: '100%', marginTop: '20px' }}></div>}
                        </div>
                        {user.hascoborrower === 'withcoborrower' && (
                            <>
                                {!creditConsentStatus.find(item => item.role === 'coborrower')?.isConsent && !borrowerMarried() && <div className='credit-check-consented-title'>The following borrower(s) hasn't consented to access their personal credit information yet, so they will not be pulled.</div>}
                                <div 
                                    style={{
                                        border: !creditConsentStatus.find(item => item.role === 'coborrower')?.isConsent && !borrowerMarried() ? '1px solid #CB8600' : '1px solid #ECECEC',
                                        borderTop: borrowerMarried() ? 'none' : '1px solid #ECECE',
                                        borderTopLeftRadius: borrowerMarried() ? '0' : '8px',
                                        borderTopRightRadius: borrowerMarried() ? '0' : '8px',
                                    }}
                                    className='credit-check-borrowers-box' onClick={() => {
                                    const coborrower = isChecked.includes('coborrower')
                                    if (coborrower) {
                                        setIsChecked(isChecked.filter((item) => item !== 'coborrower'))
                                    } else {
                                        setIsChecked([...isChecked, 'coborrower'])
                                    }
        
                                }}>
                                    {/* <Checkbox value='coborrower' ></Checkbox> */}
                                    <div className='credit-check-borrowers-top'>
                                        <div className='personal-information'>
                                            <span >
        
                                                {user.coborrower.firstname} {user.coborrower.lastname}
                                            </span> <span style={{
                                                fontWeight: 400
                                            }}>·SSN {user.coborrower.socialsecurity}</span></div>
                                        <div className='credit-status'>
                                            Credit not pulled
                                        </div>
                                    </div>
                                    <div style={{ color: "#586570", marginTop: '15px' }}>
                                        <div className='credit-check-info-item'>
                                            <div className='info-item-label'>Date of birth:</div><div>{user.coborrower.dateofbirth === '' ? '--' : formatDate(user.coborrower.dateofbirth)}</div>
                                        </div>
                                        <div className='credit-check-info-item'>
                                            <div className='info-item-label'>Current address:</div><div>{user.coborrower.presentaddressstreet === '' ? '--' : formatAddress('coborrower')}</div>
                                        </div>
                                    </div>
                                    <div className='credit-check-borrowers-bottom'>
                                        <div style={creditConsentStatus.find(item => item.role === 'coborrower')?.isConsent ? { backgroundColor: "#D4F8D3", color: '#138D2E' } : { backgroundColor: "#F8E8D3", color: '#CB8600' }} className='credit-consent-status'>
                                            <img src={`/images/credit-${creditConsentStatus.find(item => item.role === 'coborrower')?.isConsent ? 'confirmed' : 'unconfirmed'}.svg`} /><span>Credit consent</span>
                                        </div>
                                        {!creditConsentStatus.find(item => item.role === 'coborrower')?.isConsent && <div className='credit-consent-btn' onClick={() => handleConsent('coborrower')}>Consent confirmation</div>}
                                    </div>
                                </div>
                            </>
                        )}
                        {borrowerMarried() && <div style={{ fontFamily: 'Inter', fontSize: 16, color: '#707070', lineHeight: '20px' }}>*A <span style={{textDecorationLine: 'underline'}}>joint credit pull</span> will be pulled for married co-borrowers.</div>}
                    </Checkbox.Group>

                </div>
            </div>

            <div className={`consent-confirmed-notice ${openNotice ? 'showNotice' : 'hideNotice'}`}>
                <div>Credit consent confirmed</div>
                <div className='consent-confirmed-notice-btn' onClick={() => setOpenNotice(false)}>Undo</div>
                <div className='consent-confirmed-notice-close' onClick={() => setOpenNotice(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="5.66406" y="19.8064" width="2" height="20" rx="1" transform="rotate(-135 5.66406 19.8064)" fill="white"/>
                        <rect x="4.25" y="5.66577" width="2" height="20" rx="1" transform="rotate(-45 4.25 5.66577)" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    }
    function getUserName(type) {

        if (accountData.length === 0) return ''
        const data = accountData.find(item => item.Type === type)

        if (
            !data
        ) {
            return ''
        } else {
            return <span>{vendorsList[data.Vendor]} - {data.Username}</span>

        }
    }
    function getOrder() {
        return <div className='borrowerContent'>
            <div>
                <div className='credit-check-title'>
                    New credit order

                </div>
                <div className='credit-check-subtitle'>
                    Enter the required information to order credit for your borrowers.
                </div>
                <div className='credit-check-select-label'>
                    What type of credit order you would like to run?
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Radio.Group style={{ display: "flex" }} value={creditRadio} onChange={(e) => {

                        setCreditRadio(e.target.value)

                    }}>
                        {
                            getUserName('SoftPull') !== '' && <label className='credit-check-select-radio' style={creditRadio === 'SoftPull' ? { borderColor: "#325CEB" } : {}}>
                                <Radio value='SoftPull'>Soft credit check</Radio>
                            </label>
                        }
                        {
                            getUserName('HardPull') !== '' && <label className='credit-check-select-radio' style={creditRadio === 'HardPull' ? { borderColor: "#325CEB" } : {}}>
                                <Radio value='HardPull'>Hard credit check</Radio>
                            </label>
                        }
                    </Radio.Group>
                </div>
                {
                    getUserName(creditRadio) === '' ? <></> : <div>
                        <div style={{ fontFamily: 'Inter', fontSize: 16, color: '#586570', lineHeight: 'normal', fontWeight: 500, marginTop: "18px" }}>
                            Your credit account
                        </div>
                        <div className='credit-check-account'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10.0007 9.99996C12.3018 9.99996 14.1673 8.13447 14.1673 5.83329C14.1673 3.53211 12.3018 1.66663 10.0007 1.66663C7.69947 1.66663 5.83398 3.53211 5.83398 5.83329C5.83398 8.13447 7.69947 9.99996 10.0007 9.99996Z" fill="#474849" />
                                <path d="M10.0008 12.0834C5.82578 12.0834 2.42578 14.8834 2.42578 18.3334C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3925 18.75 17.5758 18.5667 17.5758 18.3334C17.5758 14.8834 14.1758 12.0834 10.0008 12.0834Z" fill="#474849" />
                            </svg>
                            {getUserName(creditRadio)}
                        </div>
                    </div>
                }
                {/* <div className='ContentSelect' style={{ marginTop: "30px" }}>
                    Choose credit bureau(s)
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Checkbox.Group style={{ display: "flex" }} value={bureauRadio} onChange={(e) => {
                        setBureauRadio(e)
                    }}>
                        {
                            Bureau.map((item, index) => {

                                return (
                                    <div style={creditRadio === item.value ? { borderColor: "#325CEB" } : {}} className={index === 0 ? 'BureauRadio' : 'BureauRadio CreditRadioLeft'}>
                                        <Checkbox value={item.value}>
                                            <div>
                                                {getImg(item.value)}
                                            </div>
                                            <div>{item.value}</div>

                                        </Checkbox>

                                    </div>
                                )
                            })
                        }
                    </Checkbox.Group>
                </div> */}
            </div>
            {/* <div style={{ display: "flex", justifyContent: 'space-between', paddingTop: "10px", borderTop: "1px solid #ECECEC" }}>

                <Button type='link' onClick={() => {

                    setNext(true)
                }}> GO back</Button>
                <Button type='primary' onClick={async () => {

                    try {
                        setCheckLoading(true)
                        let token = sessionStorage.getItem("ZeitroA")
                        const response = await fetch(`/los/customercreditreport`, {
                            method: 'POST',
                            body: JSON.stringify({
                                LoanID: urlParams
                            }),
                            headers: {
                                Authorization: "Bearer " + token,
                                Cache: "no-cache"
                            }
                        })
                        await response.json()
                        setNext(true)
                        setIsModalOpen(false
                        )
                        getXml()
                    } catch (error) {
                        alert('An unexpected error occurred. Please try again later.')
                        setNext(true)
                        setIsModalOpen(false
                        )
                        setCheckLoading(false)
                    }

                }} loading={CheckLoading}>Check now</Button>
            </div> */}
        </div>


    }
    function getImg(title) {
        if (title.includes('Equifax')) {
            return <img style={{marginTop: 6}} src='/images/equifax.svg'></img>

        }
        if (title.includes('TransUnion') || title.includes('FICORiskScoreClassic')) {
            return <img src='/images/transunion.svg'></img>

        }
        if (title.includes('Experian')) {
            return <img src='/images/experian.svg'></img>

        }
    }
    function getName(title) {
        if (title.includes('Equifax')) {
            return 'Equifax'
        }
        if (title.includes('TransUnion') || title.includes('FICORiskScoreClassic')) {
            return 'TransUnion'

        }
        if (title.includes('Experian')) {
            return 'Experian'
        }
    }
    async function getXml() {

        const type = {
            CustomerSoftCreditReport: 'Soft credit pull',
            CustomerHardCreditReport: 'Hard credit pull'
        }
        let token = sessionStorage.getItem("ZeitroA")

        const response = await fetch(`/los/getcreditreport/${urlParams}`, {
            method: 'get',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const { Error, Status, CreditReports } = await response.json()
        // setXml(mockXML.CreditReportXML)
        console.log(Error, Status)
        // return
        if (Status === 'Error') {
            alert('An unexpected error occurred. Please try again later.')
            setParties([{
                credit: [],
                liabilities: [],
                id: "",
                name: "",
                type: ''

            },])
            return

        } else {

            setCreditReports(CreditReports.map((item, index) => {
                item.key = index
                item.label = <div>
                    {type[item.Type]} {dayjs(item.RequestedTime).format('MM/DD/YYYY HH:mm:ss')}
                </div>

                return item

            }))
            if (CreditReports.length === 0) {
                setParties([{
                    credit: [],
                    liabilities: [],
                    id: "",
                    name: "",
                    type: ''

                }])
            } else if (CreditReports.length === 1) {
                setXml(CreditReports[0].CreditReportXML)


            } else {
                const type = {
                    CustomerSoftCreditReport: 'Soft credit pull',
                    CustomerHardCreditReport: 'Hard credit pull'
                }
                const string = type[CreditReports[0].Type] + " " + new Date(CreditReports[0].RequestedTime).toLocaleDateString()
                setCurrentReports(string)
                setXml(CreditReports[0].CreditReportXML)

            }

        }


        // var xhr = new XMLHttpRequest()
        // xhr.open('GET', 'http://localhost:3001/error.xml', true)
        // // 当请求完成时触发的回调函数
        // xhr.onreadystatechange = async () => {
        //     if (xhr.readyState === 4 && xhr.status === 200) {
        //         // 请求成功，开始解析XML
        //         var parser = new DOMParser()
        //         var xmlDoc = parser.parseFromString(xhr.responseText, 'text/xml')
        //         const CREDIT_SCORES = xmlDoc.getElementsByTagName("CREDIT_SCORES")
        //         const CREDIT_LIABILITIES = xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")
        //         const CreditErrorMessageText = xmlDoc.getElementsByTagName('CreditErrorMessageText')
        //         const { error, content } = await getError(CreditErrorMessageText)
        //         console.log(error, content)
        //         setErrorData({
        //             error,
        //             content
        //         })
        //         let parties = xmlDoc.getElementsByTagName('PARTIES')
        //         const partiesList = await getParties(parties)
        //         const relationships = xmlDoc.getElementsByTagName('RELATIONSHIPS')[1].childNodes
        //         const shipParties = await getRelationships(relationships, partiesList)
        //         const to = CREDIT_SCORES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_SCORES")[0]?.childNodes : []
        //         const creditParties = await getCreditScores(to, shipParties)
        //         const liability = CREDIT_LIABILITIES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")[0].childNodes : []
        //         getCreditLiability(liability, creditParties)
        //         let pdfDom = xmlDoc.getElementsByTagName('FOREIGN_OBJECT')
        //         let summaryDom = xmlDoc.getElementsByTagName('CREDIT_SUMMARY')
        //         getPdfString(pdfDom)
        //         getSummary(summaryDom)


        //     } else if (xhr.status !== 200) {
        //         console.error('Error loading XML file.')
        //     }
        // }

        // // 发送请求
        // xhr.send()
    }
    async function setXml(xml) {

        var parser = new DOMParser()

        var xmlDoc = parser.parseFromString(xml, 'text/xml')
        const CREDIT_SCORES = xmlDoc.getElementsByTagName("CREDIT_SCORES")
        const CREDIT_LIABILITIES = xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")
        const CreditErrorMessageText = xmlDoc.getElementsByTagName('CreditErrorMessageText')
        const { error, content } = await getError(CreditErrorMessageText)
        console.log(error, content)
        setErrorData({
            error,
            content
        })
        let parties = xmlDoc.getElementsByTagName('PARTIES')
        const partiesList = await getParties(parties)
        const relationships = xmlDoc.getElementsByTagName('RELATIONSHIPS')[1].childNodes
        const shipParties = await getRelationships(relationships, partiesList)
        const to = CREDIT_SCORES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_SCORES")[0]?.childNodes : []
        const creditParties = await getCreditScores(to, shipParties)
        const liability = CREDIT_LIABILITIES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")[0].childNodes : []
        getCreditLiability(liability, creditParties)
        let pdfDom = xmlDoc.getElementsByTagName('FOREIGN_OBJECT')
        let summaryDom = xmlDoc.getElementsByTagName('CREDIT_SUMMARY')
        getPdfString(pdfDom)
        getSummary(summaryDom)

    }
    function getError(dom) {
        return new Promise((resolve, reject) => {
            if (dom.length === 0) {
                resolve({
                    error: false
                })
            } else {
                const content = dom[0].innerHTML

                resolve({
                    error: true,
                    content: content
                })
            }
        })
    }
    function getRelationships(dom, res) {
        return new Promise((resolve, reject) => {
            const userIdList = res
            for (let i = 0; i < dom.length; i++) {
                const element = dom[i]
                // console.log(element)
                for (let j = 0; j < userIdList.length; j++) {
                    const id = userIdList[j].id
                    if (id === element.getAttribute('d7p1:to')) {
                        userIdList[j].type.push(element.getAttribute('d7p1:from'))
                    }
                }
            }
            resolve(userIdList)
            setParties(userIdList)

        })

    }
    function getParties(dom) {
        return new Promise((resolve, reject) => {
            const element = dom[1].childNodes
            const userData = Array.from({ length: element.length - 1 }, () => {
                return {
                    name: "",
                    id: '',
                    type: [],
                    credit: [],
                    liabilities: []

                }
            })
            for (let i = 0; i < element.length; i++) {
                const parties = element[i].childNodes
                for (let j = 0; j < parties.length; j++) {
                    const party = parties[j]
                    if (party.tagName === 'INDIVIDUAL' && party.children[0].tagName !== 'NAME') {
                        break
                    }
                    if (party.tagName === 'INDIVIDUAL') {
                        userData.find((item, index) => index === i - 1).name = party.children[0].children[1].innerHTML
                    }
                    if (party.tagName === 'ROLES') {
                        userData.find((item, index) => index === i - 1).id
                            = party.childNodes[0].getAttribute('d7p1:label')
                    }
                }
            }

            setParties(userData)
            resolve(userData)
        })




    }
    function getPdfString(dom) {
        // console.log(dom, 99)
        for (let i = 0; i < dom.length; i++) {
            const element = dom[i].childNodes
            // console.log(element)
            for (let j = 0; j < element.length; j++) {
                const dom = element[j]
                if (dom.tagName === 'EmbeddedContentXML') {
                    setPdfString(dom.innerHTML)
                }
                if (dom.tagName === 'MIMETypeIdentifier' && dom.innerHTML === 'application/pdf') {
                    // console.log(dom)
                    // setPdfString(dom.innerHTML)
                }


            }

        }
    }
    function getSummary(dom) {
        const list = Array.from({ length: (dom[0].childNodes[0].childNodes.length - 2) / 5 }, () => {
            return {
                Balance: "",
                Count: '',
                Payment: "",
                PastDue: '',
                HighCredit: '',
                Type: "",

            }
        })
        for (let i = 0; i < dom.length; i++) {
            const element = dom[i].childNodes
            for (let j = 0; j < element.length; j++) {
                const elementDom = element[j]
                if (elementDom.tagName === 'CREDIT_SUMMARY_DETAIL') {
                    if (elementDom.children[0].innerHTML === 'TradeSummary') {
                        const summary = element[0].childNodes
                        const data = {
                            Balance: "",
                            Count: '',
                            Payment: "",
                            PastDue: '',
                            HighCredit: '',
                            Type: "",
                        }

                        for (let l = 0; l < summary.length; l++) {
                            const elementSummary = summary[l].childNodes

                            if (elementSummary[0].innerHTML.includes("LiabilityOldest")) {
                                continue
                            }
                            if (elementSummary[0].innerHTML.includes('Mortgage')) {
                                const type = 'Mortgage'
                                list.find((item, index) => index === 0).Type = 'Mortgage'
                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 0).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 0).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 0).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 0).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 0).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Installment')) {
                                const type = 'Installment'
                                list.find((item, index) => index === 1).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 1).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 1).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 1).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 1).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 1).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('InstallmentOther')) {
                                const type = 'InstallmentOther'
                                list.find((item, index) => index === 2).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 2).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 2).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 2).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 2).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 2).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Auto')) {
                                const type = 'Auto'
                                list.find((item, index) => index === 3).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 3).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 3).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 3).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 3).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 3).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Education')) {
                                const type = 'Education'
                                list.find((item, index) => index === 4).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 4).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 4).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 4).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 4).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 4).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Revolving')) {
                                const type = 'Revolving'
                                list.find((item, index) => index === 6).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 6).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 6).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 6).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 6).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 6).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Open')) {
                                const type = 'Open'
                                list.find((item, index) => index === 5).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 5).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 5).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 5).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 5).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 5).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Unknown')) {
                                const type = 'Unknown'
                                list.find((item, index) => index === 7).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 7).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 7).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 7).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 7).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 7).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('TotalLiability')) {
                                const type = 'TotalLiability'
                                list.find((item, index) => index === 8).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 8).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 8).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 8).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 8).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 8).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            // console.log(elementSummary[0].innerHTML)

                        }

                    }

                }

            }


        }
        setSummary(list)

    }
    function getCreditScores(to, partiesList) {
        return new Promise((resolve, reject) => {
            const list = partiesList
            for (let i = 0; i < to.length; i++) {
                const element = to[i]
                const creditId = element.getAttribute('d7p1:label')
                for (let j = 0; j < list.length; j++) {
                    const listElement = list[j]
                    if (listElement.type.includes(creditId)) {
                        const data = {
                            time: "",
                            value: '',
                            title: "",
                            factors: []
                        }
                        for (let index = 0; index < element.childNodes.length; index++) {
                            const elementNode = element.childNodes[index]
                            if (elementNode.tagName === 'CREDIT_SCORE_DETAIL') {
                                for (let k = 0; k < elementNode.childNodes.length; k++) {
                                    const elementChildNode = elementNode.childNodes[k]

                                    if (elementChildNode.tagName === 'CreditScoreDate') {
                                        data.time = elementChildNode.innerHTML
                                    }
                                    if (elementChildNode.tagName === 'CreditScoreValue') {
                                        data.value = elementChildNode.innerHTML.replace(/^\+?0*([1-9]\d*)/, '$1')
                                    }
                                    if (elementChildNode.tagName === 'CreditScoreModelNameType') {
                                        data.title = elementChildNode.innerHTML
                                    }

                                }
                            }
                            if (elementNode.tagName === 'CREDIT_SCORE_FACTORS') {
                                for (let k = 0; k < elementNode.childNodes.length; k++) {
                                    const elementChildNode = elementNode.childNodes[k].children
                                    data.factors.push(`${elementChildNode[0].innerHTML
                                        }-${elementChildNode[1].innerHTML}`)

                                }

                            }

                        }



                        listElement.credit.push(data)
                    }

                }

            }
            setParties(list)
            resolve(list)
        })

    }
    function getCreditLiability(to, creditList) {
        const list = creditList
        const CREDIT_LIABILITY = Array.from({ length: to.length }, () => {
            return {
                name: '',
                balance: '',
                payment: "",
                monthly: '',
                date: '',
                credit: '',
                account: "",
                filter: [],
            }
        })

        for (let i = 0; i < to.length; i++) {
            const Attribute = to[i].getAttribute('d7p1:label')
            const content = to[i].childNodes
            for (let f = 0; f < list.length; f++) {
                const data = {
                    name: '',
                    balance: '',
                    payment: "",
                    monthly: '',
                    date: '',
                    credit: '',
                    account: "",
                    filter: [],
                }
                const listElement = list[f]
                if (listElement.type.includes(Attribute)) {
                    for (let j = 0; j < content.length; j++) {
                        const creditor = content[j].childNodes
                        //name
                        if (content[j].tagName === 'CREDIT_LIABILITY_CREDITOR') {
                            // console.log(content[j])

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'NAME') {
                                    data.name = creditor[k].children[0].innerHTML

                                }
                            }
                        }
                        //balance
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityUnpaidBalanceAmount') {
                                    // console.log(creditor[k]);
                                    data.balance = creditor[k].innerHTML
                                }
                            }
                        }
                        //payment
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityMonthlyPaymentAmount') {
                                    // console.log(creditor[k]);
                                    data.payment = creditor[k].innerHTML
                                }
                            }
                        }
                        //monthly
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityTermsMonthsCount') {
                                    // console.log(creditor[k]);
                                    data.monthly = creditor[k].innerHTML
                                }
                            }
                        }
                        //date
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityAccountOpenedDate') {
                                    // console.log(creditor[k]);
                                    data.date = creditor[k].innerHTML
                                }
                            }
                        }
                        //credit
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLoanType') {
                                    // console.log(creditor[k]);
                                    data.credit = creditor[k].innerHTML
                                }
                            }
                        }
                        //account
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityAccountStatusType') {
                                    // console.log(creditor[k]);
                                    data.account = creditor[k].innerHTML
                                }
                            }
                        }
                        //filter
                        if (content[j].tagName === 'CREDIT_REPOSITORIES') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CREDIT_REPOSITORY') {

                                    data.filter.push(
                                        creditor[k].children[0].innerHTML
                                    )

                                }
                            }
                        }

                    }


                }
                listElement.liabilities.push(data)
            }

            for (let j = 0; j < content.length; j++) {
                const creditor = content[j].childNodes
                //name
                if (content[j].tagName === 'CREDIT_LIABILITY_CREDITOR') {
                    // console.log(content[j])

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'NAME') {
                            CREDIT_LIABILITY.find((item, index) => index === i).name = creditor[k].children[0].innerHTML
                        }
                    }
                }
                //balance
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityUnpaidBalanceAmount') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).balance = creditor[k].innerHTML
                        }
                    }
                }
                //payment
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityMonthlyPaymentAmount') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).payment = creditor[k].innerHTML
                        }
                    }
                }
                //monthly
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityTermsMonthsCount') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).monthly = creditor[k].innerHTML
                        }
                    }
                }
                //date
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityAccountOpenedDate') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).date = creditor[k].innerHTML
                        }
                    }
                }
                //credit
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLoanType') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).credit = creditor[k].innerHTML
                        }
                    }
                }
                //account
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityAccountStatusType') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).account = creditor[k].innerHTML
                        }
                    }
                }
                //filter
                if (content[j].tagName === 'CREDIT_REPOSITORIES') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CREDIT_REPOSITORY') {

                            CREDIT_LIABILITY.find((item, index) => index === i).filter.push(
                                creditor[k].children[0].innerHTML
                            )

                        }
                    }
                }

            }
        }
        const creditLoanTypeList = [...new Set(CREDIT_LIABILITY.map((item => item.credit)))].map((item) => {

            return {
                value: item,
                label: item
            }
        })
        const AccountStatusList = [...new Set(CREDIT_LIABILITY.map((item => item.account)))].map((item) => {

            return {
                value: item,
                label: item
            }
        }).filter((item) => item.value !== '')
        setAccountStatus(AccountStatusList)
        setCreditLoanType(creditLoanTypeList)
        setParties(list)
        // console.log(CREDIT_LIABILITY)
        // setCreditLiability(CREDIT_LIABILITY)
        console.log(list)


    }
}