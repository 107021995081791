import * as com from "../../../Common";

export interface Listing {
  key: number;
  address: string;
  state: string;
  county: string;
  city: string;
  zip: string;
  propertytype: string;
  price: string;
  incomeindicator: string;
  minority: string;
  totalpopulation: string;
  hispanic: string;
  black: string;
  asian: string;
  pacific: string;
  msa: string;
  daysOnMarket: string | null;
  agentName: string | null;
  agentPhone: string | null;
  agentEmail: string | null;
}

export const getListingOnCity = async (
  city: string,
  state: string,
  county: string,
  targetMsa?: string
): Promise<{
  success: boolean;
  listingUnavailable: boolean;
  data: Listing[];
}> => {
  try {
    // handle special case for Fairfield County, CT
    let countyName = county.replace("County", "").trim();
    if (
      (state == "CT" || state == "Connecticut") &&
      countyName == "Fairfield"
    ) {
      if (city == "Bridgeport") {
        countyName = "Greater Bridgeport";
      } else if (city == "Norwalk" || city == "Stamford") {
        countyName = "Western Connecticut";
      }
    }
    const response = await fetch("/los/gmcc/saleslistings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        City: city,
        State: com.convertStateToAbbr(state),
        County: countyName,
      }),
    });

    if (!response.ok) {
      alert("Failed to get listing data. Please try again later. in failed 1.");
      return {
        success: false,
        listingUnavailable: true,
        data: [],
      };
    }
    const responseJson = await response.json();
    let data: Listing[] = [];
    let listingUnavailable = false;

    if (responseJson === undefined || responseJson === null) {
      listingUnavailable = true;
    } else {
      data = responseJson.map((listing, index) => ({
        key: index,
        address: listing.FullAddress,
        state: listing.State,
        county: listing.County,
        city: listing.City,
        zip: listing.ZipCode,
        propertytype: listing.PropertyType,
        price: listing.Price.trim(),
        incomeindicator: listing.IncomeIndicator,
        minority: listing.MinorityPercent,
        totalpopulation: listing.TotalPopulation,
        hispanic: listing.Hispanic,
        black: listing.Black,
        asian: listing.Asian,
        pacific: listing.Pacific,
        msa: listing.MsaCode,
        daysOnMarket: listing.DaysOnMarket,
        agentName: listing.AgentName,
        agentPhone: com.fixPhoneInput(listing.AgentPhone),
        agentEmail: listing.AgentEmail,
      }));
      listingUnavailable = false;

      data.sort((a, b) => Number(a.price) - Number(b.price));
      if (targetMsa) {
        data = data.filter((x) => x.msa === targetMsa);
      }
    }

    return {
      success: true,
      listingUnavailable: listingUnavailable,
      data: data,
    };
  } catch (err) {
    console.log(err);

    alert("Failed to get listing data. Please try again later. in failed 2.");
    return {
      success: false,
      listingUnavailable: true,
      data: [],
    };
  }
};

export const getLoProfile = async (): Promise<{
  success: boolean;
  data?: {
    name: string;
    email: string;
    nmls: string;
    phone: string;
    liscenseStates: string[];
  };
}> => {
  try {
    const token = sessionStorage.getItem("ZeitroA");
    const response = await fetch("/los/getprofilesetup", {
      cache: "no-cache",
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-CustomerID": com.getCustomerId(),
        "X-LoID": com.getLoId(),
      },
    });

    if (!response.ok) {
      console.log(response);
    } else {
      const js = await response.json();
      let lo = js;
      let su = lo.LandingPageSetUp;
      let liscenseStates: string[] = [];
      if (typeof su.States === "undefined") {
        liscenseStates = [];
      } else {
        liscenseStates = su.States;
      }
      let name = lo.FirstName + " " + lo.LastName;

      return {
        success: true,
        data: {
          name: name,
          email: lo.Email,
          nmls: lo.NMLS,
          phone: lo.Phone,
          liscenseStates: liscenseStates,
        },
      };
    }
  } catch (error) {
    console.log("Revalidate catch error " + error);
  }
  return {
    success: false,
    data: undefined,
  };
};
