import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Profile.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropzone from './Photo/Dropzone'
import * as com from './Common'
import { Skeleton } from "antd";



class Profile extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.form1 = React.createRef();
        this.password = React.createRef();
        this.password1 = React.createRef();
        this.imageref = React.createRef();
        this.state = {
            validated: false,
            validated1: false,
            lastName: "",
            firstName: "",
            avatarsrc: "/avatar.png",
            email: "",
            password: "",
            password1: "",
            required: true,
            delete: false,
            nmls: "",
            licenseid: "",
            loading: true,
            introduction: "",
            loadingUpdate: false,
        }
        this.getProfile()
    }
    getProfile = ()=> {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/getprofile', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Please contact Zeitro for help.');
                    this.setState({ loading: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ loading: false })
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Error)
                    } else {
                        let lo = js.LoanOfficers[0]
                        console.log("profile info returned: %s ", JSON.stringify(js))
                        this.setState({
                            firstName: lo.FirstName, lastName: lo.LastName,
                            email: lo.Email, phone: lo.Phone, avatarsrc: lo.Image, nmls: lo.Nmls,
                            introduction: lo.Introduction, licenseid: lo.LicenseID,
                        })


                    }
                });
            }
        ).catch(function (err) {
            alert('Fetch Error :-S', err);
            this.setState({ loading: false })
        });
    }
    updateProfile = () => {
        this.setState({loadingUpdate: true})
        let token = sessionStorage.getItem("ZeitroA")
        let body = {
            email: this.state.email, firstname: this.state.firstName,
            lastname: this.state.lastName,
            phone: this.state.phone, nmls: this.state.nmls, image: this.state.avatarsrc,
            introduction: this.state.introduction, licenseid: this.state.licenseid,
        }

        fetch('/los/updateprofile', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({loadingUpdate: false})
                    alert('Looks like there was a problem. Please contact Zeitro for help.');
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({loadingUpdate: false})
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        alert('Looks like there was a problem. Please contact zeitro for help.');
                    } else {
                        alert("Your profile information has been updated.")
                        window.location.reload()
                    }
                });
            }
        ).catch(function (err) {
            this.setState({loadingUpdate: false})
            alert('Fetch Error :-S', err);
        });
    }
    updatePassword = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { password: this.state.password }

        fetch('/los/updatepassword', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                    } else {
                        alert("Password updated successfully")
                    }
                });
            }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }
    handleSubmit = (event) => {

        if (this.form.current.reportValidity() === false) {

            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        this.updateProfile()
        return false
    }

    handleSubmit1 = (event) => {
        this.checkPasswordValidity()
        if (this.form1.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated1: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated1: false })
        event.stopPropagation();
        this.updatePassword()

        return false
    }

    checkPasswordValidity = () => {
        console.log("%s, %s", this.state.password, this.state.password1)
        if (this.state.password !== this.state.password1) {
            this.password1.current.setCustomValidity("Passwords need to match")
        } else {
            if (this.state.password.length < 8) {

                this.password1.current.setCustomValidity("Password must have at least 8 characters")

            } else
                this.password1.current.setCustomValidity("")
        }
    }
    onpassword = e => {
        console.log(e.target.value)
        this.setState({ password: e.target.value })
        //this.state.password = e.target.value
        this.checkPasswordValidity()
    }
    onpassword1 = e => {
        this.setState({ password1: e.target.value })
        //this.state.password1 = e.target.value
        this.checkPasswordValidity()
    }
    changeFirstName = (e) => {
        this.setState({ firstName: e.target.value })
    }
    changeLastName = (e) => {
        console.log(e.target.value)
        this.setState({ lastName: e.target.value })
    }
    changeUsername = (e) => {
        console.log(e.target.value)
        this.setState({ email: e.target.value })
    }
    changeNMLS = (e) => {
        this.setState({ nmls: e.target.value })
    }
    changeLicenseID = (e) => {
        this.setState({ licenseid: e.target.value })
    }
    onFilesAdded = (files) => {
        let file = files[0]
        let reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (e) => {
            // Render thumbnail
            this.setState({ avatarsrc: e.target.result })
            this.imageref.current.src = e.target.result
        }


        // Read in the image file as a data URL.
        reader.readAsDataURL(file);
    }
    changePhone = (e) => {
        let phone = com.fixPhoneInput(e.target.value)
        this.setState({ phone: phone })
    }
    render() {
        if (this.state.loading) {
            return (
                <div className="w-90 my-5 py-5">
                    <Skeleton active />
                </div>
            )
        } else
            return (
                <div className="text-center mt-0 px-5  border-left border-right text-body">

                    <Form ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                        <Row className="mt-3">
                            <Col md="auto" style={{display: 'flex', justifyContent: 'center'}}><Dropzone
                                disabled={false}
                                onFilesAdded={this.onFilesAdded}
                                src={this.state.avatarsrc}
                                imageref={this.imageref}
                            />
                            </Col>
                            <Col className="text-left pt-1 upload-profile-btn">
                                <h4 style={{marginBottom: 24}}>Your Profile</h4>
                                <Dropzone
                                    disabled={false}
                                    type="button"
                                    btnText="Add your photo"
                                    onFilesAdded={this.onFilesAdded}
                                    src={this.state.avatarsrc}
                                    imageref={this.imageref}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2 pt-2 border-top border-dark">
                            <Col xs={12} md className="text-left">
                                <Form.Group controlId="firstname" className="text-left" >
                                    <Form.Label className="text-left" >First Name:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="firstname"
                                        type="text"
                                        defaultValue={this.state.firstName}
                                        onChange={this.changeFirstName}
                                        pattern="^[\w\s-@.,\/#&]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your first name.
                      </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md className="text-left">
                                <Form.Group controlId="lastname" className="text-left" >
                                    <Form.Label className="text-left" >Last Name:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="lastname"
                                        type="text"
                                        defaultValue={this.state.lastName}
                                        onChange={this.changeLastName}
                                        pattern="^[\w\s-@.,\/#&]+$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your last name.
                      </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-left mt-3 mb-1 ">
                            <Col xs={12}  md className="text-left">
                                <Form.Group controlId="username" className="text-left" >
                                    <Form.Label className="text-left" >Email:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="username"
                                        type="text"
                                        defaultValue={this.state.email}
                                        onChange={this.changeUsername}
                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"

                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide a valid email address.
                      </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md className="text-left">
                                <Form.Group controlId="contactphone" className="text-left" >
                                    <Form.Label className="text-left" >Contact Phone:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="contactphone"
                                        type="text"
                                        value={this.state.phone}
                                        placeholder="(###)-###-####"
                                        onChange={this.changePhone}
                                        pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide your contact phone.
                            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="text-left mt-3 mb-1 ">
                            <Col xs={12} md className="text-left">
                                <Form.Group controlId="username" className="text-left" >
                                    <Form.Label className="text-left" >NMLS License:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name="username"
                                        type="text"
                                        defaultValue={this.state.nmls}
                                        onChange={this.changeNMLS}
                                        pattern="^[0-9]{1,7}$"

                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide a valid nmls number.
                      </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="text-left">
                                <Form.Group controlId="licenseid" className="text-left" >
                                    <Form.Label className="text-left" >State License ID:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        name="licenseid"
                                        type="text"
                                        defaultValue={this.state.licenseid}
                                        onChange={this.changeLicenseID}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide a valid license id.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-left mt-3 mb-1 ">
                            <Col  md className="text-left">
                                <Form.Group controlId="introduction" className="text-left" >
                                    <Form.Label className="text-left" >Introduction:</Form.Label>
                                    <Form.Control size="sm"
                                        name="introduction"
                                        as="textarea" 
                                        rows={3}
                                        defaultValue={this.state.introduction}
                                        onChange={(e)=>this.setState({introduction: e.target.value})}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="text-center pt-3 pb-3  ">
                            <Col xs={12} md className="text-center" md-auto="true">
                                <Button variant="primary" type="submit" className="text-center ml-3"  >
                                {this.state.loadingUpdate ? <div className="p-5"><Skeleton active/></div> : "Update Profile"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <Form ref={this.form1} id="asswordpform" noValidate validated={this.state.validated1} onSubmit={this.handleSubmit1}>
                        <Row className="text-left mt-3 mb-1 pt-2 border-top border-dark">
                            <Col xs={12} md className="text-left">
                                <Form.Group controlId="newpassword" className="text-left" >
                                    <Form.Label className="text-left" >Password:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        ref={this.password}
                                        name="newpassword"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.onpassword}
                                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Password should be 8-20 characters long with a number, mix of small and large letters.
            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md className="text-left">
                                <Form.Group controlId="newpassword1" className="text-left" >
                                    <Form.Label className="text-left" >Retype Password:</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        ref={this.password1}
                                        name="newpassword1"
                                        type="password"
                                        value={this.state.password1}
                                        onChange={this.onpassword1}
                                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Passwords should be identical.
            </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-center pt-3 pb-3  ">
                            <Col xs={12} md className="text-center" md-auto="true">
                                <Button variant="primary" type="submit" className="text-center ml-3"  >&nbsp;&nbsp;Update Password&nbsp;&nbsp;</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )
    }
}
export default Profile
