import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useStatsigClient } from "@statsig/react-bindings/src";

const GMCCDismond = (props) => {
    const src = "https://hub.collateralanalytics.com/correspondentsearch";

    //statsig logEvent
    const { client } = useStatsigClient();
    const handleClick = () => {
        const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
        client.logEvent("use_diamond", src, {
            item_name: "useDiamond",
            action: "clickHref",
            link: src,
            startTime: (new Date).toString(),
            timestamp: Date.now().toString(),
            customerid: savedUserInfo.custom.customerid,
            email: savedUserInfo.email,
        });
    }
    return <div className="p-5 gmccDiamond">
        <div className="title-3">GMCC Diamond Community Lending</div>
        <a
            className="aAsLink mt-3"
            target="_blank"
            href={src}
            onClick={handleClick}
        >
            {src}
        </a>
        <div className="title-5 mt-3">GMCC Diamond Community Lending area, subject to change without advance notice. Result are Estimate only.</div>
        {/* <iframe src={iframeSrc} style={{width: '100%'}} title="GMCC Diamond Community Lending" sandbox='allow-scripts allow-modal' loading='eager'></iframe> */}
    </div>
}
export default withRouter(GMCCDismond);
