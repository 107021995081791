import React, { useEffect, useRef, useState } from "react";
import { Carousel, Checkbox, message, Popover, Spin, Table } from "antd";
// react-bootstrap is used for form elements and form submission
import { Row, Col, Form, Button } from "react-bootstrap";
import { ColumnsType } from "antd/es/table";
import { LeftOutlined } from "@ant-design/icons";

import * as com from "../../../Common";
import {
  DiamondCommunityLendingStates,
  EProgramType,
  fifteenK_states,
  fiveK_states,
  MSAtoCountyList_conforming,
  MSAtoCountyList_grandslam,
  MSAtoCountyList_grandslam_full,
  MSAtoCountyList_nonagency_full,
  MSAtoCountyList_nonagency,
  tenK_states,
} from "../programs_consts";
import {
  getCityOptions,
  getCountyOptionsFromMsaProgramState,
  getCountyOptionsFromState,
  getMSAList,
} from "../programs_utils";
import FlyerGenerator from "../../FlyerGenerator";
import {
  programs_templateMap,
  programs_pricesheetMap,
} from "../../GMCCProgramEngine";
import { getQualifiedProgramsForListing, programTypeOptions } from "./utils";
import RealtorPopconfirm from "./RealtorPopconfirm";
import { getListingOnCity, getLoProfile, Listing } from "./db_utils";
import Filters from "./Filters";
import ListingPopover from "./ListingPopover";
import { useStatsigClient } from "@statsig/react-bindings/src";

const programsToStateToMSAMap = {
  [EProgramType.GRANDSLAM]: MSAtoCountyList_grandslam,
  [EProgramType.CONFORMING]: MSAtoCountyList_conforming,
  [EProgramType.NONAGENCY]: MSAtoCountyList_nonagency,
};

const programsToStateToMSAMapFull = {
  [EProgramType.GRANDSLAM]: MSAtoCountyList_grandslam_full,
  [EProgramType.CONFORMING]: MSAtoCountyList_conforming,
  [EProgramType.NONAGENCY]: MSAtoCountyList_nonagency_full,
};

interface IMarketByProgramCheckProps {
  gotoCelebrity: () => void;
  chosenMode?: string;
}

const MarketByProgramCheck = (props: IMarketByProgramCheckProps) => {
  //check if screenwidth is less than 875px
  const isPad = window.innerWidth < 875;
  // This is used for the message api to display a message when some event happens
  const [messageApi, contextHolder] = message.useMessage();

  // Set program related states
  const [programType, setProgramType] = useState<EProgramType | "">(""); // the type of the program

  // Set MSA related states
  const [selectedMsa, setSelectedMsa] = useState("");

  // Set property related states
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [onlyShowExtraExtraIncentive, setOnlyShowExtraExtraIncentive] =
    useState(false);

  // Set realtor related states
  const [realtorInfoFilled, setRealtorInfoFilled] = useState(false);
  const [realtorName, setRealtorName] = useState("");
  const [realtorID, setRealtorID] = useState("");
  const [realtorEmail, setRealtorEmail] = useState("");
  const [realtorPhone, setRealtorPhone] = useState("");
  const [dreID, setDreID] = useState("");
  const [haveDRE, setHaveDRE] = useState(false);

  // Carousel related states, refs and functions
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const goToNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
    carouselRef.current.next();
  };
  const goToPrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
      carouselRef.current.prev();
    }
  };

  // This is used to copy the address to the clipboard
  const copyTarget = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        messageApi.success("copy success!");
      })
      .catch((error) => {
        messageApi.error("copy error!");
      });
  };

  // Loan Officer related states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nmls, setNMLS] = useState("");
  const [phone, setPhone] = useState("");
  const [liscenseStates, setLiscenseStates] = useState([]);
  const { client } = useStatsigClient();

  useEffect(() => {
    getLoProfile().then((res) => {
      if (res.success) {
        setName(res.data.name);
        setEmail(res.data.email);
        setNMLS(res.data.nmls);
        setPhone(res.data.phone);
        setLiscenseStates(res.data.liscenseStates);
      }
    });
  }, []);

  const onProgramTypeChange = (e) => {
    setProgramType(e.target.value);
    setPropertyState("");
    setSelectedMsa("");
    setPropertyCounty("");
    setPropertyCity("");
  };

  const onPropertyStateChange = (e) => {
    setPropertyState(e.target.value);
    setSelectedMsa("");
    setPropertyCounty("");
    setPropertyCity("");
  };

  const onMSAChange = (e) => {
    setSelectedMsa(e.target.value);
    setPropertyCounty("");
    setPropertyCity("");
  };

  const onPropertyCountyChange = (e) => {
    setPropertyCounty(e.target.value);
    setPropertyCity("");
  };

  const onPropertyCityChange = (e) => {
    setPropertyCity(e.target.value);
  };

  // Listing related states
  const [listingData, setListingData] = useState<Listing[]>([]);
  const [filteredListingData, setFilteredListingData] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 9,
    showSizeChanger: false,
  });
  const [listingTarget, setListingTarget] = useState("");
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<Listing | null>(null);
  const listingColumns = [
    {
      title: "MSA",
      dataIndex: "msa",
      key: "msa",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <div>${com.commaize(text)}</div>,
    },
    {
      title: "Days on market",
      dataIndex: "daysOnMarket",
      key: "daysOnMarket",
      sorter: (a, b) => (a.daysOnMarket || 0) - (b.daysOnMarket || 0),
      render: (text) => (
        <div style={{ whiteSpace: "nowrap" }}>{text ? `${text}` : ""}</div>
      ),
    },
    {
      title: "Active listing address",
      dataIndex: "address",
      key: "address",
      width: "40%",
      render: (text, record: Listing) => (
        <Popover
          rootClassName="listingPopover"
          content={<ListingPopover record={record} />}
          title={null}
          trigger="hover"
          open={hoveredRow === record.key}
        >
          <div className="d-flex justify-content-between">
            <div>{text}</div>
            <div className="d-flex">
              {(record.incomeindicator === "1" ||
                record.incomeindicator === "2") && (
                <div className="blueBulb mr-2">Low-to-moderate</div>
              )}
              {parseInt(record.minority) > 50 && (
                <div className="blueBulb">In MMCT</div>
              )}
            </div>
          </div>
        </Popover>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Zip code",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "Property type",
      dataIndex: "propertytype",
      key: "propertytype",
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const onGetListingOnCity = (e) => {
    // statsig logEvent
    const savedUserInfo = JSON.parse(
      sessionStorage.getItem("statsig_user_info")
    );
    client.logEvent("use_programs_map", "marketCheck", {
      item_name: "useProgramsMap",
      action: "clickGetListings",
      startTime: new Date().toString(),
      timestamp: Date.now().toString(),
      customerid: savedUserInfo.custom.customerid,
      email: savedUserInfo.email,
      type: "marketCheck",
    });

    // prevent page from refreshing
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    getListingOnCity(
      propertyCity,
      propertyState,
      propertyCounty,
      selectedMsa
    ).then((res) => {
      if (res.success) {
        setListingData(res.data);
        setFilteredListingData(res.data);
      } else {
        messageApi.error(
          "Failed to get listing data. Please try again later. in failed 3."
        );
      }
      setLoading(false);
    });
  };

  // Search results related states when clicking "Check" button
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const handleMarketByProgramFormSubmit = (e) => {
    // statsig logEvent
    const savedUserInfo = JSON.parse(
      sessionStorage.getItem("statsig_user_info")
    );
    client.logEvent("use_programs_map", "marketCheck", {
      item_name: "useProgramsMap",
      action: "clickSearch",
      startTime: new Date().toString(),
      timestamp: Date.now().toString(),
      customerid: savedUserInfo.custom.customerid,
      email: savedUserInfo.email,
      type: "marketCheck",
    });

    e.preventDefault();
    e.stopPropagation();

    const { flierOptions, results } = getQualifiedProgramsForListing(
      selectedRecord,
      propertyState,
      propertyCounty,
      listingTarget,
      selectedMsa
    );
    setSearchResults(results);
    setLoading(false);
    goToNextSlide();
  };

  return (
    <div className="fastSectionWrap" style={{ overflowY: "scroll" }}>
      {contextHolder}
      <Carousel
        id="massiveFormCarousel"
        ref={carouselRef}
        afterChange={setCurrentSlide}
        dots={false}
        effect="fade"
        touchMove={false}
      >
        <div className="scenarioSlide">
          <div className="checkPaneTitle">Market by Program Check</div>
          <Form
            className="fastFormLayout"
            /** use onSubmit to query city listing data to check if there is
             * any required button that hasn't been selected
             */
            onSubmit={onGetListingOnCity}
          >
            <div className="mobileScrollControl">
              <Row style={{ marginBottom: "20px", justifyContent: "center" }}>
                {/* Select program type */}
                <Col xs={12} md={isPad ? 12 : 2}>
                  <Form.Group>
                    <Form.Label className="text-left">Program type</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      required
                      onChange={onProgramTypeChange}
                      value={programType}
                    >
                      {programTypeOptions.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* Select property state */}
                <Col xs={12} md={isPad ? 12 : 2}>
                  <Form.Label className="text-left">Property state</Form.Label>
                  <Form.Group>
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      defaultValue=""
                      onChange={onPropertyStateChange}
                      value={propertyState}
                    >
                      <option value="">Select a state</option>
                      {!!programsToStateToMSAMap[programType] &&
                        (onlyShowExtraExtraIncentive
                          ? Object.keys(
                              programsToStateToMSAMap[programType]
                            ).map((state) => (
                              <option value={state}>{state}</option>
                            ))
                          : Object.keys(
                              programsToStateToMSAMapFull[programType]
                            ).map((state) => (
                              <option value={state}>{state}</option>
                            )))}
                      {programType === EProgramType.DIAMOND &&
                        DiamondCommunityLendingStates.map((state) => (
                          <option value={com.convertStateToAbbr(state)}>
                            {com.convertStateToAbbr(state)}
                          </option>
                        ))}
                      {programType === EProgramType.CELEBRITY &&
                        [
                          ...fiveK_states,
                          ...tenK_states,
                          ...fifteenK_states,
                        ].map((state) => (
                          <option value={com.convertStateToAbbr(state)}>
                            {com.convertStateToAbbr(state)}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* Select property MSA */}
                <Col xs={12} md={isPad ? 12 : 2}>
                  <Form.Group>
                    <Form.Label className="text-left">MSA</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={onMSAChange}
                      value={selectedMsa}
                    >
                      {getMSAList(
                        programType,
                        propertyState,
                        onlyShowExtraExtraIncentive
                      ).map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col xs={12} md={isPad ? 12 : 2}>
                  <Form.Group>
                    <Form.Label className="text-left">
                      Property county
                    </Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={onPropertyCountyChange}
                      value={propertyCounty}
                    >
                      {getCountyOptionsFromMsaProgramState(
                        selectedMsa,
                        programType,
                        propertyState,
                        onlyShowExtraExtraIncentive
                      ).map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={isPad ? 12 : 2}>
                  <Form.Group>
                    <Form.Label className="text-left">Property city</Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      as="select"
                      onChange={onPropertyCityChange}
                      value={propertyCity}
                    >
                      {getCityOptions(propertyState, propertyCounty).map(
                        (option) => (
                          <option value={option.value}>{option.label}</option>
                        )
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <div className="d-flex align-items-center pl-2">
                  <Button type="submit" className="programEngineSearchLong">
                    {loading ? (
                      <div className="gmccSpin">
                        <Spin />
                      </div>
                    ) : (
                      "Get listings"
                    )}
                  </Button>
                </div>
              </Row>

              {programType === EProgramType.NONAGENCY && (
                <Row style={{ marginBottom: "10px", justifyContent: "center" }}>
                  <Checkbox
                    onChange={(event) => {
                      setOnlyShowExtraExtraIncentive(event.target.checked);
                      onPropertyStateChange({ target: { value: "" } });
                    }}
                  >
                    Only show extra extra incentive
                  </Checkbox>
                </Row>
              )}

              <div className="d-flex align-items-center justify-content-between">
                <Filters
                  listingData={listingData}
                  pagination={pagination}
                  setFilteredListingData={setFilteredListingData}
                  setPagination={setPagination}
                />
                <div className="text-center mt-2 mr-3 d-flex justify-content-center align-items-center">
                  <div className="massiveTargetInfo mr-3">
                    {listingTarget === ""
                      ? "Select an address to check"
                      : `Checking for ${listingTarget}`}
                  </div>
                  <Button
                    disabled={listingTarget === ""}
                    className="programEngineSearch"
                    onClick={handleMarketByProgramFormSubmit}
                  >
                    {loading ? (
                      <div className="gmccSpin">
                        <Spin />
                      </div>
                    ) : (
                      "Check"
                    )}
                  </Button>
                </div>
              </div>

              <div className="px-3 mt-2">
                <Table
                  columns={listingColumns as ColumnsType<Listing>}
                  dataSource={filteredListingData}
                  pagination={pagination}
                  onRow={(record) => ({
                    onClick: () => {
                      setListingTarget(record.address);
                      setRealtorInfoFilled(true);
                      setRealtorEmail(record.agentEmail);
                      setRealtorPhone(record.agentPhone);
                      setRealtorName(record.agentName);
                      copyTarget(record.address);
                      setSelectedRecord(record);
                      // setPropertyAddress(record.address.split(",")[0])
                      // setPropertyState(com.mapAbbrFullName[record.state])
                      // setPropertyCity(record.city)
                      // setPropertyZip(record.zip)
                      // setPropertyCounty(record.county)
                    },
                    onMouseEnter: () => {
                      setHoveredRow(record.key);
                    },
                    onMouseLeave: () => {
                      setHoveredRow(null);
                    },
                  })}
                  rowClassName="listingTableRow"
                  scroll={{ x: 1800, y: 400 }}
                  className="listingTable"
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </Form>
        </div>
        <div className="scenarioSlide">
          <div
            className="fastResultMobileView"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="resultPaneHeader">
              <div
                className="text-left"
                style={{ cursor: "pointer" }}
                onClick={() => goToPrevSlide()}
              >
                <LeftOutlined className="mr-2" rev={null} />
                Back
              </div>
              {propertyState === "California" && (
                <div>
                  <div className="chooseFliersWrap">
                    <div style={{ fontWeight: "500" }}>
                      Enter your DRE ID as needed for DRE endorsement:
                    </div>
                    <input
                      value={dreID}
                      onChange={(e) => setDreID(e.target.value)}
                      onBlur={(e) => setHaveDRE(e.target.value !== "")}
                      placeholder="enter your DRE ID here"
                      className="dreIDInput"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="text-center resultHeading mt-2">
              {searchResults.length} programs found:
            </div>
            <RealtorPopconfirm
              realtorName={realtorName}
              realtorEmail={realtorEmail}
              realtorPhone={realtorPhone}
              setRealtorInfoFilled={setRealtorInfoFilled}
              setRealtorName={setRealtorName}
              setRealtorID={setRealtorID}
              setRealtorEmail={setRealtorEmail}
              setRealtorPhone={setRealtorPhone}
            />
            <div
              className="massiveResultWrapMobile"
              style={{ marginTop: "10px", padding: "0px 10px 0px 10px" }}
            >
              {searchResults.map((result, idx) => {
                return (
                  <div className="massiveResultContainer">
                    <div style={{ fontWeight: "500", fontSize: "16px" }}>
                      {result.label}
                    </div>
                    {/*Massive check flyergenerator*/}
                    <FlyerGenerator
                      disabled={false}
                      buttonClass="downloadFlierLink"
                      display="none"
                      programName={`${result.label} program`}
                      buttonLabel="Download my flyer"
                      cta="Apply with me now for this qualified address!"
                      addressInfo={listingTarget}
                      name={name}
                      nmls={nmls}
                      dre={dreID !== "" ? "DRE# " + dreID : ""}
                      email={email}
                      phone={phone}
                      reName={realtorName}
                      reID={realtorID}
                      rePhone={realtorPhone}
                      reEmail={realtorEmail}
                      hasRE={realtorInfoFilled}
                      gotoCelebrity={props.gotoCelebrity}
                      imgsrc={`/images/gmccflyers/${
                        programs_templateMap[result.label]
                      }`}
                      ratesheet={
                        programs_pricesheetMap[result.label]
                          ? "/images/gmccflyers/" +
                            programs_pricesheetMap[result.label]
                          : ""
                      }
                      loID={com.getLoId()}
                      chosenMode={props.chosenMode}
                    />
                  </div>
                );
              })}
            </div>

            {searchResults.length === 0 && (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#EAEFFD",
                  height: "400px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
                <div
                  className="text-center"
                  style={{ fontSize: "24px", fontWeight: "500" }}
                >
                  Could not find matching program results, please try adjust
                  your scenario and search again.
                </div>
              </div>
            )}
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default MarketByProgramCheck;
