import React, { useState, useEffect } from "react"
import {
    Form,
    Input,
    Button,
    Col,
    Row,
    Carousel,
    Spin,
    Modal,
    Checkbox
} from "antd"
import { RightOutlined } from "@ant-design/icons"
import * as com from "./Common.js"
import EmailVerification from "./Application/EmailVerification.js"
import { RecaptchaV3, RecaptchaV3SiteKey } from "./Recaptcha.js"
import { Redirect, withRouter } from "react-router-dom"
import {
    UR_LoanOfficerManager,
    UR_LoanProcessor,
    UR_Owner,
    UR_PriceEngineUser,
} from "./State.js"
import { checkAccessByToken } from "./Auth.js"
import "./LoginPage.css"
import cookie from 'react-cookies'
import LoginPage from './LoginGooglePage.js'
import { useSelector, useDispatch } from 'react-redux'
import { useStatsigClient } from "@statsig/react-bindings/src";
import {updateStatsigUser} from './statsig/statsigMethod.js'

function Login(props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [msg, setMsg] = useState("")
    const [showEmailVerification, setShowEmailVerification] = useState(false)
    const [customerid, setCustomerid] = useState("")
    const [redirect, setRedirect] = useState("")
    const [remember, setRemember] = useState(false)
    const [companyName, setCompanyName] = useState("")
    const [status, setStatus] = useState(2) // 1 customerId  2 Continue 3 Sign in 4 Reset your password 5 success
    const [showChooseCompany, setShowChooseCompany] = useState(false)
    const [customerIDs, setCustomerIDs] = useState([])
    const [customerType, setCustomerType] = useState("one")
    const [operationType, setOperationType] = useState("login")
    const formInitial = {}
    const dispatch = useDispatch()
    const { client } = useStatsigClient();
    useEffect(() => {
        if (cookie.load("remember")) {
            let cache = cookie.load("remember")
            setRemember(true)
            form.setFieldsValue({
                email: cache.username,
                password: cache.password
            })
        }
        dispatch({ type: "OPEN", data: false })
    }, [])

    const getcustomeridsbyemail = () => {
        let tosend = JSON.stringify({
            Email: form.getFieldValue('email').toLowerCase(),
        })
        setLoading(true)
        fetch("/los/getcustomeridsbyemail", {
            method: "POST",
            body: tosend,
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                if (response.status !== 200) {
                    setLoading(false)
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    setLoading(false)
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        setMsg(js.Text)
                    } else {
                        const filteredCustomerIDs = js.CustomerIDs.filter(customer => customer.CustomerID !== "individualcustomers")
                        if (filteredCustomerIDs.length === 0) {
                            setCustomerType('')
                            setCustomerid('')
                            setCustomerIDs([])
                            setShowChooseCompany(true)
                        } else if (filteredCustomerIDs.length === 1) {
                            setCustomerType('one')
                            setCustomerid(filteredCustomerIDs[0].CustomerID)
                            localStorage.setItem("customerid", filteredCustomerIDs[0].CustomerID)
                            setCompanyName(filteredCustomerIDs[0].CompanyName)
                            setStatus(prev => prev + 1)
                        } else {
                            setCustomerType('multiple')
                            setCustomerid('')
                            setCustomerIDs(filteredCustomerIDs)
                            setStatus(prev => prev + 1)
                        }
                    }
                })
            })
            .catch((err) => {
                setLoading(false)
                console.log("Fetch Error :-S", err)
            })
    }

    const onFinish = (val) => {
        if (status === 2) {
            getcustomeridsbyemail()
        } else if (status === 3) {
            setOperationType('login')
            if (customerType === 'one') {
                setLoading(true)
                window.grecaptcha.ready(() => {
                    setLoading(false)
                    window.grecaptcha
                        .execute(RecaptchaV3SiteKey, { action: "submit" })
                        .then((token) => {
                            login(token, customerid)
                        })
                })
            } else {
                setShowChooseCompany(true)
            }
        } else if (status === 5) {
            setStatus(3)
        }
    }
    const signinCode = () => {
        setOperationType('code')
        if (customerType === 'one') {
            checkEmail(customerid)
        } else {
            setShowChooseCompany(true)
        }
    }
    const checkEmail = (id) => {
        let tosend = JSON.stringify({
            email: form.getFieldValue('email').toLowerCase(),
            customerid: id,
        })
        setLoading(true)
        fetch("/los/checksigninemail", {
            method: "POST",
            body: tosend,
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                if (response.status !== 200) {
                    setLoading(false)
                    console.log("response: ")
                    response.text().then((errorText) => {
                        let title = 'An internal error has occurred'
                        let content = 'Please try again later or contact contact@zeitro.com for assistance.'
                        console.log('errorText: ' + errorText)
                        // check if customerid does not exist in database
                        if (errorText.includes('relation') && errorText.includes('does not exist')) {
                            title = 'Invalid Company ID'
                            content = 'The Company ID you entered is invalid. Please check your Company ID and try again.'
                        }
                        Modal.info({
                            title: title,
                            content: content,
                        })
                        return
                    })
                    return
                }
                response.json().then((js) => {
                    if (js.Status !== "OK") {
                        Modal.info({
                            title: 'The email has not been registered yet',
                            content: 'Please check your email or contact your company administrator for assistance.',
                        })
                    } else {
                        setShowEmailVerification(true)
                    }
                    setLoading(false)
                })
            })
            .catch(function (err) {
                setLoading(false)
                console.log("Fetch Error :-S", err)
            })
    }
    const handleClose = () => {
        setShow(false)
    }
    const login = (token, id) => {
        let tosend = JSON.stringify({
            username: form.getFieldValue('email').toLowerCase(),
            password: form.getFieldValue('password'),
            token: token,
            customerid: id,
        })
        setLoading(true)
        fetch("/los/signin", {
            method: "POST",
            body: tosend,
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                if (response.status !== 200) {
                    setLoading(false)
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    //console.log("js: " + js)
                    //console.log("js: " + JSON.stringify(js))
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        setMsg(js.Text)
                        setShow(true)
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        setLoading(false)
                    } else {
                        if (remember) {
                            let inFifteenMinutes = new Date(new Date().getTime() + 72 * 3600 * 1000)
                            cookie.save("remember", { username: form.getFieldValue('email').toLowerCase(), password: form.getFieldValue('password')}, { expires: inFifteenMinutes })
                        } else {
                            cookie.remove("remember")
                        }
                        sessionStorage.setItem("ZeitroA", js.Token)
                        // now seed the local state!
                        //console.log(" state: " + js.State)

                        sessionStorage.setItem("state", js.State)
                        sessionStorage.setItem("originalstate", js.State)

                        setLoading(false)
                        if (
                            checkAccessByToken(js.Token, [UR_LoanOfficerManager, UR_Owner])
                        ) {
                            updateStatsigUser(client,
                                form.getFieldValue('email'),
                                id,
                            );
                            client.logEvent("sign_in_with_password", id, {
                                signin_method: "password",
                                item_name: "lo_signin",
                                timestamp: new Date().getTime(),
                                customerid: id,
                                email: form.getFieldValue('email').toLowerCase().trim(),
                            });
                            window.location.href = "/app/welcome"
                        } else if (checkAccessByToken(js.Token, [UR_LoanProcessor])) {
                            updateStatsigUser(client,
                                form.getFieldValue('email'),
                                id,
                            );
                            client.logEvent("sign_in_with_password", id, {
                                signin_method: "password",
                                item_name: "lo_signin",
                                timestamp: new Date().getTime(),
                                customerid: id,
                                email: form.getFieldValue('email').toLowerCase().trim(),
                            });
                            window.location.href = "/app/borrowers"
                        } else if (checkAccessByToken(js.Token, [UR_PriceEngineUser])) {
                            updateStatsigUser(client,
                                form.getFieldValue('email'),
                                id,
                            );
                            client.logEvent("sign_in_with_password", id, {
                                signin_method: "password",
                                item_name: "lo_signin",
                                timestamp: new Date().getTime(),
                                customerid: id,
                                email: form.getFieldValue('email').toLowerCase().trim(),
                            });
                            window.location.href = "/app/purchasecalculator"
                        } else {
                            updateStatsigUser(client,
                                form.getFieldValue('email'),
                                id,
                            );
                            client.logEvent("sign_in_with_password", id, {
                                signin_method: "password",
                                item_name: "lo_signin",
                                timestamp: new Date().getTime(),
                                customerid: id,
                                email: form.getFieldValue('email').toLowerCase().trim(),
                            });
                            window.location.href = "/app/welcome"
                        }

                        //console.log(js)
                    }
                })
            })
            .catch(function (err) {
                console.log("Fetch Error :-S", err)
            })
    }

    const handleValuesChange = (changedValues, allValues) => {

    }
    const back = (type) => {
        setShowEmailVerification(false)
        setStatus(type + 1)
    }
    const pageHeader = () => {
        if (status === 2) {
            return (
                <>
                    <div className="login-title mb-4">Sign in to your account</div>
                </>
            )
        } else {
            return (
                <div style={{ marginBottom: 24 }}>
                    <img src="/images/password-check.png" style={{ width: 69, height: 69, marginBottom: 11 }} />
                    <div className="login-title">{status === 3 ? 'Enter your password' : status === 4 ? 'Reset your password' : 'You are all set!'}</div>
                    {status === 5 && <div className="login-title-tips">Your password has been successfully reset,click the following to continue your access.</div>}
                </div>
            )
        }
    }
    const onChangeRemember = (e) => {
        setRemember(e.target.checked)
    }

    const toSignup = () => {
        setRedirect(<Redirect to="/signup" />)
    }
    const chooseCompany = (item) => {
        setShowChooseCompany(false)
        setCompanyName(item.CompanyName)
        document.title = `${item.CompanyName} - mortgage platform`
        setCustomerid(item.CustomerID)
        localStorage.setItem("customerid", item.CustomerID)
        if (operationType === 'login') {
            setLoading(true)
            window.grecaptcha.ready(() => {
                setLoading(false)
                window.grecaptcha
                    .execute(RecaptchaV3SiteKey, { action: "submit" })
                    .then((token) => {
                        login(token, item.CustomerID)
                    })
            })
        } else {
            checkEmail(item.CustomerID)
        }
    }

    const displayCarousel = () => {
        const contentStyle = {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          };
        return (
            <div className='los-carousel-box'>
                <div className='content-box'>
                <Carousel className='create-carousel' autoplay>
                    <div>
                        <div style={contentStyle}>
                            <div className='center-box'>
                                <div>GuidelineGPT</div>
                                <div style={{fontSize: 16, color:"#222222", fontWeight: 400, marginBottom: 20}}>AI-powered expertise across Conventional, Non-QM, FHA, VA, USDA, and Business-Purpose Loan guidelines allows you to solve complex scenarios quickly and efficiently. </div>
                                <img src='/images/carousel-img-1.svg' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={contentStyle}>
                            <div className='center-box'>
                                <div>AI-powered efficiency</div>
                                <div style={{fontSize: 16, color:"#222222", fontWeight: 400, marginBottom: 20}}>Streamline every aspect of mortgage origination, from rate quotes to document processing, saving hours on every loan.</div>
                                <img src='/images/carousel-img-2.svg' />
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div style={contentStyle}>
                            <div className='center-box'>
                                <div>Wholesale integration</div>
                                <div style={{fontSize: 16, color:"#222222", fontWeight: 400, marginBottom: 20}}>Automatically connect to multiple wholesalers, ensuring you always have the most competitive rates for your borrowers.</div>
                                <div >
                                    <img src='/images/carousel-img-3.svg' />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Carousel>
                </div>
            </div>
        )
    }
    return (
        <div className='los-signin-wrap'>
            <div className="sign-up-box">
                {redirect}
                <div className="create-account">
                    <div className="sign-up-logo">
                        {companyName !== '' && <span className="company-name">{companyName}</span>}
                    </div>
                    <div className="mobile-nav">
                        {companyName !== '' && <span className="company-name">{companyName}</span>}
                    </div>
                    {showEmailVerification ? (
                        <div className="create-centerBox">
                            <EmailVerification
                                showBack
                                fromSignIn
                                customerid={customerid}
                                email={form.getFieldValue("email").toLowerCase()}
                                back={(type) => back(type)}
                            />
                        </div>
                    ) : (
                        <div className="create-centerBox">
                            {pageHeader()}

                            <Form
                                className="create-account-form"
                                layout="vertical"
                                form={form}
                                initialValues={formInitial}
                                onFinish={onFinish}
                                onValuesChange={handleValuesChange}
                                size="large"
                            >
                                {
                                    [2, 3, 4].includes(status) && (
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="email"
                                                    label="Email"
                                                    style={{ marginBottom: status !== 2 ? 0 : 20 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            type: "email",
                                                            message: 'Please enter a valid email address.',
                                                        },
                                                    ]}
                                                >

                                                    {status === 2 && <Input placeholder="Your email" />}
                                                    {(status === 3 || status === 4) && <div className="enterPwd-email"><span>{form.getFieldValue('email')}</span>{status === 3 && <span onClick={() => setStatus(2)}>Edit</span>}</div>}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {status === 3 && (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                style={{ marginBottom: 6 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Enter your password!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password placeholder="Your password" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                {
                                    <Row>
                                        <Col span={24}>
                                            <RecaptchaV3 />
                                        </Col>
                                    </Row>
                                }
                                {
                                    status === 3 && (
                                        <Row className="login-operate">
                                            <Checkbox checked={remember} onChange={onChangeRemember}><span className="remember-pwd">Remember me</span></Checkbox>
                                            {/* <span className="forget-pwd" onClick={()=>window.location.href = "/forgot-password"}>Forgot password?</span> */}
                                        </Row>
                                    )
                                }
                                <Spin spinning={loading}>
                                    <Button
                                        htmlType="submit"
                                        className="create-btn sign-zeitro"
                                        block
                                    >
                                        {status === 2 ? "Continue" : "Sign in"}
                                    </Button>
                                </Spin>
                            </Form>
                            <div style={{
                                width: "400px",
                                margin: 'auto'
                            }}>

                                <LoginPage setLoading={setLoading}></LoginPage>
                            </div>
                            <div className="bottom-link">
                                {status === 3 && <div>or<span onClick={signinCode}>Sign in with one-time code</span></div>}
                            </div>
                                        <div className='mt-3' style={{color: "#6E6E70"}}>
                                    Don't have an account? <a href="https://lo.zeitro.com/signup" style={{color: "#325CEB"}}>Sign up</a>
                                </div>
                        </div>
                    )}

                    {
                        status === 3 ? (
                            <div className="create-copyright-box create-centerBox">
                                <div className="create-copyright">© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
                            </div>
                        ) : (
                            <div className="create-copyright">© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
                        )
                    }
                </div>
            </div>
            {displayCarousel()}
            <Modal
                open={show}
                title="Authentication Failed"
                cancelText="Close"
                onCancel={handleClose}
                footer={(_, { CancelBtn }) => (
                    <>
                        <CancelBtn />
                    </>
                )}
            >
                <div>{msg}</div>
            </Modal>
            <Modal
                className="chooseCompany-modal"
                title={customerIDs.length === 0 ? 'Email Address Not Found' : 'Select an organization to log in'}
                open={showChooseCompany}
                footer={null}
                onCancel={() => {
                    // this.editEmail('')
                    setShowChooseCompany(false)
                }
                }>
                {
                    customerIDs.length === 0 ? (
                        <div>{form.getFieldValue('email')} is not registered in our system. If you would like to create a new account, please <span style={{ color: '#325CEB', cursor: 'pointer' }} onClick={toSignup}>click here to sign up</span>.</div>
                    ) : (
                        <>
                            <div className="ft-500">{form.getFieldValue('email')} is associated with accounts at the following orginizations, please select one to log in.</div>
                            <div className="chooseCompany-list">
                                {
                                    customerIDs.map((item, index) => (
                                        <div key={index} className="chooseCompany" onClick={() => chooseCompany(item)}>
                                            <div>{item.CompanyName}</div>
                                            <RightOutlined />
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </Modal>
        </div>
    )
}
export default withRouter(Login)
