import React, { useEffect, useState } from "react";
import { Popconfirm } from "antd";

import { Form } from "react-bootstrap";

interface RealtorPopconfirmProps {
  realtorName: string;
  realtorEmail: string;
  realtorPhone: string;
  setRealtorInfoFilled: (filled: boolean) => void;
  setRealtorName: (name: string) => void;
  setRealtorID: (id: string) => void;
  setRealtorEmail: (email: string) => void;
  setRealtorPhone: (phone: string) => void;
}

const RealtorPopconfirm = (props: RealtorPopconfirmProps) => {
  const [realtorName, setRealtorName] = useState(props.realtorName);
  const [realtorID, setRealtorID] = useState("");
  const [realtorEmail, setRealtorEmail] = useState(props.realtorEmail);
  const [realtorPhone, setRealtorPhone] = useState(props.realtorPhone);

  useEffect(() => {
    setRealtorName(props.realtorName);
    setRealtorEmail(props.realtorEmail);
    setRealtorPhone(props.realtorPhone);
  }, [props.realtorName, props.realtorEmail, props.realtorPhone]);

  return (
    <Popconfirm
      okText="OK"
      className="realtorInfoPop"
      title=""
      showCancel={false}
      icon={null}
      onConfirm={() => {
        if (
          realtorName === "" &&
          realtorID === "" &&
          realtorEmail === "" &&
          realtorPhone === ""
        ) {
          props.setRealtorInfoFilled(false);
        } else {
          props.setRealtorInfoFilled(true);
        }
      }}
      description={
        <div className="realtorInfoWrap">
          <Form.Group>
            <Form.Label className="text-left">Realtor name</Form.Label>
            <Form.Control
              size="sm"
              as="input"
              onChange={(e) => {
                setRealtorName(e.target.value);
                props.setRealtorName(e.target.value);
              }}
              value={realtorName}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-left">License number</Form.Label>
            <Form.Control
              size="sm"
              as="input"
              onChange={(e) => {
                setRealtorID(e.target.value);
                props.setRealtorID(e.target.value);
              }}
              value={realtorID}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-left">Phone number</Form.Label>
            <Form.Control
              size="sm"
              as="input"
              onChange={(e) => {
                setRealtorPhone(e.target.value);
                props.setRealtorPhone(e.target.value);
              }}
              value={realtorPhone}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-left">Email</Form.Label>
            <Form.Control
              size="sm"
              as="input"
              onChange={(e) => {
                setRealtorEmail(e.target.value);
                props.setRealtorEmail(e.target.value);
              }}
              value={realtorEmail}
            />
          </Form.Group>
        </div>
      }
    >
      <div className="d-flex justify-content-center w-100">
        <div className="realtorInfoBtn">Verify Realtor Info</div>
      </div>
    </Popconfirm>
  );
};

export default RealtorPopconfirm;
