import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useMemo } from 'react';
import { Tag, Select, Form, Input, Col, Row, Upload, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import "./BasicInfoSetup.css";
import CropperModal from '../Photo/CropperModal';
import { isEmpty } from 'lodash';

const LANGUAGE_OPTIONS = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Tagalog', label: 'Tagalog' },
    { value: 'Vietnamese', label: 'Vietnamese' },
    { value: 'French', label: 'French' },
]

const STATES_MAP = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
}

const BasicInfoSetup = forwardRef(({ info, finish, change, children, changeProgress }, ref,) => {
    const [form] = Form.useForm();
    const [applink, setApplink] = useState(info.appLink);
    const [percent, setPercent] = useState(0);
    const [required, setRequired] = useState(50);
    const [cropperVisible, setCropperVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [tags, setTags] = useState([]);
    const [popularTags, setPopularTags] = useState([])
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    const [profilePhoto, setProfilePhoto] = useState('');
    const [linkList, setLinkList] = useState([
        { name: 'Linkedin', link: '', edit: false },
        { name: 'Google', link: '', edit: false },
        { name: 'Yelp', link: '', edit: false },
        { name: 'Twitter', link: '', edit: false },
    ])

    const isInit = useRef(true)

    useEffect(() => {
        if (inputVisible && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputVisible]);

    useEffect(() => {
        if (editInputRef.current) {
            editInputRef.current.focus()
        }
    }, [editInputValue]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        setTags(newTags)
    }

    const handleClick = (tag) => {
        if (!tags.includes(tag) && tags.length < 5) {
            setTags([...tags, tag])
        }
    }

    useEffect(() => {
        if (isInit.current) {
            isInit.current = false
        } else {
            const formValues = form.getFieldValue()
            const obj = {
                ...formValues,
                tagsList: tags,
                popularTags: popularTags,
                google: getLinkByName('Google'),
                yelp: getLinkByName('Yelp'),
                linkedin: getLinkByName('Linkedin'),
                twitter: getLinkByName('Twitter'),
                appLink: applink,
            }
            change(obj)
        }
    }, [tags])

    useEffect(() => {
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google: getLinkByName('Google'),
            yelp: getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter: getLinkByName('Twitter'),
            appLink: applink,
        }
        setProgressValue(obj)
    }, [percent, tags, linkList])

    useEffect(() => {
        const { tagsList, popularTags, google, linkedin, twitter, yelp, appLink, ...formValue } = info
        const initialLink = { google, linkedin, twitter, yelp }
        form.setFieldsValue(formValue)
        setTags(tagsList)
        setPopularTags(popularTags)
        const updatedLinkList = linkList.map(item => {
            const key = item.name.toLowerCase()
            return {
                ...item,
                link: initialLink[key],
                edit: initialLink[key] !== ''
            }
        })
        setLinkList(updatedLinkList)
        setApplink(appLink)

    }, [info])

    const getLinkByName = (name) => {
        const item = linkList.find(item => item.name === name)
        return item ? item.link : null
    }

    const showInput = () => {
        setInputVisible(true)
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }
        setInputVisible(false)
        setInputValue('')
    }

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value)
    }

    const handleEditInputConfirm = () => {
        const newTags = [...popularTags]
        newTags[editInputIndex] = editInputValue
        setPopularTags(newTags)
        setEditInputIndex(-1)
        setEditInputValue('')
    }

    const stateOptions = useMemo(() => {
        const options = []
        for (const s in STATES_MAP) {
            options.push({ value: s, label: STATES_MAP[s] })
        }
        return options
    }, [])

    const progressStatus = () => {
        if (percent < required) {
            return 'undone'
        } else if (percent >= required && percent < 100) {
            return 'normal'
        }
    }

    const validateMessages = {
        required: '${label} is required!',
    }

    useImperativeHandle(ref, () => ({
        getPercent() {
            return percent
        },

        async validate() {
            return await form.validateFields()
        },

        validateForm() {
            form.validateFields().then((values) => {
                const info = {
                    ...values,
                    tagsList: tags,
                    popularTags: popularTags
                }

                finish(info)
            }).catch((error) => {
                console.error('Validation failed:', error);
            });
        },
        resetForm() {
            form.resetFields()
        }
    }))

    const handleValuesChange = (changedValues, allValues) => {
        const obj = {
            ...allValues,
            tagsList: tags,
            popularTags: popularTags,
            google: getLinkByName('Google'),
            yelp: getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter: getLinkByName('Twitter'),
            appLink: applink,
        }
        setProgressValue(obj)
        change(obj)
    }

    const handleUploadChange = async ({ file, fileList }) => {
        if (file.status === 'done') {
            const base64Data = await readFileAsDataURL(file.originFileObj)
            setImageSrc(base64Data)
            setCropperVisible(true)
            const formValues = form.getFieldValue()
            const newFileList = fileList.map((f) => {
                if (f.uid === file.uid) {
                    return { ...f, url: base64Data }
                }
                return f
            })

            form.setFieldsValue({
                file: newFileList,
            })

            const obj = {
                ...formValues,
                file: newFileList,
                tagsList: tags,
                popularTags: popularTags,
                google: getLinkByName('Google'),
                yelp: getLinkByName('Yelp'),
                linkedin: getLinkByName('Linkedin'),
                twitter: getLinkByName('Twitter'),
                appLink: applink,
            }
            change(obj)
        } else if (file.status === 'error') {
            console.error('File upload error:', file);
        }
    }

    const handleSave = (croppedImage) => {
        const formValues = form.getFieldValue()
        const imgList = form.getFieldValue('file')
        const newFileList = imgList.map((f) => {
            return { ...f, url: croppedImage }
        })

        form.setFieldsValue({
            file: newFileList,
        })

        const obj = {
            ...formValues,
            file: newFileList,
            tagsList: tags,
            popularTags: popularTags,
            google: getLinkByName('Google'),
            yelp: getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter: getLinkByName('Twitter'),
            appLink: applink,
        }
        change(obj)
    }

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => resolve(e.target.result)
            reader.onerror = (error) => reject(error)
        })

    }
    const setProgressValue = (form) => {
        const fillNum = Object.keys(form).filter((key) => {
            const value = form[key];

            return !isEmpty(value)

        }).length

        const progressValue = Math.round((fillNum / 14) * 100)
        setPercent(progressValue)
        changeProgress(progressValue)
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }

    const handleCustomRequest = (options) => {
        const { onSuccess } = options
        onSuccess()
    }

    const handleCropper = () => {
        const base64Data = form.getFieldValue('file')[0].url
        setImageSrc(base64Data)
        setCropperVisible(true)
    }

    const itemRender = (originNode, file, fileList, actions) => (
        <div style={{ marginTop: '6px', display: 'flex' }}>
            <img onClick={handleCropper} className='profile-photo' src={profilePhoto || file.url}></img>
            <img className='remove-photo' src='/images/remove.svg' onClick={() => { actions.remove(file) }} />
        </div>
    )

    const changeEdit = (name) => {
        setLinkList(prevList =>
            prevList.map(item => (
                item.name === name ? { ...item, edit: true } : item
            ))
        )
    }

    const changeLink = (name, newLink) => {
        setLinkList(prevList =>
            prevList.map(item =>
                item.name === name ? { ...item, link: newLink } : item
            )
        )
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google: name === 'Google' ? newLink : getLinkByName('Google'),
            yelp: name === 'Yelp' ? newLink : getLinkByName('Yelp'),
            linkedin: name === 'Linkedin' ? newLink : getLinkByName('Linkedin'),
            twitter: name === 'Twitter' ? newLink : getLinkByName('Twitter'),
            appLink: applink,
        }

        change(obj)
    }

    const changePrequalLink = (newLink) => {
        // if (!newLink.includes("http")) {
        //     newLink = "https://" + newLink
        // }
        setApplink(newLink)
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google:  getLinkByName('Google'),
            yelp:  getLinkByName('Yelp'),
            linkedin: getLinkByName('Linkedin'),
            twitter:  getLinkByName('Twitter'),
            appLink: newLink
        }
        change(obj)
    }

    const linkRemove = (name) => {
        setLinkList(prevList =>
            prevList.map(item => (
                item.name === name ? { ...item, link: '', edit: false } : item
            ))
        )
        const formValues = form.getFieldValue()
        const obj = {
            ...formValues,
            tagsList: tags,
            popularTags: popularTags,
            google: name === 'Google' ? '' : getLinkByName('Google'),
            yelp: name === 'Yelp' ? '' : getLinkByName('Yelp'),
            linkedin: name === 'Linkedin' ? '' : getLinkByName('Linkedin'),
            twitter: name === 'Twitter' ? '' : getLinkByName('Twitter'),
            appLink: applink,
        }
        change(obj)
    }

    const file = form.getFieldValue('file')

    return (
        <>
            <Form
                className='basic-info-form'
                layout='vertical'
                form={form}
                onValuesChange={handleValuesChange}
                validateMessages={validateMessages}
                size="large"
            >
                <div className="form-row">
                    {/* Left photo upload */}
                    <div className="upload-section">
                        <Form.Item
                            name="file"
                            label="Profile photo"
                            rules={[{ required: true }]}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                maxCount={1}
                                customRequest={handleCustomRequest}
                                itemRender={itemRender}
                                onChange={handleUploadChange}
                            >
                                {isEmpty(file) && (
                                    <div className="upload-placeholder">
                                        <img src="/images/upload-camera.svg" alt="camera" />
                                        <button className="upload-btn">
                                            <img src="/images/photo-upload.svg" alt="upload" />
                                            <span>Upload</span>
                                        </button>
                                    </div>
                                )}
                            </Upload>
                        </Form.Item>
                    </div>

                    {/* Right form section */}
                    <div className='form-section'>
                        <Row gutter={31}>
                            <Col span={24} sm={12}>
                                <Form.Item
                                    name="firstname"
                                    label="First name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern:/^[a-zA-Z\u4e00-\u9fa5]+$/,
                                            message: 'Please enter your first name',
                                        },
                                    ]}
                                >
                                    <Input placeholder=""  maxLength={20} />
                                </Form.Item>
                            </Col>
                            <Col span={24} sm={12}>
                                <Form.Item
                                    name="lastname"
                                    label="Last name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern:/^[a-zA-Z\u4e00-\u9fa5]+$/,
                                            message: 'Please enter your last name',
                                        },
                                    ]}
                                >
                                    <Input placeholder=""  maxLength={20}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="nmls"
                            label="NMLS number"
                            rules={[
                                {
                                    pattern:/^\d+$/,
                                    message: 'Please enter your NMLS ID',
                                },
                            ]}
                        >
                            <Input placeholder=""  maxLength={20}/>
                        </Form.Item>
                        <Form.Item name="states" label="License states">
                            <Select mode="multiple" allowClear options={stateOptions} />
                        </Form.Item>
                        <Form.Item name="languages" label="Language">
                            <Select mode="multiple" allowClear options={LANGUAGE_OPTIONS} />
                        </Form.Item>
                    </div>
                </div>

                {children}

                <div className='form-item-title'>Social media links</div>
                {linkList.map((item, index) => (
                    item.edit && (
                        <div key={item.name} className='link-edit-item'>
                            <div className='link-title'>{item.name === 'Twitter' ? 'X (Twitter)' : item.name}</div>
                            <div className='link-edit'>
                                <Input defaultValue={item.link} onChange={(e) => changeLink(item.name, e.target.value)} />
                                <img className='link-remove' src='/images/remove.svg' onClick={() => linkRemove(item.name)} />
                            </div>
                        </div>
                    )
                ))}
                <Row gutter={11} className='link-list'>
                    {linkList.map((item, index) => (
                        !item.edit && item.link === '' && (
                            <Col key={item.name}>
                                <div
                                    className={`link-list-item ${item.link !== '' ? 'link-completed' : ''}`}
                                    onClick={() => changeEdit(item.name)}
                                >
                                    <span>{item.name === 'Twitter' ? 'X (Twitter)' : item.name}</span>
                                    {item.link === '' && (
                                        <img style={{ width: '12.75px', height: '12.75px' }} src='/images/site-add.svg' />
                                    )}
                                </div>
                            </Col>
                        )
                    ))}
                </Row>

                <div className="form-divider"></div>
                <div style={{ marginBottom: '16px' }}>
                    <div style={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: '#222222', marginBottom: '5px' }}>SPECIALTY TAG</div>
                    <div style={{ fontSize: '14px', fontWeight: 400, lineHeight: '32px', color: '#6E6E70' }}>
                        Highlight your specialties and strengths with up to 5 tags
                        <span style={{ fontSize: '14px', fontWeight: 600, lineHeight: '32px', color: '#6E6E70', marginLeft: '70px' }}>
                            {tags ? tags.length : 0} / 5 added
                        </span>
                    </div>
                </div>
                {tags && (
                    <div className='tag-list'>
                        {tags.map((tag, index) => {
                            const tagElem = (
                                <Tag
                                    key={tag}
                                    closable
                                    color='default'
                                    className='tag-item'
                                    onClose={() => handleClose(tag)}
                                >
                                    <span>{tag}</span>
                                </Tag>
                            );
                            return tagElem
                        })}
                        {tags.length < 5 && (
                                inputVisible ? (
                                    <Input
                                        ref={inputRef}
                                        type="text"
                                        size="small"
                                        className="tag-input"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleInputConfirm}
                                        onPressEnter={handleInputConfirm}
                                    />
                                ) : (
                                    <Tag
                                        className='tag-item tag-item-add'
                                        onClick={showInput}
                                        bordered={false}
                                        color='default'
                                    >
                                        Add your specialty...
                                        <img
                                            style={{ width: '12.75px', height: '12.75px', marginLeft: '10px' }}
                                            src='/images/site-add.svg'
                                            alt='add'
                                        />
                                    </Tag>
                                )
                        )}
                    </div>
                )}

                <div
                    style={{
                        marginTop: '50px',
                        marginBottom: '5px',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '32px',
                        color: '#6E6E70'
                    }}
                >
                    Suggested tags
                </div>
                {!isEmpty(popularTags) && (
                    <div className='popular-tags'>
                        {popularTags.map((tag, index) => {
                            if (editInputIndex === index) {
                            return (
                                <Input
                                    ref={editInputRef}
                                    key={tag}
                                    size="small"
                                    className="tag-input"
                                    value={editInputValue}
                                    onChange={handleEditInputChange}
                                    onBlur={handleEditInputConfirm}
                                    onPressEnter={handleEditInputConfirm}
                                />
                            );
                        } else {
                            if (!tags.includes(tag)) {
                                const tagElem = (
                                    <Tag
                                        key={tag}
                                        className='tag-item tag-item-add'
                                        style={{ color: '#000000' }}
                                        bordered={false}
                                        onClick={() => handleClick(tag)}
                                    >
                                        <span
                                            style={{ display: 'inline-flex', alignItems: 'center' }}
                                            onDoubleClick={(e) => {
                                                setEditInputIndex(index)
                                                setEditInputValue(tag)
                                                e.preventDefault()
                                            }}
                                        >
                                            {tag}
                                            <img
                                                style={{ width: '12.75px', height: '12.75px', marginLeft: '10px' }}
                                                src='/images/site-add.svg'
                                                alt='add'
                                            />
                                        </span>
                                    </Tag>
                                );
                                return tagElem
                            }
                            }
                        })}
                    </div>
                )}

                <div className="form-divider"></div>
                <div className='form-item-title d-flex align-items-center'>
                    <div>Application button URL (optional)</div>
                    <Popover
                        rootClassName="applyBtnTooltip"
                        content={
                            <>
                                <div className="mb-2">
                                    This field allows you to customize the URL for the "Apply now" buttons on your website.
                                </div>
                                <div className="mb-2">
                                    By default, the buttons will link to your system's application URL.
                                    If you prefer to direct your website visitors to a different application URL,
                                    please enter your desired URL here.
                                </div>
                                <div className="mb-2">
                                    Make sure the URL begins with "http://" or "https://".
                                </div>
                            </>
                        }
                    >
                        <QuestionCircleOutlined className="ml-1" />
                    </Popover>
                </div>
                <Input defaultValue={applink} onChange={(e) => changePrequalLink(e.target.value)} />
            </Form>

            <CropperModal
                aspectRatio={1}
                type="circle"
                visible={cropperVisible}
                imageSrc={imageSrc}
                onClose={() => setCropperVisible(false)}
                onSave={handleSave}
            />
        </>
    );
})

export default BasicInfoSetup;
