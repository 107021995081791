import React from "react";
import { Listing } from "./db_utils";

interface ListingPopoverProps {
  record: Listing;
}

const ListingPopover = ({ record }: ListingPopoverProps) => (
  <div>
    <div className="previewMSA">
      <div className="previewMSAInfo" style={{ width: "80%" }}>
        <div>MSA Code:</div>
        <div>{record.msa}</div>
      </div>
    </div>

    <hr />
    <div className="populationIncomeWrap">
      <div className="previewPopulation w-100">
        <div className="previewInfoWrap">
          <div>Tract Income Level:</div>
          {record.incomeindicator === "1" && <div>Low Income Area</div>}
          {record.incomeindicator === "2" && <div>Moderate Income Area</div>}
          {record.incomeindicator === "3" && <div>Middle Income Area</div>}
          {record.incomeindicator === "4" && <div>Upper Income Area</div>}
        </div>
        <div className="previewInfoWrap">
          <div>Tract Minority:</div>
          <div>{Math.floor(Number(record.minority))}%</div>
        </div>

        <div className="previewInfoWrap">
          <div>Majority AA/HP:</div>
          <div>
            {parseInt(record.hispanic) + parseInt(record.black)} (
            {(
              (100 * (parseInt(record.hispanic) + parseInt(record.black))) /
              parseInt(record.totalpopulation)
            ).toFixed(0)}
            %)
          </div>
        </div>

        <div className="previewInfoWrap">
          <div>Total Population:</div>
          <div>{record.totalpopulation}</div>
        </div>
        <div className="previewInfoWrap">
          <div>Hispanic Population:</div>
          <div>
            {record.hispanic} (
            {(
              (100 * parseInt(record.hispanic)) /
              parseInt(record.totalpopulation)
            ).toFixed(0)}
            %)
          </div>
        </div>
        <div className="previewInfoWrap">
          <div>Black Population:</div>
          <div>
            {record.black} (
            {(
              (100 * parseInt(record.black)) /
              parseInt(record.totalpopulation)
            ).toFixed(0)}
            %)
          </div>
        </div>
        <div className="previewInfoWrap">
          <div>Asian/Pacific Population:</div>
          <div>
            {parseInt(record.asian) + parseInt(record.pacific)} (
            {(
              (100 * (parseInt(record.asian) + parseInt(record.pacific))) /
              parseInt(record.totalpopulation)
            ).toFixed(0)}
            %)
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ListingPopover;
