import { Checkbox, Form, Input, Select, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import * as com from '../../../Common'
import './RequireCallback.css'
import { useStatsigClient } from "@statsig/react-bindings";

const PREFERRED_TIME_OPTIONS = [
    { value: '', label: 'Select your preferred time' },
    { value: '8am - 9am', label: '8am - 9am' },
    { value: '9am - 10am', label: '9am - 10am' },
    { value: '10am - 11am', label: '10am - 11am' },
    { value: '11am - 12pm', label: '11am - 12pm' },
    { value: '12pm - 1pm', label: '12pm - 1pm' },
    { value: '1pm - 2pm', label: '1pm - 2pm' },
    { value: '2pm - 3pm', label: '2pm - 3pm' },
    { value: '3pm - 4pm', label: '3pm - 4pm' },
    { value: '4pm - 5pm', label: '4pm - 5pm' },
    { value: '5pm - 6pm', label: '5pm - 6pm' },
    { value: '6pm - 7pm', label: '6pm - 7pm' },
    { value: '7pm - 8pm', label: '7pm - 8pm' },
    { value: '8pm - 9pm', label: '8pm - 9pm' },
]

const TIME_ZONE_OPTIONS = [
    { value: '', label: 'Select your time zone' },
    { value: 'Eastern', label: 'Eastern UTC-5' },
    { value: 'Central', label: 'Central UTC-6' },
    { value: 'Mountain', label: 'Mountain UTC-7' },
    { value: 'Pacific', label: 'Pacific UTC-8' },
]

function RequireCallback({
    loid,
    customerid,
    handleSubmit,
    isPreviewMode,
    ratesData,
    loanOfficerEmail,
}) {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [preferredTime, setPreferredTime] = useState('')
    const [timeZone, setTimeZone] = useState('')
    const [includeRates, setIncludeRates] = useState(false)
    const { client } = useStatsigClient()

    const onFinish = async (values) => {
        try {
            setSubmitting(true)

            // Determine API endpoint
            let url = '/borrower/contactme'
            if (loid === 'gmcc') {
                url = '/borrower/gmcc/contactme'
            }

            // Include rates data if checkbox is checked
            const payload = {
                BorrowerFirstName: values.firstName,
                BorrowerLastName: values.lastName,
                BorrowerPhone: values.phone,
                BorrowerEmail: values.email,
                BorrowerPreferredTimeZone: values.timeZone,
                BorrowerPreferredTime: values.preferredTime,
                Message: values.message,
                IncludeRates: values.includeRates,
                LOID: loid,
                CustomerID: customerid,
                From: "personalsite",
            }

            // Add rates data if checkbox is checked
            if (includeRates && ratesData) {
                payload.RatesData = ratesData
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            // Handle success
            form.resetFields()
            handleSubmit('success')

            //Statsig LogEvent
            client.logEvent("leads_generated", values.email, {
              item_name: "leadsGenerated",
              startTime: new Date,
              timestamp: Date.now(),
              BorrowerName: `${values.firstName} ${values.lastName}`,
              BorrowerPhone: values.phone,
              BorrowerEmail: values.email,
            })

            client.logEvent("Lo_detail_page_send_request_click", values.email, {
              item_name: "loDetailPageSendRequestClick",
              startTime: new Date,
              timestamp: Date.now(),
              pageUrl: window.location.href,
              loanOfficerID: loanOfficerEmail,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phone,
              type: "personalsite",
            })
        } catch (error) {
            console.error('Failed to submit form:', error)
            handleSubmit('error')
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div className="require-callback-container">
            <div className="require-callback-title">Require a call back</div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    className="require-callback-form"
                >
                    <div className="name-fields">
                        <Form.Item
                            name="firstName"
                            label="First name"
                            className="form-item"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name',
                                },
                            ]}
                        >
                            <Input
                                placeholder="First name"
                                className="input-field"
                            />
                        </Form.Item>

                        <Form.Item
                            name="lastName"
                            label="Last name"
                            className="form-item"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your last name',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Last name"
                                className="input-field"
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="email"
                        label="Email"
                        className="form-item"
                        required
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email',
                            },
                        ]}
                        getValueFromEvent={(e) => {
                            const value = e.target.value.replace(
                                /[^a-zA-Z0-9@._-]/g,
                                ''
                            )
                            if ((value.match(/@/g) || []).length > 1)
                                return e.target.value
                            if (value && /^[._@-]/.test(value))
                                return e.target.value
                            if (/[._@-]{2,}/.test(value)) return e.target.value
                            return value
                        }}
                    >
                        <Input
                            placeholder="Input email address here"
                            className="input-field"
                        />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="Phone number"
                        className="form-item"
                        rules={[
                            {
                                pattern:
                                    /^\([1-9][0-9]{2}\)-[0-9]{3}-[0-9]{4}$/,
                                message: 'Please enter a valid phone number.',
                            },
                        ]}
                        getValueFromEvent={(e) => {
                            return com.fixPhoneInput(e.target.value)
                        }}
                    >
                        <Input
                            placeholder="Input phone number here"
                            className="input-field"
                            maxLength={14}
                        />
                    </Form.Item>

                    <div className="time-fields">
                        <Form.Item
                            name="preferredTime"
                            label="Preferred contact time"
                            className="form-item"
                        >
                            <Select
                                placeholder="Select your preferred time"
                                value={preferredTime || undefined}
                                className="select-field"
                                options={PREFERRED_TIME_OPTIONS.filter(
                                    (opt) => opt.value !== ''
                                )}
                                onChange={(value) => setPreferredTime(value)}
                            />
                        </Form.Item>

                        <Form.Item
                            name="timeZone"
                            label="Time zone"
                            className="form-item"
                            required
                            rules={[
                                {
                                    message: 'Please select your time zone',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select your time zone"
                                value={timeZone || undefined}
                                className="select-field"
                                options={TIME_ZONE_OPTIONS.filter(
                                    (opt) => opt.value !== ''
                                )}
                                onChange={(value) => setTimeZone(value)}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="message"
                        label="Message to the loan officer"
                        className="form-item"
                    >
                        <TextArea
                            placeholder="Leave a message here"
                            className="textarea-field"
                            autoSize={{ minRows: 5, maxRows: 10 }}
                            style={{ lineHeight: '32px' }}
                        />
                    </Form.Item>

                    <Form.Item name="includeRates" valuePropName="checked">
                        <Checkbox
                            className="checkbox-text"
                            checked={includeRates}
                            onChange={(e) => setIncludeRates(e.target.checked)}
                        >
                            Include your loan information and rate table
                        </Checkbox>
                    </Form.Item>

                    <Button
                        type="primary"
                        htmltype="submit"
                        className="submit-button"
                        style={{
                            cursor: isPreviewMode ? 'not-allowed' : 'pointer',
                        }}
                        loading={submitting ? true : undefined}
                        disabled={isPreviewMode}
                    >
                        {submitting ? <Spin /> : 'Send'}
                    </Button>

                    <div className="disclaimer-text">
                        By clicking the button, your agree to share your entered
                        information and customized rates (if chosen) with your
                        selected loan officer.
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default RequireCallback
