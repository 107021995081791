import React, { useEffect } from "react";
import { Select } from "antd";
import { IPagination } from "./utils";
import { Listing } from "./db_utils";
import { useState } from "react";

const { Option } = Select;

interface FiltersProps {
  listingData: Listing[]; // this is the original listing data
  pagination: IPagination; // this is the current pagination object
  setFilteredListingData: (data: Listing[]) => void;
  setPagination: (pagination: IPagination) => void;
}

const Filters = ({
  listingData,
  pagination,
  setFilteredListingData,
  setPagination,
}: FiltersProps) => {
  const [currentFilterType, setCurrentFilterType] = useState<string[]>([]);
  const [currentSortType, setCurrentSortType] = useState<string>("");
  const [filteredListingData, setFilteredListingDataLocal] =
    useState<Listing[]>(listingData);

  useEffect(() => {
    setFilteredListingDataLocal(listingData);
  }, [listingData]);

  const handleTableFilter = (values: string[]) => {
    setCurrentSortType("");
    setCurrentFilterType(values);
    let filtered = listingData;

    if (!Array.isArray(values) || values.length === 0) {
      filtered = listingData;
    } else {
      values.forEach((value) => {
        if (value === "minority") {
          filtered = filtered.filter((x) => parseInt(x.minority) > 50);
        }
        if (value === "incomeindicator") {
          filtered = filtered.filter(
            (x) => x.incomeindicator === "1" || x.incomeindicator === "2"
          );
        }
        if (value === "AAHP") {
          filtered = filtered.filter(
            (x) =>
              (100 * (parseInt(x.black) + parseInt(x.hispanic))) /
                parseInt(x.totalpopulation) >
              50
          );
        }
        if (value === "less500") {
          filtered = filtered.filter((x) => parseInt(x.price) <= 500000);
        }
        if (value === "500to1000") {
          filtered = filtered.filter(
            (x) => parseInt(x.price) > 500000 && parseInt(x.price) <= 1000000
          );
        }
        if (value === "1000to3000") {
          filtered = filtered.filter(
            (x) => parseInt(x.price) > 1000000 && parseInt(x.price) <= 3000000
          );
        }
        if (value === "over3000") {
          filtered = filtered.filter((x) => parseInt(x.price) > 3000000);
        }
      });
    }

    setFilteredListingData(filtered);
    setFilteredListingDataLocal(filtered);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleTableSorter = (value: string) => {
    setCurrentSortType(value);
    let sorted = [];
    if (value === undefined || value === "") {
      sorted = [...filteredListingData];
    }
    if (value === "price") {
      sorted = [...filteredListingData].sort(
        (a, b) => parseInt(a.price) - parseInt(b.price)
      );
    }
    if (value === "zip") {
      sorted = [...filteredListingData].sort(
        (a, b) => parseInt(a.zip) - parseInt(b.zip)
      );
    }
    if (value === "address") {
      sorted = [...filteredListingData].sort((a, b) =>
        a.address.localeCompare(b.address)
      );
    }
    if (value === "incentives") {
      //sort the data by checking the number of conditions that the record met.  The more conditions met, the higher the record will be placed. Conditions are: minority >50, incomeindicator 1 or 2
      sorted = [...filteredListingData].sort((a, b) => {
        let aCount = 0;
        let bCount = 0;
        if (parseInt(a.minority) > 50) {
          aCount += 1;
        }
        if (a.incomeindicator === "1" || a.incomeindicator === "2") {
          aCount += 1;
        }
        if (parseInt(b.minority) > 50) {
          bCount += 1;
        }
        if (b.incomeindicator === "1" || b.incomeindicator === "2") {
          bCount += 1;
        }
        return bCount - aCount;
      });
    }
    setFilteredListingData(sorted);
    setFilteredListingDataLocal(sorted);
    setPagination({
      ...pagination,
      current: 1,
    });
  };
  return (
    <div className="d-flex align-items-center">
      <div className="mt-2" style={{ paddingLeft: "15px" }}>
        <Select
          mode="multiple"
          className="massiveTableFilter"
          placeholder="Filter by"
          allowClear
          value={currentFilterType}
          onChange={(value) => handleTableFilter(value)}
        >
          <Option key={"minority"} value={"minority"}>
            MMCT
          </Option>
          <Option key={"incomeindicator"} value={"incomeindicator"}>
            Low-to-moderate
          </Option>
          <Option key={"AAHP"} value={"AAHP"}>
            Majority AA/HP
          </Option>
          <Option key={"less500"} value={"less500"}>
            Under $500K
          </Option>
          <Option key={"500to1000"} value={"500to1000"}>
            $500K - $1M
          </Option>
          <Option key={"1000to3000"} value={"1000to3000"}>
            $1M - $3M
          </Option>
          <Option key={"over3000"} value={"over3000"}>
            Over $3M
          </Option>
        </Select>
      </div>
      <div
        className="mt-2 d-flex align-items-center"
        style={{ paddingLeft: "15px" }}
      >
        <div>Sort by&nbsp;&nbsp;</div>
        <Select
          className="massiveTableFilter"
          placeholder="Sort by"
          value={currentSortType}
          onChange={(value) => handleTableSorter(value)}
        >
          <Option key={"none"} value={""}>
            None
          </Option>
          <Option key={"address"} value={"address"}>
            Address
          </Option>
          <Option key={"zip"} value={"zip"}>
            Zipcode
          </Option>
          <Option key={"price"} value={"price"}>
            Price
          </Option>
          <Option key={"incentives"} value={"incentives"}>
            Incentives status
          </Option>
        </Select>
      </div>
    </div>
  );
};

export default Filters;
