import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {
    Progress,
    Tabs,
    Tag,
    DatePicker,
    Button,
    Checkbox,
    Tooltip,
    Select,
    Switch,
    Modal,
    Divider,
    Form,
    Input,
    InputNumber,
    Slider,
    Col,
    Row,
    Upload
} from 'antd'
import "./AboutmeSetup.css"
import * as com from '../Common'
import { isEmpty } from 'lodash'

const { TextArea } = Input

const AboutmeSetup = forwardRef(({ info, finish, change, changeProgress, currentForm }, ref) => {
    const [form] = Form.useForm();
    const [percent, setPercent] = useState(0);
    const [required, setRequired] = useState(38);

    useEffect(() => {
        const formValues = form.getFieldValue()
        setProgressValue(formValues)
    }, [percent])

    useEffect(() => {
        const formValues = form.getFieldValue()
        currentForm(formValues)
    }, [])

    const progressStatus = () => {
        if (percent < required) {
            return 'undone'
        } else if (percent >= required && percent < 100) {
            return 'normal'
        }
    }

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }

    const onFinish = (val) => {

    }

    const formInitial = { ...info }
    useImperativeHandle(ref, () => ({
        async validate() {
            return await form.validateFields()
        },
        validateForm() {
            form.validateFields().then((values) => {
                finish(values)
            }).catch((error) => {
                console.warn('Validation failed:', error);
            });

        },
        resetForm() {
            form.resetFields()
        }
    }))

    const handleValuesChange = (changedValues, allValues) => {
        const form = { ...allValues }
        setProgressValue(form)
        currentForm(form)
        change(form)
    }

    const onChangePhone = (e) => {
        let phone = com.fixPhoneInput(e.target.value)
        form.setFieldsValue({
            phone: phone,
        })
    }

    const setProgressValue = (form) => {
        const fillNum = Object.keys(form).filter((key) => {
            const value = form[key];
            return !isEmpty(value)
        }).length
        const progressValue = Math.round((fillNum / 14) * 100)
        setPercent(progressValue)
        changeProgress(progressValue)
    }

    const getRandomInt = (min, max) => {
        const randomNum = Math.floor(Math.random() * (max - min + 1))
        return randomNum + min
    }

    const IntroductionList = [
        `I am a dedicated Loan Officer with over 10 years of experience. I specialize in helping first-time homebuyers navigate the mortgage landscape. My mission is to provide clear, straightforward advice so you can make informed decisions. Let's turn your dream home into a reality!`,
        `With a passion for financial education and a knack for numbers, I've helped countless clients secure loans that fit their needs and lifestyle. I believe in a personalized, no-stress approach to lending. Reach out, and let's find the perfect loan for you!`,
        `Whether you are considering refinancing your current mortgage or are in the process of purchasing a new property, I am committed to providing unparalleled service and guidance from start to finish. Let us commence this financial journey together.`,
        `A certified Loan Officer with a keen focus on client financial education. I have successfully facilitated loans for a diverse clientele and take a bespoke approach to meet individual needs with minimal stress. I invite you to reach out for a customized loan solution tailored to your financial circumstances`,
    ]

    const autoGenerate = () => {
        const random = getRandomInt(0, 4)
        form.setFieldsValue({
            introduction: IntroductionList[random],
        })
        const formValues = form.getFieldValue()
        setProgressValue(formValues)
        currentForm(formValues)
        change(formValues)
    }

    return (
        <div className="form-wrap">
            <Form
                className="about-me-form"
                layout="vertical"
                form={form}
                initialValues={formInitial}
                onFinish={onFinish}
                onValuesChange={handleValuesChange}
                validateMessages={validateMessages}
                size="large"
            >
                <Row>
                    <Col style={{ position: 'relative' }} span={24}>
                        <Form.Item
                            name="introduction"
                            label="Bio"
                            rules={[{ required: true }]}
                        >
                            <TextArea
                                style={{ padding: '9px 11px 56px' }}
                                autoSize={{ minRows: 4 }}
                            />
                        </Form.Item>
                        <Button
                            className="auto-generate"
                            icon={<img src="/images/tip.svg" alt="tip" />}
                            size="default"
                            onClick={autoGenerate}
                        >
                            Auto generate
                        </Button>
                    </Col>
                </Row>

                <div className="form-divider"></div>
                <div style={{ marginTop: '30px', marginBottom: '13px' }}>
                    <div style={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: '#222222', marginBottom: '5px' }}>CONTACT</div>
                    <div style={{ fontSize: '14px', fontWeight: 400, lineHeight: '32px', color: '#6E6E70' }}>
                        Let borrowers know how to reach you
                    </div>
                </div>

                <Row gutter={31}>
                    <Col span={24} sm={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                            tooltip="The email address provided here is displayed as the contact information on your landing page."
                            rules={[{ required: true, type: 'email' }]}
                        >
                            <Input placeholder="" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                        <Form.Item
                            name="phone"
                            label="Cell phone number"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please provide your contact phone.',
                                },
                                {
                                    pattern:
                                        /^\([1-9][0-9]{2}\)-[0-9]{3}-[0-9]{4}$/,
                                    message:
                                        'Please provide your contact phone.',
                                },
                            ]}
                        >
                            <Input placeholder="" onChange={onChangePhone} maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
})

export default AboutmeSetup;
