import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import "./HomePage.css"
import { AnthenticatedMenu } from "./Menu.js"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Helmet } from 'react-helmet'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import Sidebar from './Sidebar/Sidebar'
import Profile from './Profile'
import Borrowers from './Borrowers'
import Borrower from './Borrower'
import Tasks from './Tasks/Tasks'
import LogoutPage from './LogoutPage'
import LOInput from './LOInput/LOInput'
import Notifications from './Notifications'
import ContactCustomer from './Tools/ContactCustomer/ContactCustomer'
import Affordability from './Tools/Afordability/Affordability'
import Login from './Tools/CustomerRegistration/Login'
import Management from './Management/Management'
import RateQuoter from "./Tools/MortgageCalculators/RateQuote"
import SubscribersList from './Tools/Subscription/SubscribersList'
import SubscriptionDashboard from './Tools/Subscription/SubscriptionDashboard'
import { ReportIssuesForm } from './Tools/MortgageCalculators/ReportIssues'
import LandingPageSetup from "./InitialSetup/LandingPageSetup"
import NewLandingPageSetup from "./InitialSetup/NewLandingPageSetup"
import NewEditLandingPageSetup from "./InitialSetup/NewEditLandingPageSetup"
import NewPreviewLandingPageSetup from "./InitialSetup/NewPreviewLandingPageSetup"
import WelcomePage from './WelcomePage'
import MortgageGPT from './MortgageGPT/MortgageGPT'
import IntervewBorrowerView from './InterviewBorrowerView'
import { checkAccess, checkAloneAccess, RouteWrapper } from './Auth'
import { UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner, UR_LoanProcessor, UR_PriceEngineUser } from './State'
import * as com from "./Common"
import {
    Switch,
    Route
} from "react-router-dom"
import BorrowerForLoanProcessors from './BorrowerForLoanProcessors'
import BorrowersForLoanProcessors from './BorrowersForLoanProcessors/BorrowersForLoanProcessors'
import Interview1003View from './Interview1003View/Interview1003View'
import ProspectLoans from './Management/ProspectLoans'
import ActiveLoans from './Management/Borrowers'
import FundedLoans from './Management/FundedLoans'
import Pricing from './Billing/Pricing'
import Billing from './Billing/Billing'
import Subscribe from './Billing/Subscribe'
import Account from './Billing/Account'
import Pipeline from './Management/Borrowers'
import { Menu, Layout, Modal, Tooltip, Drawer, Spin, Dropdown, Popconfirm, Button, Space, Radio } from 'antd'
import { withRouter } from 'react-router-dom'
import Setting from './setting'
import GMCCUniverseCalculator from './Tools/MortgageCalculators/GMCCUniverseCalculator'
import GMCCProgramEngine from './GMCCCalculator/GMCCProgramEngine'
import PlanModal from './planModal'
import { connect } from 'react-redux'
import {
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons'
import eventBus from './Bus'
import FloatingFeedback from './FloatingFeedback/FloatingFeedback'
import GMCCDiamond from './GMCCCalculator/GMCCDiamond'
import GMCCCalculators from './Management/GMCCCalculators'
import CensusChecker from './GMCCCalculator/CensusChecker'
import MobileHomeLoanApplicationFormExtractor from './Documents/MobileHomeLoanApplicationFormExtractor'
import NonQMCalculator from './Tools/NonQMCalculator/NonQMCalculator'
import LoanBorrowerPopUp from './Common/components/loanOrBorrow'
import ImportMISMOFile from "./Common/components/ImportMISMOFile/index"
import { withStatsig } from "./statsig/statsigMethod.js"
const { Header, Content, Footer, Sider } = Layout
const { Item: MenuItem } = Menu
const { confirm } = Modal
const mapStateToProps = (state) => {
    return {
        subscriptionReducer: state.subscriptionReducer.currentInfo,
        isNonQMCustomer: state.subscriptionReducer.currentInfo.configurations?.isNonQMCustomer,
    }
}
// const subscription = useSelector(state => {

//   return state.subscriptionReducer.currentInfo
// })
class HomePage extends React.Component {
    state = {
        shrunk: false,
        collapsed: false,
        avatar: "/avatar.png",
        email: "",
        firstName: "",
        lastName: "",
        current: "dashboard",
        companyName: "",
        navDrawer: false,
        isModalOpen: false,
        showLoanPop: false,
        openLoanPopup: false,
        loanType: '',
        openCreate: false,
        getInfo: false,
        visible: false,
        quickCreate: false,
        showFeedback: false,
        createLoanType: 'manually'
    }

    shrink = () => {
        this.setState({ shrunk: !this.state.shrunk })
    }
    listenResizeEvent = e => {
        //console.log("listenResizeEvent")
        if (window.innerWidth < 800) {
            if (!this.state.hidden) {
                this.setState({ hidden: true, shrunk: true })
                //console.log("small resize")
            }
        } else {
            if (this.state.hidden) {
                this.setState({ hidden: false, shrunk: false })
                //console.log("big resize")
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            sessionStorage.removeItem('currentBorrower')

            this.setMenuSelect()
        }
    }

    componentDidMount() {

        this.setMenuSelect()
        com.getReturnURLs()
        eventBus.addListener('updateProfile', this.getprofile)
        window.addEventListener('resize', this.listenResizeEvent)
        window.addEventListener("popstate", this.setMenuSelect.bind(this))
        this.listenResizeEvent()
        this.getprofile()
        this.getCompanyName()
    }
    componentWillUnmount() {
        eventBus.removeListener('updateProfile', this.getprofile)
        window.addEventListener('resize', this.listenResizeEvent)
        window.removeEventListener("popstate", this.setMenuSelect.bind(this))
    }
    setMenuSelect = () => {
        const urlObj = {
            'dashboard': 'welcome',
            'activeLoans': 'active-loans',
            'loans': 'loans',
            // 'leads' : '',
            'borrowers': 'borrowers',
            'rates': 'purchasecalculator',
            'nonqmcalculator': 'nonqmcalculator',
            'gpt': 'mortgagegpt',
            'mySite': 'landingpage',
            'management': 'management',
            // 'search' : '',
            'settings': 'profile',
            "mobilehomeloan": "mobilehomeloan",

        }
        const currentKey = Object.keys(urlObj).find(key => window.location.pathname.includes(urlObj[key]))
        if (currentKey) {
            this.setState({ current: currentKey, collapsed: currentKey !== 'dashboard' })
        }
    }
    getCompanyName = () => {
        fetch('/data/companycustomization', {
            headers: {
                'X-CustomerId': com.getCustomerId() || 'zeitro',
            }
        }).then(async response => {
            if (!response.ok) {
                return
            }
            response.json().then(json => {
                this.setState({ companyName: json.CompanyName })
            })
        })
    }
    getprofile = () => {
        let token = window.sessionStorage.getItem("ZeitroA")
        fetch("/los/getprofile", {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {
            let d = new Date()
            //console.log(JSON.stringify(response))
            if (!response.ok) {
                console.log("Auth fetch error, at: " + d.toLocaleString())
                sessionStorage.removeItem("ZeitroA")
            } else {

                response.json().then(js => {
                    //console.log(JSON.stringify(js))
                    if (js.Status !== "OK") {

                    } else {
                        let loan = js.LoanOfficers[0]
                        this.setState({ avatar: loan.Image === '' ? '/avatar.png' : loan.Image, email: loan.Email, firstName: loan.FirstName, lastName: loan.LastName })
                    }
                })
            }
        }).catch(error => {
            let d = new Date()
            console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
        })
    }
    getClassName = () => {


        if (this.state.collapsed) {
            if (this.state.quickCreate) {
              return 'collapsedStyle quick-create-btn-wrap'
            } else {
              return 'collapsedNormal quick-create-btn-wrap'
            }
        } else {
            if (this.state.quickCreate) {
                return 'IconStyle quick-create-btn-wrap'
            } else {
                return 'quick-create-btn-wrap'
            }

        }
    }
    getItem = (label, key, icon, children, type) => {
        return {
            key,
            icon,
            children,
            label,
            type,
        }
    }
    onClose = () => {
        this.setState({ navDrawer: false })
    }
    showDrawer = () => {
        this.setState({ navDrawer: !this.state.navDrawer })
    }
    toDashboard = () => {
        this.props.history.push('/app/welcome')
    }
    logout = (props) => {
        props.history.push('/app/logout')
    }
    modalCancel = () => {
        this.setState({ loanTypeModal: false, createLoanType: 'manually' })
    }
    modalConfirm = () => {
        this.modalCancel()
        if (this.state.createLoanType === 'manually') {
            const info = sessionStorage.getItem('currentBorrower')
            if (info !== null) {
                this.setState({
                    loanType: "loan",
                    openCreate: true,
                    visible: false
                })
            } else {
                this.setState({
                    loanType: "loan",
                    openLoanPopup: true,
                    visible: false,
                })
            }
        } else {
            this.setState({ mismoModal: true })
        }
    }
    onChangeCreateLoanType = (e) => {
        this.setState({ createLoanType: e.target.value })
    }
    mismoModalCancel = () => {
        this.setState({ mismoModal: false })
    }
    callSidebar = () => {
        if (checkAloneAccess([UR_PriceEngineUser])) {
            return ""
        }
        const sidebaricon = (key) => {
            return this.state.current === key ? <img className='sidebar-icon' src={`/sidebaricons/${key}-active.svg`} /> : <img className='sidebar-icon' src={`/sidebaricons/${key}.svg`} />
        }
        const urlObj = {
            'dashboard': 'welcome',
            'activeLoans': 'active-loans',
            'loans': 'loans',
            // 'leads' : '',
            'borrowers': 'borrowers',
            'rates': 'purchasecalculator',
            'nonqmcalculator': 'nonqmcalculator',
            'censuschecker': 'censuschecker',
            'specials': 'programengine',
            'gmcccelebrityrates': 'gmcccelebrityrates',
            'gmccuniverserates': 'gmccuniverserates',
            'gmccdiamond': 'gmccdiamond',
            'gmcccalculators': 'gmcccalculators',
            'gpt': 'mortgagegpt',
            'mySite': 'landingpage',
            'management': 'management',
            'settings': 'creditpullsetting',
            'mobilehomeloan': 'mobilehomeloan',
        }

        const onSidebarClick = (e) => {

            this.setState({ collapsed: e.key !== 'dashboard', current: e.key, showLoanPop: false })
            if (e.key == "quickCreate") return
            const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
            if (status === undefined) return
            if (e.key === 'gmcccalculators') {
                if (status === 'free') {
                    this.setState({ isModalOpen: true })
                    return

                }

            }
            let url = `/app/${urlObj[e.key]}`
            this.props.statsigClient.logEvent(`lo_click_sidebar_${e.key}`, com.getCustomerId(), {
                operation_method: "click",
                item_name: "lo_sidebar_click",
                timestamp: new Date().getTime(),
                customerid: com.getCustomerId(),
                email: this.state.email.toLowerCase().trim(),
            });
            this.props.history.push(url)
        }
        const logout = (props) => {
            confirm({
                title: 'Are you sure you want to log out?',
                onOk() {
                    props.history.push('/app/logout')
                },
                onCancel() {

                },
            })
        }

        const handleCollapse = () => {
            this.setState({ collapsed: !this.state.collapsed })
        }
        const toDashboard = () => {
            this.props.history.push('/app/welcome')
        }
        const handleQuickCreate = (flag) => {
          this.setState({ quickCreate: flag })
        }
        const items = [
            {
                key: '1',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                        1st menu item
                    </a>
                ),
            },
            {
                key: '2',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                        2nd menu item
                    </a>
                ),
            },
            {
                key: '3',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                        3rd menu item
                    </a>
                ),
            },
        ]
        // return <div className="call-sidebar"><Col className="px-2 m-0 " style={{position:"sticky", top:"55px", height:"calc(100vh - 56px)"}}><Sidebar /></Col></div>
        return (
            <Spin spinning={false}>
                <div className='call-sidebar' onClick={(e) => {
                    // e.preventDefault()
                    // e.stopPropagation()
                    // this.setState({ showLoanPop: false })

                }}>
                    <div className='collapse-btn' onClick={handleCollapse}>{this.state.collapsed ? <RightOutlined /> : <LeftOutlined />}</div>
                    <Sider className='main-sider' collapsible collapsed={this.state.collapsed} trigger={null}>
                        {!this.state.collapsed && <div className='sidebar-logo'><div className='logo-box' onClick={toDashboard} style={{ fontSize: this.state.companyName.length > 14 ? 20 : 24 }}>{this.state.companyName}</div></div>}
                        {/* <Menu onClick={onSidebarClick} defaultSelectedKeys={[this.state.current]} mode="inline" items={items} /> */}
                        <Menu className='main-menu' onClick={onSidebarClick} selectedKeys={[this.state.current]} mode="inline">
                            <MenuItem key="dashboard" icon={sidebaricon('dashboard')}>Dashboard</MenuItem>
                            <MenuItem key="activeLoans" icon={sidebaricon('activeLoans')}>Loans</MenuItem>
                            {/* <MenuItem key="leads" icon={sidebaricon('leads')}>Leads</MenuItem> */}
                            <MenuItem key="borrowers" icon={sidebaricon('borrowers')}>Borrowers</MenuItem>
                            <MenuItem key="rates" icon={sidebaricon('rates')}>Rates</MenuItem>
                            {this.props.isNonQMCustomer && <MenuItem key="nonqmcalculator" icon={sidebaricon('nonqmcalculator')}>NonQM</MenuItem>}
                            {/* {com.getCustomerId() === "gmcc" && checkAccess([UR_Owner]) && <MenuItem key="specials" icon={sidebaricon('rates')}>Programs Map</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmcccelebrityrates" icon={sidebaricon('gmcccelebrityrates')}>Celebrity</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmccuniverserates" icon={sidebaricon('gmccuniverserates')}>Universe</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmccdiamond" icon={sidebaricon('gmccdiamond')}>Diamond</MenuItem>} */}
                            {com.getCustomerId() === "changehomemortgage" && <MenuItem key="censuschecker" icon={sidebaricon('gmcccalculators')}>Census Checker</MenuItem>}
                            {com.getCustomerId() === "gmcc" && <MenuItem key="gmcccalculators" icon={sidebaricon('gmcccalculators')}>GMCC Specials</MenuItem>}
                            <MenuItem key="gpt" icon={sidebaricon('gpt')}>Guideline GPT</MenuItem>
                            {com.getCustomerId() !== "yeeleecapital" && !checkAloneAccess([UR_LoanProcessor]) && <MenuItem key="mySite" icon={sidebaricon('mySite')}>My site</MenuItem>}
                            {checkAccess([UR_LoanOfficerManager, UR_Owner]) && <MenuItem key="management" icon={sidebaricon('management')}>Management</MenuItem>}
                            {(com.getCustomerId() === "zeitro" || com.getCustomerId() === "serenityhomeloans") && <MenuItem key="mobilehomeloan" icon={sidebaricon('mobilehomeloan')}>MH.AI</MenuItem>}

                            <div className='menu-line'></div>
                            <MenuItem key="settings" icon={sidebaricon('settings')}>Settings</MenuItem>


                            <div style={this.state.collapsed ? {
                                padding: "0 3px",
                                marginTop: -4
                            } : this.state.quickCreate ? {
                                padding: "0 8px",
                                marginTop: 4
                            } : { marginTop: 4 }}>
                                <Popconfirm
                                    open={this.state.quickCreate}
                                    trigger="click"
                                    placement="left"
                                    showCancel={false}
                                    overlayClassName='quick-create-pop'
                                    arrow={false}
                                    icon={null}
                                    title={null}
                                    okButtonProps={{ style: { display: 'none' } }}
                                    onOpenChange={handleQuickCreate}
                                    description={

                                        <div style={{ width: '122px', cursor: 'pointer' }} >
                                            <div
                                                onClick={() => {
                                                    this.setState({loanTypeModal: true})
                                                }}
                                                style={{
                                                    borderBottom: "1px solid #DBDCDE",
                                                    height: 36,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingLeft: 12
                                                }}>
                                                <img src='/images/moneyLoans.svg' ></img>
                                                <span className='loadTitleP'> Loan</span>
                                            </div>
                                            <div
                                                style={{
                                                    height: 36,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingLeft: 12
                                                }}
                                                onClick={() => {
                                                    // document.getElementById("#root").click()

                                                    this.setState({
                                                        // showLoanPop: false,
                                                        loanType: "borrower",
                                                        openLoanPopup: true,
                                                        visible: false
                                                    })
                                                }}>
                                                <img src='/images/peopleload.svg' />

                                                <span className='loadTitleP'> Borrower</span>
                                            </div>

                                        </div>
                                    }

                                >

                                    {
                                      this.state.collapsed ? (
                                        <Tooltip
                                          placement="right" 
                                          title="Quick create"
                                          align={{
                                            offset: [16, 0]
                                          }}
                                        >
                                          <div
                                              onClick={(e) => {
                                                  e.preventDefault()
                                                  e.stopPropagation()
                                                  this.setState({ showLoanPop: true, visible: true })
                                              }}
                                              className={this.getClassName()}
                                              style={this.state.quickCreate ? {
                                                  background: '#D6E2FB',
                                                  borderRadius: 3, cursor: 'pointer', paddingLeft: 8, height: 40
                                              } : { cursor: 'pointer', height: 40 }}
                                          >
                                              <img style={{
                                                  width: 32,
                                                  height: 32,
                                                  marginLeft: '-2px'
                                              }} src='/images/add-square.svg'></img>
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                this.setState({ showLoanPop: true, visible: true })
                                            }}
                                            className={this.getClassName()}
                                            style={this.state.quickCreate ? {
                                                background: '#D6E2FB',
                                                borderRadius: 3, cursor: 'pointer', paddingLeft: 8, height: 40
                                            } : { paddingLeft: 16, cursor: 'pointer', height: 40 }}
                                        >
                                            <img style={{width: 24, height: 24}} src='/images/add-square.svg'></img>
                                            {<span style={{ fontWeight: 600, marginLeft: 16 }} onClick={(e) => {
                                                // e.preventDefault()
                                                // e.stopPropagation()
                                                // this.setState({ showLoanPop: true })
                                            }}>Quick create</span>}
                                        </div>
                                      )
                                    }
                                </Popconfirm>
                            </div>

                            <div style={this.state.collapsed ? {
                                marginTop:10, paddingLeft: 16, cursor: 'pointer'
                                
                            } : { marginTop: 10, paddingLeft: 16, cursor: 'pointer' }}>
                                <div onClick={() => {
                                    this.setState({showFeedback: !this.state.showFeedback})
                                }}>
                                    <img style={this.state.collapsed ? {width: 31, height: 31} : {width: 24, height: 24}} src='/images/icon-feedback.svg'></img>
                                    {!this.state.collapsed && <span style={{fontWeight: 600, marginLeft: 16}}>Send feedback</span>}
                                </div>
                            </div>

                        </Menu>
                        <div>

                        </div>
                        <div className={`sidebar-botttom ${this.state.collapsed ? 'sidebar-collapsed-style' : ''}`} >
                            <img alt="avatar" className="header-img" src={this.state.avatar} />

                            {this.state.collapsed && <div style={{ marginTop: 2 }} className='logout' onClick={() => logout(this.props)}>logout</div>}
                            {
                                !this.state.collapsed && (
                                    <div style={{ flex: 1, marginLeft: 10 }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '3px' }}><div className='load-name'><Tooltip title={`${this.state.firstName} ${this.state.lastName}`}>{`${this.state.firstName} ${this.state.lastName}`}</Tooltip></div><div className='logout' onClick={() => logout(this.props)}>logout</div></div>
                                        <div className='load-email'><Tooltip title={this.state.email}>{this.state.email}</Tooltip></div>
                                    </div>
                                )
                            }
                        </div>
                    </Sider>
                </div>
            </Spin>
        )
    }
    render = () => {
        const loanTypeFooter = [
            <Button className="mismo-btn-back" type='link' onClick={this.modalCancel}>Cancel</Button>,
            <Button className="mismo-btn-confirm" type="primary" onClick={this.modalConfirm}>Confirm</Button>
        ]
        return (
            <div id="myapp" className="py-0 mb-0">
                
                <Helmet>
                    <title>{this.state.companyName} - mortgage platform </title>
                </Helmet>
                {/* <AnthenticatedMenu /> */}
                <div className='mobileMenu'>
                    <div className='sidebar-logo'><div className='logo-box' onClick={this.toDashboard} style={{ fontSize: "15px" }}>{this.state.companyName}</div></div>
                    <img src='/images/menu.svg' onClick={this.showDrawer} />
                </div>
                <Row id="pane" className="w-100 mx-0 px-0 pt-0 align-top d-flex backimage0">
                    {this.callSidebar()}
                    <Col id="home" className="my-0  text-wrap align-top home p-0 m-0" >
                        <Switch>
                            <Route exact path="/app/home">
                                <h1>Welcome back!</h1>
                            </Route>

                            <RouteWrapper
                                Component={WelcomePage}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/welcome"
                            />
                            <RouteWrapper
                                Component={Pricing}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/pricing"
                            />
                            <RouteWrapper
                                Component={Billing}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/billing"
                            />
                            <RouteWrapper
                                Component={Subscribe}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/billing/subscribe"
                            />
                            <RouteWrapper
                                Component={Account}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/billing/account"
                            />
                            <RouteWrapper
                                Component={MortgageGPT}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/mortgagegpt/:threadID?"
                            />
                            <RouteWrapper
                                Component={Tasks}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/tasks"
                            />
                            <RouteWrapper
                                Component={Pipeline}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/prospect-loans"
                            />
                            <RouteWrapper
                                Component={ActiveLoans}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_Owner, UR_LoanOfficer]}
                                exact
                                path="/app/active-loans"
                            />
                            <RouteWrapper
                                Component={FundedLoans}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_Owner, UR_LoanOfficer]}
                                exact
                                path="/app/funded-loans"
                            />
                            <RouteWrapper
                                Component={Management}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/management"
                            />
                            <RouteWrapper
                                Component={MobileHomeLoanApplicationFormExtractor}
                                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                                exact
                                path="/app/mobilehomeloan"
                            />
                            <RouteWrapper
                                Component={Borrowers}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/borrowers/:borrowerid?"
                            />
                            <RouteWrapper
                                Component={LOInput}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/loinput"
                            />

                            <RouteWrapper
                                Component={Borrower}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                path="/app/borrower/:id"
                            />

                            <RouteWrapper
                                Component={BorrowerForLoanProcessors}
                                roles={[UR_LoanProcessor]}
                                path="/app/borrowerforlp/:id"
                            />
                            <RouteWrapper
                                Component={BorrowersForLoanProcessors}
                                roles={[UR_LoanProcessor]}
                                path="/app/borrowersforlp"
                            />
                            <RouteWrapper
                                Component={ContactCustomer}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/emailcustomer"
                            />

                            <RouteWrapper
                                Component={Login}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/customerregistration"
                            />

                            <RouteWrapper
                                Component={Affordability}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/affordability"
                            />

                            <RouteWrapper
                                Component={Profile}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/profile"
                            />
                            <RouteWrapper
                                Component={Setting}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/creditpullsetting"
                            />

                            <RouteWrapper
                                Component={RateQuoter}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/purchasecalculator"
                            />

                            <RouteWrapper
                                Component={NonQMCalculator}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/nonqmcalculator"
                            />

                            <RouteWrapper
                                Component={CensusChecker}
                                roles={[UR_Owner, UR_LoanOfficer]}
                                exact
                                path="/app/censuschecker"
                            />

                            <RouteWrapper
                                Component={GMCCProgramEngine}
                                roles={[UR_Owner]}
                                exact
                                path="/app/programengine"
                            />
                            <RouteWrapper
                                Component={GMCCUniverseCalculator}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/gmccuniverserates"
                            />
                            <RouteWrapper
                                Component={RateQuoter}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/gmcccelebrityrates"
                            />
                            <RouteWrapper
                                Component={GMCCDiamond}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/gmccdiamond"
                            />
                            <RouteWrapper
                                Component={GMCCCalculators}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/gmcccalculators"
                            />
                            <RouteWrapper
                                Component={IntervewBorrowerView}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/interviewborrower"
                            />

                            <RouteWrapper
                                Component={SubscriptionDashboard}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/distribution"
                            />
                            <RouteWrapper
                                Component={ReportIssuesForm}
                                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                                exact
                                path="/app/feedback"
                            />
                            {/* <RouteWrapper
                Component={LandingPageSetup}
                roles={[UR_LoanOfficer, UR_Owner]}
                exact
                path="/app/landingpage"
              /> */}
                            <RouteWrapper
                                Component={NewPreviewLandingPageSetup}
                                roles={[UR_LoanOfficer, UR_Owner, UR_LoanOfficerManager]}
                                exact
                                path="/app/landingpage"
                            />
                            <RouteWrapper
                                Component={LogoutPage}
                                roles={[UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner, UR_LoanProcessor, UR_PriceEngineUser]}
                                exact
                                path="/app/logout"
                            />

                            <Route >
                                <h1>Error 404! Can't render this URL!</h1>
                            </Route>

                        </Switch>
                    </Col>
                </Row>
                <Notifications />
                <Drawer
                    title={
                        <div className='d-flex align-items-center'>
                            <img style={{ width: "30px", height: "30px", marginRight: "10px" }} alt="avatar" className="header-img" src={this.state.avatar} />
                            <div style={{ marginRight: "5px" }}>{this.state.firstName + " " + this.state.lastName}</div>
                            <div style={{ marginTop: 2, textDecoration: "underline", color: "#325CEB" }} className='logout' onClick={() => this.logout(this.props)}>logout</div>
                        </div>
                    }
                    rootClassName="mobileMenu-Drawer"
                    placement="top"
                    onClose={this.onClose}
                    open={this.state.navDrawer}>
                    <div className='nav' onClick={() => { this.props.history.push('/app/welcome'); this.setState({ navDrawer: false }) }}>Dashboard</div>
                    <div className='nav' onClick={() => { this.props.history.push('/app/active-loans'); this.setState({ navDrawer: false }) }}>Loans</div>
                    <div className='nav' onClick={() => { this.props.history.push('/app/borrowers'); this.setState({ navDrawer: false }) }}>Borrowers</div>
                    <div className='nav' onClick={() => { this.props.history.push('/app/purchasecalculator'); this.setState({ navDrawer: false }) }}>Rates</div>
                    {/* {com.getCustomerId() === "gmcc" && checkAccess([UR_Owner]) && <div className='nav' onClick={() => { this.props.history.push('/app/programengine'); this.setState({ navDrawer: false }) }}>Program Map</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmcccelebrityrates'); this.setState({ navDrawer: false }) }}>Celebrity</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmccuniverserates'); this.setState({ navDrawer: false }) }}>Universe</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmccdiamond'); this.setState({ navDrawer: false }) }}>Diamond</div>} */}
                    {com.getCustomerId() === "changehomemortgage" && <div className='nav' onClick={() => { this.props.history.push('/app/censuschecker'); this.setState({ navDrawer: false }) }}>Census Checker</div>}
                    {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmcccalculators'); this.setState({ navDrawer: false }) }}>GMCC Specials</div>}
                    <div className='nav' onClick={() => { this.props.history.push('/app/mortgagegpt'); this.setState({ navDrawer: false }) }}>Guideline GPT</div>
                    {com.getCustomerId() !== "yeeleecapital" && <div className='nav' onClick={() => { this.props.history.push('/app/landingpage'); this.setState({ navDrawer: false }) }}>My site</div>}
                    {checkAccess([UR_LoanOfficerManager, UR_Owner]) && <div className='nav' onClick={() => { this.props.history.push('/app/management'); this.setState({ navDrawer: false }) }}>Management</div>}
                    <div className='nav' onClick={() => { this.props.history.push('/app/profile'); this.setState({ navDrawer: false }) }}>Settings</div>
                </Drawer>
                <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {

                    this.setState({ isModalOpen: false })

                }}></PlanModal>
                <Modal
                  className="homePage-create-loan-modal"
                  centered
                  width={436}
                  onCancel={() => {
                    this.setState({
                        openCreate: false,

                    })

                  }} 
                  footer={<div className='create-loan-modal-footer'>

                    <Button 
                      onClick={() => {
                        this.setState({
                            openCreate: false,
                            openLoanPopup: true,
                            getInfo: false
                        })
                      }} 
                      className='create-loan-modal-btn' 
                    >
                      No
                    </Button>
                    <Button 
                      onClick={() => {
                        this.setState({
                            openCreate: false,
                            getInfo: true,
                            openLoanPopup: true
                        })
                      }}
                      className='create-loan-modal-btn'
                    >
                      Yes
                    </Button>
                </div>} open={this.state.openCreate}>
                    <div className='create-loan-modal-content'>
                      <div style={{
                          fontFamily: 'Inter',
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#222",
                          lineHeight: 'normal'
                      }}>Create a loan</div>
                      <div style={{
                          fontFamily: 'Inter',
                          fontSize: 16,
                          lineHeight: '20px',
                          color: "#222",
                          marginTop: 29
                      }}>
                          Would you like to use this borrower's information to create a loan?
                      </div>
                    </div>
                </Modal>
                <Modal
                    className='homePage-create-loan-type-modal'
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                    maskClosable={true}
                    width={566}
                    open={this.state.loanTypeModal}
                    centered={true}
                    title=""
                    onCancel={this.modalCancel}
                    footer={loanTypeFooter}
                >
                    <div className='create-loan-type-modal-title'>Create loan</div>
                    <div className='create-loan-type-modal-content'>
                        <div className='create-loan-type-modal-text'>Do you want create a new application mannually or import from an existing MISMO file?</div>
                        <div>
                            <Radio.Group onChange={this.onChangeCreateLoanType} value={this.state.createLoanType}>
                                <Space size={24} direction="vertical">
                                    <Radio className='loan-type-radio' value='manually'><span className='loan-type-radio-label'>Create manually</span></Radio>
                                    <Radio className='loan-type-radio' value='import'><span className='loan-type-radio-label'>Import MISMO file</span></Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                </Modal>
                <ImportMISMOFile
                    open={this.state.mismoModal}
                    onCancel={this.mismoModalCancel}
                >
                </ImportMISMOFile>
                <LoanBorrowerPopUp
                    getInfo={this.state.getInfo}
                    submit={(value) => {
                        // this.reload()
                        // //   this.createNewApplication(value)
                        // //         console.log(value)

                    }}
                    type={this.state.loanType}
                    open={this.state.openLoanPopup} onClose={() => {

                        this.setState({
                            getInfo: false,
                            openLoanPopup: false
                        })
                    }}></LoanBorrowerPopUp>
                <FloatingFeedback showFeedback={this.state.showFeedback} setShowFeedback={(value) => {
                    this.setState({showFeedback: value})
                }} />
            </div>
        )
    }
}
export default withStatsig(
    withRouter(connect(mapStateToProps)(HomePage)));