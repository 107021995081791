import React, { useMemo, useState, useCallback } from 'react';
import { Collapse, Typography } from 'antd';
import { isEmpty } from 'lodash';
import "./Faq.css";
import { faqData, moreFaqData } from '../commonData'
import { useStatsigClient } from "@statsig/react-bindings/src";

export default function Faq({ faqInfo, loanOfficerEmail }) {
    const [faqs, setFaqs] = useState(isEmpty(faqInfo) ? [] : faqInfo.length > 3 ? faqInfo.slice(0, 3) : faqInfo);
    const [showMore, setShowMore] = useState(false);
    const client = useStatsigClient();
    const [activeKeys, setActiveKeys] = useState([]);

    const handleClickMoreFaqs = useCallback(() => {
        setShowMore(!showMore);

        //statsig event
        const allQuestions = faqInfo?.length ?
            faqInfo.map(faq => faq.label).join(', ') :
            [...faqData, ...moreFaqData].map(faq => faq.label).join(', ');
        
        client.logEvent("lo_detail_page_more_faq_click", window.location.href, {
            item_name: "loDetailPageMoreFaqClick",
            startTime: new Date,
            timestamp: Date.now(),
            pageUrl: window.location.href,
            loanOfficerID: loanOfficerEmail,
            type: "personalsite",
            questions: allQuestions,
        });

        if (faqInfo.length == undefined) {
            if (!showMore) {
                setFaqs([...faqData, ...moreFaqData])
                setShowMore(!showMore)
            } else {
                setFaqs(faqData)
                setShowMore(!showMore)
            }
        } else {
            if (!showMore) {
                setFaqs(faqInfo)
                setShowMore(!showMore)
            } else {
                setFaqs(faqInfo.slice(0, 3))
                setShowMore(!showMore)
            }
        }
    }, [showMore]);

    const handleFaqChange = useCallback((newActiveKeys) => {
        const changedKey = newActiveKeys.length > activeKeys.length
            ? Number(newActiveKeys[newActiveKeys.length - 1])
            : Number(activeKeys.find(key => !newActiveKeys.includes(key)));

        setActiveKeys(newActiveKeys);

        const currentFaq = faqs.find(faq => faq.key === changedKey);

        if (!currentFaq) {
            return;
        }

        const isExpanding = newActiveKeys.includes(String(changedKey));

        client.logEvent(
            isExpanding ? "lo_detail_page_faq_show_more_click" : "lo_detail_page_faq_show_less_click",
            window.location.href,
            {
                item_name: isExpanding ? "loDetailPageFaqShowMoreClick" : "loDetailPageFaqShowLessClick",
                startTime: new Date(),
                timestamp: Date.now(),
                pageUrl: window.location.href,
                loanOfficerID: loanOfficerEmail,
                type: "personalsite",
                question: currentFaq.label,
                answer: currentFaq.children,
            }
        );
    }, [activeKeys, faqs, client, loanOfficerEmail]);

    return (
        <div className="faq-container">
            <div className="faq-title">
                Frequent Asked Questions
            </div>

            <div className="faq-content">
                <Collapse
                    items={faqs}
                    bordered={false}
                    className="faq-collapse"
                    expandIconPosition="end"
                    onChange={handleFaqChange}
                    activeKey={activeKeys}
                />
            </div>

            <button onClick={handleClickMoreFaqs} className="more-faqs-button">
                {showMore ? 'Close' : 'More FAQS'}
            </button>
        </div>
    );
}