import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Container, ButtonGroup } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
import "../../App.css";
import "./RateQuote.css";
import * as com from "../../Common.js";
import NumericalInput, {CommaizedInput} from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import IneligibleProductsModal from "./IneligibleProducts";
import AllRatesModal, { AllRatesTable } from "./NewAllRates";
import ReportIssuesModal from "./ReportIssues";
import { YellowWarning } from "../../Common/Warnings";
import {Radio, Skeleton, Select, Input, InputNumber, Switch, Checkbox, Popover, Popconfirm, Spin, Modal, Row, Col, Slider, Dropdown, Tooltip, Table, AutoComplete, message} from "antd";
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";
import { checkAccess } from "../../Auth";
import { UR_PriceEngineUser, UR_LoanOfficer, UR_Owner } from "../../State";
import AutoProgress from '../../DocumentChecklist/AutoProgress';
import { CaretRightOutlined } from '@ant-design/icons';
import Lenders from "../../Management/Lenders";
import AddressAutocomplete from "../../Common/AddressAutocomplete";
// import mockData from "./response_sample.json"
import { useStatsigClient } from "@statsig/react-bindings/src";

const DEFAULT_LOAN_TERM = 30; //year
const mobileHomeLenders = ["Triad", "CreditHuman"]

const tenK_qualifiedCounties = {
  California: [
    "Contra Costa",
    "Los Angeles",
    "Napa",
    "Orange",
    "Placer",
    "Riverside",
    "Sacramento",
    "San Bernardino",
    "San Diego",
    "San Francisco",
    "San Mateo",
    "Santa Clara",
    "Ventura",
  ],
};
const thirtyK_qualifiedCounties = {
  Massachusetts: ["Essex", "Middlesex", "Norfolk", "Suffolk"],
};
const fiveK_qualifiedCounties = {
  "North Carolina": [
    "Alexander",
    "Ashe",
    "Avery",
    "Beaufort",
    "Bladen",
    "Brunswick",
    "Buncombe",
    "Burke",
    "Cabarrus",
    "Caldwell",
    "Carteret",
    "Catawba",
    "Chatham",
    "Cherokee",
    "Clay",
    "Columbus",
    "Craven",
    "Cumberland",
    "Duplin",
    "Durham",
    "Forsyth",
    "Franklin",
    "Graham",
    "Greene",
    "Guilford",
    "Halifax",
    "Harnett",
    "Haywood",
    "Henderson",
    "Iredell",
    "Jackson",
    "Johnston",
    "Lee",
    "Lenoir",
    "Lincoln",
    "Macon",
    "Madison",
    "Martin",
    "McDowell",
    "Mecklenburg",
    "Moore",
    "Nash",
    "New Hanover",
    "Onslow",
    "Orange",
    "Pamlico",
    "Pasquotank",
    "Pender",
    "Pitt",
    "Polk",
    "Randolph",
    "Rockingham",
    "Rutherford",
    "Sampson",
    "Stanly",
    "Stokes",
    "Surry",
    "Swain",
    "Transylvania",
    "Union",
    "Vance",
    "Wake",
    "Warren",
    "Watauga",
    "Wayne",
    "Wilkes",
    "Wilson",
    "Yadkin",
    "Yancey",
  ],
  "South Carolina": [
    "Abbeville",
    "Aiken",
    "Anderson",
    "Barnwell",
    "Beaufort",
    "Berkeley",
    "Charleston",
    "Chester",
    "Chesterfield",
    "Clarendon",
    "Darlington",
    "Dillon",
    "Dorchester",
    "Edgefield",
    "Fairfield",
    "Florence",
    "Georgetown",
    "Greenville",
    "Greenwood",
    "Hampton",
    "Horry",
    "Kershaw",
    "Lancaster",
    "Laurens",
    "Lee",
    "Lexington",
    "Marion",
    "Marlboro",
    "McCormick",
    "Newberry",
    "Oconee",
    "Orangeburg",
    "Pickens",
    "Richland",
    "Saluda",
    "Spartanburg",
    "Sumter",
    "Williamsburg",
    "York",
  ],
  Georgia: [
    "Chatham",
    "Cherokee",
    "Cobb",
    "Columbia",
    "De Kalb",
    "Forsyth",
    "Franklin",
    "Fulton",
    "Gwinnett",
    "Hall",
    "Hart",
    "Madison",
    "McDuffie",
    "Pickens",
    "Richmond",
    "Stephens",

  ],
};

const zeroDown_qualifiedCounties = {
  California: [
    "San Francisco",
    "San Mateo",
    "Santa Clara",
    "Sacramento",
    "Contra Costa",
    "Los Angeles",
    "Orange",
    "Riverside",
    "San Bernardino",
    "San Diego",
    "Placer",
    "Napa",
    "Ventura",
  ],
  Georgia: [
    "Chatham",
    "Cherokee",
    "Cobb",
    "Columbia",
    "DeKalb",
    "Forsyth",
    "Franklin",
    "Fulton",
    "Gwinnett",
    "Hall",
    "Hart",
    "Madison",
    "McDuffie",
    "Pickens",
    "Richmond",
    "Stephens",
  ],
  Massachusetts: ["Essex", "Middlesex", "Norfolk", "Suffolk"],
  "North Carolina": [
    "Alexander",
    "Ashe",
    "Avery",
    "Beaufort",
    "Bladen",
    "Brunswick",
    "Buncombe",
    "Burke",
    "Cabarrus",
    "Caldwell",
    "Carteret",
    "Catawba",
    "Chatham",
    "Cherokee",
    "Clay",
    "Columbus",
    "Craven",
    "Cumberland",
    "Duplin",
    "Durham",
    "Forsyth",
    "Franklin",
    "Graham",
    "Greene",
    "Guilford",
    "Halifax",
    "Harnett",
    "Haywood",
    "Henderson",
    "Iredell",
    "Jackson",
    "Johnston",
    "Lee",
    "Lenoir",
    "Lincoln",
    "McDowell",
    "Macon",
    "Madison",
    "Martin",
    "Mecklenburg",
    "Mitchell",
    "Montgomery",
    "Moore",
    "Nash",
    "New Hanover",
    "Onslow",
    "Orange",
    "Pamlico",
    "Pasquotank",
    "Pender",
    "Pitt",
    "Polk",
    "Randolph",
    "Richmond",
    "Robeson",
    "Rockingham",
    "Rowan",
    "Rutherford",
    "Sampson",
    "Stanly",
    "Stokes",
    "Surry",
    "Swain",
    "Transylvania",
    "Tyrrell",
    "Union",
    "Vance",
    "Wake",
    "Warren",
    "Washington",
    "Watauga",
    "Wayne",
    "Wilkes",
    "Wilson",
    "Yadkin",
    "Yancey",
  ],
  "South Carolina": [
    "Abbeville",
    "Aiken",
    "Anderson",
    "Barnwell",
    "Beaufort",
    "Berkeley",
    "Charleston",
    "Cherokee",
    "Chester",
    "Chesterfield",
    "Clarendon",
    "Darlington",
    "Dillon",
    "Dorchester",
    "Edgefield",
    "Fairfield",
    "Florence",
    "Georgetown",
    "Greenville",
    "Greenwood",
    "Horry",
    "Kershaw",
    "Lancaster",
    "Laurens",
    "Lee",
    "Lexington",
    "Marion",
    "Marlboro",
    "McCormick",
    "Newberry",
    "Oconee",
    "Orangeburg",
    "Pickens",
    "Richland",
    "Saluda",
    "Spartanburg",
    "Sumter",
    "Union",
    "Williamsburg",
    "York",
  ],
  Virginia: [
    "Albermarle",
    "Alleghany",
    "Arlington",
    "Augusta",
    "Bedford",
    "Botetourt",
    "Brunswick",
    "Campbell",
    "Carroll",
    "Charlottesville City",
    "Chesterfield",
    "Covington City",
    "Danville City",
    "Emporia City",
    "Fairfax",
    "Fairfax City",
    "Frederick",
    "Fredericksburg City",
    "Galax City",
    "Greensville",
    "Harrisonburg City",
    "Henrico",
    "Loudoun",
    "Lunenburg",
    "Lynchburg City",
    "Mecklenburg",
    "Montgomery",
    "Nottoway",
    "Pittsylvania",
    "Roanoke",
    "Roanoke City",
    "Rockingham",
    "Salem City",
    "Spotsylvania",
    "Staunton City",
    "Warren",
    "Waynesboro City",
    "Winchester City",
  ],
};

const craSpecial_qualifiedCounties = {
  "North Carolina": ["Franklin", "Johnston", "Wake","Chatham", "Durham", "Orange", "New Hanover", "Cabarrus", "Mecklenburg","Union", "Moore"],
  "South Carolina": ["Beaufort", "York", "Berkeley", "Charleston", "Dorchester"],
}

const mapSpecialAdjustments = {
  LMIB: -0.125,
  LMIT: -0.125,
  MMCT: -0.125,
  CRA: -0.75,
  Holiday: -0.25,
  Market: -0.375
}

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const RateQuoteLOVersion = (props) => {
  let customerid = com.getCustomerId();
  const { client } = useStatsigClient();
  
  const [specialAdjustments, setSpecialAdjustments] = useState({});
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [loanTerm,setLoanTerm] = useState("fixed30");
  const [productTypes, setProductTypes] = useState(["Agency", "Jumbo", "FHA"]);
  const [showForm, setShowForm] = useState(true);
  const [useDiscountFormat, setUseDiscountFormat] = useState(false);
  const [showConnectedLendersOnly, setShowConnectedLendersOnly] = useState(true);
  const [eligibilityView, setEligibilityView] = useState("eligible");
  const [pointFormat, setPointFormat] = useState(true);
  const [targetPriceDollar, setTargetPriceDollar] = useState(3000);
  const [targetPricePoint, setTargetPricePoint] = useState(101.75); //for zeitro set compensation as 1.75, for generate client use 100
  const [calcOptions, setCalcOptions] = useState(["lockpoint", "adjustment", "compensation"]);
  const [resultlenderSet, setResultlenderSet] = useState(new Set());
  const [formContainerHeight, setFormContainerHeight] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [extended, setExtended] = useState(true);
  const [stdproducts,setStdProducts] = useState([
    {label:"Agency",value: "Agency"},
    {label:"Jumbo",value: "Jumbo"},
    {label:"FHA",value: "FHA"},
    {label:"VA",value: "VA"},
  ]);
  const [assets, setAssets] = useState("fullassets");
  const [incomeCondition, setIncomeCondition] = useState("fullincome");
  const [employment,setEmployment] = useState("Employed");
  const [emptydti,setEmptyDTI] = useState(false);
  const [emptymonthlyIncome,setEmptymonthlyIncome] = useState(false);
  const [notmodified,setNotmodified] = useState(true);
  const [loanLimits, setLoanLimits] = useState({});
  const [emptyLtv, setEmptyLtv] = useState(false)
  const [emptyDownpay,setEmptyDownpay] = useState(false)
  const [emptyPropertyVal,setEmptyPropertyVal] = useState(false)
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");
  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("ALAMEDA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [dti, setDTI] = useState(10);
  const [monthlyIncome, setMonthlyIncome] = useState(10000);
  const [monthlyDebt, setMonthlyDebt] = useState(3000);
  const [estOtherCosts, setEstOtherCosts] = useState(0);
  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [lockDay, setLockDay] = useState(30);
  const [citizenship, setCitizenship] = useState("US_CITIZENS");
  const [visaType, setVisaType] = useState("H_1B");
  const [numberOfOwnedProperties, setNumberOfOwnedProperties] = useState(1);
  const [currentJobInMonths, setCurrentJobInMonths] = useState(24);
  const [secondMortageAmount, setSecondMortgageAmount] = useState(0);
  const [escrowWaiver, setEscrowWaiver] = useState(false)
  const [selfEmployed, setSelfEmployed] = useState(false)
  const [rentalIncomeUsed, setRentalIncomeUsed] = useState(false)
  const [firstTimeHomeBuyer, setfirstTimeHomeBuyer] = useState("Yes")
  const [appraisalWaiver, setAppraisalWaiver] = useState(false)
  const [rsuAsIncome, setRsuAsIncome] = useState(false)
  const [giftFundUsed, setGiftFundUsed] = useState(false)
  const [workForSpecialBusiness, setWorkForSpecialBusiness] = useState(false)
  const [nonOccupancyCoBorrower, setNonOccupancyCoBorrower] = useState(false)
  const [borrowerPaidCompensation, setBorrowerPaidCompensation] = useState(false)
  const [borrowerPaidCompensationAmount, setBorrowerPaidCompensationAmount] = useState(0)
  
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [showReportIssues, setShowReportIssues] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [loProfile, setLOProfile] = useState({});

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [showFileModal, setShowFileModel] = useState(false);
  const handleCloseFileModal = () => setShowFileModel(false);
  const handleShowFileModal = () => setShowFileModel(true);
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);

  const [showEditCostDetails, setShowEditCostDetails] = useState(false);
  const [address, setAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [celebrityCounty, setCelebrityCounty] = useState("");
  const [celebrityState, setCelebrityState] = useState("");
  const [celebrityAddress, setCelebrityAddress] = useState("");
  const [loadingTractData, setLoadingTractData] = useState(false);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [skipCRA, setSkipCRA] = useState(true);
  const [medianIncome, setMedianIncome] = useState(0);
  const [incomeIndicator, setIncomeIndicator] = useState("");
  const [minority, setMinority] = useState(0);
  const [mfi, setMfi] = useState(0);
  const [applyLMIB, setApplyLMIB] = useState(false);
  const [applyLMIT, setApplyLMIT] = useState(false);
  const [applyMMCT, setApplyMMCT] = useState(false);
  const [totalPopulation, setTotalPopulation] = useState(0);
  const [hispanic, setHispanic] = useState(0);
  const [black, setBlack] = useState(0);
  const [asian, setAsian] = useState(0);
  const [pacific, setPacific] = useState(0);
  const [msaCode, setMsaCode] = useState(0);
  const [applyExtraCRA, setApplyExtraCRA] = useState(false);
  const handleEditCostDetailsModal = () => setShowEditCostDetails(false);
  const [cannotshop, setCannotshop] = useState([])
  const [canshop, setCanshop] = useState([])
  const [cannotshopOptions, setCannotshopOptions] = useState([])
  const [canshopOptions, setCanshopOptions] = useState([])
  const [connectedLendersMap, setConnectedLendersMap] = useState({});
  const [openLender, setOpenLender] = useState(false)
  const [lenderData, setLenderData] = useState([])
  const specificProgram = props.specificProgram

  let getHeight = () => {
    if (checkAccess([UR_PriceEngineUser])) {
      return viewportHeight-52
    }
    return viewportHeight - 85
  }

  let targetPriceOptions = [
    { label: "Lock point", value: "lockpoint" },
    { label: "Adjustment", value: "adjustment" },
    { label: "Compensation", value: "compensation" },

  ];
  let preselectedTermOptions = [
    { name: "Fixed 30 Yr", key: "fixed30" },
    { name: "Fixed 15 Yr", key: "fixed15" },
    { name: "ARM 5/6", key: "arm56" },
  ];

  let productTypesMap = {
    Agency: ["CONFORMING", "SUPER_CONFORMING"],
    Jumbo: ["JUMBO"],
    FHA: ["FHA", "FHA_HIGH_BALANCE"],
    VA: ["VA", "VA_HIGH_BALANCE"],
  }

  let fileSuffixMap = {
    Fairway: "pdf",
    HomePoint: "xlsx",
    PRMG: "xlsx",
    AmeriHome: "xlsx",
    HomePointDelegated: "xlsx",
    FifthThirdBank: "xls",
    RocketPro: "xlsx",
    USBank: "pdf",
    PRMG: "xls",
    Caliber: "pdf",
    LoanDepot: "pdf",
    Amwest: "pdf",
    DivineMortgage: "pdf",
    LoanStore: "pdf",
    NewWaveLending: "pdf",
    WholesaleMortgageLender: "pdf",
    ResiCentral: "pdf",
    Provident: "csv",
    GMCCCelebrities : "xlsx",
    FlagStar : "xlsx",
    PlanetHome : "xlsx",
  };

  const TYPE_MAP = {
    Purchase: "Purchase",
    RateTermRefi: "Refinance",
    CashOutRefi: "Cashout"
  }

  useEffect(() => {
    measureFormContainerHeight();

    window.addEventListener("resize", measureFormContainerHeight);

    return () => {
      window.removeEventListener("resize", measureFormContainerHeight);
    }
  },[]);

  useEffect(() => {
    // Function to update viewport height when the window is resized
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth)
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    if (propertyType === "manufacturedhome") {
      setLoanTerm("fixed25")
    }
  }, [propertyType])

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);
  const underwritingOptions = [
    { label: "DU", value: "DU" },
    { label: "LP", value: "LP" },
    { label: "Manual", value: "Manual" },
  ];
  const [selectedAUS, setSelectedAUS] = useState(underwritingOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.round((getLoanAmount() / parseInt(propertyValue)) * 100);
    let cltv =  Math.round(((getLoanAmount() + parseInt(secondMortageAmount)) / parseInt(propertyValue)) * 100);
    let hcltv =  Math.round(((getLoanAmount() + parseInt(secondMortageAmount))/ parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, cltv, hcltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  let checkGrantEligibility = () => {
    if (loanTerm !=="fixed30") {
       return "None"
    }

    if((tenK_qualifiedCounties[celebrityState] !== undefined && (tenK_qualifiedCounties[celebrityState].length===0|| tenK_qualifiedCounties[celebrityState].includes(celebrityCounty.replace("County", "").trim()))) && 
    (monthlyIncome*12 <= mfi*1.5 || (monthlyIncome*12 <= mfi*2 && firstTimeHomeBuyer)) && 
    (minority>50 || incomeIndicator === 1 || incomeIndicator === 2) &&
    fico >=620 && getLoanAmount()>=25000 && getLoanAmount()<=766550 && getLtv()[0]>=75
  ){
        return "10K"
    }
    else if ( (fiveK_qualifiedCounties[celebrityState] !== undefined && (fiveK_qualifiedCounties[celebrityState].length===0|| fiveK_qualifiedCounties[celebrityState].includes(celebrityCounty.replace("County", "").trim()))) && 
    (monthlyIncome*12 <= mfi*1.5 || (monthlyIncome*12 <= mfi*2 && firstTimeHomeBuyer)) && 
    (minority>50 || incomeIndicator === 1 || incomeIndicator === 2) &&
    fico >=620 && getLoanAmount()>=25000 && getLoanAmount()<=766550 && getLtv()[0]>=80
  ){
      return "5K"
    }
    else if((thirtyK_qualifiedCounties[celebrityState] !== undefined && (thirtyK_qualifiedCounties[celebrityState].length===0|| thirtyK_qualifiedCounties[celebrityState].includes(celebrityCounty.replace("County", "").trim()))) && 
    (monthlyIncome*12 <= mfi*1.5 || (monthlyIncome*12 <= mfi*2 && firstTimeHomeBuyer)) && 
    (minority>50 || incomeIndicator === 1 || incomeIndicator === 2) &&
    fico >=620 && getLoanAmount()>=25000 && getLoanAmount()<=766550 && getLtv()[0]>=80
  ){
      return "30K"
    }
    else{
      return "None"
    }
  }

  let checkCelebrityLendingArea = () => {
    if (skipCRA){
      let parsedState = com.capitalize(state)
      let parsedCounty = com.capitalize(county)
      if ( (zeroDown_qualifiedCounties[parsedState] !== undefined && (zeroDown_qualifiedCounties[parsedState].length===0|| zeroDown_qualifiedCounties[parsedState].includes(parsedCounty.replace("County", "").trim())))){
        return true
      } else {
        return false
      }
    }
    if (showUnavailable){
      let parsedState = com.capitalize(state)
      let parsedCounty = com.capitalize(county)
      if ( (zeroDown_qualifiedCounties[parsedState] !== undefined && (zeroDown_qualifiedCounties[parsedState].length===0|| zeroDown_qualifiedCounties[parsedState].includes(parsedCounty.replace("County", "").trim())))){
        return true
      } else {
        return false
      }
    } else {
      if ( (zeroDown_qualifiedCounties[celebrityState] !== undefined && (zeroDown_qualifiedCounties[celebrityState].length===0|| zeroDown_qualifiedCounties[celebrityState].includes(celebrityCounty.replace("County", "").trim())))){
        return true
      }
      else{
        return false
      }
    }
  }

  let downloadRatesheet = (loan) => {
    let s2ab = (s) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    let blob = new Blob([s2ab(atob(loan.FileB))], { type: "" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    if (fileSuffixMap[loan.Lender] == undefined) {
      handleShowFileModal();
      return;
    }

    link.download = loan.Lender + "_Ratesheet." + fileSuffixMap[loan.Lender];
    link.click();
  };


  let handleEditCostDetails = () => {
    if (showEditCostDetails) {
      setShowEditCostDetails(false);
    } else {
      setShowEditCostDetails(true);
    }
  };

  let getProducts = () => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;


    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();
    
    if (state != "TEXAS") _county = county;

    let aus = []
    for (let a of selectedAUS) {
      aus.push(a.label)
    }

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: false,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      firsttimehomebuyer: firstTimeHomeBuyer==="Yes" ? true : false,
      selfemployed: selfEmployed,
      citizenship: citizenship,
      visaType: visaType,
      numberOfOwnedProperties: numberOfOwnedProperties,
      escrowWaiver: escrowWaiver,
      appraisalWaiver: appraisalWaiver,
      currentJobInMonths: currentJobInMonths,
      rsuAsIncome: rsuAsIncome ? 10 : -1,
      giftFundUsed: giftFundUsed,
      familyRelatedOrSmallBusiness: workForSpecialBusiness,
      borrowerPaidCompensationAmount: borrowerPaidCompensationAmount,
      dti:dti,
      Rate_LMIBAdjustment: applyLMIB,
      Rate_LMITAdjustment: applyLMIT,
      Rate_MMCTAdjustment: applyMMCT,
      Rate_CRAAdjustment: applyExtraCRA,
    };
    setLoading(true);
    // statsig logevent for Rate Quotes Generated
    const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
    client.logEvent("use_rate_quote", savedUserInfo.custom.customerid, {
      item_name: "useRateQuote",
      startTime: new Date,
      timestamp: Date.now(),
      FICO: fico,
      purpose: purpose,
      customerid: savedUserInfo.custom.customerid,
      email: savedUserInfo.email
    })

    fetch("/data/lopriceengine/" + customerid, {
      method: "POST",
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/json",
        "userEmail": savedUserInfo.email
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          return;
        }
        response.json().then((prd) => {
          const noLendersAdded = Object.values(prd.connected_lender_map).every(value => value === false)
          setConnectedLendersMap(prd.connected_lender_map)
          let products = prd.result;
          let prTypesSet = new Set();
          if (products !== undefined) {
            if (specificProgram !== undefined) {
              products = products.filter((p) => p.Lender === specificProgram);
            }
            let lenderSet = new Set();
            products.forEach((product) => {
              lenderSet.add(product.Lender);
            });
            setResultlenderSet(lenderSet);
            const data = Array.from(lenderSet).map((lender) => ({
              label: com.divideStringByConsecutiveCapitalLetter(lender),
              value: lender,
              myLenders: prd.connected_lender_map[lender] || false
            })).sort((a, b) => b.myLenders - a.myLenders)
            setLenderData(data)

            setSelectedLenders(showConnectedLendersOnly? Array.from(lenderSet).filter(x=>prd.connected_lender_map[x]): Array.from(lenderSet));

            // if no lenders are added
            if (noLendersAdded) {
              setShowConnectedLendersOnly(false);
              setShowIneligible(false);
              setShowAddLendersModal(true);
              setSelectedLenders(Array.from(lenderSet))
            }            
          } 

          if (prd.ineligible_reasons != undefined) {
            if (specificProgram !== undefined) {
              if (prd.ineligible_reasons[specificProgram]) {
                let ine = {}
                ine[specificProgram] = prd.ineligible_reasons[specificProgram]
                setIneligibleProducts(ine);
              }
            } else {
              let ine = {}
              for (let key in prd.connected_lender_map) {
                if (prd.ineligible_reasons[key]) {
                  ine[key] = prd.ineligible_reasons[key]
                }
              }
              setIneligibleProducts(ine);
            }
          } else {
            setIneligibleProducts({});
          }
          if (products === undefined) {
            setNoProducts(true)
            setShowIneligible(true);
            if(prd.message ==="can not get lender fee data"){
              setShowIneligible(false);
              setShowAddLendersModal(true);
            }
            setLoading(false);
            return;
          } else {
            setNoProducts(false)
          }
          products.forEach((product) => {
            let lender = product.Lender;
            let fb = product.FileB;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              let adjustments = pr.appliedAdjustments;
              let ausengine = pr.AUSEngine;
              if (showConnectedLendersOnly && !noLendersAdded) {
                if (prd.connected_lender_map[lender]) {
                  prTypesSet.add(commonproduct.Mtype);
                }
              } else {
                prTypesSet.add(commonproduct.Mtype);
              }

              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  Adjustments: adjustments,
                  FileB: fb,
                };
                
                if (rate.lockDay !== lockDay) {
                  return;
                }
                let commonAUS = selectedAUS.filter(val => pdkt.Aus.includes(val.label));
                if (commonAUS.length === 0) {
                  return
                }
                const filterPdkt = () => {
                  if (customerid==="siliconcitymortgage" && pdkt.Mtype.includes("FHA")) {
                    return false
                  }
                  return true
                }

                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();
                if (filterPdkt()) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                }
              });
            });
          });
          
          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            const filteredPrds = products.filter((p) => {
              return p.lender_credit > 0;
            });

            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0;
            let bestPoints = 1000000;
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 1500);
                bestIndex = i;
              }
            }
            return bestIndex;
          };
          let matchingProductTypes = (arr) => {
            let detectedTypes = []
            for (let type of arr) {
              for (let key of Object.keys(productTypesMap)) {
                if (productTypesMap[key].includes(type)) {
                  detectedTypes.push(key)
                }
              }
            }
            setProductTypes(detectedTypes)
          }
          
          matchingProductTypes(Array.from(prTypesSet));

          allRates = {};
          allRates.fixed30 = fixed30;
          allRates.fixed25 = fixed25;
          allRates.fixed20 = fixed20;
          allRates.fixed15 = fixed15;
          allRates.fixed10 = fixed10;
          allRates.arm56 = arm56;
          allRates.arm76 = arm76;
          allRates.arm106 = arm106;
          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);


          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
        }).catch((err) => {
          message.error('An internal error has occured')
          setLoading(false)
        })
      })
      .catch((err) => {
        setLoading(false)
      })
  }


  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    };


  const ref = useRef();
  const formContainerRef = useRef(null);

  const measureFormContainerHeight = () => {
    if (formContainerRef.current) {
      setFormContainerHeight(formContainerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    measureFormContainerHeight();

    window.addEventListener("resize", measureFormContainerHeight);

    return () => {
      window.removeEventListener("resize", measureFormContainerHeight);
    }
  },[]);

  useEffect(() => {
    if (celebrityState !== "" && celebrityCounty !== "") {
      setLoadingTractData(true)
      fetch("/data/loanlimitswithincome", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: celebrityAddress,
          State: celebrityState,
          City: city,
          Zip: zip,
        })
      })
        .then((res) => {
          if (!res.ok) {
            setShowUnavailable(true)
            setLoadingTractData(false)
            return
          }
          res.json().then((js) => {
            setShowUnavailable(false)      
            setMfi(js.MedianIncome)
            setIncomeIndicator(js.IncomeIndicator)
            setMinority(js.MinorityPercent)
            setTotalPopulation(js.TotalPopulation)
            setHispanic(js.Hispanic)
            setBlack(js.Black)
            setAsian(js.Asian)
            setPacific(js.Pacific)
            setMsaCode(js.MsaCode)
            setLoadingTractData(false)
        })
    })
    .catch((err) => {
        
    });
    }
  }, [celebrityCounty, celebrityState, celebrityAddress]);

  useEffect(() => {
    if (celebrityState !== "" && celebrityCounty !== "") {
        fetch("/data/gethudmedianfamilyincome", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              State: celebrityState,
              County: celebrityCounty,
            })
          })
            .then((res) => {
              if (!res.ok) {
                setMedianIncome(181300)
                return
              }
              res.json().then((js) => {
                setMedianIncome(js.MedianIncome)
              });
            })
            .catch((err) => {
              
            })
    }   
  },[celebrityAddress,celebrityCounty,celebrityState])

  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  useEffect(() => {
    let specialAdj = {}
    let total = 0
    if (purpose === "Purchase" && occupancy==="principal" && loanTerm === "fixed30" && (zeroDown_qualifiedCounties[celebrityState] !== undefined && (zeroDown_qualifiedCounties[celebrityState].length===0|| zeroDown_qualifiedCounties[celebrityState].includes(celebrityCounty.replace("County", "").trim())))){
      if (monthlyIncome*12 <= mfi*0.8){
        setApplyLMIB(true)
        specialAdj["LMIB"] = mapSpecialAdjustments["LMIB"]
        total += mapSpecialAdjustments["LMIB"]
      }
      if (incomeIndicator === 1 || incomeIndicator === 2){
        setApplyLMIT(true)
        specialAdj["LMIT"] = mapSpecialAdjustments["LMIT"]
        total += mapSpecialAdjustments["LMIT"]
      }
      if (minority > 50){
        setApplyMMCT(true)
        specialAdj["MMCT"] = mapSpecialAdjustments["MMCT"]
        total += mapSpecialAdjustments["MMCT"]
      }
    }
    if (purpose === "Purchase" && occupancy==="principal"){
      specialAdj["Holiday"] = mapSpecialAdjustments["Holiday"]
      total += mapSpecialAdjustments["Holiday"]
    }
    specialAdj['Market'] = mapSpecialAdjustments["Market"]
    total += mapSpecialAdjustments["Market"]
    if (loanTerm === "fixed30"){
      if ((fiveK_qualifiedCounties[celebrityState] !== undefined && (fiveK_qualifiedCounties[celebrityState].length===0|| fiveK_qualifiedCounties[celebrityState].includes(celebrityCounty.replace("County", "").trim()))) && (monthlyIncome*12 <= mfi*0.8) && (minority>50)){
        setApplyExtraCRA(true)
        specialAdj["CRA"] = mapSpecialAdjustments["CRA"]
        total += mapSpecialAdjustments["CRA"]
      }
    }
    specialAdj["Total"] = total
    setSpecialAdjustments(specialAdj)

  }, [minority, medianIncome, incomeIndicator, celebrityState, celebrityCounty, monthlyIncome, purpose, occupancy, loanTerm, mfi]);

  useEffect(() => {
    getloanestimatedclosingfee()
    // getProducts();
    
    getLoanLimits();

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            
          } else {
            let lo = js.LoanOfficers[0];
            setLOProfile({
              firstName: lo.FirstName,
              lastName: lo.LastName,
              email: lo.Email,
              phone: lo.Phone,
              avatarsrc: lo.Image,
              nmls: lo.Nmls,
            });
          }
        });
      })
      .catch(function (err) {
        
      });
  }, []);
  const getloanestimatedclosingfee = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getloanestimatedclosingfee", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      response.json().then((js) => {
        if (js.status !== "OK") {
          
        } else {
          let data = js.configurations
          if (data.loanEstimatedClosingFee) {
            let cannotshop = data.loanEstimatedClosingFee.cannotshopservices.length > 0 ? data.loanEstimatedClosingFee.cannotshopservices.map(item => ({ name: item.servicename, value: item.estimateamount })) : []
            let canshop = data.loanEstimatedClosingFee.canshopservices.length > 0 ? data.loanEstimatedClosingFee.canshopservices.map(item => ({ name: item.servicename, value: item.estimateamount })) : []
            if (data.loanEstimatedClosingFee.cannotshopservices.length > 0) {
              setCannotshopOptions(data.loanEstimatedClosingFee.cannotshopservices.map(item => ({ price: item.estimateamount, value: item.servicename })))
              setCannotshop(cannotshop)
            }
            if (data.loanEstimatedClosingFee.canshopservices.length > 0) {
              setCanshopOptions(data.loanEstimatedClosingFee.canshopservices.map(item => ({ price: item.estimateamount, value: item.servicename })))
              setCanshop(canshop)
            }
            updateTotal(cannotshop, canshop)
          }
        }
      })
    })
    .catch(function (err) {
      
    })
  }
  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false);
  }, [showSubscribe, showRatePreview]);

  useEffect(() => {
    setMonthlyDebt(parseInt(monthlyIncome * dti / 100));
  }, [monthlyIncome, dti]);



  let showCounties = () => {
    let options = [<option value="">Select your county</option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates = () => {
    let options = [<option value="">Select your state</option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  }

  let updateTargetPrice = (e) => {
    let val = e.target.value;
    if (val === "") {
      setTargetPriceDollar(0);
      setTargetPricePoint(0);
    } else {
      let num = parseInt(val);
      setTargetPriceDollar(num);
      setTargetPricePoint(num * 100);
    }
  };

  let handleCalcOptionChange = (value) => {
    setCalcOptions(value);
  };

  let handleSubmit = (event) => {
    //statsig logEvent
    const savedUserInfo = JSON.parse(sessionStorage.getItem('statsig_user_info'));
    const type = TYPE_MAP[purpose] || "unknownType";
    client.logEvent("use_celebrity", type, {
      item_name: "useCelebrity",
      action: "clickCheckRates",
      startTime: (new Date).toString(),
      timestamp: Date.now().toString(),
      customerid: savedUserInfo.custom.customerid,
      email: savedUserInfo.email,
      purpose: type
    });
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
 
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      const mapLabel = {
        "Purchase": "Down payment",
        "RateTermRefi": "Mortgage balance",
        "CashOutRefi": "Mortgage balance"
      }
      alert(`${mapLabel[purpose]} can't be bigger than purchase price` );
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    setNotmodified(false);
    setShowForm(false);
    getProducts();
    setExtended(false)
    return false;
  };

  let onMoreOptions = (e) => {
    if (showMoreOptions) {
      setShowMoreOptions(false);
    } else {
      setShowMoreOptions(true);
    }
  };

  let handleCheckProduct = (name) =>{
    let i=parseInt(name)
    let newproducts=[...stdproducts];
    if(newproducts[i].status == "checked"){
      newproducts[i].status = "unchecked"
    }else{
      newproducts[i].status = "checked"
    }
    setStdProducts(newproducts);
  };

  let handleClearProducts = () => {
    let newstdproducts=[...stdproducts];
    newstdproducts.forEach(newstdproduct=>newstdproduct.status="unchecked");
    setStdProducts(newstdproducts);
  };
  let updateTotal = (f1, f2) => {
    let t = 0;
    let all = [...f1, ...f2];
    for (let fe of all) {
      if (!isNaN(parseFloat(fe.value))) {
        t += parseFloat(fe.value)
      }
    }
    setEstOtherCosts(t);
  };
  let getEditCostsModal = () => {
   
    let handleCannotshop = (i, e) => {
      let newFormValues = [...cannotshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };
    let selectCannotshop = (i, option) => {
      setCannotshop((prev) => {
        const updatedCannotshop = prev.map((item, index) =>
          index === i ? { ...item, value: option.price } : item
        )
        updateTotal(canshop, updatedCannotshop)
        return updatedCannotshop
      })
    }
    let addCannotshop = () => {
      setCannotshop([...cannotshop, { name: "", value: "" }]);
    };

    let removeCannotshop = (i) => {
      let newFormValues = [...cannotshop];
      newFormValues.splice(i, 1);
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };
    let handleCanshop = (i, e) => {
      let newFormValues = [...canshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };
    let selectCanshop = (i, option) => {
      setCanshop((prev) => {
        const updatedCanshop = prev.map((item, index) =>
          index === i ? { ...item, value: option.price } : item
        )
        updateTotal(cannotshop, updatedCanshop)
        return updatedCanshop
      })
    }
    let addCanshop = () => {
      setCanshop([...canshop, { name: "", value: "" }]);
    };

    let removeCanshop = (i) => {
      let newFormValues = [...canshop];
      newFormValues.splice(i, 1);
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    }
    return (
      <Modal
          className="edit-fees-modal"
          destroyOnClose
          maskClosable={true}
          width={452}
          open={showEditCostDetails}
          title=""
          onCancel={handleEditCostDetailsModal}
          footer={null}
      >
        <div className="edit-fees-modal-title">Edit Fees</div>
        <div className="edit-fees-modal-describe">The estimated fees on this page is prefilled by your management team. Please make any necessary adjustments.</div>
        <div className="edit-fees-modal-content">
          <Form
            style={{
              height: 'calc(100vh - 400px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <div className="edit-fees-form-label">Services you <span style={{fontWeight: 600}}>CANNOT</span> shop for</div>
            {cannotshop.map((element, index) => (
              <Row gutter={2}>
                <Col sm={13} span={24}>
                  <AutoComplete
                      name="name"
                      className="estFee-select"
                      options={cannotshopOptions}
                      style={{
                        width: "100%",
                        height: 50,
                        marginBottom: 12
                      }}
                      value={element.name || ""}
                      filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(value) => handleCannotshop(index, { target: { name: 'name', value } })}
                      onSelect={(value, option) => selectCannotshop(index, option)}
                  />
                </Col>
                <Col sm={8} span={24}>
                  <InputNumber
                    className="estFee-input"
                    name="value"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    value={element.value || ""}
                    onChange={(value) => handleCannotshop(index, { target: { name: "value", value } })}
                  />
                </Col>
                <Col sm={2} span={24}>
                  <div style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 6, paddingBottom: 12 }}>
                    <img style={{ cursor: 'pointer' }} src="/images/delete.svg" onClick={() => removeCannotshop(index)} />
                  </div>
                </Col>
              </Row>
            ))}
            <div className="estFee-add-more" onClick={() => addCannotshop()}>Add more</div>
            <div className="edit-fees-form-label">Services you <span style={{fontWeight: 600}}>CAN</span> shop for</div>
            {canshop.map((element, index) => (
              <Row gutter={2}>
                <Col sm={13} span={24}>
                  <AutoComplete
                      name="name"
                      className="estFee-select"
                      options={canshopOptions}
                      style={{
                        width: "100%",
                        height: 50,
                        marginBottom: 12
                      }}
                      value={element.name || ""}
                      filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(value) => handleCanshop(index, { target: { name: 'name', value } })}
                      onSelect={(value, option) => selectCanshop(index, option)}
                  />
                </Col>
                <Col sm={8} span={24}>
                  <InputNumber
                    className="estFee-input"
                    name="value"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    value={element.value || ""}
                    onChange={(value) => handleCanshop(index, { target: { name: "value", value } })}
                  />
                </Col>
                <Col sm={2} span={24}>
                  <div style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 6, paddingBottom: 12 }}>
                    <img style={{ cursor: 'pointer' }} src="/images/delete.svg" onClick={() => removeCanshop(index)} />
                  </div>
                </Col>
              </Row>
            ))}
            <div className="estFee-add-more" onClick={() => addCanshop()}>Add more</div>
          </Form>
        </div>

        <div className="edit-fees-modal-footer">
          <div className="total-fees-text">Total estimated fees: ${com.commaize(estOtherCosts)}</div>
          <Button className="edit-fees-modal-save-btn" variant="primary" onClick={handleEditCostDetailsModal}>
            Save
          </Button>
        </div>
      </Modal>
    );
  };
  const updateProperValue = (e) => {
    let val = e.target.value
    if (downpayment > val) {
      updateDownpayment({target: { value: val > 0 ? Number(val) - 1 : 0 }})
    }
    setEmptyLtv(false)
    if (val==="") {
      setEmptyPropertyVal(true)
      setPropertyValue(100000)
    } else {
      setEmptyPropertyVal(false)
      setPropertyValue(parseInt(val))
    }
  }
  const updateMonthlyIncome = (e) => {
    let val = e.target.value
    if (val==="") {
      setEmptymonthlyIncome(true)
      setMonthlyIncome(0)
    } else {
      setEmptymonthlyIncome(false)
      setMonthlyIncome(parseInt(val))
    }
  }
 
  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val > propertyValue) {
      updateProperValue({target: { value: val > 0 ? Number(val) + 1 : 0 }})
    }
    setEmptyLtv(false)
    if (val==="") {
      setDownpayment(Math.round(propertyValue * 0.03))
      setEmptyDownpay(true)
    } else {
      setEmptyDownpay(false)
      setDownpayment(parseInt(val))
    }
  }
  const updateLTV = (e) => {
    let val = e.target.value
    setEmptyDownpay(false)
    if (val==="") {
      setDownpayment(Math.round(propertyValue))
      setEmptyLtv(true)
    } else {
      let ltv = parseInt(val)
      setEmptyLtv(false)
      if (ltv <= 0) { // TBD
        setDownpayment(Math.round(propertyValue))
      } else if (ltv > 100) {
        setDownpayment(Math.round(propertyValue * 0))
      } else {
        if(purpose==="Purchase"){
          setDownpayment(Math.round(propertyValue * (1 - 0.01 * ltv)))
        }
        else{
          setDownpayment(Math.round(propertyValue * (0.01 * ltv)))
        }
      }
    }
  }

  const updateDTI = (e) => {
    let val = e.target.value
    setEmptyDTI(false)
    if (val==="") {
      setDTI(0)
      setEmptyDTI(true)
    } else {
      setEmptyDTI(false)
      setDTI(parseInt(val))
      // setMonthlyDebt(parseInt(val) * 0.01 * parseInt(monthlyIncome))
    }
  }
  const handleChangeEmployment = (e) => {
    setEmployment(e.target.value)
    if (e.target.value==="Selfemployed") {
      setSelfEmployed(true)
    }
  }

  const radioOptions=[
    {
      label:"Purchase",
      value:"Purchase"
    },
    {
      label:"Refinance",
      value:"RateTermRefi"
    },
    {
      label:"Cashout",
      value:"CashOutRefi"
    }
  ]

  const onRadioChange=({target:{value}}) =>{
    setPurpose(value);
  }
  const handleExpandButtonClick = () => {
    setExtended(!extended);
}

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setZip(zip)

    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    setCelebrityState(longstate)
    setState(longstate.toUpperCase())

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setCelebrityCounty(county)
    setCounty(county.toUpperCase())

    let short = com.getSubaddress(address, "route", "long_name");
    setCelebrityAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
    setSkipCRA(false)
  };

  const handleManagementDirect = () => {
    if(window.location.hostname.includes("localhost")){
      window.location.href = "http://localhost:3001/app/management"
    }else{
      window.location.href = "https://lo.zeitro.com/app/management"
    }
  }

  const onEligibilityViewChange = (e) => {
    setEligibilityView(e);
  }

  const getFormBody = () => {
    return (
      <div
      style={{maxWidth:"1050px",display:"flex",flexDirection:"column",justifyContent:"space-between",height:notmodified?formContainerHeight-30:formContainerHeight-60}}
      className="ratequoterForm"
    >
      <div style={{height:formContainerHeight-120,overflowY:"auto", overflowX:"hidden"}}>
      <div style={{textAlign:'center',marginBottom:"20px"}}>
            <Radio.Group
              className="search-fields-tabs"
              options={radioOptions}
              onChange={onRadioChange}
              value={purpose}
              optionType="button"
              buttonStyle="solid"
            />
      </div> 
      <hr style={{borderTop:"1px solid #DBDCDE"}}/> 
      {props.specificProgram === "GMCCCelebrities" && <>
      <Row gutter={20}>
        <Col md={16}>
          <AddressAutocomplete
            id='scenario-address'
            label="Property address"
            required={false}
            name="addessline"
            defaultValue={displayedAddress}
            onChange={(e) => setDisplayedAddress(e.target.value)}
            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
            placeholder="Please type your home address and select from the dropdown addresses"
            goodfeedback="Looks good!"
            badfeedback="Please provide your street address."
            onCompletion={e => onStreetAddressCompletion(e)}
            size="md"
          />
        </Col>
        <Col md={8}>
          <Form.Group className="text-left">
            <Form.Label className="ratequoterFormLabels">
              {" "}
            </Form.Label>
            <Popover 
              overlayClassName="tractInfoTip" 
              placement="bottom"
              content={
                skipCRA?
                <div style={{display:"flex",justifyContent:"center", color:"red", fontWeight:"500"}}>Pleas enter target address first.</div>
                :
                showUnavailable?
                <div style={{color:"#CB8600", display:"flex",justifyContent:"center", fontWeight:"500"}}>Tract info currently unavailable, you can still proceed to search rates without incentives.</div>
                :
                tractInfo
              }
            >
              <div className="tractInfoBtn">{loadingTractData?<Spin />:"Show tract info on this address"}</div>
            </Popover>
          </Form.Group>
        </Col>

        {showUnavailable && <Col className="mb-3" md={24}>
          <div className="incentiveUnavailable">Special incentives check currently unavailable, you can still proceed to search rates for standard programs.</div>
        </Col>}
      </Row>
      </>
      }
      <Row gutter={20}>
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              {purpose === "Purchase"
                ? "Property value"
                : "Home value"}
            </Form.Label>
            <div className="formCommaizedInput">
              <div className="prefix">$</div>
              <CommaizedInput value={emptyPropertyVal?"":propertyValue} onChange={updateProperValue} />
            </div>
          </Form.Group>
        </Col>

        <Col md={8} style={{marginBottom:"0px", paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label
              className="ratequoterFormLabels text-left"
            >
              {purpose === "Purchase"
                ? "Down payment"
                : "Mortgage balance"}
            </Form.Label>
            {/* <div className="rateQuoterFormSliderOutline"> */}
            <div className="formCommaizedInput">
              <div className="prefix">$</div>
              <CommaizedInput value={emptyDownpay?"":downpayment} onChange={updateDownpayment} />
            </div>
          </Form.Group>
        </Col>

        <Col md={8} style={{marginBottom:"0px", paddingRight:"10px"}}>
          <Form.Group className="text-left" style={{display:"flex", flexDirection:"column"}}>
            <Form.Label className="ratequoterFormLabels">
              LTV
            </Form.Label>
            <div className="formInputWithSuffix">
              <input className="ltv" type="number" style={{minWidth:"15px",border:"none",paddingLeft:"10px"}} value={emptyLtv?"":getLtv()[0]} onChange={updateLTV}/>
              <div className="ltvSuffix" style={{marginRight:"10px", backgroundColor:"#FCFCFC"}}>%</div>
            </div>
          </Form.Group>
        </Col>
  
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Loan term
            </Form.Label>
            <Form.Control
              size="sm"
              as="select"
              onChange={(e) => setLoanTerm(e.target.value)}
              value={loanTerm}
              required
            >
              {propertyType !== "manufacturedhome" && <option value="fixed30">Fixed 30 year</option>}
              <option value="fixed25">Fixed 25 year</option>
              <option value="fixed20">Fixed 20 year</option>
              <option value="fixed15">Fixed 15 year</option>
              <option value="fixed10">Fixed 10 year</option>
              <option value="arm56">ARM 5/6</option>
              <option value="arm76">ARM 7/6</option>
              <option value="arm106">ARM 10/6</option>

            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={8}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="occupancy" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Occupancy
            </Form.Label>
            <Form.Control
              name="occupancy"
              as="select"
              size="sm"
              defaultValue={occupancy}
              onChange={(e) => {
                onOccupancy(e.target.value);
              }}
            >
              <option value="principal">Primary Residence</option>
              <option value="secondhome">Second Home</option>
              <option value="investment">Investment</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="propertytype" className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Property type
            </Form.Label>
            <Form.Control
              name="propertytype"
              as="select"
              size="sm"
              type="text"
              required
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
            >
              <option value=""></option>
              <option value="singlefamilydetached">
                Single Family Home, Detached
              </option>
              <option value="singlefamilyattached">
                Single Family Home, Attached
              </option>
              <option value="condominiumdetached">
                Condominium, Detached
              </option>
              <option value="condominium">Condominium, Attached</option>
              <option value="pudattached">
                Planned Unit Development, attached
              </option>
              <option value="puddetached">
                Planned Unit Development, detached
              </option>
              <option value="twotofourfamily">
                2 to 4 Unit Multifamily
              </option>
              <option value="manufacturedhome">Manufactured Home</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {propertyType === "twotofourfamily" ? (
          <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
            <Form.Group controlId="propertytype" className="text-left ">
              <Form.Label 
                className="ratequoterFormLabels"
              >
                Number of units
              </Form.Label>
              <Form.Control
                name="propertytype"
                as="select"
                size="sm"
                type="text"
                required
                value={units}
                onChange={(e) => setUnits(e.target.value)}
              >
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </Form.Control>
            </Form.Group>
          </Col>
        ) : (
          ""
        )}

        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">State</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              id="state"
              onChange={(e) => setState(e.target.value)}
              required
              value={state}
            >
              {showStates()}
            </Form.Control>
          </Form.Group>
        </Col>
        {state !== "TEXAS" ? (
          <Col md={8} style={{marginBottom:"0px", paddingRight:"10px"}} >
            <Form.Group className="text-left ">
              <Form.Label className="ratequoterFormLabels">
                County
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                id="country"
                name="country"
                onChange={(e) => setCounty(e.target.value)}
                value={county}
                required
              >
                {showCounties()}
              </Form.Control>
            </Form.Group>
          </Col>
        ) : (
          ""
        )}
        
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group controlId="firstTimeHomeBuyer" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              {specificProgram==="GMCCCelebrities"?"First generation home buyer" :"First time home buyer"}
            </Form.Label>
            <Form.Control
              name="firstTimeHomeBuyer"
              as="select"
              size="sm"
              defaultValue={firstTimeHomeBuyer}
              onChange={(e) => {
                setfirstTimeHomeBuyer(e.target.value);
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Control>
          </Form.Group>
        </Col>
      
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Credit score
            </Form.Label>
            <Form.Control
              size="sm"
              as="input"
              onChange={(e) => e.target.value===""? setFico(0) : setFico(parseInt(e.target.value))}
              value={fico}
              placeholder="Enter credit score"
              required
            ></Form.Control>
          </Form.Group>
        </Col>

        {!(incomeCondition ==="noincome") && <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label className="ratequoterFormLabels">
              Monthly income
            </Form.Label>
            <div className="formCommaizedInput">
              <div className="prefix">$</div>
              <CommaizedInput value={emptymonthlyIncome?"":monthlyIncome} onChange={updateMonthlyIncome} />
              {specificProgram === "GMCCCelebrities" && <div className="prefix">${com.commaize(monthlyIncome*12)}/yr</div>}
            </div>
          </Form.Group>
        </Col>}

        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Current DTI
            </Form.Label>
            <div className="formInputWithSuffix">
              <input className="ltv" type="number" style={{minWidth:"15px",border:"none",paddingLeft:"10px"}} value={emptydti?"":dti} onChange={updateDTI}/>
              <div className="ltvSuffix" style={{marginRight:"10px", backgroundColor:"#FCFCFC"}}>%</div>
            </div>
          </Form.Group>
        </Col>
      
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
            <Form.Group className="text-left ">
              <Form.Label className="ratequoterFormLabels">
                Lock day
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                onChange={(e) => setLockDay(parseInt(e.target.value))}
                value={lockDay}
                required
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
              </Form.Control>
            </Form.Group>
          </Col>
        <Col md={24}>
          <div className=" d-flex text-left" style={{ marginBottom: "0px", fontWeight:"500", fontSize:"12px",lineHeight:"20px",color:'#586570'}}>
              Target price (Base 100)
          </div>
          <div className="targetPriceFilter">
            <div className="targetPriceInputWrap">
              {pointFormat ? (
                <>
                  <input type="number" className="targetPriceInput" value={targetPricePoint} onChange={e=>setTargetPricePoint(e.target.value)} />
                  <div className="inputSign">%</div>
                </>
              ) : (
                <>
                  <div style={{backgroundColor:"#FCFCFC", marginLeft:"10px",borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingBottom:"13px",paddingRight:"8px"}}>$</div>
                  <CommaizedInput style={{border:"none"}} className="targetPriceInput" value={targetPriceDollar} onChange={updateTargetPrice} />
                </>
              )}
            </div>
            <div className="calcOptionSelectWrap">
              <div className="calcOptionsPrefix">Includes</div>
              <Select 
                mode= "multiple"
                className="calcOptionsSelect"
                allowClear
                value={calcOptions}
                onChange={handleCalcOptionChange}
                options={targetPriceOptions}
                placeholder="Select point type included in the calculation"
              />
            </div>
          </div>
          <div className="calculationHightlight">
            <div>{"*This filter helps identify rates matching your target lender pricing by calculating the current target price from the sum of "}
            {calcOptions.includes("lockpoint") && " lock point "} {calcOptions.includes("lockpoint") && (calcOptions.includes("adjustment") || calcOptions.includes("compensation")) && " , "} 
            {calcOptions.includes("adjustment") && " adjustment point "} {calcOptions.includes("adjustment") && calcOptions.includes("compensation") && " , "}
            {calcOptions.includes("compensation") && " compensation point "}</div>
          </div>
        </Col>
      </Row>
      {showMoreOptions && (
        <Row gutter={20}>
          <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="employment" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Employment
            </Form.Label>
            <Form.Control
              name="employment"
              as="select"
              size="sm"
              defaultValue={employment}
              onChange={handleChangeEmployment}
            >
              <option value="Employed">Employed</option>
              <option value="Selfemployed">Self-Employed</option>
              {/* <option value="Unemployed">Unemployed</option> */}
            </Form.Control>
          </Form.Group>
        </Col>
        {!(employment === "Unemployed") && <Col md={8}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="incomeCondition" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Income
            </Form.Label>
            <Form.Control
              name="incomeCondition"
              as="select"
              size="sm"
              defaultValue={incomeCondition}
              onChange={(e) => {
                setIncomeCondition(e.target.value);
              }}
            >
              <option value="fullincome">Full income</option>
              <option value="partialincome">Partial income</option>
              {/* <option value="noincome">No income</option> */}
            </Form.Control>
          </Form.Group>
        </Col>}
       
        <Col md={8}  style={{marginBottom:"0px",paddingRight:"10px"}} >
          <Form.Group controlId="assets" className="text-left ">
            <Form.Label
              className="ratequoterFormLabels"
            >
              Impound
            </Form.Label>
            <Form.Control
              name="escrow"
              as="select"
              size="sm"
              defaultValue={escrowWaiver}
              onChange={(e) => {
                setEscrowWaiver(e.target.value);
              }}
            >
              <option value="false">Yes</option>
              <option value="true">No</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left numericalInputWraper">
            <Form.Label className="ratequoterFormLabels">
              Second loan amount
            </Form.Label>
            <NumericalInput
              size="sm"
              value={secondMortageAmount}
              required
              onChange={(e) => setSecondMortgageAmount(e.target.value)}
              min={0}
              step={10000}
              noarrow={true}
            />
          </Form.Group>
        </Col>
        <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
            <Form.Group className="text-left">
              <Form.Label className="ratequoterFormLabels">
                Citizenship
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                onChange={(e) => setCitizenship(e.target.value)}
                value={citizenship}
                required
              >
                <option value="US_CITIZENS">US Citizen</option>
                <option value="PERMANENT_RESIDENT_ALIEN">
                  Permanent Resident Alien
                </option>
                <option value="NON_RESIDENT_ALIEN">
                  Non Resident Alien
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
          {(citizenship !== "US_CITIZENS" && citizenship !== "PERMANENT_RESIDENT_ALIEN") && (
            <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
              <Form.Group className="text-left ">
                <Form.Label className="ratequoterFormLabels">
                  Visa type
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  onChange={(e) => setVisaType(e.target.value)}
                  value={visaType}
                  required
                >
                  <option value="A_1"> A-1</option>
                  <option value="A_2"> A-2</option>
                  <option value="A_3"> A-3</option>
                  <option value="B_1"> B-1</option>
                  <option value="B_2"> B-2</option>
                  <option value="B_3"> B-3</option>
                  <option value="C_1"> C-1</option>
                  <option value="C_2"> C-2</option>
                  <option value="C_3"> C-3</option>
                  <option value="C_4"> C-4</option>
                  <option value="C_1D"> C-1D</option>
                  <option value="CP"> CP</option>
                  <option value="D_1"> D-1</option>
                  <option value="D_2"> D-2</option>
                  <option value="E_1"> E-1</option>
                  <option value="E_2"> E-2</option>
                  <option value="E_3"> E-3</option>
                  <option value="F_1"> F-1</option>
                  <option value="F_2"> F-2</option>
                  <option value="F_3"> F-3</option>
                  <option value="G_1"> G-1</option>
                  <option value="G_2"> G-2</option>
                  <option value="G_3"> G-3</option>
                  <option value="G_4"> G-4</option>
                  <option value="G_5"> G-5</option>
                  <option value="H_1A"> H-1A</option>
                  <option value="H_1B"> H-1B</option>
                  <option value="H_1B1"> H-1B1</option>
                  <option value="H_1C"> H-1C</option>
                  <option value="H_2"> H-2</option>
                  <option value="H_2A"> H-2A</option>
                  <option value="H_2B"> H-2B</option>
                  <option value="H_3"> H-3</option>
                  <option value="H_4"> H-4</option>
                  <option value="I"> I </option>
                  <option value="OR_1"> OR-1</option>
                  <option value="J_1"> J-1</option>
                  <option value="J_2"> J-2</option>
                  <option value="K_1"> K-1</option>
                  <option value="K_2"> K-2</option>
                  <option value="K_3"> K-3</option>
                  <option value="K_4"> K-4</option>
                  <option value="L_1"> L-1</option>
                  <option value="L_2"> L-2</option>
                  <option value="M_1"> M-1</option>
                  <option value="M_2"> M-2</option>
                  <option value="M_3"> M-3</option>
                  <option value="N_8"> N-8</option>
                  <option value="N_9"> N-9</option>
                  <option value="NACARA"> NACARA</option>
                  <option value="NATO_1_6"> NATO-1-6</option>
                  <option value="NATO_7"> NATO-7</option>
                  <option value="O_1"> O-1</option>
                  <option value="O_2"> O-2</option>
                  <option value="O_3"> O-3</option>
                  <option value="P_1"> P-1</option>
                  <option value="P_2"> P-2</option>
                  <option value="P_3"> P-3</option>
                  <option value="P_4"> P-4</option>
                  <option value="Q_1"> Q-1</option>
                  <option value="Q_2"> Q-2</option>
                  <option value="Q_3"> Q-3</option>
                  <option value="R_1"> R-1</option>
                  <option value="R_2"> R-2</option>
                  <option value="S_5"> S-5</option>
                  <option value="S_6"> S-6</option>
                  <option value="S_7"> S-7</option>
                  <option value="T_1"> T-1</option>
                  <option value="T_2"> T-2</option>
                  <option value="T_3"> T-3</option>
                  <option value="T_4"> T-4</option>
                  <option value="TD"> TD</option>
                  <option value="TN_1"> TN-1</option>
                  <option value="TN_2"> TN-2</option>
                  <option value="U_1"> U-1</option>
                  <option value="U_2"> U-2</option>
                  <option value="U_3"> U-3</option>
                  <option value="U_4"> U-4</option>
                  <option value="V_1"> V-1</option>
                  <option value="V_2"> V-2</option>
                  <option value="V_3"> V-3</option>
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          <Col md={8} style={{marginBottom:"0px",paddingRight:"10px"}}>
          <Form.Group className="text-left numericalInputWraper">
            <Form.Label className="ratequoterFormLabels d-flex">
              Est. fee
              <img style={{width: 15, height: 15, marginLeft: 6, cursor: 'pointer'}} src='/images/document-edit.svg' onClick={handleEditCostDetails}/>
            </Form.Label>
            <NumericalInput
              size="sm"
              value={estOtherCosts}
              required
              onChange={(e) => setEstOtherCosts(e.target.value)}
              min={0}
              noarrow={true}
            />
          </Form.Group>
        </Col>
        </Row>
        
      )}
    
      <Row className="text-left">
          <Button style={{left: 0, paddingLeft: 0, color:"#325CEB",marginBottom: props.isMobile?"20px":""}}
            variant="rateQuoterAdvancedOptions"
            onClick={onMoreOptions}
            size="sm"
          >
            {showMoreOptions ? <>Less Options</> : <>Advanced Options</>}
            
          </Button>{" "}
      </Row>
      </div>
    </div>
  )}

  const updateResultFromFilter = () => {
    setNotmodified(false);
    getProducts();
  }

  const handleChangeSelectedlenders = (value) => {
    setSelectedLenders(value)
  }

  const tractInfo = <div className='tractInfoPreview w-100'>
    <div className="previewMSA">
        <div className='previewMSAInfo'>
            <div>MSA Code:</div>
            <div>{msaCode}</div>
        </div>
    </div>
    
    <hr />
    <div className='populationIncomeWrap'>
        <div className="previewPopulation">
            <div className='previewInfoWrap'>
                <div>Tract Income Level:</div> 
                {incomeIndicator === 1 && <div>Low Income Area</div>}
                {incomeIndicator === 2 &&<div>Moderate Income Area</div>}
                {incomeIndicator === 3 &&<div>Middle Income Area</div>}
                {incomeIndicator === 4 &&<div>Upper Income Area</div>}
            </div>
            <div className='previewInfoWrap'>
                <div>Tract Minority:</div>
                <div>{Math.floor(minority)}%</div>
            </div>

            <div className='previewInfoWrap'>
                <div>Total Population:</div>
                <div>{totalPopulation}</div>
            </div>
            <div className='previewInfoWrap'>
                <div>Hispanic Population:</div>
                <div>{hispanic} ({(100*hispanic/totalPopulation).toFixed(0)}%)</div>
            </div>
            <div className='previewInfoWrap'>
                <div>Black Population:</div>
                <div>{black} ({(100*black/totalPopulation).toFixed(0)}%)</div>
            </div>
            <div className='previewInfoWrap'>
                <div>Asian/Pacific Population:</div>
                <div>{asian+pacific} ({(100*(asian+pacific)/totalPopulation).toFixed(0)}%)</div>
            </div>
        </div>

        <div className='previewIncomeLimit'>
            <div className='previewInfoWrap'>
                <div>80% AMI:</div>
                <div>${com.commaize(0.8* medianIncome)}</div>
            </div>
            <div className='previewInfoWrap'>
                <div>100% AMI:</div>
                <div>${com.commaize(medianIncome)}</div>
            </div>
            <div className='previewInfoWrap'>
                <div>150% AMI:</div>
                <div>${com.commaize(1.5*medianIncome)}</div>
            </div>
            <div className='previewInfoWrap'>
                <div>80% MFI:</div>
                <div>${com.commaize(0.8* mfi)}</div>
            </div>
            <div className='previewInfoWrap'>
                <div>100% MFI:</div>
                <div>${com.commaize(mfi)}</div>
            </div>
            <div className='previewInfoWrap'>
                <div>150% MFI:</div>
                <div>${com.commaize(1.5* mfi)}</div>
            </div>
        </div>
    </div>
  </div>
  const closeLender = () => {
    setOpenLender(false)
  }
  const handleLenderDropdown = (flag) => {
    setOpenLender(flag)
  }
  const selectAll = (type) => {
    const isMyLenders = type === 'my'
    const selectedLendersForType = lenderData.filter(item => item.myLenders === isMyLenders).map(item => item.value)
    setSelectedLenders((prevSelected) => {
      const isAllSelected = selectedLendersForType.every(item => prevSelected.includes(item));
      if (isAllSelected) {
        return prevSelected.filter(item => !selectedLendersForType.includes(item))
      } else {
        return Array.from(new Set([...prevSelected, ...selectedLendersForType]))
      }
    })
  }
  const clearAll = () => {
    setSelectedLenders([])
  }
  const lenderDropdownContent = () => {
    return (
        <div className='document-lender-content' style={{width: 410}}>
            <div className='lender-title'>
                <span>Lenders</span>
                <div className='close-lender-box'><CloseOutlined className='close-lender' onClick={closeLender} /></div>
            </div>
            <div className="lender-select">
              <Checkbox.Group
                  className='filter-checkbox-group'
                  style={{
                      width: '100%',
                  }}
                  value={selectedLenders}
                  onChange={handleChangeSelectedlenders}
              >
                  <div style={{width: '100%'}}>
                      <div className="lender-select-item-title">
                        <div className="select-title">My lenders</div>
                        <div className="select-all" onClick={() => selectAll('my')}>Select all</div>
                      </div>
                      {
                        lenderData.filter(item => item.myLenders).map((item, index) => (
                            <div style={{height: 42, display: 'flex', alignItems: 'center'}} key={index}>
                                <Checkbox className="lender-checkbox" value={item.value}><span className='lender-checkbox-label'>{item.label}</span></Checkbox>
                            </div>
                        ))
                      }
                      {
                        lenderData.filter(item => !item.myLenders).length > 0 && (
                          <div style={{marginTop: 32}} className="lender-select-item-title">
                            <div className="select-title">
                              <span>Products from other lenders</span>
                              <div style={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 2}}>
                                <Tooltip trigger="click" placement="top" title="Explore competitive rates from Zeitro's partner lenders. Connect directly to learn more and get started.">
                                  <QuestionCircleOutlined style={{fontSize: 14, cursor: 'pointer'}}/>
                                </Tooltip>
                              </div>
                            </div>
                            <div className="select-all"  onClick={() => selectAll('other')}>Select all</div>
                          </div>
                        )
                      }
                      {
                        lenderData.filter(item => !item.myLenders).map((item, index) => (
                            <div style={{height: 42, display: 'flex', alignItems: 'center'}} key={index}>
                                <Checkbox className="lender-checkbox" value={item.value}><span className='lender-checkbox-label'>{item.label}</span></Checkbox>
                            </div>
                        ))
                      }
                  </div>
              </Checkbox.Group>
            </div>
            <div className='lender-action'>
              <div className='lender-clear' onClick={clearAll}>Clear</div>
            </div>
        </div>
    )
  }
  const maincolumns = [
    {
      title: 'Lender & Product',
      dataIndex: 'program',
      key: 'program',
      width:"150px",
      className: "outerRateColumn",
      render:(_,record)=>(<div>{_}</div>),
      sorter:(a, b) => a.program.localeCompare(b.program),
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"70px",
      className: "outerRateColumn",
      render:(_,record)=>(<div>{_}</div>),
      sorter:(a, b) => {
          return a.rate - b.rate;
      },
      sortDirections: ['ascend','descend',"ascend"],
    },
    {
      title: 'PMT',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"90px",
      className: "outerRateColumn",
      render:(_,record)=>(<div>{_}</div>),
      sorter:(a, b) => a.monthlypay - b.monthlypay,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title="The adjusted price is calculated by adding the price adjustment points to the lock day price.">
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img src="/images/rates-price-icon.svg"/> 
          <span>Price</span>
        </div>
      </Tooltip>,
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"130px",
      className: "outerRateColumn",
      render:(_,record)=>(<div>{_}</div>),
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title='The compensation point established on the "Lenders" page.'>LO. Comp.</Tooltip>,
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"110px",
      className: "outerRateColumn",
      render:(_,record)=>(<div>{_}</div>),
      sorter:(a, b) => a.compensationprice - b.compensationprice,
      sortDirections: ['descend','ascend','descend'],
    },

    {
      title: <Tooltip title='The total price is the sum of the lock day price, adjustment points, and compensation points.'>Net pricing</Tooltip>,
      dataIndex: 'points',
      key: 'points',
      width:"120px",
      className: "outerRateColumn",
      render:(_,record)=> (<div>{_}</div>),
      sorter:(a, b) => a.compensationprice - b.compensationprice,
      sortDirections: ['ascend','descend','ascend'],
    }
  ]
  return (
    <div
      id="contain"
      ref={ref}
      style={{backgroundColor:"#F6F6F6",color: "black",justifyContent:"start"}}
      className="w-100 h-100 text-center"
    >
      <div className="w-100">
        <div style={{ height: 68, border: 'none', borderBottom: '1px solid #DBDCDE' }} className="filterWraper">
          {/* <div className="eligibilityWraper">Eligible Selects</div> */}
          <div className="d-flex align-items-center h-100">
            <Select 
              className="eligibilitySelect"
              onChange={(e)=> onEligibilityViewChange(e)}
              value = {eligibilityView}
              options={[
                {value:"eligible",label:"Eligible"},
                {value:"ineligible",label:"Ineligible"}
              ]}
            />
            <div className={props.specificProgram==="GMCCCelebrities"?"gmccFilters d-flex":"filters d-flex"}>
              {/* <div className="formInputOutline mr-2">
                <div className="d-flex mr-2" style={{fontSize:"14px",whiteSpace:"nowrap"}}>Property value</div>
                <div style={{backgroundColor:"FCFCFC", fontSize:"16px"}}>$</div>
                <CommaizedInput style={{border:"none"}} value={emptyPropertyVal?"":propertyValue} onChange={updateProperValue} />
              </div> */}
              <Popconfirm
                overlayClassName="rate-popup-confirm"
                okText="Update"
                showCancel={false}
                onConfirm={updateResultFromFilter}
                icon={null}
                description={
                  <div>
                    <Slider
                      min={0}
                      max={1000000}
                      className="rate-popup-slider"
                      onChange={(value) => updateProperValue({target: { value }})}
                      value={emptyPropertyVal ? 0 : propertyValue}
                      tooltip={{
                        open: true,
                        formatter: (value) => {
                          return <div>${com.commaize(value)}</div>
                        },
                        getPopupContainer: (triggerNode) => triggerNode.parentNode,
                      }}
                    />
                  </div>
                }
              >
                <div style={{height: 44}} className="formInputOutline">
                  <div className="d-flex mr-2" style={{fontSize:"14px",whiteSpace:"nowrap"}}>Property value</div>
                  <div style={{backgroundColor:"FCFCFC", fontSize:"16px",marginRight:"2px"}}>$</div>
                  <div style={{fontSize:"16px"}}>{com.commaize(propertyValue)}</div>
                </div>
              </Popconfirm>
              <Popconfirm
                overlayClassName="rate-popup-confirm"
                okText="Update"
                showCancel={false}
                onConfirm={updateResultFromFilter}
                icon={null}
                description={
                  <div>
                    <Slider
                      min={0}
                      max={propertyValue - 1}
                      className="rate-popup-slider"
                      onChange={(value) => updateDownpayment({target: { value }})}
                      value={emptyDownpay ? 0 : downpayment}
                      tooltip={{
                        open: true,
                        formatter: (value) => {
                          return <div>${com.commaize(value)}</div>
                        },
                        getPopupContainer: (triggerNode) => triggerNode.parentNode,
                      }}
                    />
                  </div>
                }
              >
                <div style={{height: 44}} className="formInputOutline">
                  <div className="d-flex mr-2" style={{fontSize:"14px",whiteSpace:"nowrap"}}>{purpose == "Purchase" ? "Down payment" : "Mortgage balance"}</div>
                  <div style={{backgroundColor:"FCFCFC", fontSize:"16px"}}>$</div>
                  <div style={{fontSize:"16px"}}>{com.commaize(downpayment)}</div>
                </div>
              </Popconfirm>
              <div className="formInputOutline" style={{width: 172, height: 44}}>
                <div className="d-flex mr-2" style={{fontSize:"14px",whiteSpace:"nowrap"}}>Target price</div>
                <input type="number"  value={targetPricePoint} onChange={e=>setTargetPricePoint(e.target.value)} /> 
                {/* <div style={{backgroundColor:"FCFCFC", fontSize:"16px"}}>%</div> */}
              </div>
              <Select
                style={{
                  marginRight:"5px",
                  height: 44
                }}
                className="termSelectShortcut"
                onChange={(e)=> setLoanTerm(e)}
                value={loanTerm}
                options={[
                  {value:"fixed30",label:"Fixed 30 year"},
                  {value:"fixed25",label:"Fixed 25 year"},
                  {value:"fixed20",label:"Fixed 20 year"},
                  {value:"fixed15",label:"Fixed 15 year"},
                  {value:"fixed10",label:"Fixed 10 year"},
                  {value:"arm56",label:"ARM 5/6"},
                  {value:"arm76",label:"ARM 7/6"},
                  {value:"arm106",label:"ARM 10/6"}
                ]}
              />
              <Select 
                mode="multiple"
                style={{
                  marginRight:"5px",
                  height: 44,
                  minWidth: 180
                }}
                className="typeSelect"
                placeholder="Select types"
                value={productTypes}
                options={stdproducts}
                maxTagCount={1}
                maxTagPlaceholder={(values)=>`+${values.length}`}
                onChange={(e)=> setProductTypes(e)}
              />
              <Dropdown
                  trigger={['click']}
                  overlayClassName="filter-dropdown"
                  open={openLender}
                  dropdownRender={lenderDropdownContent}
                  placement="bottomRight"
                  onOpenChange={handleLenderDropdown}
              >
                  <div style={selectedLenders.length === 0 ? {paddingLeft: 0, backgroundColor: '#FFF'} : {}} className="rate-select-lender">
                    {
                      selectedLenders && selectedLenders.length > 0 ? (
                        <>
                          <div className="select-lender-text"><div>{selectedLenders[0]}</div>{selectedLenders.length > 1 && <span>+{selectedLenders.length - 1}</span>}</div>
                          <div className="select-lender-clear"><CloseOutlined style={{fontSize: 13}} onClick={clearAll} /></div>
                        </>
                      ) : (
                        <div>Select lenders</div>
                      )
                    }
                  </div>
              </Dropdown>
              {/* {checkAccess([UR_Owner]) && <div className="pl-3 d-flex justify-content-end  align-items-center bold" >
                <div style={{whiteSpace:"nowrap"}}>Display my lenders only</div>
                <Switch checked={showConnectedLendersOnly} className="ml-2 mr-4" onChange={(e)=>setShowConnectedLendersOnly(!showConnectedLendersOnly)}/>
              </div>}
              <div className="d-flex justify-content-end  align-items-center bold" >
                <div style={{whiteSpace:"nowrap"}}>Switch pricing format</div>
                <Switch checked={useDiscountFormat} className="ml-2 mr-4" onChange={(e)=>setUseDiscountFormat(!useDiscountFormat)}/>
              </div> */}
            </div>
          </div>
          <div className="rates-filter-btn" onClick={()=> setShowForm(true)}>
            <img src="/images/allfiltersIcon.png" style={{width:"24px", height:"24px"}}/>
            <span>All filters</span>
          </div>
        </div>
      </div>
      {notmodified? (<div>
          <div style={{justifyContent:"center", marginTop:props.isMobile?"20px":"300px", fontFamily: 'Inter', width: 324}}>
            <img src="/images/new-explorerates.svg"></img>
            <div style={{textAlign:"left", fontSize: 12, color: '#6E6E70',padding:"0px 32px 0px 32px", lineHeight: 'normal', letterSpacing: '0.2px', marginTop: 8}}>Adjust filters of borrower's information to explore rates and pricing.</div>
            <div className="d-flex justify-content-center"><Button className="rates-search-btn" onClick={()=> setShowForm(true)}>Search rates</Button></div>
          </div>
        </div>
      ):           
      loading ? (
        <div style={{width: '100%', height: '100%', padding: '20px 16px'}}>
          <Table
            className="outertable rates-page-table full-table"
            dataSource={[]}
            pagination={false}
            locale={{
              emptyText: (
                <AutoProgress 
                    type="circle"
                    showInfo={false}
                    percent="auto"
                    strokeWidth={12} 
                    trailColor="#BDCBFB" 
                    strokeColor="#325CEB"
                    size={108}
                />
              ),
            }}
            columns={maincolumns}
          />
        </div>
      ) : 
      ( Object.keys(allRates).length === 0 && !noProducts ? 
        <div className="errorMessageWraper">
          <YellowWarning text="Unfortunately, this feature is currently unavailable. Please contact support@zeitro.com for assistance."/>
        </div>
       :
        (
          (!notmodified)&&noProducts ? 
            <div className="errorMessageWraper">
              <YellowWarning text="No qualified products."/>
            </div> 
          :
          <>
          
          <div className={props.isMobile?"resultContainer-mobile":"resultContainer"} style={{height:"100%"}}>
            <AllRatesTable
            isCelebrity={specificProgram==="GMCCCelebrities"}
            grantAvailable={checkGrantEligibility()}
            checkCelebrityLendingArea={checkCelebrityLendingArea()}
            resultlenderSet={resultlenderSet}
            connectedLendersMap={connectedLendersMap}
            selectedlenders={selectedLenders}
            allRates={allRates}
            formExtended={extended}
            stdproducts={productTypes}
            viewportH = {viewportHeight}
            borrowerPaidCompensationAmount={borrowerPaidCompensationAmount}
            loanInfo={{loanAmount: getLoanAmount(), occupancy: occupancy, purpose: purpose, propertyType: propertyType, fico:fico, ltv:getLtv(),escrow:escrowWaiver}}
            isMobile={isMobile}
            targetPrice={targetPricePoint}
            setTargetPrice={setTargetPricePoint}
            targetOptions={calcOptions}
            loanterm={loanTerm}
            eligibilityView={eligibilityView}
            ineligibleProducts={ineligibleProducts}
            mobileHomeLenders={mobileHomeLenders}
            setShowForm={setShowForm}
            specialAdjustments={specialAdjustments}
          />
          </div>
          </>
        )
      )
      }
      <Modal
          className="rates-search-fields-modal"
          destroyOnClose
          maskClosable={true}
          width={976}
          open={showForm}
          title=""
          onCancel={()=>setShowForm(false)}
          footer={null}
      >
        <Form
          ref={form}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{
            height:"100vh",
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="search-fields-title">Search fields</div>
          <div className="search-fields-content">{getFormBody()}</div>
          <div className="search-fields-footer">
            {checkAccess([UR_Owner]) && <Checkbox rootClassName="showOtherLendersCheck" style={{display:"flex", alignItems:"center",whiteSpace:'nowrap', marginRight: 26}} checked={!showConnectedLendersOnly} onChange={(e)=> setShowConnectedLendersOnly(!e.target.checked)}>
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <span style={{fontFamily: 'Inter', color: '#222'}}>Show products from other lenders</span>
                <Popover overlayClassName="showConnectedTip" content="Explore competitive rates from Zeitro's partner lenders. Connect directly to learn more and get started.">
                  <QuestionCircleOutlined style={{ color: '#8A8C90', marginLeft: 7 }} />
                </Popover>
              </div>
            </Checkbox>}
            <Button type="submit" size="sm" className="search-fields-check-btn" style={{width:props.isMobile?"100%":"", marginBottom:props.isMobile?"10px":""}}>
                Check rates
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        className="lender-tip-modal"
        open={showAddLenderModal}
        onCancel={()=>setShowAddLendersModal(false)}
        footer={null}
        centered
      >
        <div className="lender-tip-modal-content">
          <div style={{color: '#222', fontSize: 20, fontWeight: 600, lineHeight: 'normal', marginBottom: 30}}>You haven't add any company-level lender setting into the system yet.</div>
          <div>
            {checkAccess([UR_Owner])?(
              <div className="d-flex" style={{flexDirection:"column", fontSize: 16, color: '#222', lineHeight: '20px'}}>
                You can see general rates right now, but for rates tailored to your lenders, update your lender settings in the Lenders section on the Management page.            </div>
            ):(
              <div className="d-flex" style={{fontSize: 16, color: '#222', lineHeight: '20px'}}>
                While you can always check general rates, for a more tailored view based on your lenders, your account admin can update settings in the Management page's Lenders section.            </div>
            )}
          </div>
        </div>

        <div className="lender-tip-modal-footer">
          {checkAccess([UR_Owner]) && <Button variant="link" className="lender-tip-modal-updateBtn" key="back" onClick={handleManagementDirect}>
            Update my lenders
          </Button>}
          <Button key="back" className="lender-tip-modal-ratesBtn" onClick={()=>setShowAddLendersModal(false)}>
            View general rates now
          </Button>
        </div>
      </Modal>

      {showReportIssues && (
        <ReportIssuesModal
          show={true}
          onHide={() => setShowReportIssues(false)}
        />
      )}
      {/* {showIneligible && (
        <IneligibleProductsModal
          show={true}
          onHide={() => setShowIneligible(false)}
          ineligibleProducts={ineligibleProducts}
          message={message}
        />
      )} */}
      {showEditCostDetails ? getEditCostsModal() : ""}
    </div>
  );
};
export default withRouter(RateQuoteLOVersion);
