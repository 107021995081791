import React from "react";

import { Col, Row, Space, Typography } from "antd";

import "./Footer.css";
import { GMCCDisclaimer } from "GMCCCalculator/Disclaimer";
import { useStatsigClient } from "@statsig/react-bindings/src";

const { Paragraph } = Typography;

interface FooterProps {
  scrollToSection: (section: string) => void;
  basicInfo: any;
  aboutInfo: any;
  customerID: string;
}

const tabs = [
  { key: "aboutMe", label: "About Me" },
  { key: "rates", label: "Rates" },
  { key: "myCompany", label: "My Company" },
  { key: "reviews", label: "Reviews" },
  { key: "faq", label: "FAQ" },
  { key: "requireCallback", label: "Contact" },
];

const Footer = ({ scrollToSection, basicInfo, aboutInfo, customerID }: FooterProps) => {

  const displaySocialMedia =  aboutInfo && (aboutInfo.twitter || aboutInfo.linkedin || aboutInfo.google || aboutInfo.yelp);

  const { client } = useStatsigClient();

  return (
    <>
    <Row className="landing-page-footer d-flex flex-row justify-content-between align-items-start">
      <Col span={4}>
        <div className="d-flex justify-content-center align-items-start h-100">
          <img
            src={ aboutInfo && aboutInfo.companyLogo || "/images/logo.svg"}
            alt="Zeitro Marketplace"
            width={142}
            height={31}
          />
        </div>
      </Col>
      <Col span={20}>
        <Row>
          <Col span={6}>
            <Row className="footer-header">Sitemap</Row>
            {tabs.map((tab, index) => (
              <Row
                key={index}
                className="footer-link"
                onClick={() => {
                  scrollToSection(tab.key);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  client?.logEvent('footer_button_click', "Sitemap", {
                    item_name: "loDetailPagePrequalifiedClick",
                    startTime: new Date().toISOString(),
                    timestamp: Date.now().toString(),
                    pageUrl: window.location.href,
                    loanOfficerID: aboutInfo.email,
                    type: "personalsite",
                    footerName: tab.label,
                  });
                }}
              >
                {tab.label}
              </Row>
            ))}
          </Col>
          {aboutInfo && aboutInfo.phone && aboutInfo.email && <Col span={6}>
            <Row className="footer-header">Contact</Row>
            <Row className="footer-un-link">{aboutInfo.phone}</Row>
            <Row
              className="footer-link"
                onClick={() => {
                  window.open("mailto:" + aboutInfo.email, "_blank"); 
                  client?.logEvent('footer_button_click', 'Contact', {
                    item_name: "loDetailPagePrequalifiedClick",
                    startTime: new Date().toISOString(),
                    timestamp: Date.now().toString(),
                    pageUrl: window.location.href,
                    loanOfficerID: aboutInfo.email,
                    type: "personalsite",
                    footerName: 'email',
                  });
                }}
            >
              {aboutInfo.email}
            </Row>
          </Col>}
          {displaySocialMedia && <Col span={6}>
            <Row className="footer-header">Social Media</Row>
            <Row>
              <Space
                direction="horizontal"
                className="social-media-icons"
                size={16}
              >
                {aboutInfo.google && <img
                  className="footer-un-link"
                  src="/images/google.svg"
                  alt="Google"
                    onClick={() => {
                      window.open(aboutInfo.google, "_blank"); 
                      client?.logEvent('footer_button_click', 'SocialMedia', {
                        item_name: "loDetailPagePrequalifiedClick",
                        startTime: new Date().toISOString(),
                        timestamp: Date.now().toString(),
                        pageUrl: window.location.href,
                        loanOfficerID: aboutInfo.email,
                        type: "personalsite",
                        footerName: 'Google',
                        google: aboutInfo.google,
                      });
                    }}
                />}
                {aboutInfo.twitter && <img
                  className="footer-un-link"
                  src="/images/twitter.svg"
                  alt="Twitter"
                    onClick={() => {
                      window.open(aboutInfo.twitter, "_blank"); 
                      client?.logEvent('footer_button_click', 'SocialMedia', {
                        item_name: "loDetailPagePrequalifiedClick",
                        startTime: new Date().toISOString(),
                        timestamp: Date.now().toString(),
                        pageUrl: window.location.href,
                        loanOfficerID: aboutInfo.email,
                        type: "personalsite",
                        footerName: 'Twitter',
                        twitter: aboutInfo.twitter,
                      });
                    }}
                />}
                {aboutInfo.linkedin && <img
                  className="footer-link"
                  src="/images/linkedin.svg"
                  alt="Linkedin"
                    onClick={() => {
                      window.open(aboutInfo.linkedin, "_blank"); 
                      client?.logEvent('footer_button_click', 'SocialMedia', {
                        item_name: "loDetailPagePrequalifiedClick",
                        startTime: new Date().toISOString(),
                        timestamp: Date.now().toString(),
                        pageUrl: window.location.href,
                        loanOfficerID: aboutInfo.email,
                        type: "personalsite",
                        footerName: 'Linkedin',
                        linkedin: aboutInfo.linkedin,
                      });
                    }}
                />}
                {aboutInfo.yelp && <img
                  className="footer-link"
                  src="/images/yelp.svg"
                  alt="Yelp"
                    onClick={() => {
                      window.open(aboutInfo.yelp, "_blank"); 
                      client?.logEvent('footer_button_click', 'SocialMedia', {
                        item_name: "loDetailPagePrequalifiedClick",
                        startTime: new Date().toISOString(),
                        timestamp: Date.now().toString(),
                        pageUrl: window.location.href,
                        loanOfficerID: aboutInfo.email,
                        type: "personalsite",
                        footerName: 'Yelp',
                        yelp: aboutInfo.yelp,
                      });
                    }}
                />}
              </Space>
            </Row>
          </Col>}
          <Col span={6}>
            <Row className="footer-header">Legal</Row>
            <Row
              className="footer-link"
              onClick={() => {
                window.open("https://www.zeitro.com/privacy-policy", "_blank");
                client?.logEvent('footer_button_click', 'Legal', {
                  item_name: "loDetailPagePrequalifiedClick",
                  startTime: new Date().toISOString(),
                  timestamp: Date.now().toString(),
                  pageUrl: window.location.href,
                  loanOfficerID: aboutInfo.email,
                  type: "personalsite",
                  footerName: 'Privacy Policy',
                });
              }}
            >
              Privacy Policy
            </Row>
            <Row
              className="footer-link"
              onClick={() => {
                window.open("https://www.zeitro.com/terms-conditions", "_blank");
                client?.logEvent('footer_button_click', 'Legal', {
                  item_name: "loDetailPagePrequalifiedClick",
                  startTime: new Date().toISOString(),
                  timestamp: Date.now().toString(),
                  pageUrl: window.location.href,
                  loanOfficerID: aboutInfo.email,
                  type: "personalsite",
                  footerName: 'Terms of Use',
                });
              }}
            >
              Terms of Use
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    { customerID === "gmcc" && <div className="p-3" style={{backgroundColor: "#fff"}}><GMCCDisclaimer /></div>}
    </>
  );
};

export default Footer;
