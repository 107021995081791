import { Button, Typography } from "antd";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";

const { Text } = Typography;

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [image, setImage] = useState(null);

  const capturePhoto = () => {
    const screenshot = webcamRef.current.getScreenshot();
    setImage(screenshot);
  };

  const [countdown, setCountdown] = useState(3);

  const startCountdown = () => {
    let count = 3;
    setCountdown(count);
    const interval = setInterval(() => {
      count -= 1;
      setCountdown(count);
      if (count === 0) {
        clearInterval(interval);
        capturePhoto();
      }
    }, 1000);
  };

  return (
    <div>
      {!image && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={{ height: "200px" }}
          />
          <Button onClick={startCountdown}>Start Countdown</Button>
          <Text>{countdown > 0 && `Taking picture in ${countdown}...`}</Text>
        </div>
      )}
      {image && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <img src={image} alt="Captured" style={{ height: "200px" }} />
          <Button onClick={() => setImage(null)}>Retake</Button>
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;
