import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { Modal, Pagination, Button, Radio, Image, Dropdown, Steps, DatePicker, Input, AutoComplete, Select, Row, Col, InputNumber, Collapse, Skeleton } from 'antd';
import { MinusOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import * as com from "../Common.js"
import * as act from "../Store/actions"
import { Document, Page, pdfjs } from 'react-pdf';
import './IncomeCalculationModal.css';
const { TextArea } = Input;

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateIncomeMonthly: (val, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(val, who, verb))
    }
})
export default function IncomeCalculationModal(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {
        open, borrowerId, currentView, onCancel
    } = props
    const { Option } = Select
    const [pageWidth, setPageWidth] = useState(0)
    const [expand, setExpand] = useState(false)
    const containerRef = useRef(null)
    const informationRef = useRef(null)
    const rightContainerRef = useRef(null)
    const manualChangeTimeout = useRef(null)
    const [currentPage, setCurrentPage] = useState(1)
    const isManualChange = useRef(false)
    const isLeftSyncing = useRef(false)
    const isAtBoundary = useRef(false)
    const isRightSyncing = useRef(false)
    const justSwitched = useRef(false)
    const [visiblePages, setVisiblePages] = useState([])
    const [step, setStep] = useState(0)
    const [status, setStatus] = useState(['process', 'wait'])
    const [fileOptions, setFileOptions] = useState([])
    const [employmentInformation, setEmploymentInformation] = useState([])
    const [wagesList, setWagesList] = useState([])
    const [paystubs, setPaystubs] = useState([])
    const [grossEarnings, setGrossEarnings] = useState([])
    const [millitaryPersonnel, setMillitaryPersonnel] = useState([])
    const [selectIncome, setSelectIncome] = useState("")
    const [incomeCalculation, setIncomeCalculation] = useState([])
    const [frequency, setFrequency] = useState(undefined)
    const [pageNumber, setPageNumber] = useState(1)
    const [numPages, setNumPages] = useState(1)
    const [scale, setScale] = useState(1)
    const [selectFile, setSelectFile] = useState(null)
    const [file, setFile] = useState(null)
    const pageRefs = useRef([])
    const observerRef = useRef(null)
    const refs = useRef({})
    useEffect(() => {
        return () => {
            if (manualChangeTimeout.current) {
                clearTimeout(manualChangeTimeout.current)
            }
        }
    }, [])
    useEffect(() => {
        if (open && selectFile) {
            downloadAll([selectFile])
        }
    }, [open, selectFile])
    
    useLayoutEffect (() => {
        if (open) {
            const handleResize = () => {
                if (containerRef.current) {
                    setPageWidth(containerRef.current.offsetWidth - 24)
                }
            }
            setTimeout(handleResize, 0)
            window.addEventListener('resize', handleResize)
            containerRef.current.addEventListener('scroll', handleScroll)
    
            return () => {
                window.removeEventListener('resize', handleResize)
                containerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [open])
    useEffect(() => {
        if (open) {
            // mock data
            // const Information = [
            //     {
            //         "employmentInformation": [
            //             {
            //                 name: 'Employer name',
            //                 value: 'ABC inc.',
            //                 type: 'text',
            //             },
            //             {
            //                 name: 'Phone number',
            //                 value: '1231231234',
            //                 type: 'phone',
            //             },
            //             {
            //                 name: 'Start date',
            //                 value: '12/01/2023.',
            //                 type: 'date',
            //             },
            //             {
            //                 name: 'End date',
            //                 value: '12/01/2023',
            //                 type: 'date',
            //             }
            //         ],
            //         "w2s": [],
            //         "Paystubs": [
            //             {
            //                 "payPeriodStart": "09/20/2023",
            //                 "payPeriodEnd": "10/04/2024",
            //                 "details": [
            //                     {
            //                         "name": "Regular hours",
            //                         "1003Field": "base",
            //                         "thisPeriod": "",
            //                         "yearToDate": 1000
            //                     },
            //                     {
            //                         "name": "Holiday pay",
            //                         "1003Field": "bonus",
            //                         "thisPeriod": 1000,
            //                         "yearToDate": 1000
            //                     }
            //                 ],
            //                 "payperiod": "biWeekly",
            //                 "filename": "14.pdf",
            //                 "fileserialid": 695
            //             }
            //         ],
            //         "grossEarnings": [
            //             {
            //                 "name": "Base pay",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Overtime",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Bonus",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Commission",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Total",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 177515.98
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             }
            //         ],
            //         "millitaryPersonnel": [
            //             {
            //                 "name": "Base pay",
            //                 "amount": 1000
            //             },
            //             {
            //                 "name": "Rations",
            //                 "amount": 2000
            //             }
            //         ]
            //     },
            //     {
            //         "employmentInformation": [
            //             {
            //                 name: 'Employer name',
            //                 value: 'ABC inc.',
            //                 type: 'text',
            //             },
            //             {
            //                 name: 'Phone number',
            //                 value: '1231231234',
            //                 type: 'phone',
            //             },
            //             {
            //                 name: 'Start date',
            //                 value: '12/01/2023.',
            //                 type: 'date',
            //             },
            //             {
            //                 name: 'End date',
            //                 value: '12/01/2023',
            //                 type: 'date',
            //             }
            //         ],
            //         "w2s": [
            //             {
            //                 "year": '2023',
            //                 "amount": 1000,
            //                 "filename": "W2-subha-ebay-1of2 2020.pdf",
            //                 "fileserialid": 693
            //             },
            //             {
                            
            //                 "year": '2024',
            //                 "amount": 2000,
            //                 "filename": "W2-subha-ebay-1of2 2020.pdf",
            //                 "fileserialid": 699
            //             }
            //         ],
            //         "Paystubs": [
            //             {
            //                 "payPeriodStart": "09/20/2021",
            //                 "payPeriodEnd": "10/04/2021",
            //                 "details": [
            //                     {
            //                         "name": "Regular hours",
            //                         "1003Field": "Base",
            //                         "thisPeriod": 1000,
            //                         "yearToDate": 1000
            //                     },
            //                     {
            //                         "name": "Holiday pay",
            //                         "1003Field": "Bonus",
            //                         "thisPeriod": 1000,
            //                         "yearToDate": 1000
            //                     }
            //                 ],
            //                 "payperiod": "monthly",
            //                 "filename": "sample1.png",
            //                 "fileserialid": 670
            //             }
            //         ],
            //         "grossEarnings": [
            //             {
            //                 "name": "Base pay",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Overtime",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Bonus",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Commission",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 0.0
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             },
            //             {
            //                 "name": "Total",
            //                 "yearToDate": 0.0,
            //                 "details": [
            //                     {
            //                         "year": "2023",
            //                         "amount": 177515.98
            //                     },
            //                     {
            //                         "year": "2022",
            //                         "amount": 0.0
            //                     }
            //                 ]
            //             }
            //         ],
            //         "millitaryPersonnel": [
            //             {
            //                 "name": "Base pay",
            //                 "amount": 1000
            //             },
            //             {
            //                 "name": "Rations",
            //                 "amount": 2000
            //             }
            //         ]
            //     }
            // ]
            // const informationData = mergeInformation(Information)
            // const { employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel } = informationData[0]
            // formatIncomedocuments(employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel)
            // const testIncome = {
            //     "employer_name": "abc inc",
            //     "w2_calculations": [],
            //     "paystub_ytd_calculation": {
            //         "amount": {
            //             "base": 2373.2743718592965,
            //             "overtime": 12.627135678391962,
            //             "bonus": 0,
            //             "commission": 0,
            //             "other": 2.261306532663317
            //         },
            //         "method": "Most recent paystub YTD divided by months elapsed",
            //         "source_docs": [
            //             "14.pdf"
            //         ],
            //         "calculation_details": "Base YTD $15,742.72 / 6.6 months = $2,373.27 monthly\nOvertime: Holiday + Over Time YTD: $83.76\nOther: In-Service YTD: $15.00"
            //     },
            //     "paystub_average_calculation": {
            //         "amount": {
            //             "base": 2774.026666666666,
            //             "overtime": 5.199999999999999,
            //             "bonus": 0,
            //             "commission": 0,
            //             "other": 0
            //         },
            //         "method": "Average of 1 paystubs (weekly frequency)",
            //         "source_docs": [
            //             "14.pdf"
            //         ],
            //         "calculation_details": "Base: Average $640.16 × (52/12) = $2,774.03\nOvertime: Average $1.20 × (52/12) = $5.20"
            //     },
            //     "form1005_calculation": null,
            //     "recommended_monthly_income": {
            //         "base": 2373.2743718592965,
            //         "overtime": 12.627135678391962,
            //         "bonus": 0,
            //         "commission": 0,
            //         "other": 2.261306532663317
            //     },
            //     "recommendation_reason": "Using YTD calculation (no W2s available)"
            // }
            // formatCalculateincome(testIncome)
            // mock end
            getIncomedocuments()
            getCalculateincome()
            setCurrentPage(1)
        }
    }, [open])
    const formatIncomedocuments = (employmentInformation, w2s, paystubs, grossEarnings, millitaryPersonnel) => {
        setEmploymentInformation(employmentInformation)
        setWagesList(w2s)
        setPaystubs(paystubs)
        const transformedGrossEarnings = grossEarnings.map(({ details, ...rest }) => {
            const detailsObject = details.reduce((acc, detail) => {
                acc[detail.year] = detail.amount
                return acc
            }, {})
    
            return {
                ...rest,
                ...detailsObject
            }
        })
        setGrossEarnings(transformedGrossEarnings)
        setMillitaryPersonnel(millitaryPersonnel)
        const fileList = [
            ...w2s.map((file, index) => ({
                key: `w2s-${index}`,
                label: file.filename,
                value: file.fileserialid
            })),
            ...paystubs.map((file, index) => ({
                key: `paystubs-${index}`,
                label: file.filename,
                value: file.fileserialid
            }))
        ]
        setFileOptions(fileList)
        if (fileList.length > 0) {
            setSelectFile(fileList[0].value)
        }
    }
    const formatCalculateincome = (data) => {
        const calculationKeys = [
            { key: "w2_calculations", name: "W-2s" },
            { key: "paystub_ytd_calculation", name: "Paystubs" },
            { key: "paystub_average_calculation", name: "Paystubs" },
            { key: "form1005_calculation", name: "VOE" }
        ];
        
        const getEmptyStructure = () => ({
            amount: {
                base: "",
                overtime: "",
                bonus: "",
                commission: "",
                other: ""
            },
            method: "",
            source_docs: [],
            calculation_details: ""
        });
        
        const result = calculationKeys.map((item, index) => {
            const calculation = data[item.key] || null
            const processedCalculation = calculation
                ? { ...getEmptyStructure(), ...calculation }
                : getEmptyStructure();
            return {
                id: index,
                name: item.name,
                ...processedCalculation
            }
        })
        const emp = currentView === "borrower" ? props.application.borrower.occupation : props.application.coborrower.occupation
        result.push({
            id: result.length,
            name: "Current 1003",
            amount: {
                base: emp.selfemployed ? (emp.incomeorlossperiod === "monthly" ? emp.incomeorloss : emp.incomeorloss / 12) : (emp.income.basemonthly === 'monthly' ? emp.income.base : emp.income.base / 12),
                overtime: emp.income.overtimemonthly === "monthly" ? emp.income.overtime : emp.income.overtime / 12,
                bonus: emp.income.bonusesmonthly === "monthly" ? emp.income.bonuses : emp.income.bonuses / 12,
                commission: emp.income.commissionsmonthly === "monthly" ? emp.income.commissions : emp.income.commissions / 12,
                other: emp.income.othermonthly === "monthly" ? emp.income.other : emp.income.other / 12
            },
            method: "",
            source_docs: [],
            calculation_details: ""
        })
        setIncomeCalculation(result)
    }
    const mergeInformation = (data) => {
        if (data.length === 0) return []

        const mergedData = {
            employmentInformation: [],
            w2s: [],
            Paystubs: [],
            grossEarnings: [],
            millitaryPersonnel: []
        }
        const mergeAndDeduplicate = (array, key) => {
            const map = new Map()
            array.forEach(item => {
                if (!map.has(item[key])) {
                    map.set(item[key], item)
                }
            })
            return Array.from(map.values())
        }
        data.forEach(item => {
            mergedData.employmentInformation = mergedData.employmentInformation.concat(item.employmentInformation || [])
            mergedData.w2s = mergedData.w2s.concat(item.w2s || [])
            mergedData.Paystubs = mergedData.Paystubs.concat(item.Paystubs || [])
            mergedData.grossEarnings = mergedData.grossEarnings.concat(item.grossEarnings || [])
            mergedData.millitaryPersonnel = mergedData.millitaryPersonnel.concat(item.millitaryPersonnel || [])
        })
        mergedData.employmentInformation = mergeAndDeduplicate(mergedData.employmentInformation, "name")
        mergedData.grossEarnings = mergeAndDeduplicate(mergedData.grossEarnings, "name")
        mergedData.millitaryPersonnel = mergeAndDeduplicate(mergedData.millitaryPersonnel, "name")

        return [mergedData]
    }
    const getIncomedocuments = () => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        fetch('/los/getincomedocuments', {
            body: JSON.stringify({ loan_id: paths[paths.length - 1] }),
            method: 'POST',
            headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    return
                }
                response.json().then(js => {
                    const information = mergeInformation(js.parsed_data.Information)
                    if (information.length > 0) {
                        const { employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel } = information[0]
                        formatIncomedocuments(employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel)
                    }
                })
            }
        ).catch(function (err) {
            
        })
    }
    const getCalculateincome = () => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        fetch('/los/calculateincome', {
            body: JSON.stringify({ loan_id: paths[paths.length - 1] }),
            method: 'POST',
            headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    return
                }
                response.json().then(js => {
                    const parsed_data = js.parsed_data
                    const dynamicKey = Object.keys(parsed_data)[0]
                    const employerIncomes = parsed_data[dynamicKey]?.employer_incomes;
                    let incomeData = employerIncomes[0]
                    if (Object.keys(parsed_data).length > 0) {
                        for (let i=1; i< Object.keys(parsed_data).length; i++) {
                            let incomeToMerge = parsed_data[Object.keys(parsed_data)[i]].employer_incomes[0]
                            console.log(incomeToMerge)
                            for (let k of ["w2_calculations", "paystub_ytd_calculation", "paystub_average_calculation", "form1005_calculation"]) {
                                if ((incomeData[k] === null || incomeData[k].length === 0) && incomeToMerge[k]) {
                                    if (k === "w2_calculations") {
                                        incomeData[k] = incomeToMerge[k][0]
                                    } else {
                                        incomeData[k] = incomeToMerge[k]
                                    }
                                }
                            }
                        }
                        if (incomeData["w2_calculations"] && Array.isArray(incomeData["w2_calculations"])) {
                            incomeData["w2_calculations"] = incomeData["w2_calculations"][0]
                        }
                        formatCalculateincome(incomeData)
                    }
                })
            }
        ).catch(function (err) {
            
        })
    }
    const downloadAll = (ids) => {

        let token = sessionStorage.getItem("ZeitroA")
        // this.setState({ loading: true })

        fetch('/los/downloadalldocs', {
            method: 'POST',
            body: JSON.stringify({ BorrowerID: borrowerId, DocumentIDs: ids }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(async response => {
            // this.setState({ loading: false })
            if (response.status !== 200) {
                return;
            }

            await response.json().then(js => {
                let files = js.Documents
                setFile(base64ToArrayBuffer(files[0].Content))
            })
        }).catch(() => {
            // this.setState({ loading: false })
        })
    }
    const previewCancel = () => {
        setExpand(false)
        setStep(0)
        setStatus(['process', 'wait'])
        setSelectIncome("")
        onCancel()
        if (informationRef.current) {
            informationRef.current.scrollTop = 0
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setCurrentPage(1)
        setNumPages(numPages)
        setVisiblePages([1])
    }
    const createObserver = useCallback((entries) => {
        entries.forEach((entry) => {
            const pageNum = parseInt(entry.target.dataset.page, 10)
            if (entry.isIntersecting) {
                setVisiblePages((prevPages) => {
                    if (!prevPages.includes(pageNum)) {
                        return [...prevPages, pageNum]
                    }
                    return prevPages
                })
            }
        })
    }, [])

    useEffect(() => {
        observerRef.current = new IntersectionObserver(createObserver, {
            root: containerRef.current,
            rootMargin: '0px',
            threshold: 0.1
        })

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        }
    }, [createObserver])
    useEffect(() => {
        if (pageRefs.current.length && observerRef.current) {
            pageRefs.current.forEach((pageRef) => {
                if (pageRef) observerRef.current.observe(pageRef)
            })
        }

        return () => {
            if (observerRef.current) {
                pageRefs.current.forEach((pageRef) => {
                    if (pageRef) observerRef.current.unobserve(pageRef)
                })
            }
        }
    }, [numPages, visiblePages])
    const onChangePage = (page) => {
        isManualChange.current = true

        if (page === 1 || page === numPages) {
            isAtBoundary.current = true
        } else {
            isAtBoundary.current = false
        }
    
        setCurrentPage(page)
        const pdfTargetElement = document.querySelector(`#pdfPage-${page}`)
    
        if (pdfTargetElement) {
            containerRef.current.scrollTo({
                top: pdfTargetElement.offsetTop,
                behavior: 'smooth',
            })
        }
    
        if (manualChangeTimeout.current) {
            clearTimeout(manualChangeTimeout.current)
        }

        manualChangeTimeout.current = setTimeout(() => {
            isManualChange.current = false
        }, 500)
    }
    const resetScrollHandling = () => {
        containerRef.current.removeEventListener('scroll', resetScrollHandling)
        containerRef.current.addEventListener('scroll', handleScroll)
    }
    const handleScroll = () => {
        if (isManualChange.current || isLeftSyncing.current) return
        const scrollTop = containerRef.current.scrollTop
        const containerHeight = containerRef.current.offsetHeight
        const scrollHeight = containerRef.current.scrollHeight
    
        if (handleScroll.lastScrollTop === undefined) {
            handleScroll.lastScrollTop = 0
        }
        handleScroll.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    
        if (justSwitched.current) {
            return
        }
        
        if (isAtBoundary.current) {
            isAtBoundary.current = false
            return
        }
        const anchors = document.querySelectorAll('.pdf-anchor')
        let closestPage = currentPage
        let minDistance = Infinity
    
        anchors.forEach(anchor => {
            const { top } = anchor.getBoundingClientRect()
            const distance = Math.abs(top - containerHeight / 2)
            if (distance < minDistance) {
                minDistance = distance
                closestPage = parseInt(anchor.id.split('-')[1])
            }
        })
    
        if (closestPage !== currentPage) {
            setCurrentPage(closestPage)
        }
    
        setTimeout(() => {
            isLeftSyncing.current = false
        }, 100)
    }
    
    const changeZoom = (type) => {
        if (type === 'add') {
            setScale(+(Math.min(scale + 0.1, 3).toFixed(1)))
        } else {
            setScale(+(Math.max(scale - 0.1, 0.5).toFixed(1)))
        }
    }
    const removeFileExtension = (fileName) => {
        return fileName && fileName.replace(/\.[^/.]+$/, "")
    }
    const arrayBufferToBlob = (buffer, type) => {
        return new Blob([buffer], { type })
    }
    
    const createImageURLFromBase64 = (arrayBuffer, type) => {
        const blob = arrayBufferToBlob(arrayBuffer, type)
        return URL.createObjectURL(blob)
    }
    const changeFile = (type) => {
        const currentIndex = getIndexByValue(selectFile)
        if (type === 'prev' && currentIndex > 0) {
            setSelectFile(fileOptions[currentIndex - 1].value)
            refs.current[fileOptions[currentIndex - 1].value].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        } else if (type === 'next' && currentIndex >= 0 && currentIndex < fileOptions.length - 1) {
            setSelectFile(fileOptions[currentIndex + 1].value)
            refs.current[fileOptions[currentIndex + 1].value].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }
    const showDetails = () => {
        setExpand(!expand)
    }

    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }

    const handleInfoChange = (value, index, type) => {
        setEmploymentInformation((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    let updatedValue
                    if (type === 'date') {
                        updatedValue = value ? dayjs(value).format('MM/DD/YYYY') : null
                    } else if (type === 'phone') {
                        updatedValue = com.fixPhoneInput(value.target.value)
                    } else {
                        updatedValue = value.target.value
                    }
    
                    return {
                        ...item,
                        value: updatedValue,
                    }
                }
                return item
            })
        )
    }
    const handleW2Change = (value, index, field) => {
        setWagesList((prev) => {
            const updatedList = [...prev]
            if (field === 'year') {
                updatedList[index][field] = value ? dayjs(value).format('YYYY') : null
            } else if (field === 'amount') {
                updatedList[index][field] = value || ''
            }
            return updatedList
        })
    } 
    const stepChange = (value) => {
        const newStatus = status.map((s, index) =>
            index === value ? 'process' : index === 0 ? 'finish' : s
        )
        setStatus(newStatus)
        setStep(value)
    }
    const getStepIcon = (index) => {
        if (status[index] === 'finish') {
            return <img src="/images/income-step-success.svg" />
        }
        return null
    }
    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    }
    const onChangeFrequency = (value) => {
        setFrequency(value)
    }
    const handlePaystubs = (value, index, periodIndex, fieldName) => {
        setPaystubs((prevPaystubs) => {
            const updatedPaystubs = [...prevPaystubs]
            updatedPaystubs[index].details[periodIndex][fieldName] = value
            return updatedPaystubs
        })
    }
    const handleReset = (index) => {
        setPaystubs((prevPaystubs) => {
          const updatedPaystubs = [...prevPaystubs]
          updatedPaystubs[index].details = updatedPaystubs[index].details.map((detail) => ({
            ...detail,
            'name': detail.type === 'add' ? '' : detail.name,
            '1003Field': null,
            'thisPeriod': 0,
            'yearToDate': 0
          }))
          return updatedPaystubs
        })
    }

    const handleDeleteRow = (paystubIndex, rowIndex) => {
        setPaystubs((prevPaystubs) => {
          const updatedPaystubs = [...prevPaystubs]
          updatedPaystubs[paystubIndex].details.splice(rowIndex, 1)
          return updatedPaystubs
        })
    }
    const handleAddRow = (index) => {
        setPaystubs((prevPaystubs) => {
          const updatedPaystubs = [...prevPaystubs]
          updatedPaystubs[index].details.push({
            'name': '',
            'type': 'add',
            '1003Field': null,
            'thisPeriod': 0,
            'yearToDate': 0
          })
          return updatedPaystubs
        })
    }
    const handleGrossearnings = (value, periodIndex, key) => {
        setGrossEarnings(prevGrossEarnings => {
            const updatedGrossEarnings = [...prevGrossEarnings]
            updatedGrossEarnings[periodIndex][key] = value
            return updatedGrossEarnings
        })
    }
    const handleMillitaryPersonnel = (value, periodIndex) => {
        setMillitaryPersonnel(prevMillitaryPersonnel => {
            const updatedMillitaryPersonnel = [...prevMillitaryPersonnel]
            updatedMillitaryPersonnel[periodIndex].amount = value
            return updatedMillitaryPersonnel
        })
    }
    const handleDeleteGrossRow = (periodIndex) => {
        setGrossEarnings(prevGrossEarnings => {
            const updatedGrossEarnings = prevGrossEarnings.filter((_, index) => index !== periodIndex)
            return updatedGrossEarnings
        })
    }
    const onChangeIncomeCalculation = (e) => {
        setSelectIncome(e.target.value)
    }
    const getLabelByValue = (value) => {
        const option = fileOptions.find((item) => item.value === value)
        return option ? option.label : null
    }
    const frequencyOptions = [
        {
            label: 'Monthly',
            value: 'monthly',
        },
        {
            label: 'Bi-weekly',
            value: 'biWeekly',
        }
    ]
    const paystubsOptions = [
        {
            label: 'Base',
            value: 'Base',
        },
        {
            label: 'Overtime',
            value: 'Overtime',
        },
        {
            label: 'Bonus',
            value: 'Bonus',
        },
        {
            label: 'Commission',
            value: 'Commission',
        },
        {
            label: 'Other',
            value: 'Other',
        }
    ]
    const customHeader = (item) => {
        return <div className='income-calculation-collapse-header'>Pay period <span style={{fontWeight: 700}}>{item.payPeriodStart} - {item.payPeriodEnd}</span></div>
    }
    const formatIncome = (price) => {
        return <span>$ {price && price !== 0 ? com.commaize(price) : '-'} /month</span>
    }
    const getFileIcon = (fileName) => {
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)
        if (fileExtension === 'pdf') {
            return <img src="/images/pdf-icon.svg" />
        } else {
            return <img src="/images/img-icon.svg" />
        }
    }
    const handleFileChange = (value) => {
        setSelectFile(value)
        refs.current[value].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }
    const getIndexByValue = (value) => {
        return fileOptions.findIndex((item) => item.value === value)
    }

    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer
    }
    const update1003 = () => {
        const selected = incomeCalculation.find(item => item.id === selectIncome)
        props.updateIncomeMonthly(String(Math.floor(selected.amount.base || 0)), currentView, "base")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.overtime || 0)), currentView, "overtime")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.bonuses || 0)), currentView, "bonuses")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.commission || 0)), currentView, "commissions")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.other || 0)), currentView, "other")
        previewCancel()
    }
    const fileSelectDropdownContent = () => {
        return <Select
            style={{
                width: '314px',
            }}
            className='income-file-select'
            suffixIcon={<img src='/images/income-select-search.svg' />}
            placeholder="Document name"
            value={selectFile}
            onChange={handleFileChange}
        >
            {fileOptions.map(option => (
                <Option key={option.value} value={option.value}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {getFileIcon(option.label)}
                        <div style={{ marginLeft: 8, fontFamily: 'Inter', fontSize: 16, color: '#222', lineHeight: '20px' }}>{removeFileExtension(option.label)}</div>
                    </span>
                </Option>
            ))}
        </Select>
    }
    const footer = step === 0 ? [
        <Button className="income-btn-back" type='link'>Back</Button>,
        <Button className="income-btn-confirm" type="primary" onClick={() => stepChange(step + 1)}>Confirm</Button>
    ] : [
        <Button className="income-btn-back" type='link' onClick={() => stepChange(step - 1)}>Back</Button>,
        <div>
            {/* <Button className="income-btn-back" type='link' disabled={selectIncome === ''} onClick={update1003}>Update to 1003 only</Button> */}
            <Button className="income-btn-confirm" type="primary" disabled={selectIncome === ''} onClick={update1003}>Update to 1003</Button>
        </div>
    ]
    return (
        <>
            <Modal
                className={`income-calculation-modal check-list`}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                maskClosable={true}
                width='90%'
                open={open}
                title=""
                onCancel={previewCancel}
                footer={footer}
            >
                <div className='preview-modal-top'>Income calculation</div>
                <Row style={{flex: step === 0 ? 1 : 'none'}}>
                    <Col span={24}>
                        <div className='income-calculation-steps-wrapper'>
                            <Steps
                                className='income-calculation-steps'
                                size="small"
                                current={step}
                                onChange={stepChange}
                                items={[
                                    {
                                        title: 'Information',
                                        icon: getStepIcon(0),
                                    },
                                    {
                                        title: 'Calculation',
                                        icon: getStepIcon(1),
                                    }
                                ]}
                            />
                            {/* {step === 1 && <div style={{fontSize: 16, fontWeight: 600, color: '#325CEB', lineHeight: 'normal', display: 'flex', alignItems: 'center', gap: 12, cursor: 'pointer'}}><img style={{width: 22, height: 22}} src='/images/document-download-blue.svg'/> Download worksheet</div> } */}
                        </div>
                    </Col>
                    <Col style={{ display: step === 0 ? 'block' : 'none'}} span={12}>
                        <div style={{padding: '0 34px 24px 50px'}} ref={informationRef} className='income-calculation-content'>
                            <div className='income-item'>
                                <div className='income-item-title'>Employment information</div>
                                {
                                    employmentInformation.length > 0 ? (
                                        employmentInformation.map((item, index) => (
                                            <Row className='income-item-content' gutter={10} key={index}>
                                                <Col>
                                                    <div className='income-item-label'>{item.name}</div>
                                                </Col>
                                                <Col>
                                                    <div style={{width: 140}}>
                                                        {item.type === 'date' ? 
                                                            <DatePicker
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                                className='income-date'
                                                                disabledDate={disabledDate}
                                                                placeholder="MM/DD/YYYY"
                                                                format="MM/DD/YYYY"
                                                                suffixIcon={null}
                                                                value={item.value ? dayjs(item.value) : null}
                                                                onChange={(e) => handleInfoChange(e, index, item.type)}
                                                            />  : <Input
                                                                style={{
                                                                    width: '100%',
                                                                    fontFamily: 'Inter',
                                                                    height: 34,
                                                                    fontSize: 16,
                                                                    color: '#222',
                                                                    padding: '0 6px',
                                                                    borderRadius: '6px',
                                                                    backgroundColor: '#FCFCFC',
                                                                    border: '1px solid #ECECEC'
                                                                }}
                                                                value={item.type === 'phone' ? com.fixPhoneInput(item.value) : item.value}
                                                                onChange={(e) => handleInfoChange(e, index, item.type)}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    ) :(
                                        <div className='income-item-nodata'>No data has been detected.</div>
                                    )
                                }
                            </div>
                            <div className='income-item'>
                                <div className='income-item-title'>W-2</div>
                                {
                                    wagesList.length > 0 ? (
                                        wagesList.map((item, index) => (
                                            <Row 
                                                className='income-item-content' 
                                                gutter={10} 
                                                key={index}
                                                ref={(el) => (refs.current[item.fileserialid] = el)}
                                            >
                                                <Col>
                                                    <div className='income-item-label'>Wages</div>
                                                </Col>
                                                <Col>
                                                    <div style={{width: 140}}>
                                                        <DatePicker
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                            picker="year"
                                                            className='income-date wages-date'
                                                            suffixIcon={<img src="/images/income-date.svg" />}
                                                            value={item.year ? dayjs(item.year, 'YYYY') : null}
                                                            onChange={(e) => handleW2Change(e, index, 'year')}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{width: 140}}>
                                                        <InputNumber
                                                            style={{
                                                                width: '100%',
                                                                fontFamily: 'Inter',
                                                                height: 34,
                                                                fontSize: 16,
                                                                fontWeight: 400,
                                                                color: '#222',
                                                                padding: '0 6px',
                                                                borderRadius: '6px',
                                                                backgroundColor: '#FCFCFC',
                                                                border: '1px solid #ECECEC'
                                                            }}
                                                            className='income-inputNumber income-inputNumber-w2'
                                                            prefix="$"
                                                            min={0}
                                                            formatter={inputFormatter}
                                                            parser={inputParser}
                                                            value={item.amount}
                                                            onChange={(e) => handleW2Change(e, index, 'amount')}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <div className='income-item-nodata'>No data has been detected.</div>
                                    )
                                }
                            </div>
                            <div className='income-item'>
                                <div className='income-item-title'>Paystubs
                                    <Select
                                        style={{ width: 144, marginLeft: 20 }}
                                        className="income-select"
                                        placeholder="Select"
                                        allowClear
                                        value={frequency}
                                        onChange={onChangeFrequency}
                                        options={frequencyOptions}
                                        suffixIcon={<img src="/images/auto-reminder-select-arrow.svg" />}
                                    />
                                </div>
                                {
                                    paystubs.length > 0 ? (
                                        <Collapse className='income-calculation-collapse' collapsible="icon" expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />} expandIconPosition="end">
                                            {
                                                paystubs.filter(item => !frequency || item.payperiod === frequency).map((item, index) => {
                                                    const totalThisPeriod = item.details.reduce((sum, detail) => sum + (detail.thisPeriod || 0), 0)
                                                    const totalYearToDate = item.details.reduce((sum, detail) => sum + (detail.yearToDate || 0), 0)
                                                    return (
                                                        <Collapse.Panel className="income-calculation-collapse-item" ref={(el) => (refs.current[item.fileserialid] = el)} header={customHeader(item)} key={index}>
                                                            <div className="dti-calculator-card-wrap">
                                                                <div className='paystubs-table'>
                                                                    <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                                        <div className='paystubs-table-form'>
                                                                            <div style={{width: '23%', color: '#325CEB', cursor: 'pointer'}} onClick={() => handleReset(index)}>Reset all</div>
                                                                            <div style={{width: '31%'}}>1003 field</div>
                                                                            <div style={{width: '23%'}}>This period</div>
                                                                            <div style={{width: '23%'}}>Year to date</div>
                                                                        </div>
                                                                        <div className='paystubs-table-delete'></div>
                                                                    </div>
                                                                    {
                                                                        item.details.map((period, periodIndex) => (
                                                                            <div style={{marginBottom: 16}} className='paystubs-table-row' key={periodIndex}>
                                                                                <div className='paystubs-table-form'>
                                                                        
                                                                                    <div style={{width: '23%'}}>
                                                                                        {
                                                                                            period.type === 'add' ? (
                                                                                                <AutoComplete
                                                                                                    className="income-select"
                                                                                                    // options={servicesOptions}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: 34
                                                                                                    }}
                                                                                                    value={period.name}
                                                                                                    placeholder="Text"
                                                                                                    filterOption={(inputValue, option) =>
                                                                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                                                    }
                                                                                                    onChange={(e) => handlePaystubs(e, index, periodIndex, 'name')}
                                                                                                />
                                                                                            ) : (
                                                                                                <span>{period.name}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{width: '31%'}}>
                                                                                        <Select
                                                                                            style={{ width: '100%' }}
                                                                                            className="income-select"
                                                                                            value={period['1003Field']}
                                                                                            onChange={(e) => handlePaystubs(e, index, periodIndex, '1003Field')}
                                                                                            options={paystubsOptions}
                                                                                            placeholder='Options'
                                                                                            suffixIcon={<img src="/images/auto-reminder-select-arrow.svg" />}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '23%'}}>
                                                                                        <InputNumber
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                fontFamily: 'Inter',
                                                                                                height: 34,
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                color: '#222',
                                                                                                padding: '0 6px',
                                                                                                borderRadius: '6px',
                                                                                                backgroundColor: '#FCFCFC',
                                                                                                border: '1px solid #ECECEC'
                                                                                            }}
                                                                                            className='income-inputNumber'
                                                                                            prefix="$"
                                                                                            min={0}
                                                                                            formatter={inputFormatter}
                                                                                            parser={inputParser}
                                                                                            value={period.thisPeriod}
                                                                                            onChange={(e) => handlePaystubs(e, index, periodIndex, 'thisPeriod')}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '23%'}}>
                                                                                        <InputNumber
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                fontFamily: 'Inter',
                                                                                                height: 34,
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                color: '#222',
                                                                                                padding: '0 6px',
                                                                                                borderRadius: '6px',
                                                                                                backgroundColor: '#FCFCFC',
                                                                                                border: '1px solid #ECECEC'
                                                                                            }}
                                                                                            className='income-inputNumber'
                                                                                            prefix="$"
                                                                                            min={0}
                                                                                            formatter={inputFormatter}
                                                                                            parser={inputParser}
                                                                                            value={period.yearToDate}
                                                                                            onChange={(e) => handlePaystubs(e, index, periodIndex, 'yearToDate')}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='paystubs-table-delete'><img style={{cursor: 'pointer'}} onClick={() => handleDeleteRow(index, periodIndex)} src='/images/delete.svg'/></div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                                        <div className='paystubs-table-form'>
                                                                            <div style={{width: '23%', color: '#222'}}>Total</div>
                                                                            <div style={{width: '31%'}}></div>
                                                                            <div style={{width: '23%', color: '#222'}}>$ {com.commaizeFloat(totalThisPeriod)}</div>
                                                                            <div style={{width: '23%', color: '#222'}}>$ {com.commaizeFloat(totalYearToDate)}</div>
                                                                        </div>
                                                                        <div className='paystubs-table-delete'></div>
                                                                    </div>
                                                                    <div className='paystubs-table-add'>
                                                                        <div className='paystubs-table-add-btn' onClick={() => handleAddRow(index)}><img src='/images/add.svg'/><div>Add</div></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Collapse.Panel>
                                                    )   
                                                })
                                            }
                                        </Collapse>
                                    ) : (
                                        <div className='income-item-nodata'>No data has been detected.</div>
                                    )
                                }
                                
                            </div>
                            <div className='income-item'>
                                <div className='income-item-title'>Verification of Employement (Form 1005)</div>
                                <div className='income-item-subtitle'>Gross earnings</div>
                                {
                                    grossEarnings.length > 0 ? (
                                        <div style={{paddingRight: 26}} className='paystubs-table'>
                                            <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                <div className='paystubs-table-form'>
                                                    <div style={{width: '25%'}}></div>
                                                    <div style={{width: '25%'}}>Year to date</div>
                                                    {
                                                        grossEarnings.length > 0 && Object.keys(grossEarnings[0]).filter(key => key !== 'name' && key !== 'yearToDate' && key !== 'filename' && key !== 'fileserialid').map((key, index) => (
                                                            <div key={index} style={{ width: '23%' }}>{key}</div>
                                                        ))
                                                    }
                                                </div>
                                                <div className='paystubs-table-delete'></div>
                                            </div>
                                            {
                                                grossEarnings.filter(item => item.name !== 'Total').map((period, periodIndex) => {
                                                    return (
                                                        <div style={{marginBottom: 16}} className='paystubs-table-row' key={periodIndex}>
                                                            <div className='paystubs-table-form'>
                                                                <div style={{width: '25%'}}>{period.name}</div>
                                                                {
                                                                    Object.keys(period).filter(key => key !== 'name' && key !== 'filename' && key !== 'fileserialid').map((key, keyIndex) => (
                                                                        <div key={keyIndex} style={{ width: '25%' }}>
                                                                            <InputNumber
                                                                                style={{
                                                                                    width: '100%',
                                                                                    fontFamily: 'Inter',
                                                                                    height: 34,
                                                                                    fontSize: 14,
                                                                                    fontWeight: 400,
                                                                                    color: '#222',
                                                                                    padding: '0 6px',
                                                                                    borderRadius: '6px',
                                                                                    backgroundColor: '#FCFCFC',
                                                                                    border: '1px solid #ECECEC'
                                                                                }}
                                                                                className='income-inputNumber'
                                                                                prefix="$"
                                                                                min={0}
                                                                                formatter={inputFormatter}
                                                                                parser={inputParser}
                                                                                value={period[key]}
                                                                                onChange={(e) => handleGrossearnings(e, periodIndex, key)}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='paystubs-table-delete'><img style={{cursor: 'pointer'}} onClick={() => handleDeleteGrossRow(periodIndex)} src='/images/delete.svg'/></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                <div className='paystubs-table-form'>
                                                    <div style={{width: '25%', color: '#222'}}>Total</div>
                                                    {
                                                        grossEarnings.length > 0 && Object.keys(grossEarnings[0]).filter(key => key !== 'name' && key !== 'filename' && key !== 'fileserialid').map((key, keyIndex) => (
                                                            <div key={keyIndex} style={{ width: '23%', color: '#222'}}>
                                                                $ {com.commaizeFloat(grossEarnings.filter(item => item.name !== 'Total').reduce((sum, period) => sum + (period[key] || 0), 0))}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className='paystubs-table-delete'></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='income-item-nodata'>No data has been detected.</div>
                                    )
                                }
                            </div>
                            <div className='income-item'>
                                <div className='income-item-subtitle'>For millitary personnel</div>
                                {
                                    millitaryPersonnel.length > 0 ? (
                                        <>
                                            {
                                                millitaryPersonnel.map((item, index) => (
                                                    <Row className='income-item-content' gutter={10} key={index}>
                                                        <Col>
                                                            <div className='income-item-label'>{item.name}</div>
                                                        </Col>
                                                        <Col>
                                                            <div style={{width: 140}}>
                                                                <InputNumber
                                                                    style={{
                                                                        width: '100%',
                                                                        fontFamily: 'Inter',
                                                                        height: 34,
                                                                        fontSize: 14,
                                                                        fontWeight: 400,
                                                                        color: '#222',
                                                                        padding: '0 6px',
                                                                        borderRadius: '6px',
                                                                        backgroundColor: '#FCFCFC',
                                                                        border: '1px solid #ECECEC'
                                                                    }}
                                                                    className='income-inputNumber'
                                                                    prefix="$"
                                                                    min={0}
                                                                    formatter={inputFormatter}
                                                                    parser={inputParser}
                                                                    value={item.amount}
                                                                    onChange={(e) => handleMillitaryPersonnel(e, index)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))
                                            }
                                            <div style={{ height: 34, fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center', gap: 10 }}>
                                                <div style={{width: 128}}>Total</div>
                                                <div>$ {com.commaizeFloat(millitaryPersonnel.reduce((sum, period) => sum + (period.amount || 0), 0))}</div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='income-item-nodata'>No data has been detected.</div>
                                    )
                                }
                            </div>
                        </div>
                    </Col>
                    <Col style={{position: 'relative', display: step === 0 ? 'block' : 'none'}} span={12}>
                        <div className='income-preview-wrap'>
                            <div style={{display: 'flex', justifyContent: 'center', gap: 8, marginBottom: 12}}>
                                <div className='income-calculation-change-file'>
                                    <img className={`prev-file ${getIndexByValue(selectFile) <= 0 && 'arrow-disabled'}`} src={`/images/document-arrow-left${getIndexByValue(selectFile) <= 0 ? '-disabled' : ''}.svg`} onClick={() => changeFile('prev')}/>
                                    <div className='flie-total'>
                                        File <span>{getIndexByValue(selectFile) > -1 ? (getIndexByValue(selectFile) + 1) : 0} / {fileOptions.length}</span>
                                    </div>
                                    <div className='separate'></div>
                                    <div className='file-name'>
                                        {selectFile && selectFile !== '' && <><img src={ getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf' ? '/images/pdf-icon.svg' : '/images/img-icon.svg'}/><div>{removeFileExtension(getLabelByValue(selectFile))}</div></>}
                                    </div>
                                    <img className={`next-file ${(getIndexByValue(selectFile) < 0 || (getIndexByValue(selectFile) === fileOptions.length - 1)) && 'arrow-disabled'}`} src={`/images/document-arrow-right${(getIndexByValue(selectFile) < 0 || (getIndexByValue(selectFile) === fileOptions.length - 1)) ? '-disabled' : ''}.svg`} onClick={() => changeFile('next')}/>
                                </div>
                                <Dropdown
                                    trigger={['click']}
                                    dropdownRender={fileSelectDropdownContent}
                                    placement="bottomRight"
                                >
                                    <div className='income-calculation-select-file'><img src='/images/income-file.svg'/></div>
                                </Dropdown>
                            </div>
                            <div className='document-preview-content' ref={containerRef} onScroll={handleScroll}>
                                <div style={{ width: pageWidth * scale }} className='preview-box'>
                                    {
                                        selectFile && (getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf') ? (
                                            <Document error={<Skeleton active />} loading={<Skeleton active />} file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                {Array.from(new Array(numPages), (_, index) => (
                                                    <div
                                                        id={`pdfPage-${index + 1}`}
                                                        key={`page_${index + 1}`}
                                                        data-page={index + 1}
                                                        ref={(ref) => (pageRefs.current[index] = ref)}
                                                        style={{ width: pageWidth * scale }}
                                                        className="pdf-anchor"
                                                    >
                                                        {visiblePages.includes(index + 1) ? (
                                                            <Page
                                                                loading={<Skeleton active />}
                                                                renderAnnotationLayer={false}
                                                                pageNumber={index + 1}
                                                                width={pageWidth * scale}
                                                            />
                                                        ) : (
                                                            <Skeleton active />
                                                        )}
                                                    </div>
                                                ))}
                                            </Document>
                                        ) : (
                                            selectFile && <Image
                                                preview={false}
                                                width={pageWidth * scale}
                                                src={createImageURLFromBase64(file, `image/${getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1)}` )}
                                            />
                                        )

                                    }
                                </div>
                            </div>
                            <div className='document-preview-bottom-bar'>
                                <div className="document-preview-pagination">
                                    <Pagination size='small' simple current={selectFile && (getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf') ? currentPage : 1} pageSize={1} total={selectFile && (getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf') ? numPages : 1} onChange={onChangePage} />
                                </div>
                                <div className="document-preview-scale">
                                    <div className='zoom-min'><MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} /></div>
                                    <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                    <div className='zoom-add'><PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} /></div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col style={{display: step === 1 ? 'block' : 'none'}} span={24}>
                        <div style={{padding: '0 16px 16px'}} className='income-calculation-content'>
                            <div style={{marginBottom: 10, paddingLeft: 30}} className='income-item-title'>Select income calculation</div>
                            <div style={{fontFamily: 'Inter', fontSize: 16, lineHeight: '20px', color: '#6E6E70', fontWeight: 500, paddingLeft: 30}}>Review the methods below and select an income calculation result to apply to Form 1003.</div>
                            <Radio.Group onChange={onChangeIncomeCalculation} value={selectIncome}>
                                <div className='income-calculation-list'>
                                    <div className='income-calculation-label'>
                                        <div>Base</div>
                                        <div>Overtime</div>
                                        <div>Bonus</div>
                                        <div>Commission</div>
                                        <div>Military Entitlements</div>
                                        <div>Other</div>
                                        <div style={{color: '#222', fontWeight: 700}}>Totals / Monthly</div>
                                        <div style={{color: '#222', fontWeight: 700}}>DTI</div>
                                    </div>
                                    <div className='income-calculation-select'>
                                        {
                                            incomeCalculation.map((item, index) => {
                                                const total = Object.values(item.amount).reduce((sum, value) => sum + (isNaN(Number(value)) ? 0 : Number(value)), 0)
                                                return (
                                                    <label key={item.id} className={`income-calculation-item ${item.id === selectIncome ? 'income-calculation-item-selected' : ''}`}>
                                                        <div>
                                                            <div className='income-calculation-item-name'>{index !== incomeCalculation.length - 1 ? <Radio className='income-calculation-radio' value={item.id}></Radio> : <span style={{display: 'inline-block', width: 26}}></span>}{item.name}</div>
                                                            <div className='income-calculation-item-description'>{item.method}</div>
                                                        </div>
                                                        <div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.base)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.overtime)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.bonus)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.commission)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.militaryEntitlements)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.other)}</div>
                                                            <div className='income-calculation-item-value bold-value'>{formatIncome(total)}</div>
                                                            {/* <div className='income-calculation-item-value bold-value'>{item.DTI} / {item.frontEndDTI}</div> */}
                                                            <div className='income-calculation-item-value bold-value'>- / -</div>
                                                        </div>
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
IncomeCalculationModal = connect(mapStateToProps, mapDispatchToProps)(IncomeCalculationModal)